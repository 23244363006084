import { Button as k, Pressable as C, useTheme as I, Menu as F, View as a, useClipboard as Y, PresenceTransition as _, useBreakpointValue as g, Text as h, useColorModeValue as xe, ScrollView as Q, KeyboardAvoidingView as X, Box as J, Input as K, Slider as T, Radio as Ce, useColorMode as ve, Avatar as Ie, Image as _e, theme as Se, Center as Re, HStack as M, Alert as O, VStack as Z, Tooltip as Ne } from "native-base";
import H, { useMemo as y, memo as c, useState as x, useCallback as f, useEffect as R, forwardRef as E, useImperativeHandle as Te, useLayoutEffect as ee, createRef as Be, useRef as Ae } from "react";
import { jsx as e, jsxs as u } from "react/jsx-runtime";
import { h as Me, g as te, c as oe, b as ne, I as ze, a as De, f as ie, e as re, d as ke } from "./IconPlus-crg3RR_M.js";
import "react-native-svg";
import { StyleSheet as S, TouchableOpacity as Fe, View as le, ActivityIndicator as He, Text as se, useWindowDimensions as ae, TextInput as Ee } from "react-native";
import { unstable_createElement as Pe } from "react-native-web";
import { format as ce, parse as We } from "date-fns";
import { ru as je } from "date-fns/locale/ru";
import { formatAvatarShortName as Ve, makeThumbnails as de, formatBackendError as Le } from "@sign/shared/lib";
import { makeGravatarUri as Oe } from "@sign/shared/api";
import * as $e from "@sentry/react";
import { useUnit as ue } from "effector-react";
import "effector";
import { $traceId as he } from "@sign/shared/model";
import { useTranslation as P } from "react-i18next";
import $ from "@emoji-mart/data";
import U from "classnames";
const no = "✋", G = /* @__PURE__ */ H.memo(({
  bg: t,
  children: o,
  disabled: i,
  loading: n,
  submit: l,
  variant: r = "default",
  ...s
}) => {
  const d = y(() => {
    if (t != null)
      return t;
    switch (r) {
      case "danger":
        return "red.50";
      case "primary":
        return i ? "blue.200" : "blue.50";
      case "secondary":
        return i ? "rgba(51, 51, 67, 0.10)" : "rgba(61, 61, 77, 0.25)";
      default:
        return i ? "gray.200" : "gray.50";
    }
  }, [t, i, r]), p = y(() => {
    switch (r) {
      case "danger":
        return "red.100";
      case "primary":
        return i ? "blue.200" : "blue.100";
      case "secondary":
        return i ? "rgba(51, 51, 67, 0.10)" : "rgba(61, 61, 77, 0.35)";
      default:
        return i ? "gray.200" : "gray.100";
    }
  }, [i, r]);
  return /* @__PURE__ */ e(k, {
    _disabled: {
      opacity: 0.4
    },
    _focus: {
      bg: p
    },
    _hover: {
      bg: p
    },
    _pressed: {
      bg: p
    },
    alignItems: "center",
    bg: d,
    borderRadius: "24",
    disabled: i,
    height: "12",
    isLoading: n,
    justifyContent: "center",
    position: "relative",
    px: "6",
    ...s,
    children: o
  });
}), Ue = /* @__PURE__ */ c((t) => /* @__PURE__ */ e(C, {
  alignItems: "center",
  bg: "rgba(0, 0, 0, 0.64)",
  borderRadius: "32",
  h: "32px",
  justifyContent: "center",
  ml: "auto",
  w: "32px",
  ...t
}));
Ue.displayName = "Button32";
const Ge = /* @__PURE__ */ c((t) => /* @__PURE__ */ e(k, {
  _focus: {
    bg: "white"
  },
  _hover: {
    bg: "white"
  },
  _pressed: {
    bg: "white"
  },
  bg: "white",
  borderRadius: "32",
  bottom: {
    base: 4,
    lg: 6
  },
  h: "64px",
  onPress: t.onPress,
  position: "absolute",
  right: {
    base: 4,
    lg: 6
  },
  shadow: "1",
  w: "64px",
  zIndex: "25",
  children: /* @__PURE__ */ e(Me, {
    height: "40",
    width: "40"
  })
}));
Ge.displayName = "ButtonAddRoom";
const qe = /* @__PURE__ */ c(({
  children: t
}) => {
  const o = I();
  return /* @__PURE__ */ e(F, {
    placement: "bottom right",
    trigger: (i, n) => /* @__PURE__ */ e(C, {
      alignItems: "center",
      bg: n.open ? o.colors.gray[300] : "white",
      borderRadius: 20,
      height: 10,
      justifyContent: "center",
      marginRight: -4,
      marginTop: -2,
      width: 10,
      ...i,
      children: /* @__PURE__ */ e(te, {
        fill: n.open ? o.colors.gray[500] : o.colors.gray[400],
        height: 20,
        width: 20
      })
    }),
    children: t
  });
});
qe.displayName = "CardMenu";
const Ye = /* @__PURE__ */ c((t) => /* @__PURE__ */ e(a, {
  pl: {
    base: 6,
    lg: 104
  },
  pr: {
    base: 6,
    lg: 128
  },
  ...t
}));
Ye.displayName = "CenteredFormContainer";
const Qe = /* @__PURE__ */ c(({
  h: t = "56px",
  textForCopy: o,
  w: i = "56px",
  ...n
}) => {
  const l = I(), [r, s] = x(!1), {
    onCopy: d
  } = Y(), p = f(() => {
    r || (d(o), s(!0));
  }, [r, d, o]);
  R(() => {
    setTimeout(() => {
      s(!1);
    }, 2500);
  }, [r]);
  const m = y(() => ({
    borderRadius: 12,
    height: "100%",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%"
  }), []);
  return /* @__PURE__ */ u(C, {
    alignItems: "center",
    borderRadius: "12",
    h: t,
    justifyContent: "center",
    onPress: p,
    w: i,
    ...n,
    children: [/* @__PURE__ */ e(oe, {
      fill: l.colors.gray[400],
      height: "24",
      width: "24"
    }), /* @__PURE__ */ e(_, {
      animate: {
        opacity: 1,
        transition: {
          duration: 250
        }
      },
      initial: {
        opacity: 0
      },
      style: m,
      visible: r,
      children: /* @__PURE__ */ e(a, {
        alignItems: "center",
        bg: "white",
        borderRadius: "12",
        flexDirection: "row",
        h: t,
        justifyContent: "center",
        left: 0,
        position: "absolute",
        top: 0,
        w: i,
        children: /* @__PURE__ */ e(ne, {
          fill: l.colors.green[50],
          height: 24,
          width: 24
        })
      })
    })]
  });
});
Qe.displayName = "CopyRoom";
const ge = /* @__PURE__ */ c(({
  children: t,
  mobileView: o = "compact",
  textForCopy: i,
  view: n
}) => {
  var N;
  const l = I(), [r, s] = x(!1), {
    onCopy: d
  } = Y(), p = f(() => {
    r || (d(i), s(!0));
  }, [r, d, i]);
  R(() => {
    setTimeout(() => {
      s(!1);
    }, 2500);
  }, [r]);
  const m = y(() => ({
    borderRadius: 24,
    height: "100%",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%"
  }), []), b = g({
    base: 3.5,
    lg: 6
  }), w = g({
    base: o === "compact" ? "none" : "flex",
    lg: "flex"
  });
  return /* @__PURE__ */ u(C, {
    borderRadius: "24",
    height: "12",
    onPress: p,
    position: "relative",
    children: [/* @__PURE__ */ u(a, {
      alignItems: "center",
      bg: "gray.50",
      borderRadius: "24",
      flexDirection: "row",
      height: "12",
      justifyContent: "center",
      pl: b,
      pr: b,
      ...n,
      children: [/* @__PURE__ */ e(a, {
        display: w,
        mr: "2",
        children: t
      }), /* @__PURE__ */ e(oe, {
        fill: ((N = n == null ? void 0 : n.color) == null ? void 0 : N.toString()) ?? l.colors.gray[400],
        height: "20",
        width: "20"
      })]
    }), /* @__PURE__ */ e(_, {
      animate: {
        opacity: 1,
        transition: {
          duration: 250
        }
      },
      initial: {
        opacity: 0
      },
      style: m,
      visible: r,
      children: /* @__PURE__ */ u(a, {
        alignItems: "center",
        bg: "white",
        borderRadius: "24",
        flexDirection: "row",
        height: 12,
        justifyContent: "center",
        left: 0,
        position: "absolute",
        shadow: "1",
        top: 0,
        width: "100%",
        children: [/* @__PURE__ */ e(h, {
          color: "black",
          display: w,
          fontSize: "14",
          fontWeight: "500",
          mr: "2",
          children: "Скопировано"
        }), /* @__PURE__ */ e(ne, {
          fill: l.colors.green[50],
          height: 20,
          width: 20
        })]
      })
    })]
  });
});
ge.displayName = "CopyRoomNumber";
const B = S.create({
  button: {
    alignItems: "center",
    backgroundColor: "#8bc34a",
    borderColor: "#666",
    borderRadius: 10,
    borderWidth: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    paddingHorizontal: 10,
    paddingVertical: 5
  },
  buttonLoading: {
    backgroundColor: "#4caf50"
  },
  buttonText: {
    color: "#fff",
    fontSize: 20,
    fontWeight: "bold"
  }
}), Xe = ({
  loading: t,
  title: o,
  ...i
}) => /* @__PURE__ */ e(Fe, {
  ...i,
  children: /* @__PURE__ */ u(
    le,
    {
      style: [B.button, t && B.buttonLoading],
      children: [t && /* @__PURE__ */ e(He, {
        color: "yellow",
        size: "large"
      }), /* @__PURE__ */ e(se, {
        style: B.buttonText,
        children: o
      })]
    }
  )
});
Xe.displayName = "CustomButton";
const Je = /* @__PURE__ */ c(/* @__PURE__ */ E(({
  muted: t,
  onLoadedData: o,
  stream: i,
  ...n
}, l) => {
  const r = /* @__PURE__ */ Be();
  return Te(l, () => r.current), ee(() => {
    i && r.current && r.current.srcObject !== i && (r.current.srcObject = i);
  }, [i, r]), R(() => {
    r.current != null && (t ? (r.current.defaultMuted = !0, r.current.muted = !0) : (r.current.defaultMuted = !1, r.current.muted = !1));
  }, [t, r]), /* @__PURE__ */ e(W, {
    ref: r,
    autoPlay: !0,
    playsInline: !0,
    controls: !1,
    onLoadedData: o,
    style: Ke.video,
    ...n.videoProps
  });
}));
Je.displayName = "CustomRTCView";
const W = /* @__PURE__ */ c(/* @__PURE__ */ E((t, o) => Pe("video", {
  ...t,
  ref: o
})));
W.displayName = "Video";
W.displayName = "Video";
const Ke = S.create({
  video: {
    flex: 1,
    height: "80%"
  }
}), Ze = /* @__PURE__ */ c(({
  formatType: t = "EEEE, LLLL d",
  onPressNext: o,
  onPressPrev: i,
  value: n
}) => {
  const l = I();
  return /* @__PURE__ */ u(a, {
    bg: "white",
    borderRadius: "20",
    flexDirection: "row",
    height: "10",
    minWidth: "230",
    children: [/* @__PURE__ */ e(z, {
      onPress: i,
      children: /* @__PURE__ */ e(ze, {
        fill: l.colors.gray[400]
      })
    }), /* @__PURE__ */ e(h, {
      alignSelf: "center",
      flex: "1",
      textAlign: "center",
      children: ce(n, t, {
        locale: je,
        weekStartsOn: 1
      })
    }), /* @__PURE__ */ e(z, {
      onPress: o,
      children: /* @__PURE__ */ e(De, {
        fill: l.colors.gray[400]
      })
    })]
  });
});
Ze.displayName = "DatePicker";
const z = /* @__PURE__ */ c(({
  children: t,
  onPress: o
}) => /* @__PURE__ */ e(k, {
  _focus: {
    bg: "transparent"
  },
  _hover: {
    bg: "transparent"
  },
  _pressed: {
    bg: "transparent"
  },
  bg: "transparent",
  borderRadius: "20",
  height: "10",
  onPress: o,
  width: "10",
  children: t
}));
z.displayName = "DatePickerButton";
const et = /* @__PURE__ */ c(({
  children: t,
  onSubmit: o
}) => /* @__PURE__ */ e("form", {
  onSubmit: o,
  children: t
}));
et.displayName = "HtmlForm";
const pe = /* @__PURE__ */ c(/* @__PURE__ */ E((t, o) => /* @__PURE__ */ e(C, {
  ref: o,
  alignItems: "center",
  bg: t.bg ?? "white",
  borderRadius: "32",
  h: "63",
  justifyContent: "center",
  opacity: t.disabled ? 0.4 : 1,
  shadow: "1",
  w: "63",
  ...t
})));
pe.displayName = "CustomIconButton";
const j = /* @__PURE__ */ c(({
  children: t,
  focused: o,
  hasError: i,
  hasValue: n
}) => {
  const l = xe("text.50", "white");
  return /* @__PURE__ */ e(a, {
    left: "3",
    position: "relative",
    zIndex: n || o ? 2 : -1,
    children: /* @__PURE__ */ e(_, {
      animate: {
        transition: {
          duration: 75
        },
        translateY: -25
      },
      initial: {
        translateY: 0
      },
      visible: o || n,
      children: /* @__PURE__ */ e(h, {
        _light: {
          bg: "white"
        },
        color: i ? "red.50" : o ? "blue.50" : l,
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "20",
        pl: "1",
        position: "absolute",
        pr: "1",
        top: "1.5",
        children: t
      })
    })
  });
});
j.displayName = "InputPlaceholderAnimated";
const tt = /* @__PURE__ */ c(({
  children: t
}) => {
  const {
    height: o
  } = ae(), i = g({
    base: "0px",
    lg: o > 832 ? `${(o - 832) / 2}px` : "0px"
  }), n = g({
    base: "auto",
    lg: Math.min(832, o)
  }), l = g({
    base: o,
    lg: "none"
  }), r = g({
    base: "100%",
    xl: 1280
  });
  return /* @__PURE__ */ e(Q, {
    bg: "gray.200",
    flex: "1",
    flexDirection: "column",
    paddingTop: i,
    children: /* @__PURE__ */ e(X, {
      bg: "gray.50",
      height: n,
      margin: "auto",
      minHeight: l,
      width: r,
      children: t
    })
  });
});
tt.displayName = "MainContainer";
const me = /* @__PURE__ */ c(({
  children: t,
  opacity: o
}) => {
  const i = g({
    base: 4,
    lg: 8
  }), n = g({
    base: 4,
    lg: 5
  });
  return /* @__PURE__ */ e(a, {
    alignItems: "center",
    bg: "white",
    borderRadius: 12,
    mb: 2,
    opacity: o,
    pb: n,
    pl: i,
    pr: i,
    pt: n,
    shadow: "1",
    children: t
  });
});
me.displayName = "MeetCard";
const ot = /* @__PURE__ */ c(({
  children: t,
  title: o
}) => {
  const i = g({
    base: 24,
    lg: 28
  }), n = g({
    base: 14,
    lg: 22
  }), l = g({
    base: 22,
    lg: 26
  }), r = g({
    base: 0,
    lg: 0
  }), s = g({
    base: 8,
    lg: 4
  }), d = g({
    base: 4,
    lg: 0
  });
  return /* @__PURE__ */ u(a, {
    mt: l,
    pb: s,
    pl: r,
    pr: r,
    children: [/* @__PURE__ */ e(h, {
      color: "gray.400",
      fontSize: i,
      lineHeight: 28,
      mb: n,
      ml: d,
      children: o
    }), t]
  });
});
ot.displayName = "MeetingGroup";
const nt = /* @__PURE__ */ c((t) => {
  const o = g({
    base: 4,
    lg: 8
  }), i = g({
    base: 4,
    lg: 7
  });
  return /* @__PURE__ */ e(X, {
    bg: "white",
    borderRadius: "12",
    mb: "4",
    px: o,
    py: i,
    ...t
  });
});
nt.displayName = "SettingsContainer";
const it = /* @__PURE__ */ c((t) => /* @__PURE__ */ e(h, {
  color: "text.50",
  fontSize: "24",
  fontWeight: "500",
  lineHeight: "24",
  mb: "28",
  ...t
}));
it.displayName = "SettingsContainerTitle";
const rt = /* @__PURE__ */ c(({
  hideable: t = !1,
  isHidden: o = !1,
  text: i = {},
  value: n,
  view: l = {}
}) => {
  const r = I(), [s, d] = x(!o), p = f(() => {
    d((b) => !b);
  }, []), m = y(() => /* @__PURE__ */ e(h, {
    color: "black",
    fontSize: "16",
    fontWeight: "500",
    lineHeight: "24",
    mr: "4",
    ...i,
    children: s ? n : n.split("").map((b, w) => /* @__PURE__ */ e(h, {
      px: "1px",
      children: "•"
    }, w))
  }), [s, n, i]);
  return /* @__PURE__ */ u(a, {
    alignItems: "center",
    flexDirection: "row",
    ...l,
    children: [m, t ? /* @__PURE__ */ e(C, {
      alignItems: "center",
      h: "6",
      justifyContent: "center",
      onPress: p,
      w: "6",
      children: s ? /* @__PURE__ */ e(re, {
        fill: r.colors.gray[400],
        height: "20",
        width: "20"
      }) : /* @__PURE__ */ e(ie, {
        fill: r.colors.gray[400],
        height: "20",
        width: "20"
      })
    }) : null]
  });
});
rt.displayName = "SettingsItemValue";
const be = /* @__PURE__ */ c(({
  noBorder: t,
  ...o
}) => {
  const i = g({
    base: 0,
    lg: "72px"
  }), n = g({
    base: "column",
    lg: "row"
  }), l = g({
    base: "flex-start",
    lg: "center"
  });
  return /* @__PURE__ */ e(J, {
    alignItems: l,
    borderBottomColor: "gray.100",
    borderBottomWidth: t ? 0 : 1,
    flexDirection: n,
    justifyContent: "space-between",
    minHeight: i,
    py: "4",
    w: "100%",
    ...o
  });
});
be.displayName = "SettingsItemRow";
const lt = /* @__PURE__ */ c(({
  time: t
}) => /* @__PURE__ */ u(a, {
  alignItems: "flex-end",
  flexDirection: "row",
  h: "64px",
  children: [/* @__PURE__ */ e(h, {
    color: "text.50",
    fontSize: "12",
    h: "13px",
    lineHeight: "13",
    w: "10",
    children: t
  }), /* @__PURE__ */ e(a, {
    borderTopColor: "gray.200",
    borderTopWidth: "1px",
    flex: "1",
    height: "0",
    mb: "6px"
  })]
}));
lt.displayName = "TimePeriod";
const st = /* @__PURE__ */ c(({
  errorMessage: t,
  ...o
}) => /* @__PURE__ */ u(le, {
  style: A.container,
  children: [/* @__PURE__ */ e(Ee, {
    style: A.customTextInput,
    ...o
  }), t && /* @__PURE__ */ e(se, {
    style: A.errorMessage,
    children: t
  })]
}));
st.displayName = "CustomTextInput";
const A = S.create({
  container: {
    marginBottom: 25,
    width: "100%"
  },
  customTextInput: {
    borderRadius: 5,
    fontSize: 14,
    marginBottom: 8,
    paddingHorizontal: 16,
    paddingVertical: 18,
    width: "100%"
  },
  errorMessage: {
    fontSize: 25
  }
}), at = "_input_15s4f_1", ct = {
  input: at
}, q = "yyyy-MM-dd'T'HH:mm", dt = /* @__PURE__ */ c(({
  mode: t,
  onBlur: o,
  onChange: i,
  value: n
}) => {
  const l = f((s) => {
    const d = We(s.target.value, q, /* @__PURE__ */ new Date());
    i(d);
  }, [i]), r = y(() => n == null ? void 0 : ce(typeof n == "string" ? new Date(n) : n, q), [n]);
  return /* @__PURE__ */ e("input", {
    className: ct.input,
    onBlur: o,
    onChange: l,
    type: t === "datetime" ? "datetime-local" : t,
    value: r
  });
});
dt.displayName = "FormDatePicker";
const V = /* @__PURE__ */ H.memo(({
  message: t,
  text: o,
  view: i
}) => /* @__PURE__ */ e(a, {
  borderColor: "red.50",
  borderRadius: "8",
  borderWidth: "1",
  mb: "2",
  mt: "2",
  p: "2",
  ...i,
  children: /* @__PURE__ */ e(h, {
    color: "red.50",
    fontSize: "12",
    pl: "2",
    ...o,
    children: t
  })
}));
V.displayName = "FormErrorMessage";
const ut = /* @__PURE__ */ c(({
  message: t = `Введите адрес электронной почты — 
отправим инструкцию восстановления пароля`
}) => {
  const o = g({
    base: 6,
    lg: "32px"
  }), i = g({
    base: 6,
    lg: -6
  });
  return /* @__PURE__ */ e(h, {
    color: "black",
    display: "flex",
    fontSize: "14",
    fontWeight: "500",
    lineHeight: "20",
    mb: o,
    mt: i,
    children: t
  });
});
ut.displayName = "FormInstructionPasswordForgot";
const ht = /* @__PURE__ */ c(({
  errorMessage: t,
  onChangeText: o,
  placeholder: i,
  ...n
}) => {
  const l = Ae(), r = I(), [s, d] = x("password"), [p, m] = x(!1), b = f(() => {
    m(!0);
  }, []), w = f(() => {
    m(!1);
  }, []), N = f(() => {
    d((we) => we === "password" ? "text" : "password");
  }, []);
  return /* @__PURE__ */ u(a, {
    mb: "6",
    position: "relative",
    children: [/* @__PURE__ */ e(j, {
      focused: p,
      hasError: !!n.isInvalid,
      hasValue: !!n.value,
      children: i
    }), /* @__PURE__ */ e(K, {
      ref: l,
      InputRightElement: /* @__PURE__ */ e(C, {
        onPress: N,
        pl: "3",
        pr: "3",
        children: s === "text" ? /* @__PURE__ */ e(ie, {
          fill: r.colors.gray[400],
          height: "20",
          width: "20"
        }) : /* @__PURE__ */ e(re, {
          fill: r.colors.gray[400],
          height: "20",
          width: "20"
        })
      }),
      ...gt,
      borderColor: "gray.200",
      borderRadius: "8",
      color: "black",
      focusOutlineColor: "blue.50",
      fontSize: "16",
      fontWeight: "500",
      height: "12",
      isFocused: !1,
      isHovered: !1,
      onBlur: w,
      onChangeText: o,
      onFocus: b,
      px: "4",
      type: s,
      width: "100%",
      ...n
    }), n.isInvalid && t != null ? /* @__PURE__ */ e(V, {
      message: t
    }) : null]
  });
});
ht.displayName = "FormPasswordInput";
const gt = {
  _dark: {
    color: "gray.400"
  },
  _light: {
    _focus: {
      bg: "rgba(255, 255, 255, 0)",
      borderColor: "blue.50"
    },
    _hover: {
      bg: "rgba(255, 255, 255, 0)",
      borderColor: "gray.200"
    },
    _invalid: {
      bg: "rgba(255, 255, 255, 0)",
      borderColor: "red.50"
    },
    bg: "rgba(255, 255, 255, 0)"
  }
}, pt = S.create({
  wrap: {}
}), fe = /* @__PURE__ */ c(({
  buttonView: t,
  checked: o,
  disabled: i,
  onChange: n,
  switcher: l,
  view: r
}) => n == null ? /* @__PURE__ */ e(a, {
  bg: o ? "blue.50" : "gray.200",
  borderRadius: 16,
  height: "20px",
  opacity: i ? 0.5 : 1,
  position: "relative",
  w: "32px",
  ...r,
  children: /* @__PURE__ */ e(D, {
    checked: o,
    switcher: l
  })
}) : /* @__PURE__ */ e(C, {
  bg: o ? "blue.50" : "gray.200",
  disabled: i,
  onPress: () => n(!o),
  opacity: i ? 0.5 : 1,
  ...pt.wrap,
  ...t,
  children: /* @__PURE__ */ e(D, {
    checked: o,
    switcher: l
  })
}));
fe.displayName = "FormSwitch";
const D = /* @__PURE__ */ c(({
  checked: t,
  switcher: o
}) => /* @__PURE__ */ e(_, {
  animate: {
    transition: {
      duration: 50
    },
    translateX: 14
  },
  initial: {
    translateX: 2
  },
  visible: t,
  children: /* @__PURE__ */ e(a, {
    bg: "white",
    borderRadius: "16",
    h: "16px",
    left: "0",
    position: "relative",
    top: "2px",
    w: "16px",
    ...o
  })
}));
D.displayName = "Swither";
const mt = /* @__PURE__ */ H.memo(({
  auto: t,
  icon: o,
  onRangeChanged: i,
  onRangeChanging: n,
  onSetAuto: l,
  rangeValue: r
}) => {
  const s = y(() => ({
    height: 20,
    left: 0,
    position: "absolute",
    top: -4,
    width: 6
  }), []), d = y(() => t ? 0 : r, [r, t]);
  return /* @__PURE__ */ u(a, {
    alignItems: "center",
    flexDirection: "row",
    h: "6",
    w: "272",
    children: [/* @__PURE__ */ e(a, {
      w: "30px",
      children: o
    }), /* @__PURE__ */ u(a, {
      h: "24px",
      position: "relative",
      w: "158px",
      children: [/* @__PURE__ */ u(T, {
        defaultValue: 20,
        maxValue: 100,
        maxW: "158px",
        minValue: 0,
        onChange: n,
        onChangeEnd: i,
        opacity: "0",
        position: "relative",
        step: 1,
        value: d,
        w: "158px",
        zIndex: "3",
        children: [/* @__PURE__ */ e(T.Track, {
          children: /* @__PURE__ */ e(T.FilledTrack, {})
        }), /* @__PURE__ */ e(T.Thumb, {})]
      }), /* @__PURE__ */ e(a, {
        alignItems: "center",
        flexDirection: "row",
        h: "6",
        justifyContent: "space-between",
        left: "0",
        position: "absolute",
        top: "0",
        w: "158px",
        children: Array.from({
          length: 20
        }).map((p, m) => {
          const b = Math.ceil(d / 5) === m + 1, w = m + 1 < Math.ceil(d / 5);
          return /* @__PURE__ */ u(a, {
            bg: w ? "blue.50" : "#E1E1EB",
            borderRadius: "6",
            h: "12px",
            position: "relative",
            w: "6px",
            children: [/* @__PURE__ */ e(_, {
              animate: {
                opacity: 1,
                scaleY: 1,
                transition: {
                  duration: 25
                }
              },
              initial: {
                opacity: 0,
                scaleY: 0.6
              },
              style: s,
              visible: b,
              children: /* @__PURE__ */ e(a, {
                bg: "blue.50",
                borderRadius: "6",
                h: "20px",
                w: "6px"
              }, m)
            }), /* @__PURE__ */ e(_, {
              animate: {
                opacity: 1,
                transition: {
                  duration: 25
                }
              },
              initial: {
                opacity: 0
              },
              style: {
                ...s,
                top: 0
              },
              visible: w,
              children: /* @__PURE__ */ e(a, {
                bg: "blue.50",
                borderRadius: "6",
                h: "12px",
                w: "6px"
              }, m)
            })]
          }, m);
        })
      })]
    }), /* @__PURE__ */ e(a, {
      w: "52px",
      children: /* @__PURE__ */ e(h, {
        color: "#A4A4B3",
        fontSize: "14",
        fontWeight: "500",
        textAlign: "center",
        children: t ? "auto" : String(r)
      })
    }), /* @__PURE__ */ e(a, {
      w: "32px",
      children: /* @__PURE__ */ e(fe, {
        checked: t,
        onChange: () => {
          l(!t);
        }
      })
    })]
  });
});
mt.displayName = "FormRange";
const bt = /* @__PURE__ */ c(({
  children: t,
  label: o,
  noBorder: i,
  radio: n = {},
  selected: l,
  value: r,
  view: s = {}
}) => {
  const d = y(() => ({
    ...s,
    alignItems: {
      base: "flex-start",
      lg: "center"
    },
    flexDirection: {
      base: "column",
      lg: "row"
    }
  }), [s]);
  return /* @__PURE__ */ u(be, {
    noBorder: i,
    ...d,
    children: [/* @__PURE__ */ e(Ce, {
      my: 1,
      ...n,
      value: r,
      children: /* @__PURE__ */ e(h, {
        color: l ? "black" : "text.100",
        fontSize: "16",
        fontWeight: "500",
        children: o.replace(/\s*:\s*$/, "")
      })
    }), /* @__PURE__ */ e(a, {
      display: {
        base: t != null ? "flex" : "none",
        lg: "none"
      },
      h: t != null ? 4 : 0
    }), t]
  });
});
bt.displayName = "FormSettingsRadio";
const ft = /* @__PURE__ */ c(({
  errorMessage: t,
  mb: o = "6",
  placeholder: i,
  withAnimation: n = !0,
  ...l
}) => {
  const [r, s] = x(!1), d = f(() => {
    s(!0);
  }, []), p = f(() => {
    s(!1);
  }, []);
  return /* @__PURE__ */ u(a, {
    mb: o,
    position: "relative",
    children: [n ? /* @__PURE__ */ e(j, {
      focused: r,
      hasError: !!l.isInvalid,
      hasValue: !!l.value,
      children: i
    }) : null, /* @__PURE__ */ e(K, {
      ...yt,
      borderColor: "gray.200",
      borderRadius: "8",
      color: "black",
      focusOutlineColor: "blue.50",
      fontSize: "16",
      fontWeight: "500",
      isFocused: !1,
      isHovered: !1,
      onBlur: p,
      onFocus: d,
      pl: "4",
      pr: "4",
      width: "100%",
      ...l
    }), l.isInvalid && t != null ? /* @__PURE__ */ e(V, {
      message: t
    }) : null]
  });
});
ft.displayName = "FormTextInput";
const yt = {
  _dark: {
    color: "gray.400"
  },
  _light: {
    _focus: {
      bg: "rgba(255, 255, 255, 0)",
      borderColor: "blue.50"
    },
    _hover: {
      bg: "rgba(255, 255, 255, 0)",
      borderColor: "gray.200"
    },
    _invalid: {
      bg: "rgba(255, 255, 255, 0)",
      borderColor: "red.50"
    },
    bg: "rgba(255, 255, 255, 0)"
  }
}, wt = /* @__PURE__ */ c(({
  children: t
}) => {
  const o = g({
    base: 6,
    lg: 44
  });
  return /* @__PURE__ */ e(h, {
    color: "black",
    display: "flex",
    fontSize: "24",
    fontWeight: "500",
    lineHeight: "24",
    mb: o,
    children: t
  });
});
wt.displayName = "FormTitle";
const xt = () => g({
  base: "base",
  lg: "lg"
});
function io() {
  var d;
  const [t, o] = x(!0), [i, n] = x({}), l = f(() => n({}), []), r = typeof window < "u" && ((d = window.navigator) == null ? void 0 : d.userActivation) !== void 0;
  return ee(() => {
    let p = !0;
    r && (p = !window.navigator.userActivation.isActive && !window.navigator.userActivation.hasBeenActive), o(p);
  }, [i, r]), {
    hide: f(() => {
      r ? l() : o(!1);
    }, [l, r]),
    needInteraction: t
  };
}
function ro() {
  const {
    setColorMode: t
  } = ve();
  R(() => (t("dark"), () => {
    t("light");
  }), [t]);
}
const Ct = /* @__PURE__ */ c(({
  email: t,
  nickname: o,
  showName: i = !0,
  total: n,
  uri: l
}) => {
  const r = g({
    base: n < 4 ? 160 : n < 5 ? 130 : n < 7 ? 100 : n < 9 ? 16 : 58,
    lg: 200
  }), s = Ve({
    nickname: o
  }), d = y(() => de(l, r), [l, r]);
  return /* @__PURE__ */ u(a, {
    alignItems: "center",
    children: [/* @__PURE__ */ e(a, {
      height: r,
      width: r,
      children: /* @__PURE__ */ e(Ie, {
        bg: "rgba(255, 255, 255, 0.08)",
        h: r,
        source: d,
        w: r,
        children: /* @__PURE__ */ e(h, {
          color: "#70707F",
          fontSize: {
            base: n < 6 ? 36 : n < 9 ? 28 : 22,
            lg: 72
          },
          fontWeight: 400,
          children: s.toLocaleUpperCase()
        })
      })
    }), i ? /* @__PURE__ */ e(h, {
      color: "white",
      fontSize: {
        base: n < 5 ? 20 : n < 7 ? 16 : 14,
        lg: 24
      },
      fontWeight: 500,
      lineHeight: 24,
      mt: {
        base: n < 7 ? 4 : 1,
        lg: 5
      },
      textAlign: "center",
      children: o
    }) : null]
  });
});
Ct.displayName = "BigAvatarMainCard";
const vt = /* @__PURE__ */ c(({
  hideName: t,
  nickname: o,
  small: i,
  uri: n
}) => {
  const l = y(() => (n != null && n.trim() !== "" ? de(n, 200) : void 0) ?? {
    uri: Oe(o)
  }, [o, n]);
  return /* @__PURE__ */ u(a, {
    alignItems: "center",
    flex: "1",
    justifyContent: "center",
    position: "relative",
    w: "100%",
    children: [/* @__PURE__ */ e(a, {
      h: 160,
      maxHeight: {
        base: 160,
        lg: 200,
        md: i ? 60 : void 0
      },
      maxWidth: {
        base: 160,
        lg: 200,
        md: i ? 60 : void 0
      },
      w: 160,
      children: /* @__PURE__ */ e(_e, {
        alt: `${o} avatar`,
        borderRadius: "50%",
        h: "100%",
        source: l,
        style: It.image,
        w: "auto"
      })
    }), /* @__PURE__ */ e(a, {
      bottom: "2",
      flexDirection: "row",
      justifyContent: "center",
      left: "48px",
      position: "absolute",
      right: "48px",
      children: t ? null : /* @__PURE__ */ e(a, {
        alignItems: "center",
        bg: "rgba(0,0,0,.64)",
        borderRadius: "32",
        display: "inline-flex",
        flexDirection: "row",
        maxWidth: "100%",
        px: "4",
        py: "1",
        children: /* @__PURE__ */ e(h, {
          color: "gray.400",
          ellipsizeMode: "tail",
          fontSize: "14",
          fontWeight: "500",
          lineHeight: "24",
          numberOfLines: 1,
          children: o
        })
      })
    })]
  });
});
vt.displayName = "BigAvatarWithName";
const It = S.create({
  image: {
    aspectRatio: 1
  }
}), _t = /* @__PURE__ */ c(({
  children: t,
  info: o,
  /*    inRoom,*/
  isOwner: i,
  menu: n,
  terminated: l,
  textForCopy: r,
  textForDisplay: s,
  title: d
}) => {
  const p = g({
    base: 20,
    lg: 24
  }), m = g({
    base: 2,
    lg: 1
  }), b = g({
    base: 24,
    lg: 28
  });
  return /* @__PURE__ */ u(me, {
    opacity: l ? 0.4 : 1,
    children: [/* @__PURE__ */ u(a, {
      alignItems: "flex-start",
      flexDirection: "row",
      justifyContent: "space-between",
      mb: 2,
      width: "100%",
      children: [/* @__PURE__ */ u(h, {
        isTruncated: !0,
        alignItems: "center",
        display: "flex",
        fontSize: p,
        fontWeight: "500",
        lineHeight: b,
        noOfLines: m,
        width: "85%",
        children: [i ? /* @__PURE__ */ e(ke, {
          fill: Se.colors.yellow[500],
          height: 16,
          style: St.crown,
          width: 16
        }) : null, " ", d]
      }), n]
    }), /* @__PURE__ */ e(a, {
      alignItems: "center",
      flexDirection: "row",
      mb: "5",
      width: "100%",
      children: o
    }), /* @__PURE__ */ u(a, {
      alignItems: "center",
      flexDirection: "row",
      width: "100%",
      children: [/* @__PURE__ */ e(a, {
        mr: "2",
        children: /* @__PURE__ */ e(ge, {
          textForCopy: r,
          children: /* @__PURE__ */ e(h, {
            color: "text.50",
            fontSize: "14",
            fontWeight: "500",
            lineHeight: "24",
            children: s
          })
        })
      }), /* @__PURE__ */ e(a, {
        marginLeft: "auto",
        children: t
      })]
    })]
  });
});
_t.displayName = "Card";
const St = S.create({
  crown: {
    flexShrink: 0
  }
}), L = /* @__PURE__ */ c(({
  children: t,
  disabled: o,
  hasCancel: i,
  okButtonText: n,
  onClose: l,
  onOk: r,
  title: s,
  transparent: d,
  viewModal: p,
  viewWrap: m
}) => {
  const {
    t: b
  } = P(), {
    width: w
  } = ae();
  return /* @__PURE__ */ e(a, {
    alignItems: "center",
    bg: d ? "opaque.black" : "black",
    bottom: "0",
    justifyContent: "center",
    left: "0",
    position: "absolute",
    right: "0",
    top: "0",
    zIndex: 99,
    ...m,
    children: /* @__PURE__ */ u(a, {
      bg: "#141414",
      borderRadius: "12",
      maxWidth: Math.min(w - 32, 380),
      p: "6",
      w: "380",
      ...p,
      children: [/* @__PURE__ */ e(h, {
        color: "white",
        fontSize: "20",
        fontWeight: "500",
        lineHeight: "28",
        textAlign: "center",
        children: s
      }), /* @__PURE__ */ e(a, {
        mb: "8",
        mt: "2",
        children: /* @__PURE__ */ e(h, {
          color: "#BCBCCC",
          fontSize: "14",
          fontWeight: "500",
          lineHeight: "18",
          textAlign: "center",
          children: t
        })
      }), /* @__PURE__ */ e(Re, {
        children: /* @__PURE__ */ u(M, {
          space: 2,
          children: [i && /* @__PURE__ */ e(G, {
            onPress: l,
            variant: "secondary",
            children: /* @__PURE__ */ e(h, {
              color: "white",
              children: b("BUTTON.CANCEL", {
                defaultValue: "Отмена"
              })
            })
          }), /* @__PURE__ */ e(G, {
            disabled: o,
            onPress: r,
            variant: "primary",
            children: /* @__PURE__ */ e(h, {
              color: "white",
              children: n ?? b("BUTTON.CLOSE", {
                defaultValue: "Закрыть"
              })
            })
          })]
        })
      })]
    })
  });
});
L.displayName = "FullScreenMessage";
const Rt = /* @__PURE__ */ c(({
  customMessages: t,
  error: o,
  onOk: i
}) => {
  const n = ue(he), l = y(() => o != null ? Le(o, t) : null, [t, o]);
  return l != null && console.error(o), R(() => {
    o != null && $e.captureException(o);
  }, [o]), l != null ? i != null ? /* @__PURE__ */ u(L, {
    okButtonText: "Продолжить",
    onClose: i,
    onOk: i,
    title: "Произошла ошибка",
    children: [/* @__PURE__ */ e(h, {
      children: l
    }), /* @__PURE__ */ u(h, {
      children: ["trace id: ", n]
    })]
  }) : /* @__PURE__ */ e(O, {
    left: 0,
    position: "absolute",
    right: 0,
    status: "error",
    top: 0,
    children: /* @__PURE__ */ e(Z, {
      flexShrink: 1,
      space: 2,
      w: "100%",
      children: /* @__PURE__ */ e(M, {
        flexShrink: 1,
        justifyContent: "space-between",
        space: 2,
        children: /* @__PURE__ */ u(M, {
          flexShrink: 1,
          space: 2,
          children: [/* @__PURE__ */ e(O.Icon, {
            mt: "1"
          }), /* @__PURE__ */ e(h, {
            color: "gray.800",
            fontSize: "md",
            children: l
          })]
        })
      })
    })
  }) : null;
});
Rt.displayName = "DisplayGraphQLError";
const Nt = {
  emojis: ["+1", "-1", "grinning", "kissing_heart", "heart_eyes", "laughing", "stuck_out_tongue_winking_eye", "sweat_smile", "joy", "scream", "disappointed", "unamused", "weary", "sob", "sunglasses", "heart"],
  id: "often_used"
}, Tt = /* @__PURE__ */ c(({
  onEmojiSelect: t,
  visible: o
}) => {
  const {
    t: i
  } = P(), n = $.categories, l = $.emojis, r = y(() => [Nt, ...n].map((s) => s.id === "flags" || s.id === "symbols" ? null : /* @__PURE__ */ u(a, {
    children: [/* @__PURE__ */ e(h, {
      bg: "rgba(82, 82, 91, .9)",
      color: "white",
      fontSize: "14",
      position: "sticky",
      py: "1",
      top: "0",
      zIndex: "2",
      children: i(`CHAT.GROUP.${s.id.toUpperCase()}`)
    }), /* @__PURE__ */ e(a, {
      flexDirection: "row",
      flexWrap: "wrap",
      mb: "4",
      children: s.emojis.slice(0, Math.min(60, s.emojis.length)).map((d) => {
        const p = l[d];
        return p == null || p.version !== 1 ? null : /* @__PURE__ */ e(ye, {
          emoji: p.skins[0].native,
          onEmojiSelect: t
        }, p.id);
      })
    })]
  }, s.id)), [n, i, l, t]);
  return /* @__PURE__ */ e(J, {
    background: "gray.600",
    borderRadius: "xl",
    bottom: "100%",
    display: o ? "block" : "none",
    flexDirection: "row",
    flexWrap: "wrap",
    left: "10%",
    p: "2",
    position: "absolute",
    right: "10%",
    children: /* @__PURE__ */ e(Q, {
      maxHeight: {
        base: 240,
        lg: 440
      },
      children: r
    })
  });
});
Tt.displayName = "EmojiModal";
const ye = /* @__PURE__ */ c(({
  emoji: t,
  onEmojiSelect: o
}) => {
  const i = f((n) => {
    n.stopPropagation(), n.preventDefault(), o(t);
  }, [o, t]);
  return /* @__PURE__ */ e("span", {
    className: "emoji",
    children: /* @__PURE__ */ e(C, {
      _pressed: {
        opacity: 0.15
      },
      onPress: (n) => i(n),
      position: "relative",
      children: /* @__PURE__ */ e(h, {
        fontSize: "xl",
        children: t
      })
    })
  });
});
ye.displayName = "EmojiItem";
const Bt = /* @__PURE__ */ c(({
  children: t,
  onClose: o,
  onOk: i,
  title: n
}) => {
  const {
    t: l
  } = P(), r = ue(he);
  return /* @__PURE__ */ e(L, {
    onClose: o,
    onOk: i,
    title: n ?? l("DEFAULT_ERROR_TITLE"),
    children: /* @__PURE__ */ u(Z, {
      children: [/* @__PURE__ */ e(h, {
        children: t
      }), /* @__PURE__ */ u(h, {
        color: "coolGray.600",
        mt: 4,
        children: ["trace: ", r]
      })]
    })
  });
});
Bt.displayName = "FullscreenErrorMessageWithTraceId";
const At = /* @__PURE__ */ c(({
  children: t,
  size: o
}) => {
  const i = xt(), n = I();
  return /* @__PURE__ */ e(F, {
    bg: "#232324",
    borderRadius: "12",
    placement: i === "lg" ? "top" : "top right",
    trigger: (l, r) => /* @__PURE__ */ e(pe, {
      _focus: {
        bg: "rgba(255, 255, 255, 0.12)"
      },
      _hover: {
        bg: "rgba(255, 255, 255, 0.12)"
      },
      _pressed: {
        bg: "rgba(255, 255, 255, 0.12)"
      },
      bg: r.open ? "rgba(255, 255, 255, 0.12)" : "rgba(255, 255, 255, 0.08)",
      borderRadius: "32",
      h: o === "lg" ? {
        base: "48px",
        lg: "48px",
        md: "36px"
      } : "24px",
      p: "0",
      w: o === "lg" ? {
        base: "48px",
        lg: "48px",
        md: "36px"
      } : "24px",
      ...l,
      children: /* @__PURE__ */ e(te, {
        fill: n.colors.gray[400],
        height: o === "lg" ? "24px" : "12px",
        width: o === "lg" ? "24px" : "12px"
      })
    }),
    children: t
  });
});
At.displayName = "Menu";
const Mt = /* @__PURE__ */ c(({
  children: t,
  disabledText: o,
  ...i
}) => {
  const [n, l] = x(!1), r = /* @__PURE__ */ e(F.Item, {
    _focus: {
      bg: "#232324"
    },
    _hover: {
      bg: "#232324"
    },
    _pressed: {
      bg: "#232324"
    },
    _text: {
      color: n ? "white" : "gray.400"
    },
    bg: "#232324",
    isDisabled: o != null,
    onHoverIn: () => l(!0),
    onHoverOut: () => l(!1),
    ...i,
    children: t
  });
  return o != null ? /* @__PURE__ */ e(Ne, {
    label: o,
    placement: "bottom left",
    children: r
  }) : r;
});
Mt.displayName = "MenuItem";
const zt = "_button_13vzv_1", Dt = "_buttonDisabled_13vzv_9", kt = "_buttonSquare_13vzv_13", Ft = "_buttonText_13vzv_30", Ht = "_buttonRed_13vzv_38", Et = "_buttonBlue_13vzv_46", Pt = "_buttonGreen_13vzv_54", v = {
  button: zt,
  buttonDisabled: Dt,
  buttonSquare: kt,
  buttonText: Ft,
  buttonRed: Ht,
  buttonBlue: Et,
  buttonGreen: Pt
}, Wt = /* @__PURE__ */ c(({
  children: t,
  disabled: o,
  icon: i,
  iconWeb: n,
  onPress: l,
  theme: r
}) => {
  const s = f(() => {
    o || l();
  }, [l, o]);
  return /* @__PURE__ */ u("div", {
    className: U(v.button, {
      [v.buttonDisabled]: o
    }),
    onClick: s,
    children: [/* @__PURE__ */ e("div", {
      className: U(v.buttonSquare, {
        [v.buttonBlue]: r === "blue",
        [v.buttonRed]: r === "red",
        [v.buttonGreen]: r === "green"
      }),
      children: n
    }), /* @__PURE__ */ e("div", {
      className: v.buttonText,
      children: t
    })]
  });
});
Wt.displayName = "RoomActionButton";
export {
  G as ActionButton,
  Ct as BigAvatarMainCard,
  vt as BigAvatarWithName,
  Ue as Button32,
  Ge as ButtonAddRoom,
  _t as Card,
  qe as CardMenu,
  Ye as CenteredFormContainer,
  Qe as CopyRoom,
  ge as CopyRoomNumber,
  Xe as CustomButton,
  pe as CustomIconButton,
  Je as CustomRTCView,
  Ze as DatePicker,
  Rt as DisplayGraphQLError,
  Nt as EMOJI_OFTEN_USED,
  ye as EmojiItem,
  Tt as EmojiModal,
  dt as FormDatePicker,
  V as FormErrorMessage,
  ut as FormInstructionPasswordForgot,
  ht as FormPasswordInput,
  bt as FormSettingsRadio,
  fe as FormSwitch,
  ft as FormTextInput,
  wt as FormTitle,
  L as FullScreenMessage,
  Bt as FullscreenErrorMessageWithTraceId,
  no as HAND_RAISED,
  j as InputPlaceholderAnimated,
  tt as MainContainer,
  me as MeetCard,
  ot as MeetingGroup,
  At as Menu,
  Mt as MenuItem,
  Wt as RoomActionButton,
  nt as SettingsContainer,
  it as SettingsContainerTitle,
  be as SettingsItemRow,
  rt as SettingsItemValue,
  lt as TimePeriod,
  xt as useBreakpoint,
  ro as useDarkMode,
  io as useNeedInteraction
};
