var Do = Object.defineProperty;
var Io = (e, n, i) => n in e ? Do(e, n, { enumerable: !0, configurable: !0, writable: !0, value: i }) : e[n] = i;
var Xi = (e, n, i) => (Io(e, typeof n != "symbol" ? n + "" : n, i), i);
import { createEvent as h, sample as w, createStore as x, createEffect as $, attach as Tn, combine as we, withFactory as Ao, split as Oo, restore as Vo } from "effector";
import { ApolloError as ua, useQuery as qn, useMutation as Cn } from "@apollo/client";
import { makeUniqueId as mi } from "@apollo/client/utilities";
import { hasData as Xn, initialized as en, failure as ka, success as he, maybeData as Se, remoteMap as Xe, RemoteDataStatus as va, fold as Wt, pending as Po } from "@dmitry.olyenyov/remote-data";
import { t as D } from "i18next";
import { getApolloClient as Q, RtcMediaAudioMode as U, Permission as K, restartGraphqlConnection as Lo, RtcMediaAudio as ci, RtcMediaVideo as _e, ChatEmbedType as ht, makeCallRoomLink as Gt, makeGravatarUri as pi, MeetMemberPresenceState as Uo } from "@sign/shared/api";
import { isNotNull as ze, formatBackendError as $o, makeStyleSheet as Bo, postpone as zo, throttle as jo, retryEffect as Ho, waitFor as qt, makeThumbnails as Sa, formatUserName as nn, opaqueTimeToDate as Xt, formatAvatarShortName as pa, formatRoomIdAsText as Wo, createNoParamsInfiniteLoaderModel as Go, formatMaybeEmptyTime as qo, enumToList as Xo, formatMyUserName as Yo } from "@sign/shared/lib";
import { $myUserInfo as Yt, $anonymousNickname as Na, persistSES as Ko, $accessToken as Jo, useMyInfoOld as Ni, useMyInfo as Qo } from "@sign/shared/model";
import { configure as Mn } from "effector-logger";
import { View as _, useTheme as me, useMediaQuery as Zo, useBreakpointValue as bt, theme as Be, Heading as bn, Text as b, Image as fi, Box as sn, Tooltip as fa, Pressable as ne, Progress as es, Avatar as Fa, FlatList as ga, Center as Fi, HStack as Je, VStack as Bn, CloseIcon as Kt, FormControl as on, ScrollView as ha, Button as ba, Spinner as zn, TextArea as ns, Select as Un, Popover as ge, Radio as gn, Stack as yt, Alert as Tt, IconButton as is, useClipboard as as, useToast as ts, SectionList as ds } from "native-base";
import Jt, { memo as g, useCallback as E, useMemo as P, useState as H, useEffect as J, useRef as je, forwardRef as ls } from "react";
import { jsx as d, jsxs as f, Fragment as be } from "react/jsx-runtime";
import { CustomIconButton as Yn, useBreakpoint as mn, FullScreenMessage as ya, FormPasswordInput as os, SettingsItemRow as ss, ActionButton as He, Button32 as gi, EmojiModal as rs, EMOJI_OFTEN_USED as Qt, EmojiItem as ms, FormTextInput as ln, FormDatePicker as Ct, FormSwitch as Ge, FormErrorMessage as cs, DisplayGraphQLError as hi, CopyRoomNumber as Zt, HAND_RAISED as yn, MenuItem as rn, CustomRTCView as bi, Menu as us, SettingsContainer as ks, SettingsContainerTitle as vs, FormSettingsRadio as Ss, BigAvatarWithName as ed } from "@sign/uikit";
import { IconPhone as ps, IconMicro as Ta, IconCameraRound as nd, IconCameraChange as Ns, IconScreenShare as fs, IconFolder as Fs, IconCloseCircle as yi, IconEdit as gs, IconReply as hs, IconChat as id, IconPlus as bs, IconClose as ys, IconRaisedHand as Ca, IconWifi as Ts, IconMicroDisabled as ad, IconCamera as Cs, IconCameraDisabled as Ms, IconChatWithSign as ws, IconSmile as Es, IconSettings as _s, IconUserGroup as Rs, IconCameraRoundOff as xs } from "@sign/uikit/icons";
import { useUnit as A } from "effector-react";
import { useWindowDimensions as wn, View as td, Pressable as dd, NativeModules as Ds, Linking as ld, Platform as Qe, StyleSheet as En, Modal as od, ScrollView as Is, Switch as As } from "react-native";
import { zodResolver as Kn } from "@hookform/resolvers/zod";
import { useForm as _n, Controller as de, useFieldArray as sd } from "react-hook-form";
import j, { z } from "zod";
import { mediaDevices as De, RTCPeerConnection as Os } from "react-native-webrtc";
import { useTranslation as le } from "react-i18next";
import { UnreachableCaseError as X } from "ts-essentials";
import * as Mt from "@sentry/react";
import { captureMessage as Vs } from "@sentry/react";
import { interval as Ps } from "patronum";
import { parse as Ls } from "@rocket.chat/message-parser";
import Us, { AxiosError as $s } from "axios";
import { IconPaperClipWeb as Bs, IconSmileWeb as zs, IconSendWeb as js, PortalContainer as Hs, IconCloseWeb as wt, IconCheckWeb as Ws } from "@sign/uikit/web";
import xe from "classnames";
import { useDropzone as Gs } from "react-dropzone";
import { isToday as qs, isYesterday as Xs, format as Et } from "date-fns";
import Ys, { Path as Ks } from "react-native-svg";
import rd from "@emoji-mart/data";
const wv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }]
}, Ev = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }]
}, _v = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbed"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "file"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "author"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "filename"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "mime"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updated"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "message"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "EmbeddedChatMessage"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "preview"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbedPreview"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "type"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "value"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }]
}, Rv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventChatMessageEdit"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEditType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbed"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "file"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "author"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "filename"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "mime"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updated"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "message"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "EmbeddedChatMessage"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "preview"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbedPreview"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "type"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "value"
        }
      }]
    }
  }]
}, xv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reactions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbed"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "file"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "author"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "filename"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "mime"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updated"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "message"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "EmbeddedChatMessage"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "preview"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbedPreview"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "type"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "value"
        }
      }]
    }
  }]
}, Dv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventChat"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventChatType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "edit"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "messages"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventChatMessageEdit"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "sent"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "messages"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "ChatMessage"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "remove"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "messages"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reaction"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "messages"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "reactions"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "count"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "name"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "self"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "messages"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "reactions"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbed"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "file"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "author"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "filename"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "mime"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updated"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "message"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "EmbeddedChatMessage"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "preview"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbedPreview"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "type"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "value"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventChatMessageEdit"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEditType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reactions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }]
}, Js = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "RequestChatHistory"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "cursor"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "limit"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "Int"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "history"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "cursor"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "cursor"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "limit"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "limit"
                    }
                  }
                }],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cursor"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "data"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ChatMessage"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbed"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "file"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "author"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "filename"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "mime"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updated"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "message"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "EmbeddedChatMessage"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "preview"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbedPreview"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "type"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "value"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reactions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }]
}, Qs = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "subscription",
    name: {
      kind: "Name",
      value: "Meet"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "phone"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "nickname"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "space"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "password"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "email"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        arguments: [{
          kind: "Argument",
          name: {
            kind: "Name",
            value: "space"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "space"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "email"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "email"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "nickname"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "nickname"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "password"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "password"
            }
          }
        }, {
          kind: "Argument",
          name: {
            kind: "Name",
            value: "phone"
          },
          value: {
            kind: "Variable",
            name: {
              kind: "Name",
              value: "phone"
            }
          }
        }],
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventChat"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "member"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventMember"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventRtc"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "self"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventSelf"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeeetEventSpace"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "user"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventUser"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventVote"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "EmbeddedChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbedPreview"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedPreviewType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "audio"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "image"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "meta"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "description"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "site_name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "title"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "video"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "height"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "type"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "url"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "width"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessageEmbed"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEmbedType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "file"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "author"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "filename"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "mime"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "updated"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "message"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "EmbeddedChatMessage"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "preview"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbedPreview"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "type"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "value"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventChatMessageEdit"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageEditType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "ChatMessage"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "ChatMessageType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "channel"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "embeds"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "ChatMessageEmbed"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reactions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "text"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "updated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "RTCMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RTCMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "media"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "audio"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "audio_mode"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "video"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reaction"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "emotion"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "gesture"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "IceServers"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RTCICEServerType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "credential"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "urls"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_since"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "force"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMemberSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "connected_since"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetSpace"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetVote"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "DataVoteType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "cases"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "completion"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "majority"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "multiple"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventChat"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventChatType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "edit"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "messages"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetEventChatMessageEdit"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "sent"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "messages"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "ChatMessage"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "remove"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "messages"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reaction"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "messages"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "reactions"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "count"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "name"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "self"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "messages"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "reactions"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "add"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetMember"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "remove"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "id"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "source"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "ban"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "reason"
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "conflict"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "device_id"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "user_id"
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "disconnect"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "kick"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "reason"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "data"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetMember"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "change"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "id"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "connected_since"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "permissions_deny"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "permissions_grant"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "nickname"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "presence_state"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventRtc"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventRTCType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "draw"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "actor"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "primitives"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "line"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "color"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "points"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "thickness"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "target"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "add"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "RTCMember"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "change"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "RTCMember"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "remove"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "data"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "RTCMember"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "ice"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "candidates"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "init"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "codecs"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "ice_servers"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "IceServers"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "midi"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "actor"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "events"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "note"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "on"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "velocity"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "sdp"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "answer"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "offer"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventSelf"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetSelfType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "init"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "membership"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetMemberSpace"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeeetEventSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "add"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "spaces"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetSpace"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "remove"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space_ids"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "change"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "spaces"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetSpace"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventUser"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetUserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "change"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "users"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "User"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventVote"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventVoteType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "tally"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "votes"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cases"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "self"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "votes"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cases"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminate"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "channel"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "start"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetVote"
            }
          }]
        }
      }]
    }
  }]
}, Iv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }]
}, Av = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "add"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetMember"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "remove"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "id"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "source"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "ban"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "reason"
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "conflict"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "device_id"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "user_id"
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "disconnect"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "kick"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "reason"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "data"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetMember"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "change"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "members"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "id"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "connected_since"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "permissions_deny"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "permissions_grant"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "nickname"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "presence_state"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }]
}, Zs = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetSpaceMemberControl"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "ID"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "nickname"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Permission"
            }
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Permission"
            }
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "member"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "control"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "nickname"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "nickname"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "permissions_deny"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "permissions_deny"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "permissions_grant"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "permissions_grant"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Ov = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "RTCMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RTCMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "media"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "audio"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "audio_mode"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "video"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reaction"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "emotion"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "gesture"
            }
          }]
        }
      }]
    }
  }]
}, Vv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "IceServers"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RTCICEServerType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "credential"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "urls"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }]
    }
  }]
}, Pv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventRtc"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventRTCType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "draw"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "actor"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "primitives"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "line"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "color"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "points"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "thickness"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "target"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "add"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "RTCMember"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "change"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "RTCMember"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "remove"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "data"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "RTCMember"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "ice"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "candidates"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "init"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "codecs"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "ice_servers"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "IceServers"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "midi"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "actor"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "events"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "note"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "on"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "velocity"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "sdp"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "answer"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "offer"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "RTCMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RTCMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "media"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "audio"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "audio_mode"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "video"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "reaction"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "emotion"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "gesture"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "IceServers"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RTCICEServerType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "credential"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "urls"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }]
    }
  }]
}, er = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetUpdateRtcReaction"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "emotion"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "gesture"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "reaction"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "emotion"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "emotion"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "gesture"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "gesture"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Lv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMemberSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "connected_since"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetSpace"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_since"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "force"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, Uv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventSelf"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetSelfType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "init"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "membership"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetMemberSpace"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_since"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "force"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMemberSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "connected_since"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetSpace"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }]
}, $v = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }]
}, Bv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_since"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "force"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }]
}, zv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeeetEventSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "add"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "spaces"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetSpace"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "remove"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space_ids"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "change"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "spaces"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "MeetSpace"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_since"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "force"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, jv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "VanityName"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "VanityNameType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }]
}, md = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetMySpacesList"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "meet"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "list"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "spaces"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "joined"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "space"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "FragmentSpread",
                            name: {
                              kind: "Name",
                              value: "MeetSpace"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_since"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "force"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, nr = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MyMeetCalendar"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "states"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "MeetSpaceState"
            }
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "start"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "Time"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "end"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "Time"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "meet"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "list"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "calendar"
                    },
                    arguments: [{
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "start"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "start"
                        }
                      }
                    }, {
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "end"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "end"
                        }
                      }
                    }, {
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "states"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "states"
                        }
                      }
                    }],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpace"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_since"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "force"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, cd = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetSpaceByIdOrVanityName"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "ID"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "meet"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "get"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    },
                    arguments: [{
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "id"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "id"
                        }
                      }
                    }, {
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "vanity_name"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "vanity_name"
                        }
                      }
                    }],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpace"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_since"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "force"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, ud = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetSpaceSave"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "conditions"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "MeetSpaceConditionsInput"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "permissions"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Permission"
            }
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "ID"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "title"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "description"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "save"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "vanity_name"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "vanity_name"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "conditions"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "conditions"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "permissions"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "permissions"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "title"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "title"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "description"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "description"
                    }
                  }
                }],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpace"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_since"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "force"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, Hv = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetSpaceRemove"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "remove"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Wv = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetSpaceLeave"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "leave"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Gv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventUser"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventMeetUserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "change"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "users"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "FragmentSpread",
                name: {
                  kind: "Name",
                  value: "User"
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }]
}, Ti = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "FetchMemberById"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "member"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "get"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetMember"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }]
}, qv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetVote"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "DataVoteType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "cases"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "completion"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "majority"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "multiple"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }]
    }
  }]
}, Xv = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetEventVote"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "EventVoteType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "tally"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "votes"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cases"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "self"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "votes"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cases"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "channel"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "space"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminate"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "channel"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "start"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "MeetVote"
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetVote"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "DataVoteType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "cases"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "completion"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "majority"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "multiple"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }]
    }
  }]
}, ir = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetFetchVotes"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "limit"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "Int"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "cursor"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "list"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "cursor"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "cursor"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "limit"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "limit"
                    }
                  }
                }],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cursor"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "data"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetVote"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetVote"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "DataVoteType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "cases"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "count"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "me"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "completion"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "majority"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "multiple"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "member"
        }
      }]
    }
  }]
}, kd = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetVoteStart"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "title"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "cases"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "ListType",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "String"
              }
            }
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "multiple"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "Boolean"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "conditions"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "MeetVoteConditionsInput"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "cases"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "cases"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "conditions"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "conditions"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "multiple"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "multiple"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "title"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "title"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, ar = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetVoteCast"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "ID"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "cases"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "Int"
            }
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "cases"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "cases"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Yv = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetVoteTerminate"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "ID"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "terminate"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
};
var W = "/src/entities/meet/model/common.ts";
let Ye = /* @__PURE__ */ function(e) {
  return e.Ban = "Ban", e.DisconnectedFromBackend = "DisconnectedFromBackend", e.Error = "Error", e.Kick = "Kick", e.NormalHangup = "NormalHangup", e;
}({});
const ue = h({
  loc: {
    file: W,
    line: 35,
    column: 43
  },
  name: "eventMeetSpaceJoinRequested",
  sid: "6f6if8:/src/entities/meet/model/common.ts:eventMeetSpaceJoinRequested"
}), vd = h({
  loc: {
    file: W,
    line: 38,
    column: 41
  },
  name: "eventMeetSpaceRestoreCall",
  sid: "-4wuhpx:/src/entities/meet/model/common.ts:eventMeetSpaceRestoreCall"
}), Ma = h({
  loc: {
    file: W,
    line: 39,
    column: 48
  },
  name: "eventMeetSpaceJoinedSuccessfully",
  sid: "nzq41d:/src/entities/meet/model/common.ts:eventMeetSpaceJoinedSuccessfully"
}), ui = h({
  loc: {
    file: W,
    line: 40,
    column: 35
  },
  name: "eventMeetSpaceLeave",
  sid: "dxkta2:/src/entities/meet/model/common.ts:eventMeetSpaceLeave"
}), Ne = h({
  loc: {
    file: W,
    line: 41,
    column: 37
  },
  name: "eventMeetSpaceCleanup",
  sid: "u6x1ok:/src/entities/meet/model/common.ts:eventMeetSpaceCleanup"
}), Sd = h({
  loc: {
    file: W,
    line: 43,
    column: 42
  },
  name: "eventCallRatedSuccessfully",
  sid: "lwyajg:/src/entities/meet/model/common.ts:eventCallRatedSuccessfully"
});
w({
  and: [{
    clock: Sd,
    target: Ne
  }],
  or: {
    loc: {
      file: W,
      line: 45,
      column: 0
    },
    sid: "vv9tmt:/src/entities/meet/model/common.ts:"
  }
});
const tr = x(!1, {
  loc: {
    file: W,
    line: 47,
    column: 35
  },
  name: "$showRateACallModal",
  sid: "-alc5cg:/src/entities/meet/model/common.ts:$showRateACallModal"
}).reset(ue, Ne), pd = h({
  loc: {
    file: W,
    line: 52,
    column: 42
  },
  name: "eventMeetSpaceExitFinished",
  sid: "-c3wam8:/src/entities/meet/model/common.ts:eventMeetSpaceExitFinished"
}), wa = h({
  loc: {
    file: W,
    line: 54,
    column: 48
  },
  name: "eventGraphqlSubscriptionComplete",
  sid: "fxkm08:/src/entities/meet/model/common.ts:eventGraphqlSubscriptionComplete"
}), dr = h({
  loc: {
    file: W,
    line: 55,
    column: 47
  },
  name: "eventGraphqlSubscriptionStarted",
  sid: "-nhw1rc:/src/entities/meet/model/common.ts:eventGraphqlSubscriptionStarted"
}), lr = h({
  loc: {
    file: W,
    line: 57,
    column: 35
  },
  name: "eventControlsToggle",
  sid: "7guf0m:/src/entities/meet/model/common.ts:eventControlsToggle"
}), Kv = x(!0, {
  loc: {
    file: W,
    line: 58,
    column: 31
  },
  name: "$controlsOpened",
  sid: "l41m3s:/src/entities/meet/model/common.ts:$controlsOpened"
}).on(lr, (e) => !e), Jv = h({
  loc: {
    file: W,
    line: 63,
    column: 39
  },
  name: "eventCallViewFullToggle",
  sid: "bcv7tv:/src/entities/meet/model/common.ts:eventCallViewFullToggle"
}), Qv = x(!0, {
  and: {
    name: "$loading"
  },
  loc: {
    file: W,
    line: 64,
    column: 24
  },
  name: "$loading",
  sid: "-xclcn6:/src/entities/meet/model/common.ts:$loading"
}), or = h({
  loc: {
    file: W,
    line: 65,
    column: 26
  },
  name: "eventError",
  sid: "-x5jq7j:/src/entities/meet/model/common.ts:eventError"
}), Ci = x([], {
  and: {},
  loc: {
    file: W,
    line: 66,
    column: 28
  },
  name: "$fatalErrors",
  sid: "qkag5n:/src/entities/meet/model/common.ts:$fatalErrors"
}), sr = (e) => {
  Ci.on(e.failData, (n, i) => [...n, i]);
};
Ci.on(or, (e, n) => e.concat(n)).reset(Ne, ue);
Ci.watch((e) => {
  for (const n of e)
    console.error(n);
});
const Nd = h({
  loc: {
    file: W,
    line: 92,
    column: 30
  },
  name: "eventHideAlert",
  sid: "ukdne8:/src/entities/meet/model/common.ts:eventHideAlert"
}), rr = h({
  loc: {
    file: W,
    line: 94,
    column: 29
  },
  name: "eventNewAlert",
  sid: "450gre:/src/entities/meet/model/common.ts:eventNewAlert"
}), Ea = x([], {
  loc: {
    file: W,
    line: 96,
    column: 23
  },
  name: "$alerts",
  sid: "uwmibn:/src/entities/meet/model/common.ts:$alerts"
}).reset(Ne, ue);
Ea.on(Nd, (e, n) => e.filter((i) => i.id !== n)).on(rr, (e, n) => [...e, {
  id: mi("alert"),
  ...n
}]);
const Mi = $(async ({
  spaceIdOrVanityName: e
}) => {
  if (/^\d+$/.test(e))
    return e;
  try {
    return (await Q().query({
      query: cd,
      variables: {
        vanity_name: e
      }
    })).data.user.meet.get.space.id;
  } catch (n) {
    throw n instanceof ua && n.message === "sql: no rows in result set" ? new Error("404 Не найдено") : n;
  }
}, {
  loc: {
    file: W,
    line: 112,
    column: 48
  },
  name: "fetchMeetSpaceByIdOrVanityNameFx",
  sid: "e0110:/src/entities/meet/model/common.ts:fetchMeetSpaceByIdOrVanityNameFx"
});
w({
  and: [{
    clock: ue,
    target: Mi
  }],
  or: {
    loc: {
      file: W,
      line: 135,
      column: 0
    },
    sid: "jov5td:/src/entities/meet/model/common.ts:"
  }
});
sr(Mi);
const Jn = x(null, {
  loc: {
    file: W,
    line: 141,
    column: 31
  },
  name: "$currentSpaceId",
  sid: "-bwlxgs:/src/entities/meet/model/common.ts:$currentSpaceId"
}).on(Mi.doneData, (e, n) => n).reset(pd), mr = Yt.map((e) => Xn(e) ? e.data.type === "authenticated" ? e.data.user.id : e.data.type : null);
w({
  and: [{
    clock: [vd, mr],
    source: Jn,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ze,
    fn: (e) => ({
      spaceIdOrVanityName: e
    }),
    target: ue
  }],
  or: {
    loc: {
      file: W,
      line: 153,
      column: 0
    },
    sid: "klutf1:/src/entities/meet/model/common.ts:"
  }
});
const cr = x(null, {
  and: {
    name: "$roomExit"
  },
  loc: {
    file: W,
    line: 162,
    column: 31
  },
  name: "$roomExitReason",
  sid: "-9fs42o:/src/entities/meet/model/common.ts:$roomExitReason"
}).reset(ue, pd).on(wa, () => ({
  message: D("ROOM.EXIT_REASON.GENERIC_DISCONNECT"),
  type: Ye.DisconnectedFromBackend
}));
var Rn = "/src/entities/meet/model/aside-panel.ts";
const ur = h({
  loc: {
    file: Rn,
    line: 6,
    column: 37
  },
  name: "eventAsidePanelClosed",
  sid: "-l9rzsc:/src/entities/meet/model/aside-panel.ts:eventAsidePanelClosed"
}), fd = h({
  loc: {
    file: Rn,
    line: 7,
    column: 36
  },
  name: "eventToggleUsersList",
  sid: "-g9ub9i:/src/entities/meet/model/aside-panel.ts:eventToggleUsersList"
}), Qn = h({
  loc: {
    file: Rn,
    line: 8,
    column: 31
  },
  name: "eventToggleChat",
  sid: "cs34j0:/src/entities/meet/model/aside-panel.ts:eventToggleChat"
}), Fd = h({
  loc: {
    file: Rn,
    line: 9,
    column: 32
  },
  name: "eventToggleVotes",
  sid: "mdc7jz:/src/entities/meet/model/aside-panel.ts:eventToggleVotes"
}), gd = h({
  loc: {
    file: Rn,
    line: 10,
    column: 35
  },
  name: "eventToggleSettings",
  sid: "-xdgwd8:/src/entities/meet/model/aside-panel.ts:eventToggleSettings"
});
let re = /* @__PURE__ */ function(e) {
  return e[e.UserList = 0] = "UserList", e[e.Chat = 1] = "Chat", e[e.Votes = 2] = "Votes", e[e.Settings = 3] = "Settings", e;
}({});
const xn = x(null, {
  loc: {
    file: Rn,
    line: 19,
    column: 27
  },
  name: "$asidePanel",
  sid: "-em4quf:/src/entities/meet/model/aside-panel.ts:$asidePanel"
}).reset(ur, Ne).on(Qn, (e) => e === re.Chat ? null : re.Chat).on(fd, (e) => e === re.UserList ? null : re.UserList).on(Fd, (e) => e === re.Votes ? null : re.Votes).on(gd, (e) => e === re.Settings ? null : re.Settings);
function ki(e, n) {
  if (e === null || n === null || e === void 0 || n === void 0)
    return e === n;
  if (typeof e != typeof n)
    return !1;
  if (Object.is(e, n))
    return !0;
  if (typeof e == "object") {
    if (e.constructor !== n.constructor)
      return !1;
    if (Array.isArray(e)) {
      if (e.length !== n.length)
        return !1;
      for (let a = 0; a < e.length; ++a)
        if (!ki(e[a], n[a]))
          return !1;
      return !0;
    }
    if (e.constructor === RegExp)
      return e.source === n.source && e.flags === n.flags;
    if (e.valueOf !== Object.prototype.valueOf)
      return e.valueOf() === n.valueOf();
    if (e.toString !== Object.prototype.toString)
      return e.toString() === n.toString();
    const i = Object.keys(e);
    if (i.length !== Object.keys(n).length)
      return !1;
    for (let a = 0; a < i.length; ++a)
      if (!Object.prototype.hasOwnProperty.call(n, i[a]))
        return !1;
    for (let a = 0; a < i.length; ++a) {
      const t = i[a];
      if (!(t === "_owner" && e.$$typeof) && !ki(e[t], n[t]))
        return !1;
    }
    return !0;
  }
  return !1;
}
var hd = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function bd(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
var Zi = { exports: {} };
(function(e, n) {
  (function(i, a) {
    a(n);
  })(hd, function(i) {
    function a(m) {
      return m != null && typeof m == "object" && m["@@functional/placeholder"] === !0;
    }
    function t(m) {
      return function p(k) {
        return arguments.length === 0 || a(k) ? p : m.apply(this, arguments);
      };
    }
    function l(m) {
      return function p(k, F) {
        switch (arguments.length) {
          case 0:
            return p;
          case 1:
            return a(k) ? p : t(function(v) {
              return m(k, v);
            });
          default:
            return a(k) && a(F) ? p : a(k) ? t(function(v) {
              return m(v, F);
            }) : a(F) ? t(function(v) {
              return m(k, v);
            }) : m(k, F);
        }
      };
    }
    function o(m) {
      return function p(k, F, v) {
        switch (arguments.length) {
          case 0:
            return p;
          case 1:
            return a(k) ? p : l(function(N, y) {
              return m(k, N, y);
            });
          case 2:
            return a(k) && a(F) ? p : a(k) ? l(function(N, y) {
              return m(N, F, y);
            }) : a(F) ? l(function(N, y) {
              return m(k, N, y);
            }) : t(function(N) {
              return m(k, F, N);
            });
          default:
            return a(k) && a(F) && a(v) ? p : a(k) && a(F) ? l(function(N, y) {
              return m(N, y, v);
            }) : a(k) && a(v) ? l(function(N, y) {
              return m(N, F, y);
            }) : a(F) && a(v) ? l(function(N, y) {
              return m(k, N, y);
            }) : a(k) ? t(function(N) {
              return m(N, F, v);
            }) : a(F) ? t(function(N) {
              return m(k, N, v);
            }) : a(v) ? t(function(N) {
              return m(k, F, N);
            }) : m(k, F, v);
        }
      };
    }
    function s(m) {
      return Object.prototype.toString.call(m) === "[object Object]";
    }
    function r(m, p) {
      return Object.prototype.hasOwnProperty.call(p, m);
    }
    var c = o(function(p, k, F) {
      var v = {}, N;
      for (N in k)
        r(N, k) && (v[N] = r(N, F) ? p(N, k[N], F[N]) : k[N]);
      for (N in F)
        r(N, F) && !r(N, v) && (v[N] = F[N]);
      return v;
    }), u = o(function m(p, k, F) {
      return c(function(v, N, y) {
        return s(N) && s(y) ? m(p, N, y) : p(v, N, y);
      }, k, F);
    }), S = o(function(p, k, F) {
      return u(function(v, N, y) {
        return p(N, y);
      }, k, F);
    });
    i.mergeDeepWith = S, Object.defineProperty(i, "__esModule", { value: !0 });
  });
})(Zi, Zi.exports);
var kr = Zi.exports, vr = kr.mergeDeepWith;
const yd = /* @__PURE__ */ bd(vr), Sr = (e) => {
  var i;
  if (e == null)
    return "event";
  const n = (i = e.conditions) == null ? void 0 : i.terminate;
  return !(n != null && n.empty) && (n == null ? void 0 : n.time) == null ? "room" : "event";
}, Td = (e, n) => n != null ? new Set([...e ?? [], ...n.permissions_grant].filter((i) => !n.permissions_deny.includes(i))) : new Set(e);
var ee = "/src/entities/meet/model/subscription.ts";
const wi = h({
  loc: {
    file: ee,
    line: 35,
    column: 2
  },
  name: "eventMeetSubscriptionSelfReceived",
  sid: "tjf57r:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionSelfReceived"
}), pr = h({
  loc: {
    file: ee,
    line: 37,
    column: 53
  },
  name: "eventMeetSubscriptionSelfInitReceived",
  sid: "qnbmi9:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionSelfInitReceived"
}), _a = h({
  loc: {
    file: ee,
    line: 40,
    column: 2
  },
  name: "eventMeetSubscriptionMemberReceived",
  sid: "-ite3f1:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionMemberReceived"
}), Cd = h({
  loc: {
    file: ee,
    line: 43,
    column: 2
  },
  name: "eventMeetSubscriptionRtcIceCandidateReceived",
  sid: "bozjhv:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionRtcIceCandidateReceived"
}), Ra = h({
  loc: {
    file: ee,
    line: 46,
    column: 2
  },
  name: "eventMeetSubscriptionRtcInitReceived",
  sid: "-9ejhlu:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionRtcInitReceived"
}), Md = h({
  loc: {
    file: ee,
    line: 49,
    column: 2
  },
  name: "eventMeetSubscriptionRtcSDPReceived",
  sid: "-n71x6w:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionRtcSDPReceived"
}), Nr = h({
  loc: {
    file: ee,
    line: 52,
    column: 2
  },
  name: "eventMeetSubscriptionRtcMidiEventsReceived",
  sid: "-n4n041:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionRtcMidiEventsReceived"
}), Ei = h({
  loc: {
    file: ee,
    line: 55,
    column: 2
  },
  name: "eventMeetSubscriptionRtcMemberReceived",
  sid: "t7ojw2:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionRtcMemberReceived"
}), wd = h({
  loc: {
    file: ee,
    line: 60,
    column: 2
  },
  name: "eventMeetSubscriptionVoteReceived",
  sid: "-cbman3:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionVoteReceived"
}), Ed = h({
  loc: {
    file: ee,
    line: 63,
    column: 2
  },
  name: "eventMeetSubscriptionChatReceived",
  sid: "-bacway:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionChatReceived"
}), _d = h({
  loc: {
    file: ee,
    line: 66,
    column: 2
  },
  name: "eventMeetSubscriptionSpaceReceived",
  sid: "6q7c07:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionSpaceReceived"
}), xa = h({
  loc: {
    file: ee,
    line: 68,
    column: 40
  },
  name: "eventRestartSubscription",
  sid: "qdlj8s:/src/entities/meet/model/subscription.ts:eventRestartSubscription"
}), $n = h({
  loc: {
    file: ee,
    line: 71,
    column: 2
  },
  name: "eventMeetSubscriptionStatusUpdated",
  sid: "-5ccept:/src/entities/meet/model/subscription.ts:eventMeetSubscriptionStatusUpdated"
}), Rd = x(en(), {
  loc: {
    file: ee,
    line: 73,
    column: 39
  },
  name: "$meetSubscriptionStatus",
  sid: "-xyrng0:/src/entities/meet/model/subscription.ts:$meetSubscriptionStatus"
}).on($n, (e, n) => n), Da = x(null, {
  loc: {
    file: ee,
    line: 77,
    column: 22
  },
  name: "$subscription",
  sid: "uxv8op:/src/entities/meet/model/subscription.ts:$subscription"
}), xd = Tn({
  and: {
    effect: $(async ({
      currentSpaceId: e,
      email: n,
      nickname: i,
      password: a,
      subscription: t
    }) => {
      if (t != null)
        for (; !t.closed; )
          t.unsubscribe(), await Fr(100);
      let l = !1;
      return Q().subscribe({
        query: Qs,
        variables: {
          email: n,
          nickname: i,
          password: a,
          space: e
        }
      }).subscribe({
        complete() {
          wa(), $n(en());
        },
        error(o) {
          o.message === "Socket closed" || o.message === "connection exists" || o.message === "no connection" ? xa() : ($n(ka(o)), ui({
            message: $o(o, {
              invalid_state: {
                "peer already exists": "Конфликт, пользователь уже присутствует во встрече",
                "space terminated": "Данная встреча уже завершена"
              }
            }),
            type: Ye.Error
          }));
        },
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        next(o) {
          var r, c, u, S, m, p;
          o.errors && ui({
            message: o.errors,
            type: Ye.Error
          }), l || (l = !0, dr(), $n(he(void 0)));
          const s = (r = o.data) == null ? void 0 : r.meet;
          s != null && (s.self != null ? (wi(s.self), s.self.init && pr()) : ((c = s.rtc) == null ? void 0 : c.init) != null ? Ra(s.rtc.init) : ((u = s.rtc) == null ? void 0 : u.member) != null ? Ei(s.rtc.member) : ((S = s.rtc) == null ? void 0 : S.ice) != null ? Cd(s.rtc.ice) : ((m = s.rtc) == null ? void 0 : m.sdp) != null ? Md(s.rtc.sdp) : ((p = s.rtc) == null ? void 0 : p.midi) != null ? Nr(s.rtc.midi) : s.member != null ? _a(s.member) : s.chat != null ? Ed(s.chat) : s.vote != null ? wd(s.vote) : s.space != null ? _d(s.space) : console.warn("Unknown subscription event received", s));
        }
      });
    }, {
      loc: {
        file: ee,
        line: 80,
        column: 10
      },
      name: "effect",
      sid: "-oc38y6:/src/entities/meet/model/subscription.ts:effect"
    }),
    mapParams: (e, n) => ({
      currentSpaceId: e,
      /* TODO */
      email: void 0,
      nickname: n.$anonymousNickname,
      /* TODO */
      password: void 0,
      subscription: n.$subscription
    }),
    source: {
      $anonymousNickname: Na,
      $subscription: Da
    }
  },
  or: {
    loc: {
      file: ee,
      line: 79,
      column: 33
    },
    name: "subscribeToMeetFx",
    sid: "bsrixg:/src/entities/meet/model/subscription.ts:subscribeToMeetFx"
  }
});
Da.on(xd.doneData, (e, n) => n);
w({
  and: [{
    clock: Mi.doneData,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ze,
    target: xd
  }],
  or: {
    loc: {
      file: ee,
      line: 196,
      column: 0
    },
    sid: "-95u8rm:/src/entities/meet/model/subscription.ts:"
  }
});
const fr = Tn({
  and: {
    effect: $((e) => {
      e == null || e.unsubscribe();
    }, {
      loc: {
        file: ee,
        line: 204,
        column: 10
      },
      name: "effect",
      sid: "kt4k:/src/entities/meet/model/subscription.ts:effect"
    }),
    source: Da
  },
  or: {
    loc: {
      file: ee,
      line: 203,
      column: 35
    },
    name: "closeSubscriptionFx",
    sid: "-q5igis:/src/entities/meet/model/subscription.ts:closeSubscriptionFx"
  }
}), Fr = (e) => new Promise((n) => setTimeout(() => {
  n(void 0);
}, e));
var Oe = "/src/entities/meet/model/space.ts";
const gr = x(en(), {
  loc: {
    file: Oe,
    line: 32,
    column: 25
  },
  name: "$myPeerId",
  sid: "-l2ihky:/src/entities/meet/model/space.ts:$myPeerId"
}), Ia = x(en(), {
  loc: {
    file: Oe,
    line: 34,
    column: 46
  },
  name: "$meetSpacesMembershipBySpaceId",
  sid: "-n12u8i:/src/entities/meet/model/space.ts:$meetSpacesMembershipBySpaceId"
});
Ia.on(wi, (e, n) => {
  if (n.membership == null)
    return e;
  const i = Se((a) => a.ref, /* @__PURE__ */ new Map(), e);
  return n.membership.forEach((a) => {
    i.set(a.space.id, a);
  }), he({
    ref: i
  });
});
const fe = x([], {
  loc: {
    file: Oe,
    line: 66,
    column: 22
  },
  name: "$peers",
  sid: "hu79hc:/src/entities/meet/model/space.ts:$peers"
}), Dd = fe.map((e) => ({
  ref: new Map(e.filter((n) => n.stream != null).flatMap((n) => n.stream.getTracks().map((i) => [i.id, n.stream.id])))
}));
Mn(Dd, {
  log: "enabled"
});
fe.reset(Ne);
const Aa = x(en(), {
  loc: {
    file: Oe,
    line: 81,
    column: 36
  },
  name: "$meetSpacesBySpaceId",
  sid: "kzx7vj:/src/entities/meet/model/space.ts:$meetSpacesBySpaceId"
});
Aa.on(wi, (e, n) => {
  var a;
  const i = Se((t) => t.ref, /* @__PURE__ */ new Map(), e);
  return (a = n.membership) == null || a.forEach((t) => {
    i.set(t.space.id, t.space);
  }), he({
    ref: i
  });
}).on(_d, (e, n) => {
  const i = Se((a) => a.ref, /* @__PURE__ */ new Map(), e);
  return n.add != null && n.add.spaces.forEach((a) => {
    i.set(a.id, a);
  }), n.change != null && n.change.spaces.forEach((a) => {
    i.set(a.id, a);
  }), n.remove != null && n.remove.space_ids.forEach((a) => {
    i.delete(a);
  }), he({
    ref: i
  });
});
const _i = we({
  and: [{
    currentSpaceId: Jn,
    meetSpaces: Aa
  }],
  or: {
    loc: {
      file: Oe,
      line: 114,
      column: 33
    },
    name: "$currentMeetSpace",
    sid: "-q6u26d:/src/entities/meet/model/space.ts:$currentMeetSpace"
  }
}).map(({
  currentSpaceId: e,
  meetSpaces: n
}) => Se((i) => e != null ? i.ref.get(e) ?? null : null, null, n)), Oa = x([], {
  loc: {
    file: Oe,
    line: 128,
    column: 40
  },
  name: "$currentMeetSpaceMembers",
  sid: "-dmci2n:/src/entities/meet/model/space.ts:$currentMeetSpaceMembers"
}).on(_a, (e, n) => {
  let i = [...e];
  if (n.add != null && (i = [...i, ...n.add.members]), n.remove != null) {
    const a = new Set(n.remove.members.map((t) => t.id));
    i = i.filter((t) => !a.has(t.id));
  }
  return n.data != null && (i = [...n.data.members]), n.change != null && n.change.members.forEach((a) => {
    const t = i.findIndex((l) => l.id === a.id);
    t !== -1 ? i[t] = yd(
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      (l, o) => o ?? l,
      i[t],
      a
    ) : i.push({
      ...a,
      permissions_deny: a.permissions_deny ?? [],
      permissions_grant: a.permissions_grant ?? []
    });
  }), i;
}), Va = we({
  and: [
    {
      currentMeetSpaceMembers: Oa,
      currentSpaceId: Jn,
      meetSpacesMembershipBySpaceId: Ia,
      peers: fe
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    ({
      currentMeetSpaceMembers: e,
      currentSpaceId: n,
      meetSpacesMembershipBySpaceId: i,
      peers: a
    }) => {
      var l;
      if (n == null)
        return [];
      if (!Xn(i))
        return [];
      const t = (l = i.data.ref.get(n)) == null ? void 0 : l.id;
      return e.filter((o) => o.id !== t).map((o) => ({
        member: o,
        peer: a.find((s) => s.id === o.id)
      }));
    }
  ],
  or: {
    loc: {
      file: Oe,
      line: 165,
      column: 48
    },
    name: "$currentMeetSpaceMembersExceptMe",
    sid: "rre6kx:/src/entities/meet/model/space.ts:$currentMeetSpaceMembersExceptMe"
  }
});
Mn(Va, {
  log: "enabled"
});
const an = we({
  and: [
    {
      currentMeetSpaceMembers: Oa,
      currentSpaceId: Jn,
      meetSpacesMembershipBySpaceId: Ia,
      peers: fe
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    ({
      currentMeetSpaceMembers: e,
      currentSpaceId: n,
      meetSpacesMembershipBySpaceId: i,
      peers: a
    }) => {
      var o;
      if (n == null || !Xn(i))
        return null;
      const t = (o = i.data.ref.get(n)) == null ? void 0 : o.id, l = e.find((s) => s.id === t);
      return {
        member: l,
        peer: a.find((s) => s.id === (l == null ? void 0 : l.id))
      };
    }
  ],
  or: {
    loc: {
      file: Oe,
      line: 195,
      column: 35
    },
    name: "$currentMeetSpaceMe",
    sid: "-bw8m7c:/src/entities/meet/model/space.ts:$currentMeetSpaceMe"
  }
});
Mn(an, {
  log: "enabled"
});
const cn = x(/* @__PURE__ */ new Set(), {
  loc: {
    file: Oe,
    line: 225,
    column: 46
  },
  name: "$currentMeetSpaceMyPermissions",
  sid: "mhnkv0:/src/entities/meet/model/space.ts:$currentMeetSpaceMyPermissions"
});
cn.on(we({
  and: [{
    currentMeetSpace: _i,
    currentMeetSpaceMemberMe: an
  }],
  or: {
    loc: {
      file: Oe,
      line: 230,
      column: 2
    },
    sid: "hdyi6q:/src/entities/meet/model/space.ts:"
  }
}), (e, n) => {
  var a, t;
  const i = Td((a = n.currentMeetSpace) == null ? void 0 : a.permissions, (t = n.currentMeetSpaceMemberMe) == null ? void 0 : t.member);
  return ki([...e], [...i]) ? e : i;
});
var un = "/src/entities/meet/model/reaction-emojis.ts";
const Pa = h({
  loc: {
    file: un,
    line: 10,
    column: 44
  },
  name: "eventRoomReactionPanelToggle",
  sid: "j9lplr:/src/entities/meet/model/reaction-emojis.ts:eventRoomReactionPanelToggle"
}), hr = h({
  loc: {
    file: un,
    line: 11,
    column: 43
  },
  name: "eventRoomReactionPanelReset",
  sid: "3myats:/src/entities/meet/model/reaction-emojis.ts:eventRoomReactionPanelReset"
}), Id = x(!1, {
  loc: {
    file: un,
    line: 13,
    column: 40
  },
  name: "$roomReactionPanelOpened",
  sid: "-sq9557:/src/entities/meet/model/reaction-emojis.ts:$roomReactionPanelOpened"
}).on(Pa, (e) => !e).reset(hr), Ad = h({
  loc: {
    file: un,
    line: 25,
    column: 39
  },
  name: "eventRoomReactionUpdate",
  sid: "-ajqwrc:/src/entities/meet/model/reaction-emojis.ts:eventRoomReactionUpdate"
}), br = h({
  loc: {
    file: un,
    line: 26,
    column: 38
  },
  name: "eventRoomReactionReset",
  sid: "-bmcrxe:/src/entities/meet/model/reaction-emojis.ts:eventRoomReactionReset"
}), Od = 2e3, yr = x([], {
  loc: {
    file: un,
    line: 29,
    column: 30
  },
  name: "$roomReactions",
  sid: "emvdex:/src/entities/meet/model/reaction-emojis.ts:$roomReactions"
}).on(Ad, (e, n) => {
  const i = e.filter(({
    created: a
  }) => Date.now() - a.getTime() < Od);
  return i.push(...n), i;
}).reset(br, Ne, ue), Tr = [];
w({
  and: [{
    clock: Ei,
    fn: (e) => e.change != null ? e.change.filter((n) => n.reaction.emotion != null).map((n) => ({
      author: n.id,
      created: /* @__PURE__ */ new Date(),
      id: Date.now(),
      left: `${Math.floor(Math.random() * 10)}vw`,
      reaction: n.reaction.emotion ?? ""
    })) : Tr,
    target: Ad
  }],
  or: {
    loc: {
      file: un,
      line: 45,
      column: 0
    },
    sid: "-i205f1:/src/entities/meet/model/reaction-emojis.ts:"
  }
});
var Ee = "/src/entities/meet/model/vote.ts";
const jn = h("eventVoteCreateFormVisible", {
  loc: {
    file: Ee,
    line: 30,
    column: 42
  },
  name: "eventVoteCreateFormVisible",
  sid: "-x8b414:/src/entities/meet/model/vote.ts:eventVoteCreateFormVisible"
}), Vd = h({
  loc: {
    file: Ee,
    line: 34,
    column: 34
  },
  name: "eventLoadMoreVotes",
  sid: "-hbcug9:/src/entities/meet/model/vote.ts:eventLoadMoreVotes"
}), Pd = $({
  loc: {
    file: Ee,
    line: 36,
    column: 26
  },
  name: "castVoteFx",
  sid: "-m5wh2f:/src/entities/meet/model/vote.ts:castVoteFx"
}), Ri = $(({
  cursor: e,
  distanceFromEnd: n
}) => Q().query({
  query: ir,
  variables: {
    cursor: e,
    limit: Math.max(n, 5)
  }
}).then((i) => i.data.meet.vote.list), {
  loc: {
    file: Ee,
    line: 41,
    column: 28
  },
  name: "fetchVotesFx",
  sid: "-hl5199:/src/entities/meet/model/vote.ts:fetchVotesFx"
});
w({
  and: [{
    clock: Ma,
    fn: () => ({
      cursor: "",
      distanceFromEnd: 0
    }),
    target: Ri
  }],
  or: {
    loc: {
      file: Ee,
      line: 51,
      column: 0
    },
    sid: "7exatd:/src/entities/meet/model/vote.ts:"
  }
});
const Cr = x("", {
  loc: {
    file: Ee,
    line: 57,
    column: 25
  },
  name: "$votesListCursor",
  sid: "-vvwp8n:/src/entities/meet/model/vote.ts:$votesListCursor"
}).on(Ri.doneData, (e, n) => n.cursor ?? null), Ld = x([], {
  loc: {
    file: Ee,
    line: 62,
    column: 21
  },
  name: "$votesFromBE",
  sid: "i4a518:/src/entities/meet/model/vote.ts:$votesFromBE"
});
Ld.reset(ue, Ne).on(Ri.doneData, (e, n) => [...n.data, ...e]).on(wd, (e, n) => {
  var t, l, o, s;
  const i = (l = (t = n.tally) == null ? void 0 : t.count) == null ? void 0 : l.votes, a = (s = (o = n.tally) == null ? void 0 : o.self) == null ? void 0 : s.votes;
  if (n.start != null)
    return [...e, n.start];
  if (n.terminate != null) {
    const r = n.terminate.id;
    return e.filter((c) => c.id !== r);
  } else {
    if (i != null)
      return e.map((r) => {
        const c = i.find((u) => u.id === r.id);
        return c == null ? r : {
          ...r,
          cases: r.cases.map((u, S) => ({
            ...u,
            count: c.cases[S]
          }))
        };
      });
    if (a != null)
      return e.map((r) => {
        const c = a.find((u) => u.id === r.id);
        return c == null ? r : {
          ...r,
          cases: r.cases.map((u, S) => c.cases.includes(S) ? {
            ...u,
            me: !0
          } : u)
        };
      });
  }
});
const Ud = Ld.map((e) => e.map((n) => ({
  ...n,
  isActive: n.terminated == null && !n.cases.some((i) => i.me)
}))), Mr = Ud.map((e) => e.filter((n) => n.isActive).length), Zv = $({
  handler: (e) => Q().mutate({
    mutation: kd,
    variables: {
      cases: e.cases,
      conditions: e.conditions,
      multiple: e.multiple,
      title: e.title
    }
  })
}, {
  loc: {
    file: Ee,
    line: 176,
    column: 26
  },
  name: "postVoteFx",
  sid: "xec4cd:/src/entities/meet/model/vote.ts:postVoteFx"
}), wr = x(!1, {
  loc: {
    file: Ee,
    line: 195,
    column: 40
  },
  name: "$isVoteCreateFormVisible",
  sid: "8klxca:/src/entities/meet/model/vote.ts:$isVoteCreateFormVisible"
}).on(jn, (e, n) => n).reset(ue, Ne);
w({
  and: [{
    clock: Vd,
    source: Cr,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => ze(e),
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => ({
      cursor: e,
      distanceFromEnd: n.distanceFromEnd
    }),
    target: Ri
  }],
  or: {
    loc: {
      file: Ee,
      line: 199,
      column: 0
    },
    sid: "x2koa6:/src/entities/meet/model/vote.ts:"
  }
});
Pd.use(({
  cases: e,
  voteId: n
}) => Q().mutate({
  mutation: ar,
  variables: {
    cases: e,
    id: n
  }
}).then((i) => {
  var a;
  return ((a = i.data) == null ? void 0 : a.meet.vote.cast) ?? !1;
}));
const _t = $((e) => (
  /* TODOgetApolloClient()
    .mutate({ mutation: VoteEndDocument, variables: { id: vote.id } })
    .then(() => {}) */
  Promise.resolve()
), {
  loc: {
    file: Ee,
    line: 222,
    column: 25
  },
  name: "endVoteFx",
  sid: "-d6skpr:/src/entities/meet/model/vote.ts:endVoteFx"
}), Er = /* @__PURE__ */ g((e) => /* @__PURE__ */ d(_, {
  alignContent: "center",
  alignItems: "center",
  flex: "1",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
  ...e
}));
Er.displayName = "CallsView";
const _r = /* @__PURE__ */ g(() => {
  const e = me(), n = E(() => {
    ui({
      message: void 0,
      type: Ye.NormalHangup
    });
  }, []);
  return /* @__PURE__ */ d(Yn, {
    bg: "red.50",
    h: {
      base: "48px",
      lg: "48px",
      md: "36px"
    },
    onPress: n,
    w: {
      base: "48px",
      lg: "48px",
      md: "36px"
    },
    children: /* @__PURE__ */ d(ps, {
      fill: e.colors.white,
      height: "24",
      width: "24"
    })
  });
});
_r.displayName = "ControlHangup";
const Rr = 64, Yi = 64, xr = 376, Dr = 720, eS = ({
  asidePanelOpened: e,
  callViewFull: n
}) => {
  const i = A(Id), {
    height: a,
    width: t
  } = wn(), l = mn(), [o] = Zo([{
    orientation: "portrait"
  }]), s = bt({
    base: t,
    lg: e ? t - xr : t
  }), r = bt({
    base: a - ((n || l === "base") && o ? 108 : 0) - (o ? 80 : 0) - Rr - (o && l === "base" && i || i && l !== "base" ? Yi : 0),
    lg: Math.max(a - (n ? 112 : 0) - 56 - (i ? Yi : 0), Dr - (n ? 112 : 0) - 56 - (i ? Yi : 0))
  }), c = P(() => {
    if (n)
      return {
        height: r,
        width: s
      };
    const u = 16 / 9 < s / r;
    return {
      height: u ? r : s / 16 * 9,
      width: u ? r / 9 * 16 : s
    };
  }, [n, s, r]);
  return P(() => ({
    containerSize: c,
    viewCallsHeight: r,
    viewCallsWidth: s,
    winHeight: a,
    winWidth: t
  }), [c, r, s, a, t]);
};
function Ir(e) {
  const n = mn();
  return P(() => e === 1 ? "100%" : n === "base" ? e === 2 || e === 4 ? "50%" : e === 3 ? "33%" : `${90 / e * 2}%` : e < 5 ? "50%" : e > 9 ? `${100 / Math.sqrt(e)}%` : "33.333%", [n, e]);
}
function Ar(e) {
  const n = mn();
  return P(() => e === 1 ? "100%" : n === "base" ? e < 4 ? "100%" : "50%" : e < 5 ? "50%" : e > 9 ? `${100 / Math.sqrt(e)}%` : "33.333%", [n, e]);
}
function nS(e, n) {
  const i = mn(), a = Ir(i === "base" || n ? 1 : e === 1 ? 2 : e), t = Ar(i === "base" || n ? 1 : e === 1 ? 2 : e);
  return {
    cardHeight: a,
    cardWidth: t
  };
}
const Rt = j.object({
  password: j.string({
    required_error: D("VALIDATION.ERRORS.REQUIRED")
  }).nonempty(D("VALIDATION.ERRORS.REQUIRED"))
}).strict(), Or = /* @__PURE__ */ g(({
  onCancel: e,
  onSubmit: n,
  variant: i
}) => {
  const {
    control: a,
    handleSubmit: t
  } = _n({
    mode: "all",
    resolver: Kn(Rt)
  });
  return /* @__PURE__ */ d(ya, {
    hasCancel: !0,
    okButtonText: "Продолжить",
    onClose: e,
    onOk: t((o) => {
      const s = Rt.parse(o);
      n(s.password);
    }),
    title: "Для входа в комнату необходим пароль",
    children: /* @__PURE__ */ d(de, {
      control: a,
      name: "password",
      render: ({
        field: o,
        fieldState: {
          error: s
        }
      }) => /* @__PURE__ */ d(os, {
        errorMessage: s == null ? void 0 : s.message,
        isInvalid: !!s,
        onBlur: o.onBlur,
        onChangeText: o.onChange,
        placeholder: i === "room" ? "пароль для входа в комнату" : "пароль чтобы присоединиться к встрече",
        value: o.value
      })
    })
  });
});
Or.displayName = "PasswordModal";
var Zn = "/src/entities/webrtc/model/devices.ts";
const $d = h({
  loc: {
    file: Zn,
    line: 11,
    column: 38
  },
  name: "eventDeviceListChanged",
  sid: "317v9b:/src/entities/webrtc/model/devices.ts:eventDeviceListChanged"
}), Bd = h({
  loc: {
    file: Zn,
    line: 12,
    column: 42
  },
  name: "eventDeviceListInitialized",
  sid: "gxjeyt:/src/entities/webrtc/model/devices.ts:eventDeviceListInitialized"
});
De.addEventListener("devicechange", () => {
  $d();
});
const xi = x(en(), {
  loc: {
    file: Zn,
    line: 18,
    column: 24
  },
  name: "$devices",
  sid: "-8a7fnc:/src/entities/webrtc/model/devices.ts:$devices"
}), Vr = xi.map((e) => Xe((n) => n.filter((i) => i.kind === "videoinput"), e)), Pr = xi.map((e) => Xe((n) => n.filter((i) => i.kind === "audioinput"), e)), iS = xi.map((e) => Xe((n) => n.filter((i) => i.kind === "audiooutput"), e)), zd = $(async () => (await De.getUserMedia({
  audio: !0,
  video: !0
}), De.enumerateDevices()), {
  loc: {
    file: Zn,
    line: 32,
    column: 27
  },
  name: "enumerateDevicesFx",
  sid: "-re7mn9:/src/entities/webrtc/model/devices.ts:enumerateDevicesFx"
});
xi.on(zd.doneData, (e, n) => he(n));
w({
  and: [{
    clock: [$d, Bd],
    target: zd
  }],
  or: {
    loc: {
      file: Zn,
      line: 39,
      column: 0
    },
    sid: "lrz6sm:/src/entities/webrtc/model/devices.ts:"
  }
});
function jd(e) {
  switch (e.state) {
    case "plain-camera":
    case "screen-sharing":
      return e.stream;
    case "background-blur-initializing":
    case "background-blur-ready":
    case "background-image-initializing":
    case "background-image-ready":
      return e.cameraStream;
    default:
      return;
  }
}
function Lr(e) {
  switch (e.state) {
    case "plain-camera":
    case "screen-sharing":
      return e.stream;
    case "background-blur-ready":
    case "background-image-ready":
      return e.virtualBackgroundStream;
    default:
      return;
  }
}
const Hd = /* @__PURE__ */ g(({
  onChange: e,
  small: n,
  value: i,
  images: a
}) => {
  const {
    t
  } = le();
  return wn(), /* @__PURE__ */ f(be, {
    children: [/* @__PURE__ */ d(bn, {
      marginY: 3,
      size: "sm",
      children: t("SETTINGS.CAMERA.VIRTUAL_BACKGROUND.TITLE", {
        defaultValue: "Виртуальный фон"
      })
    }), /* @__PURE__ */ d(ss, {
      noBorder: !0,
      flexWrap: "wrap",
      w: "100%",
      children: /* @__PURE__ */ f(td, {
        dataSet: {
          media: Ur.imagesContainer
        },
        style: $r.imagesContainer,
        children: [/* @__PURE__ */ d(si, {
          onPress: () => e({
            backgroundType: "none"
          }),
          selected: i.backgroundType === "none",
          small: n,
          children: t("SETTINGS.CAMERA.VIRTUAL_BACKGROUND.NONE", {
            defaultValue: "Нет"
          })
        }), /* @__PURE__ */ d(si, {
          onPress: () => e({
            backgroundType: "blur"
          }),
          selected: i.backgroundType === "blur",
          small: n,
          children: /* @__PURE__ */ d(b, {
            children: t("SETTINGS.CAMERA.VIRTUAL_BACKGROUND.BLUR", {
              defaultValue: "Размытие"
            })
          })
        }), a.map((l) => /* @__PURE__ */ d(si, {
          image: !0,
          onPress: () => e({
            backgroundImage: l,
            backgroundType: "image"
          }),
          selected: i.backgroundType === "image" && i.backgroundImage === l,
          small: n,
          children: /* @__PURE__ */ d(fi, {
            height: n ? 100 : 200,
            src: l,
            width: n ? 100 : 200
          })
        }, l))]
      })
    })]
  });
});
Hd.displayName = "Background";
const si = /* @__PURE__ */ g(({
  children: e,
  image: n,
  onPress: i,
  selected: a,
  small: t
}) => /* @__PURE__ */ d(dd, {
  onPress: i,
  children: /* @__PURE__ */ d(sn, {
    mb: 3,
    mr: 3,
    children: /* @__PURE__ */ d(sn, {
      alignItems: "center",
      borderColor: a ? "blue.100" : "gray.100",
      borderStyle: "solid",
      borderWidth: a ? 3 : 1,
      height: t ? 102 : 202,
      justifyContent: "center",
      width: n ? t ? 102 : 202 : 102,
      children: e
    })
  })
}));
si.displayName = "ImageBox";
const {
  ids: Ur,
  styles: $r
} = Bo({
  imagesContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
    [`@media (min-width: ${Be.breakpoints.md}px)`]: {
      justifyContent: "flex-start"
    }
  }
}), Br = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "SendIceCandidate"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "candidate"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "ListType",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "String"
              }
            }
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "ice"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "candidates"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "candidate"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, zr = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "SendOffer"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "offer"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "sdp"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "offer"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "offer"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, jr = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "SendAnswer"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "answer"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "sdp"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "answer"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "answer"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Wd = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "UpdateMyMediaStatus"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "audio"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "RTCMediaAudio"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "video"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "RTCMediaVideo"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "audio_mode"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "RTCMediaAudioMode"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "media"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "audio"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "audio"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "video"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "video"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "audio_mode"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "audio_mode"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
};
var Hr = "/src/features/meet-webrtc/model/rtc/rtcpeerconnection.ts";
const te = x(null, {
  and: {
    name: "$rtcPeerConnection"
  },
  loc: {
    file: Hr,
    line: 6,
    column: 34
  },
  name: "$rtcPeerConnection",
  sid: "j4qimc:/src/features/meet-webrtc/model/rtc/rtcpeerconnection.ts:$rtcPeerConnection"
}), Di = te.map((e) => e != null);
Mn(Di, {
  log: "enabled"
});
var Z = "/src/features/meet-webrtc/model/rtc/video.ts";
const La = {
  facing: "user",
  type: "simple"
}, Gd = h("eventVirtualBackgroundCreated", {
  loc: {
    file: Z,
    line: 134,
    column: 45
  },
  name: "eventVirtualBackgroundCreated",
  sid: "-19zy8u:/src/features/meet-webrtc/model/rtc/video.ts:eventVirtualBackgroundCreated"
}), vi = x({
  backgroundType: "none"
}, {
  loc: {
    file: Z,
    line: 138,
    column: 39
  },
  name: "$selectedBackgroundType",
  sid: "-pnyx39:/src/features/meet-webrtc/model/rtc/video.ts:$selectedBackgroundType"
}), Wr = z.discriminatedUnion("type", [z.object({
  facing: z.union([z.literal("user"), z.literal("environment")]),
  type: z.literal("simple")
}), z.object({
  id: z.string(),
  type: z.literal("by_id")
})]).nullable(), Ua = x(null, {
  loc: {
    file: Z,
    line: 153,
    column: 31
  },
  name: "$selectedCamera",
  sid: "-gr9k9f:/src/features/meet-webrtc/model/rtc/video.ts:$selectedCamera"
}), $a = h({
  loc: {
    file: Z,
    line: 155,
    column: 36
  },
  name: "eventVideoTrackEnded",
  sid: "d7aere:/src/features/meet-webrtc/model/rtc/video.ts:eventVideoTrackEnded"
}), ce = x({
  enabled: !1,
  state: "initial"
}, {
  loc: {
    file: Z,
    line: 157,
    column: 22
  },
  name: "$video",
  sid: "-7xawc:/src/features/meet-webrtc/model/rtc/video.ts:$video"
}), aS = ce.map((e) => e.state === "background-blur-initializing" || e.state === "background-image-initializing" || e.state === "background-blur-ready" || e.state === "background-image-ready"), qd = h({
  loc: {
    file: Z,
    line: 169,
    column: 38
  },
  name: "eventToggleCameraOnOff",
  sid: "tkbhgo:/src/features/meet-webrtc/model/rtc/video.ts:eventToggleCameraOnOff"
}), Xd = h({
  loc: {
    file: Z,
    line: 171,
    column: 45
  },
  name: "eventScreenSharingIconPressed",
  sid: "xpfvxb:/src/features/meet-webrtc/model/rtc/video.ts:eventScreenSharingIconPressed"
}), Yd = h({
  loc: {
    file: Z,
    line: 173,
    column: 39
  },
  name: "eventSwitchToNextCamera",
  sid: "-tp0ot2:/src/features/meet-webrtc/model/rtc/video.ts:eventSwitchToNextCamera"
}), Kd = $({
  loc: {
    file: Z,
    line: 175,
    column: 36
  },
  name: "switchToNextCameraFx",
  sid: "fueiqb:/src/features/meet-webrtc/model/rtc/video.ts:switchToNextCameraFx"
}), Gr = $({
  loc: {
    file: Z,
    line: 184,
    column: 38
  },
  name: "changeVirtualBackgroundTypeFx",
  sid: "-7mile1:/src/features/meet-webrtc/model/rtc/video.ts:changeVirtualBackgroundTypeFx"
}), We = Tn({
  and: {
    effect: $(async ({
      event: e,
      rtcPeerConnection: n,
      selectedBackgroundType: i,
      selectedCamera: a,
      video: t
    }) => {
      if (n == null)
        throw new Error("$rtcPeerConnection is null");
      switch (t.state) {
        case "initial":
          switch (e.type) {
            case "camera-icon-pressed":
              const {
                stream: l,
                videoTrack: o
              } = await qe(a);
              return i.backgroundType === "none" ? {
                enabled: !0,
                sender: n.addTrack(o, l),
                state: "plain-camera",
                stream: l,
                videoTrack: o
              } : i.backgroundType === "blur" ? {
                cameraStream: l,
                enabled: !0,
                sender: void 0,
                state: "background-blur-initializing",
                videoTrack: o
              } : {
                backgroundImage: i.backgroundImage,
                cameraStream: l,
                enabled: !0,
                sender: void 0,
                state: "background-image-initializing",
                videoTrack: o
              };
            case "screen-sharing-icon-pressed": {
              const {
                stream: s,
                videoTrack: r
              } = await On(), c = n.addTrack(r, s);
              return {
                enabled: !0,
                oldState: t,
                sender: c,
                state: "screen-sharing",
                stream: s,
                videoTrack: r
              };
            }
            case "selected-camera-changed":
            case "selected-virtual-background-changed":
            case "rtc-peer-connection-recreated":
              return t;
            case "video-track-ended":
            case "virtual-background-created":
              return Vn(t, e);
            default:
              throw new X(e);
          }
        case "screen-sharing":
          switch (e.type) {
            case "screen-sharing-icon-pressed":
              if (t.videoTrack.stop(), t.oldState.enabled) {
                const {
                  stream: l,
                  videoTrack: o
                } = await qe(a);
                if (i.backgroundType === "none") {
                  const s = n.addTrack(o, l);
                  return await t.sender.replaceTrack(o), {
                    enabled: !0,
                    sender: s,
                    state: "plain-camera",
                    stream: l,
                    videoTrack: o
                  };
                } else
                  return i.backgroundType === "blur" ? {
                    cameraStream: l,
                    enabled: !0,
                    sender: void 0,
                    state: "background-blur-initializing",
                    videoTrack: o
                  } : {
                    backgroundImage: i.backgroundImage,
                    cameraStream: l,
                    enabled: !0,
                    sender: void 0,
                    state: "background-image-initializing",
                    videoTrack: o
                  };
              } else
                return {
                  enabled: !1,
                  sender: t.sender,
                  state: "video-track-ended",
                  stream: t.stream,
                  videoTrack: t.videoTrack
                };
            case "video-track-ended":
              return {
                enabled: !1,
                sender: t.sender,
                state: "video-track-ended",
                stream: t.stream,
                videoTrack: t.videoTrack
              };
            case "camera-icon-pressed": {
              const {
                stream: l,
                videoTrack: o
              } = await qe(a);
              return t.videoTrack.stop(), await t.sender.replaceTrack(o), i.backgroundType === "none" ? {
                enabled: !0,
                sender: n.addTrack(o, l),
                state: "plain-camera",
                stream: l,
                videoTrack: o
              } : i.backgroundType === "blur" ? {
                cameraStream: l,
                enabled: !0,
                sender: void 0,
                state: "background-blur-initializing",
                videoTrack: o
              } : {
                backgroundImage: i.backgroundImage,
                cameraStream: l,
                enabled: !0,
                sender: void 0,
                state: "background-image-initializing",
                videoTrack: o
              };
            }
            case "rtc-peer-connection-recreated": {
              const l = e.newRTCPeerConnection.addTrack(t.videoTrack, t.stream);
              return {
                ...t,
                sender: l
              };
            }
            case "selected-camera-changed":
            case "selected-virtual-background-changed":
              return t;
            case "virtual-background-created":
              return Vn(t, e);
            default:
              throw new X(e);
          }
        case "plain-camera":
          switch (e.type) {
            case "video-track-ended":
              return {
                enabled: !1,
                sender: t.sender,
                state: "video-track-ended",
                stream: t.stream,
                videoTrack: t.videoTrack
              };
            case "camera-icon-pressed":
              return t.videoTrack.enabled = !t.videoTrack.enabled, {
                ...t,
                enabled: t.videoTrack.enabled
              };
            case "screen-sharing-icon-pressed": {
              const {
                stream: l,
                videoTrack: o
              } = await On();
              return t.videoTrack.stop(), await t.sender.replaceTrack(o), {
                enabled: !0,
                oldState: t,
                sender: t.sender,
                state: "screen-sharing",
                stream: l,
                videoTrack: o
              };
            }
            case "selected-camera-changed": {
              const {
                stream: l,
                videoTrack: o
              } = await qe(e.selectedCamera);
              return t.videoTrack.stop(), await t.sender.replaceTrack(o), {
                enabled: !0,
                sender: t.sender,
                state: "plain-camera",
                stream: l,
                videoTrack: o
              };
            }
            case "rtc-peer-connection-recreated": {
              const l = e.newRTCPeerConnection.addTrack(t.videoTrack, t.stream);
              return {
                ...t,
                sender: l
              };
            }
            case "selected-virtual-background-changed":
              return e.background.backgroundType === "none" ? t : e.background.backgroundType === "blur" ? {
                cameraStream: t.stream,
                enabled: t.enabled,
                sender: void 0,
                state: "background-blur-initializing",
                videoTrack: t.videoTrack
              } : {
                backgroundImage: e.background.backgroundImage,
                cameraStream: t.stream,
                enabled: t.enabled,
                sender: void 0,
                state: "background-image-initializing",
                videoTrack: t.videoTrack
              };
            case "virtual-background-created":
              return Vn(t, e);
            default:
              throw new X(e);
          }
        case "background-blur-initializing":
        case "background-image-initializing":
          switch (e.type) {
            case "virtual-background-created": {
              if (e.canvasRef.current == null)
                throw new Error("canvasRef is null");
              e.canvasRef.current.getContext("webgl2");
              const r = e.canvasRef.current.captureStream(30), c = r.getVideoTracks()[0];
              let u = t.sender;
              return u == null ? u = n.addTrack(c, r) : await u.replaceTrack(c), t.state === "background-blur-initializing" ? {
                ...t,
                sender: u,
                state: "background-blur-ready",
                videoTrack: c,
                virtualBackgroundStream: r
              } : {
                ...t,
                sender: u,
                state: "background-image-ready",
                videoTrack: c,
                virtualBackgroundStream: r
              };
            }
            case "video-track-ended":
              return {
                enabled: !1,
                sender: t.sender,
                state: "video-track-ended",
                stream: t.cameraStream,
                videoTrack: t.videoTrack
              };
            case "camera-icon-pressed":
              return t.videoTrack.enabled = !1, {
                enabled: !1,
                sender: t.sender,
                state: "video-track-ended",
                stream: t.cameraStream,
                videoTrack: t.videoTrack
              };
            case "selected-camera-changed": {
              const {
                stream: r,
                videoTrack: c
              } = await qe(e.selectedCamera);
              return t.videoTrack.stop(), t.state === "background-blur-initializing" ? {
                cameraStream: r,
                enabled: !0,
                sender: t.sender,
                state: "background-blur-initializing",
                videoTrack: c
              } : {
                backgroundImage: t.backgroundImage,
                cameraStream: r,
                enabled: !0,
                sender: t.sender,
                state: "background-image-initializing",
                videoTrack: c
              };
            }
            case "screen-sharing-icon-pressed":
              const {
                stream: l,
                videoTrack: o
              } = await On();
              t.videoTrack.stop();
              let s = t.sender;
              return s == null ? s = n.addTrack(o, l) : await s.replaceTrack(o), {
                enabled: !0,
                oldState: t,
                sender: s,
                state: "screen-sharing",
                stream: l,
                videoTrack: o
              };
            case "selected-virtual-background-changed":
              if (e.background.backgroundType === "none") {
                t.videoTrack.stop();
                const r = t.cameraStream.getVideoTracks()[0];
                let c = t.sender;
                return c == null ? c = n.addTrack(r, t.cameraStream) : await c.replaceTrack(r), {
                  enabled: t.enabled,
                  sender: c,
                  state: "plain-camera",
                  stream: t.cameraStream,
                  videoTrack: r
                };
              } else {
                t.videoTrack.stop();
                const r = t.cameraStream.getVideoTracks()[0];
                let c = t.sender;
                return c == null ? c = n.addTrack(r, t.cameraStream) : await c.replaceTrack(r), e.background.backgroundType === "blur" ? {
                  cameraStream: t.cameraStream,
                  enabled: t.enabled,
                  sender: c,
                  state: "background-blur-initializing",
                  videoTrack: r
                } : {
                  backgroundImage: e.background.backgroundImage,
                  cameraStream: t.cameraStream,
                  enabled: t.enabled,
                  sender: c,
                  state: "background-image-initializing",
                  videoTrack: r
                };
              }
            case "rtc-peer-connection-recreated":
              return {
                ...t,
                sender: void 0
              };
            default:
              throw new X(e);
          }
        case "background-blur-ready":
        case "background-image-ready":
          switch (e.type) {
            case "camera-icon-pressed":
              return t.videoTrack.enabled = !t.videoTrack.enabled, {
                ...t,
                enabled: t.videoTrack.enabled
              };
            case "selected-camera-changed": {
              const {
                stream: s,
                videoTrack: r
              } = await qe(e.selectedCamera);
              return t.videoTrack.stop(), {
                cameraStream: s,
                enabled: !0,
                sender: t.sender,
                state: "background-blur-initializing",
                videoTrack: r
              };
            }
            case "screen-sharing-icon-pressed":
              const {
                stream: l,
                videoTrack: o
              } = await On();
              return t.videoTrack.stop(), await t.sender.replaceTrack(o), {
                enabled: !0,
                oldState: t,
                sender: t.sender,
                state: "screen-sharing",
                stream: l,
                videoTrack: o
              };
            case "virtual-background-created":
              Vn(t, e);
            case "video-track-ended":
              return {
                sender: t.sender,
                state: "video-track-ended",
                enabled: !1,
                stream: t.cameraStream,
                videoTrack: t.videoTrack
              };
            case "selected-virtual-background-changed":
              if (e.background.backgroundType === "none") {
                t.videoTrack.stop();
                const s = t.cameraStream.getVideoTracks()[0];
                return await t.sender.replaceTrack(s), {
                  enabled: t.enabled,
                  sender: t.sender,
                  state: "plain-camera",
                  stream: t.cameraStream,
                  videoTrack: s
                };
              } else {
                t.videoTrack.stop();
                const s = t.cameraStream.getVideoTracks()[0];
                return await t.sender.replaceTrack(s), e.background.backgroundType === "blur" ? {
                  cameraStream: t.cameraStream,
                  enabled: t.enabled,
                  sender: t.sender,
                  state: "background-blur-initializing",
                  videoTrack: s
                } : {
                  backgroundImage: e.background.backgroundImage,
                  cameraStream: t.cameraStream,
                  enabled: t.enabled,
                  sender: t.sender,
                  state: "background-image-initializing",
                  videoTrack: s
                };
              }
            case "rtc-peer-connection-recreated": {
              const s = e.newRTCPeerConnection.addTrack(t.videoTrack, t.cameraStream);
              return {
                ...t,
                sender: s
              };
            }
            default:
              throw new X(e);
          }
        case "video-track-ended":
          switch (e.type) {
            case "screen-sharing-icon-pressed":
              const {
                stream: l,
                videoTrack: o
              } = await On();
              t.videoTrack.stop();
              let s = t.sender;
              return s == null ? s = n.addTrack(o, l) : await s.replaceTrack(o), {
                enabled: !0,
                oldState: t,
                sender: s,
                state: "screen-sharing",
                stream: l,
                videoTrack: o
              };
            case "video-track-ended":
              Vn(t, e);
            case "virtual-background-created":
              return t;
            case "selected-camera-changed": {
              const {
                stream: r,
                videoTrack: c
              } = await qe(e.selectedCamera);
              return t.videoTrack.stop(), {
                cameraStream: r,
                enabled: !0,
                sender: t.sender,
                state: "background-blur-initializing",
                videoTrack: c
              };
            }
            case "camera-icon-pressed": {
              const {
                stream: r,
                videoTrack: c
              } = await qe(a);
              if (i.backgroundType === "none") {
                let u = t.sender;
                return u == null ? u = n.addTrack(c, r) : await u.replaceTrack(c), {
                  enabled: !0,
                  sender: u,
                  state: "plain-camera",
                  stream: r,
                  videoTrack: c
                };
              } else
                return i.backgroundType === "blur" ? {
                  cameraStream: r,
                  enabled: !0,
                  sender: void 0,
                  state: "background-blur-initializing",
                  videoTrack: c
                } : {
                  backgroundImage: i.backgroundImage,
                  cameraStream: r,
                  enabled: !0,
                  sender: void 0,
                  state: "background-image-initializing",
                  videoTrack: c
                };
            }
            case "selected-virtual-background-changed":
              return t;
            case "rtc-peer-connection-recreated":
              return {
                ...t,
                sender: void 0
              };
            default:
              throw new X(e);
          }
        default:
          throw new X(t);
      }
    }, {
      loc: {
        file: Z,
        line: 194,
        column: 10
      },
      name: "effect",
      sid: "-a9g01q:/src/features/meet-webrtc/model/rtc/video.ts:effect"
    }),
    mapParams: (e, n) => ({
      ...n,
      event: e
    }),
    source: {
      rtcPeerConnection: te,
      selectedBackgroundType: vi,
      selectedCamera: Ua,
      video: ce
    }
  },
  or: {
    loc: {
      file: Z,
      line: 193,
      column: 35
    },
    name: "videoStateReducerFx",
    sid: "-37e107:/src/features/meet-webrtc/model/rtc/video.ts:videoStateReducerFx"
  }
});
w({
  and: [{
    clock: Gd,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e) => ({
      canvasRef: e,
      type: "virtual-background-created"
    }),
    target: We
  }],
  or: {
    loc: {
      file: Z,
      line: 812,
      column: 0
    },
    sid: "vgj211:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
w({
  and: [{
    clock: vi,
    fn: (e) => ({
      background: e,
      type: "selected-virtual-background-changed"
    }),
    target: We
  }],
  or: {
    loc: {
      file: Z,
      line: 822,
      column: 0
    },
    sid: "vxkof8:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
const Jd = We.pending, qr = we({
  and: [{
    video: ce,
    videoPending: Jd
  }],
  or: {
    loc: {
      file: Z,
      line: 834,
      column: 26
    },
    name: "$videoIsOn",
    sid: "-yv7d7j:/src/features/meet-webrtc/model/rtc/video.ts:$videoIsOn"
  }
}).map(({
  video: e,
  videoPending: n
}) => n || e.state === "plain-camera" && e.enabled || e.state === "background-blur-initializing" && e.enabled || e.state === "background-blur-ready" && e.enabled || e.state === "background-image-initializing" && e.enabled || e.state === "background-image-ready" && e.enabled);
w({
  and: [{
    clock: qd,
    fn: () => ({
      type: "camera-icon-pressed"
    }),
    target: We
  }],
  or: {
    loc: {
      file: Z,
      line: 847,
      column: 0
    },
    sid: "wyew6f:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
w({
  and: [{
    clock: Xd,
    fn: () => ({
      type: "screen-sharing-icon-pressed"
    }),
    target: We
  }],
  or: {
    loc: {
      file: Z,
      line: 855,
      column: 0
    },
    sid: "xecxdw:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
ce.on(We.doneData, (e, n) => n);
w({
  and: [{
    clock: Yd,
    source: {
      rtcPeerConnection: te,
      selectedCamera: Ua,
      video: ce
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: ({
      rtcPeerConnection: e,
      selectedCamera: n,
      video: i
    }) => ({
      rtcPeerConnection: e,
      selectedCamera: n ?? La,
      video: i
    }),
    target: Kd
  }],
  or: {
    loc: {
      file: Z,
      line: 864,
      column: 0
    },
    sid: "xuur6q:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
w({
  and: [{
    // clock: $localSettings.map((s) => s.defaultCamera),
    clock: Ua,
    filter: ze,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e) => ({
      selectedCamera: e,
      type: "selected-camera-changed"
    }),
    target: We
  }],
  or: {
    loc: {
      file: Z,
      line: 879,
      column: 0
    },
    sid: "yencjq:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
w({
  and: [{
    // clock: $remoteSettings.map(({ backgroundImage, backgroundType }) => ({
    //   backgroundImage,
    //   backgroundType,
    // })),
    clock: zo({
      clock: vi,
      until: Di
    }),
    source: {
      rtcPeerConnection: te,
      selectedBackgroundType: vi,
      video: ce
    },
    target: Gr
  }],
  or: {
    loc: {
      file: Z,
      line: 892,
      column: 0
    },
    sid: "z8w16l:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
Kd.use(async ({
  selectedCamera: e
}) => {
  if (e == null)
    return La;
  if (e.type === "simple")
    return {
      facing: e.facing === "environment" ? "user" : "environment",
      type: "simple"
    };
  const n = (await De.enumerateDevices()).filter((t) => t.kind === "videoinput"), i = n.findIndex((t) => t.deviceId === e.id);
  return {
    id: n[(i + 1) % n.length].deviceId,
    type: "by_id"
  };
});
w({
  and: [{
    clock: $a,
    fn: () => ({
      type: "video-track-ended"
    }),
    target: We
  }],
  or: {
    loc: {
      file: Z,
      line: 941,
      column: 0
    },
    sid: "-nhln4u:/src/features/meet-webrtc/model/rtc/video.ts:"
  }
});
async function qe(e) {
  const n = e ?? La, i = {
    audio: !1,
    video: n.type === "simple" ? {
      facingMode: n
    } : {
      deviceId: {
        exact: n.id
      }
    }
  }, a = await De.getUserMedia(i), t = a.getVideoTracks()[0];
  return t.addEventListener("ended", () => {
    $a();
  }), {
    stream: a,
    videoTrack: t
  };
}
async function On() {
  const e = await De.getDisplayMedia({
    audio: !1,
    video: {
      // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
      // @ts-ignore
      cursor: "always"
    }
  }), n = e.getVideoTracks()[0];
  return n.addEventListener("ended", () => {
    $a();
  }), {
    stream: e,
    videoTrack: n
  };
}
function Vn(e, n) {
  throw new Error(`unreachable state: ${JSON.stringify(e)} event: ${JSON.stringify(n)}`);
}
const Qd = (e, n) => n === U.MediaAudioModePlay ? {
  audio: {
    autoGainControl: !1,
    echoCancellation: !1,
    echoCancellationType: void 0,
    noiseSuppression: !1,
    optional: [e != null ? {
      sourceId: e
    } : void 0, {
      googEchoCancellation: !1
    }, {
      googExperimentalEchoCancellation: !1
    }, {
      googNoiseSuppression: !1
    }, {
      googAutoGainControl: !1
    }, {
      googAutoGainControl2: !1
    }, {
      googExperimentalNoiseSuppression: !1
    }, {
      googHighpassFilter: !1
    }, {
      googTypingNoiseDetection: !1
    }].filter(ze)
  },
  video: !1
} : {
  audio: {
    echoCancellationType: "aec3",
    optional: [e != null ? {
      sourceId: e
    } : void 0, {
      googEchoCancellation: !0
    }, {
      googExperimentalEchoCancellation: !0
    }, {
      googNoiseSuppression: !0
    }, {
      googExperimentalNoiseSuppression: !0
    }, {
      googHighpassFilter: !0
    }, {
      googTypingNoiseDetection: !0
    }].filter(ze)
  },
  video: !1
};
var ae = "/src/features/meet-webrtc/model/rtc/audio.ts";
const Zd = h({
  loc: {
    file: ae,
    line: 103,
    column: 42
  },
  name: "eventMicrophoneIconPressed",
  sid: "-ndmagk:/src/features/meet-webrtc/model/rtc/audio.ts:eventMicrophoneIconPressed"
}), Ba = h({
  loc: {
    file: ae,
    line: 104,
    column: 37
  },
  name: "eventAudioModeChanged",
  sid: "apfnpx:/src/features/meet-webrtc/model/rtc/audio.ts:eventAudioModeChanged"
}), Ii = h({
  loc: {
    file: ae,
    line: 105,
    column: 36
  },
  name: "eventAudioTrackEnded",
  sid: "ksnm9r:/src/features/meet-webrtc/model/rtc/audio.ts:eventAudioTrackEnded"
}), za = h({
  loc: {
    file: ae,
    line: 107,
    column: 43
  },
  name: "eventNewAudioDeviceSelected",
  sid: "6yd2q3:/src/features/meet-webrtc/model/rtc/audio.ts:eventNewAudioDeviceSelected"
}), el = h({
  loc: {
    file: ae,
    line: 110,
    column: 53
  },
  name: "eventNewAudioDeviceSelectionCancelled",
  sid: "-ioi9w6:/src/features/meet-webrtc/model/rtc/audio.ts:eventNewAudioDeviceSelectionCancelled"
}), Ve = x({
  enabled: !1,
  state: "initial"
}, {
  loc: {
    file: ae,
    line: 112,
    column: 22
  },
  name: "$audio",
  sid: "5a154v:/src/features/meet-webrtc/model/rtc/audio.ts:$audio"
}), Xr = x(null, {
  loc: {
    file: ae,
    line: 117,
    column: 35
  },
  name: "$selectedMicrophone",
  sid: "-s4v88v:/src/features/meet-webrtc/model/rtc/audio.ts:$selectedMicrophone"
}), Yr = x(null, {
  loc: {
    file: ae,
    line: 118,
    column: 33
  },
  name: "$selectedSpeakers",
  sid: "-iu8nc:/src/features/meet-webrtc/model/rtc/audio.ts:$selectedSpeakers"
}), kn = Tn({
  and: {
    effect: $(async ({
      audio: e,
      currentMeetSpaceMyPermissions: n,
      event: i,
      rtcPeerConnection: a,
      selectedMicrophone: t
    }) => {
      if (a == null)
        throw new Error("$rtcPeerConnection is null");
      switch (e.state) {
        case "initial":
          switch (i.type) {
            case "rtc-peer-connection-recreated":
              return e;
            case "microphone-icon-pressed": {
              if (!n.has(K.PermissionChannelMediaAudioSend))
                return e;
              const {
                audioTrack: l,
                sender: o,
                stream: s
              } = await Ue(void 0, a, t, U.MediaAudioModeTalk);
              return {
                audioTrack: l,
                enabled: !0,
                sender: o,
                state: "mode-talk-mic-on",
                stream: s
              };
            }
            case "audio-track-ended":
            case "selected-microphone-changed":
              return e;
            case "audio-mode-changed": {
              const l = i.newMode;
              if (l === U.MediaAudioModeListen)
                return {
                  audioTrack: void 0,
                  enabled: !1,
                  sender: void 0,
                  state: "mode-listen",
                  stream: void 0
                };
              if (!n.has(K.PermissionChannelMediaAudioSend))
                return e;
              const o = await De.getUserMedia(Qd(t, l)), s = o.getAudioTracks()[0];
              s.addEventListener("ended", () => {
                Ii();
              });
              const r = a.addTrack(s, o);
              switch (l) {
                case U.MediaAudioModePlay:
                  return {
                    audioTrack: s,
                    enabled: !0,
                    sender: r,
                    state: "mode-play",
                    stream: o
                  };
                case U.MediaAudioModeTalk:
                  return {
                    audioTrack: s,
                    enabled: !0,
                    sender: r,
                    state: "mode-talk-mic-on",
                    stream: o
                  };
                default:
                  throw new X(l);
              }
            }
            case "audio-send-permission-revoked":
              return e;
            default:
              throw new X(i);
          }
        case "mode-listen":
          switch (i.type) {
            case "rtc-peer-connection-recreated":
              if (e.audioTrack != null && e.stream != null) {
                const l = i.newRTCPeerConnection.addTrack(e.audioTrack, e.stream);
                return {
                  ...e,
                  sender: l
                };
              } else
                return {
                  ...e,
                  sender: void 0
                };
            case "microphone-icon-pressed":
              return e;
            case "selected-microphone-changed":
              return e.audioTrack != null && e.stream != null ? (e.audioTrack.stop(), {
                ...e,
                audioTrack: void 0
              }) : {
                ...e
              };
            case "audio-track-ended":
              return {
                ...e,
                audioTrack: void 0
              };
            case "audio-mode-changed":
              switch (i.newMode) {
                case U.MediaAudioModeListen:
                  return e;
                case U.MediaAudioModePlay:
                  if (!n.has(K.PermissionChannelMediaAudioSend))
                    return e;
                  if (e.audioTrack != null && e.stream != null) {
                    e.audioTrack.enabled = !0;
                    let l = e.sender;
                    return l == null && (l = a.addTrack(e.audioTrack, e.stream)), {
                      audioTrack: e.audioTrack,
                      enabled: !0,
                      sender: l,
                      state: "mode-play",
                      stream: e.stream
                    };
                  } else {
                    const {
                      audioTrack: l,
                      sender: o,
                      stream: s
                    } = await Ue(e.sender, a, t, U.MediaAudioModePlay);
                    return {
                      audioTrack: l,
                      enabled: !0,
                      sender: o,
                      state: "mode-play",
                      stream: s
                    };
                  }
                case U.MediaAudioModeTalk: {
                  if (!n.has(K.PermissionChannelMediaAudioSend))
                    return e;
                  e.audioTrack != null && e.stream != null && e.audioTrack.stop();
                  const {
                    audioTrack: l,
                    sender: o,
                    stream: s
                  } = await Ue(e.sender, a, t, U.MediaAudioModeTalk);
                  return {
                    audioTrack: l,
                    enabled: !0,
                    sender: o,
                    state: "mode-talk-mic-on",
                    stream: s
                  };
                }
                default:
                  throw new X(i.newMode);
              }
            case "audio-send-permission-revoked":
              return e.audioTrack != null && (e.audioTrack.enabled = !1), {
                ...e,
                enabled: !1,
                state: "mode-talk-mic-off"
              };
            default:
              throw new X(i);
          }
        case "mode-play":
          switch (i.type) {
            case "microphone-icon-pressed":
              return e;
            case "rtc-peer-connection-recreated": {
              const l = i.newRTCPeerConnection.addTrack(e.audioTrack, e.stream);
              return {
                ...e,
                sender: l
              };
            }
            case "audio-track-ended":
              return Ba(U.MediaAudioModeTalk), {
                audioTrack: void 0,
                enabled: !1,
                sender: e.sender,
                state: "mode-talk-mic-off",
                stream: e.stream
              };
            case "selected-microphone-changed": {
              if (!n.has(K.PermissionChannelMediaAudioSend))
                return e.audioTrack.enabled = !1, {
                  ...e,
                  enabled: !1,
                  state: "mode-talk-mic-off"
                };
              const {
                audioTrack: l,
                sender: o,
                stream: s
              } = await Ue(e.sender, a, i.selectedMicrophoneId, U.MediaAudioModePlay);
              return {
                audioTrack: l,
                enabled: !0,
                sender: o,
                state: "mode-play",
                stream: s
              };
            }
            case "audio-mode-changed":
              switch (i.newMode) {
                case U.MediaAudioModeListen:
                  return e.audioTrack.enabled = !1, {
                    audioTrack: e.audioTrack,
                    enabled: !1,
                    sender: e.sender,
                    state: "mode-listen",
                    stream: e.stream
                  };
                case U.MediaAudioModePlay:
                  return e;
                case U.MediaAudioModeTalk:
                  return e.audioTrack.enabled = !0, {
                    audioTrack: e.audioTrack,
                    enabled: !0,
                    sender: e.sender,
                    state: "mode-talk-mic-on",
                    stream: e.stream
                  };
                default:
                  throw new X(i.newMode);
              }
            case "audio-send-permission-revoked":
              return e.audioTrack.enabled = !1, {
                ...e,
                enabled: !1,
                state: "mode-talk-mic-off"
              };
            default:
              throw new X(i);
          }
        case "mode-talk-mic-off":
          switch (i.type) {
            case "rtc-peer-connection-recreated":
              if (e.audioTrack != null && e.stream != null) {
                const l = i.newRTCPeerConnection.addTrack(e.audioTrack, e.stream);
                return {
                  audioTrack: e.audioTrack,
                  enabled: !1,
                  sender: l,
                  state: "mode-talk-mic-off",
                  stream: e.stream
                };
              } else
                return {
                  ...e,
                  sender: void 0,
                  state: "mode-talk-mic-off"
                };
            case "audio-mode-changed":
              switch (i.newMode) {
                case U.MediaAudioModeListen:
                  return e.audioTrack != null && (e.audioTrack.enabled = !1), {
                    audioTrack: e.audioTrack,
                    enabled: !1,
                    sender: e.sender,
                    state: "mode-listen",
                    stream: e.stream
                  };
                case U.MediaAudioModePlay: {
                  if (!n.has(K.PermissionChannelMediaAudioSend))
                    return e;
                  const {
                    audioTrack: l,
                    sender: o,
                    stream: s
                  } = await Ue(e.sender, a, t, U.MediaAudioModePlay);
                  return {
                    audioTrack: l,
                    enabled: !0,
                    sender: o,
                    state: "mode-play",
                    stream: s
                  };
                }
                case U.MediaAudioModeTalk:
                  if (e.audioTrack != null && e.stream != null)
                    return e.audioTrack.enabled = !0, {
                      audioTrack: e.audioTrack,
                      enabled: !0,
                      sender: e.sender,
                      state: "mode-talk-mic-on",
                      stream: e.stream
                    };
                  {
                    const {
                      audioTrack: l,
                      sender: o,
                      stream: s
                    } = await Ue(e.sender, a, t, U.MediaAudioModeTalk);
                    return {
                      audioTrack: l,
                      enabled: !0,
                      sender: o,
                      state: "mode-talk-mic-on",
                      stream: s
                    };
                  }
                default:
                  throw new X(i.newMode);
              }
            case "audio-track-ended":
              return {
                ...e,
                audioTrack: void 0
              };
            case "selected-microphone-changed":
              return e;
            case "microphone-icon-pressed": {
              if (e.audioTrack != null && e.stream != null)
                return e.audioTrack.enabled = !0, {
                  audioTrack: e.audioTrack,
                  enabled: !0,
                  sender: e.sender,
                  state: "mode-talk-mic-on",
                  stream: e.stream
                };
              {
                const {
                  audioTrack: l,
                  sender: o,
                  stream: s
                } = await Ue(e.sender, a, t, U.MediaAudioModeTalk);
                return {
                  audioTrack: l,
                  enabled: !0,
                  sender: o,
                  state: "mode-talk-mic-on",
                  stream: s
                };
              }
            }
            case "audio-send-permission-revoked":
              return e;
            default:
              throw new X(i);
          }
        case "mode-talk-mic-on":
          switch (i.type) {
            case "audio-track-ended":
              return {
                audioTrack: void 0,
                enabled: !1,
                sender: e.sender,
                state: "mode-talk-mic-off",
                stream: e.stream
              };
            case "rtc-peer-connection-recreated": {
              const l = i.newRTCPeerConnection.addTrack(e.audioTrack, e.stream);
              return {
                ...e,
                sender: l
              };
            }
            case "microphone-icon-pressed":
              return e.audioTrack.enabled = !1, {
                ...e,
                enabled: !1,
                state: "mode-talk-mic-off"
              };
            case "selected-microphone-changed": {
              const {
                audioTrack: l,
                sender: o,
                stream: s
              } = await Ue(e.sender, a, i.selectedMicrophoneId, U.MediaAudioModeTalk);
              return {
                audioTrack: l,
                enabled: !0,
                sender: o,
                state: "mode-talk-mic-on",
                stream: s
              };
            }
            case "audio-mode-changed":
              switch (i.newMode) {
                case U.MediaAudioModeListen:
                  return e.audioTrack.enabled = !1, {
                    audioTrack: e.audioTrack,
                    enabled: !1,
                    sender: e.sender,
                    state: "mode-listen",
                    stream: e.stream
                  };
                case U.MediaAudioModePlay:
                  const {
                    audioTrack: l,
                    sender: o,
                    stream: s
                  } = await Ue(e.sender, a, t, U.MediaAudioModePlay);
                  return {
                    audioTrack: l,
                    enabled: !0,
                    sender: o,
                    state: "mode-play",
                    stream: s
                  };
                case U.MediaAudioModeTalk:
                  return e.audioTrack.enabled = !0, {
                    audioTrack: e.audioTrack,
                    enabled: !0,
                    sender: e.sender,
                    state: "mode-talk-mic-on",
                    stream: e.stream
                  };
                default:
                  throw new X(i.newMode);
              }
            case "audio-send-permission-revoked":
              return e.audioTrack.enabled = !1, {
                ...e,
                enabled: !1,
                state: "mode-talk-mic-off"
              };
            default:
              throw new X(i);
          }
        default:
          throw new X(e);
      }
    }, {
      loc: {
        file: ae,
        line: 121,
        column: 10
      },
      name: "effect",
      sid: "vwxler:/src/features/meet-webrtc/model/rtc/audio.ts:effect"
    }),
    mapParams(e, n) {
      return {
        ...n,
        event: e
      };
    },
    source: {
      audio: Ve,
      currentMeetSpaceMyPermissions: cn,
      rtcPeerConnection: te,
      selectedMicrophone: Xr,
      selectedSpeakers: Yr
    }
  },
  or: {
    loc: {
      file: ae,
      line: 120,
      column: 35
    },
    name: "audioStateReducerFx",
    sid: "ifk1xr:/src/features/meet-webrtc/model/rtc/audio.ts:audioStateReducerFx"
  }
});
Ve.on(kn.doneData, (e, n) => n);
const Kr = kn.pending, Jr = Ve.map((e) => {
  switch (e.state) {
    case "initial":
    case "mode-talk-mic-on":
    case "mode-talk-mic-off":
      return U.MediaAudioModeTalk;
    case "mode-listen":
      return U.MediaAudioModeListen;
    case "mode-play":
      return U.MediaAudioModePlay;
    default:
      throw new X(e);
  }
}), Qr = $((e) => Q().mutate({
  mutation: Wd,
  variables: {
    audio_mode: e
  }
}).then((n) => {
  var i;
  return (i = n.data) == null ? void 0 : i.meet.rtc.media;
}), {
  loc: {
    file: ae,
    line: 709,
    column: 26
  },
  name: "audioModeChangeFx",
  sid: "cse1fo:/src/features/meet-webrtc/model/rtc/audio.ts:audioModeChangeFx"
});
w({
  and: [{
    clock: Ba,
    target: Qr
  }],
  or: {
    loc: {
      file: ae,
      line: 719,
      column: 0
    },
    sid: "-jmuk0q:/src/features/meet-webrtc/model/rtc/audio.ts:"
  }
});
const Zr = w({
  and: [{
    clock: Ei.map((e) => e.add ?? e.change),
    source: {
      currentMeetSpaceMe: an
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: ({
      currentMeetSpaceMe: e
    }, n) => {
      var a, t;
      const i = (a = e == null ? void 0 : e.member) == null ? void 0 : a.id;
      return ((t = n == null ? void 0 : n.find((l) => l.id === i)) == null ? void 0 : t.media.audio_mode) ?? null;
    }
  }],
  or: {
    loc: {
      file: ae,
      line: 721,
      column: 33
    },
    name: "eventMyAudioModeReceived",
    sid: "z7kkgp:/src/features/meet-webrtc/model/rtc/audio.ts:eventMyAudioModeReceived"
  }
});
w({
  and: [{
    clock: Zr.filter({
      fn: (e) => e != null
    }),
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e) => ({
      newMode: e,
      type: "audio-mode-changed"
    }),
    target: kn
  }],
  or: {
    loc: {
      file: ae,
      line: 731,
      column: 0
    },
    sid: "-it5nz8:/src/features/meet-webrtc/model/rtc/audio.ts:"
  }
});
w({
  and: [{
    clock: Zd,
    fn: () => ({
      type: "microphone-icon-pressed"
    }),
    target: kn
  }],
  or: {
    loc: {
      file: ae,
      line: 742,
      column: 0
    },
    sid: "-ibk8zo:/src/features/meet-webrtc/model/rtc/audio.ts:"
  }
});
w({
  and: [{
    clock: za,
    fn: (e) => ({
      selectedMicrophoneId: e.deviceId,
      type: "selected-microphone-changed"
    }),
    target: kn
  }],
  or: {
    loc: {
      file: ae,
      line: 748,
      column: 0
    },
    sid: "-i89hfi:/src/features/meet-webrtc/model/rtc/audio.ts:"
  }
});
w({
  and: [{
    clock: Ii,
    fn: () => ({
      type: "audio-track-ended"
    }),
    target: kn
  }],
  or: {
    loc: {
      file: ae,
      line: 758,
      column: 0
    },
    sid: "-hr7v1b:/src/features/meet-webrtc/model/rtc/audio.ts:"
  }
});
w({
  and: [{
    clock: cn,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => !e.has(K.PermissionChannelMediaAudioSend),
    fn: () => ({
      type: "audio-send-permission-revoked"
    }),
    target: kn
  }],
  or: {
    loc: {
      file: ae,
      line: 764,
      column: 0
    },
    sid: "-hcdf0k:/src/features/meet-webrtc/model/rtc/audio.ts:"
  }
});
const nl = x(null, {
  loc: {
    file: ae,
    line: 777,
    column: 2
  },
  name: "$showNewAudioDeviceSelect",
  sid: "yn73gl:/src/features/meet-webrtc/model/rtc/audio.ts:$showNewAudioDeviceSelect"
});
nl.reset(Ne, ue, za, el, wi).on(Ii, () => ({
  type: "disconnected"
}));
async function Ue(e, n, i, a) {
  const t = await De.getUserMedia(Qd(i, a)), l = t.getAudioTracks()[0];
  l.addEventListener("ended", () => {
    Ii();
  });
  let o = e;
  return o == null ? o = n.addTrack(l, t) : await o.replaceTrack(l), {
    audioTrack: l,
    sender: o,
    stream: t
  };
}
var ei = {};
ei.byteLength = im;
ei.toByteArray = tm;
ei.fromByteArray = om;
var Re = [], Fe = [], em = typeof Uint8Array < "u" ? Uint8Array : Array, Ki = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
for (var Fn = 0, nm = Ki.length; Fn < nm; ++Fn)
  Re[Fn] = Ki[Fn], Fe[Ki.charCodeAt(Fn)] = Fn;
Fe[45] = 62;
Fe[95] = 63;
function il(e) {
  var n = e.length;
  if (n % 4 > 0)
    throw new Error("Invalid string. Length must be a multiple of 4");
  var i = e.indexOf("=");
  i === -1 && (i = n);
  var a = i === n ? 0 : 4 - i % 4;
  return [i, a];
}
function im(e) {
  var n = il(e), i = n[0], a = n[1];
  return (i + a) * 3 / 4 - a;
}
function am(e, n, i) {
  return (n + i) * 3 / 4 - i;
}
function tm(e) {
  var n, i = il(e), a = i[0], t = i[1], l = new em(am(e, a, t)), o = 0, s = t > 0 ? a - 4 : a, r;
  for (r = 0; r < s; r += 4)
    n = Fe[e.charCodeAt(r)] << 18 | Fe[e.charCodeAt(r + 1)] << 12 | Fe[e.charCodeAt(r + 2)] << 6 | Fe[e.charCodeAt(r + 3)], l[o++] = n >> 16 & 255, l[o++] = n >> 8 & 255, l[o++] = n & 255;
  return t === 2 && (n = Fe[e.charCodeAt(r)] << 2 | Fe[e.charCodeAt(r + 1)] >> 4, l[o++] = n & 255), t === 1 && (n = Fe[e.charCodeAt(r)] << 10 | Fe[e.charCodeAt(r + 1)] << 4 | Fe[e.charCodeAt(r + 2)] >> 2, l[o++] = n >> 8 & 255, l[o++] = n & 255), l;
}
function dm(e) {
  return Re[e >> 18 & 63] + Re[e >> 12 & 63] + Re[e >> 6 & 63] + Re[e & 63];
}
function lm(e, n, i) {
  for (var a, t = [], l = n; l < i; l += 3)
    a = (e[l] << 16 & 16711680) + (e[l + 1] << 8 & 65280) + (e[l + 2] & 255), t.push(dm(a));
  return t.join("");
}
function om(e) {
  for (var n, i = e.length, a = i % 3, t = [], l = 16383, o = 0, s = i - a; o < s; o += l)
    t.push(lm(e, o, o + l > s ? s : o + l));
  return a === 1 ? (n = e[i - 1], t.push(
    Re[n >> 2] + Re[n << 4 & 63] + "=="
  )) : a === 2 && (n = (e[i - 2] << 8) + e[i - 1], t.push(
    Re[n >> 10] + Re[n >> 4 & 63] + Re[n << 2 & 63] + "="
  )), t.join("");
}
const Ji = Ds.QuickBase64;
Ji && typeof Ji.install == "function" && Ji.install();
function sm(e) {
  let n = "", i = 0, a = e.length;
  for (; i < a; ) {
    const t = e[i++];
    n += String.fromCharCode(t);
  }
  return n;
}
function rm(e) {
  const n = new ArrayBuffer(e.length), i = new Uint8Array(n);
  for (let a = 0, t = e.length; a < t; a++)
    i[a] = e.charCodeAt(a);
  return n;
}
function mm(e) {
  return typeof base64ToArrayBuffer < "u" ? new Uint8Array(base64ToArrayBuffer(e)) : ei.toByteArray(e);
}
function ja(e) {
  const n = rm(e);
  return typeof base64FromArrayBuffer < "u" ? base64FromArrayBuffer(n) : ei.fromByteArray(new Uint8Array(n));
}
function ea(e) {
  const n = mm(e);
  return sm(n);
}
var al = "/src/features/meet-webrtc/model/rtc/events.ts";
const tl = h("eventRTCTrack", {
  loc: {
    file: al,
    line: 4,
    column: 29
  },
  name: "eventRTCTrack",
  sid: "-pxbhif:/src/features/meet-webrtc/model/rtc/events.ts:eventRTCTrack"
}), dl = h("eventRTCIceCandidate", {
  loc: {
    file: al,
    line: 9,
    column: 36
  },
  name: "eventRTCIceCandidate",
  sid: "-5yfjt9:/src/features/meet-webrtc/model/rtc/events.ts:eventRTCIceCandidate"
});
var O = "/src/features/meet-webrtc/model/rtc/common.ts";
const ll = h({
  loc: {
    file: O,
    line: 53,
    column: 45
  },
  name: "eventRTCSignalingStateUpdated",
  sid: "xqgquw:/src/features/meet-webrtc/model/rtc/common.ts:eventRTCSignalingStateUpdated"
}), Ai = h({
  loc: {
    file: O,
    line: 55,
    column: 2
  },
  name: "eventRTCConnectionStateUpdated",
  sid: "-pl99j1:/src/features/meet-webrtc/model/rtc/common.ts:eventRTCConnectionStateUpdated"
}), Oi = h({
  loc: {
    file: O,
    line: 57,
    column: 2
  },
  name: "eventRTCIceConnectionStateUpdated",
  sid: "-l5eawu:/src/features/meet-webrtc/model/rtc/common.ts:eventRTCIceConnectionStateUpdated"
}), ol = h({
  loc: {
    file: O,
    line: 59,
    column: 2
  },
  name: "eventRTCIceGatheringStateUpdated",
  sid: "-2ilfx7:/src/features/meet-webrtc/model/rtc/common.ts:eventRTCIceGatheringStateUpdated"
}), Ha = h({
  loc: {
    file: O,
    line: 60,
    column: 38
  },
  name: "eventNegotiationNeeded",
  sid: "vhl96h:/src/features/meet-webrtc/model/rtc/common.ts:eventNegotiationNeeded"
}), Dn = x(null, {
  loc: {
    file: O,
    line: 62,
    column: 31
  },
  name: "$signalingState",
  sid: "-up41k1:/src/features/meet-webrtc/model/rtc/common.ts:$signalingState"
}).on(ll, (e, n) => n), vn = x(null, {
  loc: {
    file: O,
    line: 66,
    column: 32
  },
  name: "$connectionState",
  sid: "nldqg6:/src/features/meet-webrtc/model/rtc/common.ts:$connectionState"
}).on(Ai, (e, n) => n), In = x(null, {
  loc: {
    file: O,
    line: 69,
    column: 35
  },
  name: "$iceConnectionState",
  sid: "-nmfgn1:/src/features/meet-webrtc/model/rtc/common.ts:$iceConnectionState"
}).on(Oi, (e, n) => n), Wa = x(null, {
  loc: {
    file: O,
    line: 72,
    column: 34
  },
  name: "$iceGatheringState",
  sid: "-1e8kxl:/src/features/meet-webrtc/model/rtc/common.ts:$iceGatheringState"
}).on(ol, (e, n) => n);
let $e = /* @__PURE__ */ function(e) {
  return e[e.Good = 0] = "Good", e[e.Loading = 1] = "Loading", e[e.BadConnection = 2] = "BadConnection", e;
}({});
const tS = we({
  and: [{
    connectionState: vn,
    iceConnectionState: In,
    iceGatheringState: Wa,
    signalingState: Dn
  }, ({
    connectionState: e,
    iceConnectionState: n,
    iceGatheringState: i,
    signalingState: a
  }) => e === "failed" ? $e.BadConnection : e === "closed" || e === "disconnected" || e === "connecting" || e === "new" ? $e.Loading : n === "disconnected" || n === "failed" || n === "closed" ? $e.BadConnection : n === "new" || n === "checking" || i === "gathering" ? $e.Loading : a === "closed" ? $e.BadConnection : a === "have-local-offer" || a === "have-local-pranswer" || a === "have-remote-offer" || a === "have-remote-pranswer" ? $e.Loading : $e.Good],
  or: {
    loc: {
      file: O,
      line: 81,
      column: 51
    },
    name: "$webrtcUserFriendlyConnectionStatus",
    sid: "-9rbwm0:/src/features/meet-webrtc/model/rtc/common.ts:$webrtcUserFriendlyConnectionStatus"
  }
}), Ga = x([], {
  loc: {
    file: O,
    line: 135,
    column: 27
  },
  name: "$iceServers",
  sid: "-ymwy7l:/src/features/meet-webrtc/model/rtc/common.ts:$iceServers"
});
Ga.on(Ra, (e, n) => ki(e, n.ice_servers) ? e : n.ice_servers);
function cm(e) {
  const n = new Os({
    iceServers: e,
    iceTransportPolicy: "relay"
  });
  return n.addEventListener("connectionstatechange", () => {
    Ai(n.connectionState);
  }), n.addEventListener("iceconnectionstatechange", () => {
    Oi(n.iceConnectionState);
  }), n.addEventListener("icegatheringstatechange", () => {
    ol(n.iceGatheringState);
  }), n.addEventListener("signalingstatechange", () => {
    ll(n.signalingState);
  }), n.addEventListener("negotiationneeded", () => {
    Ha();
  }), n.addEventListener(
    "track",
    // @ts-expect-error wrong type
    (i) => {
      tl(i);
    }
  ), n.addEventListener(
    "icecandidate",
    // @ts-expect-error wrong type
    (i) => {
      dl(i);
    }
  ), n.addTransceiver("video", void 0), n.addTransceiver("audio", void 0), typeof window < "u" && (window.rtcPeerConnection = n), n;
}
const Vi = $({
  loc: {
    file: O,
    line: 193,
    column: 41
  },
  name: "closeRTCPeertConnectionFx",
  sid: "-cw1zrl:/src/features/meet-webrtc/model/rtc/common.ts:closeRTCPeertConnectionFx"
});
Vi.use(({
  rtcPeerConnection: e
}) => {
  e == null || e.close();
});
w({
  and: [{
    clock: wa,
    source: {
      rtcPeerConnection: te
    },
    target: Vi
  }],
  or: {
    loc: {
      file: O,
      line: 202,
      column: 0
    },
    sid: "ljxko0:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
te.reset(Vi.done);
const Pi = Tn({
  and: {
    effect: $(({
      iceServers: e,
      rtcPeerConnection: n
    }) => {
      try {
        n == null || n.close();
      } catch {
      }
      return {
        newRTCPeerConnection: cm(e)
      };
    }, {
      loc: {
        file: O,
        line: 212,
        column: 10
      },
      name: "effect",
      sid: "-6imz4z:/src/features/meet-webrtc/model/rtc/common.ts:effect"
    }),
    source: {
      iceServers: Ga,
      rtcPeerConnection: te
    }
  },
  or: {
    loc: {
      file: O,
      line: 211,
      column: 57
    },
    name: "recreateRTCPeerConnectionAfterReconnectFx",
    sid: "yhfhex:/src/features/meet-webrtc/model/rtc/common.ts:recreateRTCPeerConnectionAfterReconnectFx"
  }
}), um = w({
  and: [{
    clock: Ga,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => e.length > 0
  }],
  or: {
    loc: {
      file: O,
      line: 233,
      column: 35
    },
    name: "eventIceServersListUpdated",
    sid: "-h5i6yg:/src/features/meet-webrtc/model/rtc/common.ts:eventIceServersListUpdated"
  }
});
w({
  and: [{
    clock: Ra,
    source: te,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    // filter: (rtcPeerConnection, init) =>
    //   init.codecs.length > 0 && rtcPeerConnection != null,
    target: Pi
  }],
  or: {
    loc: {
      file: O,
      line: 243,
      column: 0
    },
    sid: "ngnuu5:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
w({
  and: [{
    clock: um,
    target: Pi
  }],
  or: {
    loc: {
      file: O,
      line: 252,
      column: 0
    },
    sid: "nx5omz:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
te.on(Pi.doneData, (e, n) => n.newRTCPeerConnection);
const sl = $(() => {
  Lo();
}, {
  loc: {
    file: O,
    line: 263,
    column: 42
  },
  name: "restartGraphQLConnectionFx",
  sid: "6h69iz:/src/features/meet-webrtc/model/rtc/common.ts:restartGraphQLConnectionFx"
}), km = w({
  and: [{
    clock: [Oi, Ai],
    filter: (e) => e === "failed"
  }],
  or: {
    loc: {
      file: O,
      line: 273,
      column: 33
    },
    name: "eventRTCConnectionFailed",
    sid: "5j768i:/src/features/meet-webrtc/model/rtc/common.ts:eventRTCConnectionFailed"
  }
});
w({
  and: [{
    clock: [Oi, Ai],
    filter: (e) => e === "failed" || e === "disconnected" || e === "closed",
    target: xa
  }],
  or: {
    loc: {
      file: O,
      line: 278,
      column: 0
    },
    sid: "oyjozj:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
w({
  and: [{
    clock: [jo({
      leading: !0,
      source: km,
      timeout: 5e3,
      trailing: !0
    }), xa],
    source: {
      currentSpaceId: Jn,
      rtcPeerConnection: te
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => e.currentSpaceId != null,
    target: sl
  }],
  or: {
    loc: {
      file: O,
      line: 285,
      column: 0
    },
    sid: "pdxxln:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
w({
  and: [{
    clock: Pi.doneData,
    fn: (e) => ({
      newRTCPeerConnection: e.newRTCPeerConnection,
      type: "rtc-peer-connection-recreated"
    }),
    target: We
  }],
  or: {
    loc: {
      file: O,
      line: 327,
      column: 0
    },
    sid: "-xrxzbw:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
const Pe = $({
  loc: {
    file: O,
    line: 337,
    column: 24
  },
  name: "hangupFx",
  sid: "1khq10:/src/features/meet-webrtc/model/rtc/common.ts:hangupFx"
});
Pe.use(({
  audio: e,
  peers: n,
  rtcPeerConnection: i,
  video: a
}) => {
  var t, l;
  if (i != null) {
    switch (e.state !== "initial" && ((t = e.audioTrack) == null || t.stop()), a.state) {
      case "background-blur-ready":
      case "background-image-ready":
        a.virtualBackgroundStream.getTracks().forEach((o) => {
          o.stop();
        });
      case "background-blur-initializing":
      case "background-image-initializing":
        a.cameraStream.getTracks().forEach((o) => {
          o.stop();
        });
        break;
      case "plain-camera":
      case "screen-sharing":
        a.stream.getTracks().forEach((o) => {
          o.stop();
        });
        break;
      case "initial":
      case "video-track-ended":
        break;
      default:
        throw new X(a);
    }
    for (const o of n)
      for (const s of ((l = o.stream) == null ? void 0 : l.getTracks()) ?? [])
        s.stop();
  }
});
cr.on(Pe.done, (e, n) => n.params.exitReason);
Ve.reset(Pe.done);
ce.reset(Pe.done);
Ci.reset(Pe.done);
tr.on(Pe, () => !0);
w({
  and: [{
    clock: Pe.done,
    target: fr
  }],
  or: {
    loc: {
      file: O,
      line: 396,
      column: 0
    },
    sid: "-uh6f5w:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
w({
  and: [{
    clock: Pe.done,
    source: {
      rtcPeerConnection: te
    },
    target: Vi
  }],
  or: {
    loc: {
      file: O,
      line: 400,
      column: 0
    },
    sid: "-k5hi1s:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
const vm = h({
  loc: {
    file: O,
    line: 406,
    column: 41
  },
  name: "eventOfferingStateUpdated",
  sid: "9r9wz5:/src/features/meet-webrtc/model/rtc/common.ts:eventOfferingStateUpdated"
}), dS = x(!1, {
  loc: {
    file: O,
    line: 407,
    column: 27
  },
  name: "$isOffering",
  sid: "-ymhgem:/src/features/meet-webrtc/model/rtc/common.ts:$isOffering"
}).on(vm, (e, n) => n), Sm = $((e) => {
  e.candidate != null && e.candidate.length > 0 && Q().mutate({
    mutation: Br,
    variables: {
      candidate: ja(JSON.stringify(e.candidate))
    }
  });
}, {
  loc: {
    file: O,
    line: 412,
    column: 34
  },
  name: "sendIceCandidateFx",
  sid: "ihae8q:/src/features/meet-webrtc/model/rtc/common.ts:sendIceCandidateFx"
}), na = Tn({
  and: {
    effect: $(async ({
      rtcPeerConnection: e
    }) => {
      if (e == null)
        throw new Error("$rtcPeerConnection is null");
      e.signalingState !== "have-local-offer" && await e.setLocalDescription(), await Q().mutate({
        mutation: zr,
        variables: {
          offer: ja(JSON.stringify(e.localDescription))
        }
      });
    }, {
      loc: {
        file: O,
        line: 425,
        column: 10
      },
      name: "effect",
      sid: "-3syxl3:/src/features/meet-webrtc/model/rtc/common.ts:effect"
    }),
    source: {
      rtcPeerConnection: te
    }
  },
  or: {
    loc: {
      file: O,
      line: 424,
      column: 27
    },
    name: "sendOfferFx",
    sid: "-yid6d3:/src/features/meet-webrtc/model/rtc/common.ts:sendOfferFx"
  }
});
w({
  and: [{
    clock: Ha,
    source: {
      isOffering: na.pending
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    // filter: ({ isOffering }) => isOffering === false,
    target: na
  }],
  or: {
    loc: {
      file: O,
      line: 449,
      column: 0
    },
    sid: "-i4cv4r:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
Ho({
  effect: na,
  maxAttempts: 5,
  target: Ha,
  timeout: 1e4
});
const pm = w({
  and: [{
    clock: [vn, In, Dn],
    filter: (e) => !["closed", "failed"].includes(e ?? "")
  }],
  or: {
    loc: {
      file: O,
      line: 466,
      column: 30
    },
    name: "eventStopReconnecting",
    sid: "14fg9g:/src/features/meet-webrtc/model/rtc/common.ts:eventStopReconnecting"
  }
}), Nm = qt({
  filter: (e) => ["closed", "failed"].includes(e.$connectionState ?? "") || ["closed", "failed"].includes(e.$iceConnectionState ?? "") || e.$signalingState === "have-local-offer",
  source: we({
    and: [{
      $connectionState: vn,
      $iceConnectionState: In,
      $signalingState: Dn
    }],
    or: {
      loc: {
        file: O,
        line: 477,
        column: 10
      },
      name: "source",
      sid: "-ojq9a2:/src/features/meet-webrtc/model/rtc/common.ts:source"
    }
  }),
  timeout: 1e4
}), fm = qt({
  filter: (e) => ["closed", "failed"].includes(e.$connectionState ?? "") || ["closed", "failed"].includes(e.$iceConnectionState ?? "") || e.$signalingState === "have-local-offer",
  source: we({
    and: [{
      $connectionState: vn,
      $iceConnectionState: In,
      $signalingState: Dn
    }],
    or: {
      loc: {
        file: O,
        line: 491,
        column: 10
      },
      name: "source",
      sid: "1ysksu:/src/features/meet-webrtc/model/rtc/common.ts:source"
    }
  }),
  timeout: 3e4
});
fm.watch(() => {
  Vs("WebRTC connection stalled for over 30 seconds.");
});
const Fm = Ao({
  sid: "-gjg3h2:/src/features/meet-webrtc/model/rtc/common.ts:eventReconnectInterval",
  fn: () => Ps({
    leading: !0,
    start: Nm,
    stop: pm,
    timeout: 15e3,
    trailing: !1
  }),
  name: "eventReconnectInterval",
  method: "interval",
  loc: {
    file: O,
    line: 503,
    column: 31
  }
});
w({
  and: [{
    clock: Fm.tick,
    source: {
      rtcPeerConnection: te
    },
    target: sl
  }],
  or: {
    loc: {
      file: O,
      line: 510,
      column: 0
    },
    sid: "-501lfk:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
const rl = $({
  name: "addIceCandidateFx"
}, {
  loc: {
    file: O,
    line: 529,
    column: 33
  },
  name: "addIceCandidateFx",
  sid: "-b8svcj:/src/features/meet-webrtc/model/rtc/common.ts:addIceCandidateFx"
});
rl.use(async ({
  candidate: e,
  rtcPeerConnection: n
}) => {
  if (n == null)
    throw new Error("$rtcPeerConnection is null");
  await Promise.all(e.candidates.map((i) => {
    const a = JSON.parse(ea(i));
    return n.addIceCandidate(a);
  }));
});
w({
  and: [{
    clock: Cd,
    source: te,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => ({
      rtcPeerConnection: e,
      candidate: n
    }),
    target: rl
  }],
  or: {
    loc: {
      file: O,
      line: 551,
      column: 0
    },
    sid: "-33bb9f:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
const ml = $({
  loc: {
    file: O,
    line: 560,
    column: 28
  },
  name: "processSDPFx",
  sid: "-wacjf9:/src/features/meet-webrtc/model/rtc/common.ts:processSDPFx"
});
w({
  and: [{
    clock: Md,
    source: te,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => ({
      rtcPeerConnection: e,
      sdp: n
    }),
    target: ml
  }],
  or: {
    loc: {
      file: O,
      line: 567,
      column: 0
    },
    sid: "-2iyxb2:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
ml.use(async ({
  rtcPeerConnection: e,
  sdp: n
}) => {
  if (e == null)
    throw new Error("$rtcPeerConnection is null");
  if (n.answer != null && n.answer !== "") {
    const a = ea(n.answer);
    await e.setRemoteDescription(new RTCSessionDescription(JSON.parse(a)));
  }
  if (!(n.offer != null && n.offer !== ""))
    return;
  const i = ea(n.offer);
  await e.setRemoteDescription(new RTCSessionDescription(JSON.parse(i))), await e.setLocalDescription(), await Q().mutate({
    mutation: jr,
    variables: {
      answer: ja(JSON.stringify(e.localDescription))
    }
  });
});
w({
  and: [{
    clock: dl,
    target: Sm
  }],
  or: {
    loc: {
      file: O,
      line: 606,
      column: 0
    },
    sid: "9altyg:/src/features/meet-webrtc/model/rtc/common.ts:"
  }
});
var gm = "/src/features/meet-webrtc/model/rtc/link-quality.ts";
let pe = /* @__PURE__ */ function(e) {
  return e[e.GREEN = 0] = "GREEN", e[e.YELLOW = 1] = "YELLOW", e[e.RED = 2] = "RED", e;
}({});
const hm = we({
  and: [{
    connectionState: vn,
    iceConnectionState: In,
    iceGatheringState: Wa,
    signalingState: Dn
  }],
  or: {
    loc: {
      file: gm,
      line: 16,
      column: 28
    },
    name: "$linkQuality",
    sid: "iwv0ll:/src/features/meet-webrtc/model/rtc/link-quality.ts:$linkQuality"
  }
}).map(({
  connectionState: e,
  iceConnectionState: n,
  iceGatheringState: i,
  signalingState: a
}) => {
  const t = e === "connected" || e == null ? pe.GREEN : e === "connecting" ? pe.YELLOW : pe.RED, l = n === "connected" || n === "completed" ? pe.GREEN : n === "new" || n === "checking" ? pe.YELLOW : pe.RED, o = i === "complete" ? pe.GREEN : pe.YELLOW, s = a === "stable" ? pe.GREEN : a === "closed" ? pe.RED : pe.YELLOW;
  return Math.max(t, l, o, s);
});
fe.on(tl, (e, {
  streams: n
}) => e.map((i) => i.id === n[0].id ? {
  ...i,
  stream: n[0]
} : i));
fe.on(Ei, (e, n) => {
  if (n.add != null)
    return e.concat(n.add);
  if (n.remove != null)
    return e.filter((i) => {
      var a;
      return !((a = n.remove) != null && a.includes(i.id));
    });
  if (n.data != null)
    return n.data;
  if (n.change != null) {
    const i = n.change;
    return e.map((a) => {
      const t = i.find((l) => l.id === a.id);
      return t == null ? a : yd((l, o) => o ?? l, a, t);
    });
  }
  return e;
});
var qa = "/src/features/meet-webrtc/model/update-av-status.ts";
const Xa = $({
  loc: {
    file: qa,
    line: 17,
    column: 31
  },
  name: "updateAVStateFx",
  sid: "edigt4:/src/features/meet-webrtc/model/update-av-status.ts:updateAVStateFx"
});
Xa.use(async (e) => {
  await Q().mutate({
    mutation: Wd,
    variables: {
      audio: e.audio,
      video: e.video
    }
  });
});
w({
  and: [{
    clock: Ve,
    source: {
      meetSubscriptionStatus: Rd,
      webrtcInitialized: Di
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      meetSubscriptionStatus: e,
      webrtcInitialized: n
    }) => e.status === va.Success && n,
    fn: (e, n) => ({
      audio: n.enabled ? ci.MediaAudioMicrophone : ci.MediaAudioOff
    }),
    target: Xa
  }],
  or: {
    loc: {
      file: qa,
      line: 33,
      column: 0
    },
    sid: "-1ykblj:/src/features/meet-webrtc/model/update-av-status.ts:"
  }
});
w({
  and: [{
    clock: ce,
    source: {
      meetSubscriptionStatus: Rd,
      webrtcInitialized: Di
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      meetSubscriptionStatus: e,
      webrtcInitialized: n
    }, i) => i.state !== "initial" && e.status === va.Success && n,
    fn(e, n) {
      return n.state === "initial" || n.state === "video-track-ended" ? {
        video: _e.MediaVideoOff
      } : {
        video: n.enabled ? n.state === "screen-sharing" ? _e.MediaVideoScreen : n.state === "plain-camera" || n.state === "background-blur-ready" || n.state === "background-image-ready" ? _e.MediaVideoCamera : _e.MediaVideoOff : _e.MediaVideoOff
      };
    },
    target: Xa
  }],
  or: {
    loc: {
      file: qa,
      line: 55,
      column: 0
    },
    sid: "-zdhmf:/src/features/meet-webrtc/model/update-av-status.ts:"
  }
});
function lS() {
  return A(vn) === "connected";
}
const oS = /* @__PURE__ */ Jt.memo(() => {
  const {
    t: e
  } = le(), n = me(), {
    audio: i,
    audioPending: a
  } = A({
    audio: Ve,
    audioPending: Kr
  }), t = A(cn), l = E(() => {
    Zd();
  }, []), o = i.enabled ? "green.50" : "rgba(255, 255, 255, 0.12)", s = i.enabled ? "green.50" : "rgba(255, 255, 255, 0.08)", r = t.has(K.PermissionChannelMediaAudioSend) ? i.enabled ? i.audioTrack.label : "" : e("ROOM.CONTROLS.MIC.NO_PERMISSION_TOOLTIP");
  return /* @__PURE__ */ d(fa, {
    closeDelay: 3e3,
    label: r,
    children: /* @__PURE__ */ d(Yn, {
      _focus: {
        bg: o
      },
      _hover: {
        bg: o
      },
      _pressed: {
        bg: o
      },
      bg: s,
      disabled: a || !t.has(K.PermissionChannelMediaAudioSend),
      h: {
        base: "48px",
        lg: "48px",
        md: "36px"
      },
      onPress: l,
      w: {
        base: "48px",
        lg: "48px",
        md: "36px"
      },
      children: /* @__PURE__ */ d(Ta, {
        fill: i.enabled ? n.colors.white : n.colors.gray[50],
        height: "24",
        width: "24"
      })
    })
  });
}), sS = /* @__PURE__ */ Jt.memo(() => {
  const e = me(), {
    video: n,
    videoIsOn: i,
    videoPending: a
  } = A({
    video: ce,
    videoIsOn: qr,
    videoPending: Jd
  }), t = A(cn), l = E(() => {
    qd();
  }, []), o = P(() => {
    var r;
    return i ? ((r = jd(n)) == null ? void 0 : r.getVideoTracks()[0].label) ?? "" : "";
  }, [n, i]), s = i ? "green.50" : "rgba(255, 255, 255, 0.12)";
  return /* @__PURE__ */ d(fa, {
    closeDelay: 3e3,
    label: o,
    children: /* @__PURE__ */ d(Yn, {
      _focus: {
        bg: s
      },
      _hover: {
        bg: s
      },
      _pressed: {
        bg: s
      },
      bg: i ? "green.50" : "rgba(255, 255, 255, 0.08)",
      disabled: !t.has(K.PermissionChannelMediaVideoSend),
      h: {
        base: "48px",
        lg: "48px",
        md: "36px"
      },
      onPress: l,
      opacity: !t.has(K.PermissionChannelMediaVideoSend) || a ? 0.4 : 1,
      w: "48px",
      children: /* @__PURE__ */ d(nd, {
        fill: i ? e.colors.white : e.colors.gray[50],
        height: "24",
        width: "24"
      })
    })
  });
}), bm = /* @__PURE__ */ g(() => {
  const [e, n] = H(!1), [i, a] = H(!1);
  J(() => {
    (async () => {
      const r = await De.enumerateDevices();
      a(r.filter((c) => c.kind === "videoinput").length >= 2);
    })();
  }, []);
  const t = mn(), l = me(), o = E(() => {
    Yd();
  }, []);
  return i || t === "base" ? /* @__PURE__ */ d(He, {
    _focus: {
      bg: "rgba(255, 255, 255, 0.12)"
    },
    _hover: {
      bg: "rgba(255, 255, 255, 0.12)"
    },
    _pressed: {
      bg: "rgba(255, 255, 255, 0.12)"
    },
    bg: "rgba(255, 255, 255, 0.08)",
    borderRadius: "32",
    h: {
      base: "48px",
      lg: "48px",
      md: "36px"
    },
    onHoverIn: () => n(!0),
    onHoverOut: () => n(!1),
    onPress: o,
    shadow: "1",
    w: {
      base: "48px",
      lg: "48px",
      md: "36px"
    },
    children: /* @__PURE__ */ d(Ns, {
      fill: e ? "white" : l.colors.gray[400]
    })
  }) : null;
});
bm.displayName = "ControlChangeCamera";
const ym = /* @__PURE__ */ g(() => {
  const e = A(Jr);
  return /* @__PURE__ */ f(_, {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    position: "relative",
    children: [/* @__PURE__ */ d(ri, {
      activeMode: e,
      mode: U.MediaAudioModeTalk
    }), /* @__PURE__ */ d(ri, {
      activeMode: e,
      mode: U.MediaAudioModeListen
    }), /* @__PURE__ */ d(ri, {
      activeMode: e,
      mode: U.MediaAudioModePlay
    })]
  });
});
ym.displayName = "ControlMode";
const ri = /* @__PURE__ */ g(({
  activeMode: e,
  mode: n
}) => {
  const [i, a] = H(!1), t = me(), l = E(() => {
    Ba(n);
  }, [n]), o = e === n;
  return /* @__PURE__ */ d(He, {
    _focus: {
      bg: o ? t.colors.green[600] : "rgba(255, 255, 255, 0.12)"
    },
    _hover: {
      bg: o ? t.colors.green[600] : "rgba(255, 255, 255, 0.12)"
    },
    _pressed: {
      bg: o ? t.colors.green[600] : "rgba(255, 255, 255, 0.12)"
    },
    bg: o ? t.colors.green[700] : "rgba(255, 255, 255, 0.08)",
    borderRadius: "32",
    h: {
      base: "48px",
      lg: "48px",
      md: "36px"
    },
    onHoverIn: () => a(!0),
    onHoverOut: () => a(!1),
    onPress: l,
    px: "5",
    shadow: "1",
    children: /* @__PURE__ */ d(_, {
      alignItems: "center",
      flexDirection: "row",
      children: /* @__PURE__ */ d(b, {
        color: i ? t.colors.white : o ? t.colors.text[300] : t.colors.text[100],
        fontSize: "14",
        ml: "3",
        children: Tm(n)
      })
    })
  });
});
ri.displayName = "ControlModeButton";
function Tm(e) {
  switch (e) {
    case U.MediaAudioModeListen:
      return D("ROOM.MUSIC_MODE.LISTEN");
    case U.MediaAudioModeTalk:
      return D("ROOM.MUSIC_MODE.TALK");
    case U.MediaAudioModePlay:
      return D("ROOM.MUSIC_MODE.PLAY");
    default:
      throw new X(e);
  }
}
const Cm = /* @__PURE__ */ g(({
  iconOnly: e
}) => {
  const [n, i] = H(!1), a = me(), {
    t
  } = le(), l = A(ce), o = E(() => {
    Xd();
  }, []), s = l.state === "screen-sharing" && l.enabled;
  return /* @__PURE__ */ d(He, {
    _focus: {
      bg: "rgba(255, 255, 255, 0.12)"
    },
    _hover: {
      bg: "rgba(255, 255, 255, 0.12)"
    },
    _pressed: {
      bg: "rgba(255, 255, 255, 0.12)"
    },
    bg: "rgba(255, 255, 255, 0.08)",
    borderRadius: "32",
    h: {
      base: "48px",
      lg: "48px",
      md: "36px"
    },
    onHoverIn: () => i(!0),
    onHoverOut: () => i(!1),
    onPress: o,
    px: "5",
    shadow: "1",
    children: /* @__PURE__ */ f(_, {
      alignItems: "center",
      flexDirection: "row",
      children: [/* @__PURE__ */ d(fs, {
        fill: s ? a.colors.green[50] : n ? a.colors.white : a.colors.gray[400],
        height: "24",
        width: "24"
      }), e ? null : /* @__PURE__ */ f(_, {
        position: "relative",
        children: [/* @__PURE__ */ d(b, {
          color: n ? a.colors.white : a.colors.text[100],
          fontSize: "14",
          ml: "3",
          opacity: s && n ? 0 : 1,
          children: s ? t("ROOM.CONTROLS.SCREEN_SHARING_START", {
            defaultValue: "Идет демонстрация"
          }) : t("ROOM.CONTROLS.SCREEN_SHARING", {
            defaultValue: "Демонстрация"
          })
        }), /* @__PURE__ */ d(b, {
          bottom: "0",
          color: "white",
          display: s && n ? "block" : "none",
          fontSize: "14",
          left: "0",
          position: "absolute",
          right: "0",
          textAlign: "center",
          top: "0",
          children: t("ROOM.CONTROLS.SCREEN_SHARING_STOP", {
            defaultValue: "Остановить"
          })
        })]
      })]
    })
  });
});
Cm.displayName = "ControlScreenShare";
const xt = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "ChatSendMessage"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "text"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "embeds"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "EmbedInput"
            }
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "send"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "text"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "text"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "embeds"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "embeds"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Mm = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "ChatEditMessage"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "embeds"
        }
      },
      type: {
        kind: "ListType",
        type: {
          kind: "NonNullType",
          type: {
            kind: "NamedType",
            name: {
              kind: "Name",
              value: "EmbedInput"
            }
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "text"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "edit"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "embeds"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "embeds"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "text"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "text"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, wm = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "ChatDeleteMessage"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "remove"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "id"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Em = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "ChatReactToMessage"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "emoji"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "messageId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "react"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "reaction"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "emoji"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "messageId"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, _m = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "ChatUnreactToMessage"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "emoji"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "messageId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "chat"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "unreact"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "reaction"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "emoji"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "id"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "messageId"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
};
function cl(e) {
  const n = e.trim();
  return n.length === 0 ? "" : Ls(n).map(oe);
}
function oe(e) {
  if (Array.isArray(e))
    return e.map(oe);
  switch (e.type) {
    case "BIG_EMOJI":
      return /* @__PURE__ */ d(b, {
        display: "inline-flex",
        fontSize: "48px",
        lineHeight: "48px",
        children: e.value.map((n) => oe(n))
      });
    case "BOLD":
      return /* @__PURE__ */ d(b, {
        bold: !0,
        children: e.value.map(oe)
      });
    case "CODE":
      return /* @__PURE__ */ d(b, {
        fontFamily: "monospace",
        children: e.value.map(oe)
      });
    case "CODE_LINE":
      return /* @__PURE__ */ d(b, {
        children: oe(e.value)
      });
    case "COLOR":
      return null;
    case "PLAIN_TEXT":
      return e.value;
    case "EMOJI":
      return /* @__PURE__ */ d(b, {
        display: "inline-flex",
        children: e.unicode != null ? e.unicode : null
      });
    case "HEADING":
      return /* @__PURE__ */ d(bn, {
        size: Rm(e.level),
        children: e.value.map(oe)
      });
    case "INLINE_CODE":
      return /* @__PURE__ */ d(b, {
        fontFamily: "monospace",
        children: oe(e.value)
      });
    case "ITALIC":
      return /* @__PURE__ */ d(b, {
        italic: !0,
        children: e.value.map(oe)
      });
    case "STRIKE":
      return /* @__PURE__ */ d(b, {
        strikeThrough: !0,
        children: e.value.map(oe)
      });
    case "LINK":
      return /* @__PURE__ */ d(dd, {
        onPress: () => ld.openURL(e.value.src.value),
        children: /* @__PURE__ */ d(b, {
          underline: !0,
          color: "blue.400",
          children: oe(e.value.label)
        })
      });
    case "PARAGRAPH":
      return [...e.value.map((n) => oe(n)), /* @__PURE__ */ d(b, {
        children: `
`
      }, "z")];
    case "KATEX":
      return e.value;
    case "INLINE_KATEX":
      return e.value;
    case "QUOTE":
      return /* @__PURE__ */ d(b, {
        bgColor: "gray.400",
        children: e.value.map(oe)
      });
    case "LIST_ITEM":
      return /* @__PURE__ */ f(b, {
        children: [e.number, " ", e.value.map(oe)]
      });
    case "MENTION_CHANNEL":
      return oe(e.value);
    case "MENTION_USER":
      return oe(e.value);
    case "TASKS":
    case "UNORDERED_LIST":
    case "ORDERED_LIST":
      return e.value.flatMap((n) => n.value.map(oe));
    case "LINE_BREAK":
      return /* @__PURE__ */ d(b, {
        children: `
`
      });
    case "IMAGE":
      return /* @__PURE__ */ d(fi, {
        source: {
          uri: e.value.src.value
        }
      });
    default:
      throw new X(e);
  }
}
function Rm(e) {
  switch (e) {
    case 1:
      return "xl";
    case 2:
      return "lg";
    case 3:
      return "md";
    case 4:
      return "sm";
    default:
      throw new X(e);
  }
}
var Sn = "/src/features/meet/chat/model/file-attachments/index.ts";
const Li = h({
  loc: {
    file: Sn,
    line: 30,
    column: 41
  },
  name: "eventFileAttachmentsAdded",
  sid: "-zgcvdc:/src/features/meet/chat/model/file-attachments/index.ts:eventFileAttachmentsAdded"
}), ul = h({
  loc: {
    file: Sn,
    line: 31,
    column: 43
  },
  name: "eventFileAttachmentsRemoved",
  sid: "jwovfx:/src/features/meet/chat/model/file-attachments/index.ts:eventFileAttachmentsRemoved"
}), kl = h({
  loc: {
    file: Sn,
    line: 32,
    column: 41
  },
  name: "eventFileAttachmentsReset",
  sid: "2kkb27:/src/features/meet/chat/model/file-attachments/index.ts:eventFileAttachmentsReset"
}), vl = h({
  loc: {
    file: Sn,
    line: 34,
    column: 48
  },
  name: "eventFileAttachmentsUploadFailed",
  sid: "-1fuia5:/src/features/meet/chat/model/file-attachments/index.ts:eventFileAttachmentsUploadFailed"
}), Sl = h({
  loc: {
    file: Sn,
    line: 38,
    column: 50
  },
  name: "eventFileAttachmentsUploadFinished",
  sid: "t7djkz:/src/features/meet/chat/model/file-attachments/index.ts:eventFileAttachmentsUploadFinished"
}), pl = h({
  loc: {
    file: Sn,
    line: 42,
    column: 57
  },
  name: "eventFileAttachmentsUploadProgressUpdated",
  sid: "-kyu6rn:/src/features/meet/chat/model/file-attachments/index.ts:eventFileAttachmentsUploadProgressUpdated"
}), Ya = x({
  ref: /* @__PURE__ */ new Map()
}, {
  loc: {
    file: Sn,
    line: 47,
    column: 40
  },
  name: "$fileAttachmentsInternal",
  sid: "-6ebvek:/src/features/meet/chat/model/file-attachments/index.ts:$fileAttachmentsInternal"
}), Nl = Ya.map((e) => [...e.ref.values()]);
Ya.on(kl, () => ({
  ref: /* @__PURE__ */ new Map()
})).on(Li, (e, n) => {
  for (const i of n)
    e.ref.set(i.name, {
      file: i,
      progress: 0,
      state: "new"
    });
  return {
    ref: e.ref
  };
}).on(ul, (e, n) => (e.ref.delete(n), {
  ref: e.ref
})).on(Sl, (e, n) => {
  const i = e.ref.get(n.fileAttachment.file.name);
  if (i != null) {
    const a = {
      file: i.file,
      response: n.response,
      state: "uploaded"
    };
    e.ref.set(i.file.name, a);
  }
  return {
    ref: e.ref
  };
}).on(vl, (e, n) => {
  const i = e.ref.get(n.fileAttachment.file.name);
  if (i != null) {
    const a = {
      error: n.error,
      file: i.file,
      state: "error"
    };
    e.ref.set(i.file.name, a);
  }
  return {
    ref: e.ref
  };
}).on(pl, (e, n) => {
  const i = e.ref.get(n.fileAttachment.file.name);
  if (i != null && i.state === "new") {
    const a = {
      ...i,
      progress: n.progress
    };
    e.ref.set(i.file.name, a);
  }
  return {
    ref: e.ref
  };
});
var Ka = "/src/features/meet/chat/model/message-edit.ts";
const fl = h({
  loc: {
    file: Ka,
    line: 3,
    column: 40
  },
  name: "eventMessageEditSelected",
  sid: "fn4ufr:/src/features/meet/chat/model/message-edit.ts:eventMessageEditSelected"
}), Fl = h({
  loc: {
    file: Ka,
    line: 5,
    column: 41
  },
  name: "eventMessageReplySelected",
  sid: "gwuwii:/src/features/meet/chat/model/message-edit.ts:eventMessageReplySelected"
}), gl = h({
  loc: {
    file: Ka,
    line: 7,
    column: 37
  },
  name: "eventMessageEditReset",
  sid: "eu2jkh:/src/features/meet/chat/model/message-edit.ts:eventMessageEditReset"
});
var B = "/src/features/meet/chat/model/common.ts";
const Ja = h({
  loc: {
    file: B,
    line: 92,
    column: 39
  },
  name: "eventChatMessageReacted",
  sid: "bmcwk1:/src/features/meet/chat/model/common.ts:eventChatMessageReacted"
}), hl = $({
  loc: {
    file: B,
    line: 97,
    column: 37
  },
  name: "sendMessageReactionFx",
  sid: "-via7k3:/src/features/meet/chat/model/common.ts:sendMessageReactionFx"
}), bl = h({
  loc: {
    file: B,
    line: 102,
    column: 41
  },
  name: "eventChatMessageUnreacted",
  sid: "-n73qsn:/src/features/meet/chat/model/common.ts:eventChatMessageUnreacted"
}), yl = $({
  loc: {
    file: B,
    line: 107,
    column: 39
  },
  name: "sendMessageUnreactionFx",
  sid: "1jvgzy:/src/features/meet/chat/model/common.ts:sendMessageUnreactionFx"
}), Tl = h("eventSendMessage", {
  loc: {
    file: B,
    line: 112,
    column: 32
  },
  name: "eventSendMessage",
  sid: "o3dia5:/src/features/meet/chat/model/common.ts:eventSendMessage"
}), Cl = x([], {
  loc: {
    file: B,
    line: 114,
    column: 36
  },
  name: "$unconfirmedMessages",
  sid: "bc9ms0:/src/features/meet/chat/model/common.ts:$unconfirmedMessages"
}), Ui = x(en(), {
  loc: {
    file: B,
    line: 118,
    column: 33
  },
  name: "$receivedMessages",
  sid: "c1bueq:/src/features/meet/chat/model/common.ts:$receivedMessages"
}), Ml = w({
  and: [{
    source: {
      $receivedMessages: Ui,
      $unconfirmedMessages: Cl
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn(e) {
      return Xe((n) => [...e.$unconfirmedMessages, ...n], e.$receivedMessages);
    }
  }],
  or: {
    loc: {
      file: B,
      line: 122,
      column: 29
    },
    name: "$chatMessages",
    sid: "2nns2x:/src/features/meet/chat/model/common.ts:$chatMessages"
  }
}), pn = x({
  cursorPosition: 0,
  localId: Wn(),
  text: " ",
  type: "new"
}, {
  loc: {
    file: B,
    line: 171,
    column: 31
  },
  name: "$currentMessage",
  sid: "-mz8gim:/src/features/meet/chat/model/common.ts:$currentMessage"
});
Ko({
  deserialize: (e) => {
    try {
      const n = JSON.parse(e);
      return n.type != null ? n : {
        curPosition: 0,
        text: " ",
        type: "new"
      };
    } catch {
      return {
        curPosition: 0,
        text: " ",
        type: "new"
      };
    }
  },
  store: pn
});
const {
  __: xm,
  eventSubscriptionChatEditMessagesReceived_: Dm,
  eventSubscriptionChatReactionCountReceived_: Im,
  eventSubscriptionChatReactionSelfReceived_: Am,
  eventSubscriptionChatRemoveMessagesReceived_: Om,
  eventSubscriptionChatSentMessagesReceived_: Vm
} = Oo({
  and: [Ed, {
    eventSubscriptionChatEditMessagesReceived_: (e) => e.edit != null,
    eventSubscriptionChatReactionCountReceived_: (e) => {
      var n;
      return ((n = e.reaction) == null ? void 0 : n.count) != null;
    },
    eventSubscriptionChatReactionSelfReceived_: (e) => {
      var n;
      return ((n = e.reaction) == null ? void 0 : n.self) != null;
    },
    eventSubscriptionChatRemoveMessagesReceived_: (e) => e.remove != null,
    eventSubscriptionChatSentMessagesReceived_: (e) => e.sent != null
  }],
  or: {
    loc: {
      file: B,
      line: 203,
      column: 4
    },
    sid: "7ri8ci:/src/features/meet/chat/model/common.ts:"
  }
});
xm.watch((e) => (
  // eslint-disable-next-line no-console
  console.warn("Unknown chat event received", e)
));
const wl = Om.map((e) => e.remove), El = Vm.map((e) => e.sent), _l = Dm.map((e) => e.edit), Pm = Im.map((e) => e.reaction.count), Lm = Am.map((e) => e.reaction.self), Rl = h({
  loc: {
    file: B,
    line: 244,
    column: 41
  },
  name: "eventCurrentMesageChanged",
  sid: "8lu8aj:/src/features/meet/chat/model/common.ts:eventCurrentMesageChanged"
}), ia = h({
  loc: {
    file: B,
    line: 248,
    column: 55
  },
  name: "eventCurrentMesageCursorPositionChanged",
  sid: "-9qv0q3:/src/features/meet/chat/model/common.ts:eventCurrentMesageCursorPositionChanged"
}), xl = h({
  loc: {
    file: B,
    line: 264,
    column: 47
  },
  name: "eventCurrentMesageEmojiInserted",
  sid: "d37329:/src/features/meet/chat/model/common.ts:eventCurrentMesageEmojiInserted"
}), An = $({
  loc: {
    file: B,
    line: 266,
    column: 33
  },
  name: "sendChatMessageFx",
  sid: "-yr2lkw:/src/features/meet/chat/model/common.ts:sendChatMessageFx"
}), Qa = $({
  loc: {
    file: B,
    line: 273,
    column: 40
  },
  name: "sendMessageUploadFilesFx",
  sid: "cbh0e0:/src/features/meet/chat/model/common.ts:sendMessageUploadFilesFx"
}), Za = h({
  loc: {
    file: B,
    line: 283,
    column: 41
  },
  name: "eventChatScrollEndReached",
  sid: "rq11wl:/src/features/meet/chat/model/common.ts:eventChatScrollEndReached"
}), Hn = $(({
  cursor: e,
  limit: n
}) => Q().query({
  fetchPolicy: "network-only",
  query: Js,
  variables: {
    cursor: e,
    limit: Math.max(n ?? 0, 5)
  }
}).then((i) => i.data.meet.chat.history), {
  loc: {
    file: B,
    line: 287,
    column: 42
  },
  name: "fetchMessagesFromHistoryFx",
  sid: "jracg:/src/features/meet/chat/model/common.ts:fetchMessagesFromHistoryFx"
}), Dl = x("", {
  loc: {
    file: B,
    line: 303,
    column: 30
  },
  name: "$messageHistoryCursor",
  sid: "mgvqr1:/src/features/meet/chat/model/common.ts:$messageHistoryCursor"
});
Dl.on(Hn.done, (e, n) => n.result.cursor ?? null).reset(ue);
w({
  and: [{
    clock: Ma,
    fn: () => ({
      cursor: ""
    }),
    target: Hn
  }],
  or: {
    loc: {
      file: B,
      line: 311,
      column: 0
    },
    sid: "mvujok:/src/features/meet/chat/model/common.ts:"
  }
});
w({
  and: [{
    clock: Za,
    source: {
      messageHistoryCursor: Dl,
      isPending: Hn.pending
    },
    filter: (e) => e.messageHistoryCursor != null && !e.isPending,
    fn: (e, n) => ({
      cursor: e.messageHistoryCursor,
      limit: n.distanceFromEnd
    }),
    target: Hn
  }],
  or: {
    loc: {
      file: B,
      line: 317,
      column: 0
    },
    sid: "mz5b8q:/src/features/meet/chat/model/common.ts:"
  }
});
const rS = An.pending, et = $({
  loc: {
    file: B,
    line: 334,
    column: 31
  },
  name: "deleteMessageFx",
  sid: "-86jp9n:/src/features/meet/chat/model/common.ts:deleteMessageFx"
}), Il = w({
  and: [{
    clock: Tl,
    source: {
      accessToken: Jo,
      currentMessage: pn,
      fileAttachments: Nl
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: ({
      currentMessage: e,
      fileAttachments: n
    }) => e.text.trim().length > 0 || n.length > 0
  }],
  or: {
    loc: {
      file: B,
      line: 336,
      column: 33
    },
    name: "eventActuallySendMessage",
    sid: "-rpkzzn:/src/features/meet/chat/model/common.ts:eventActuallySendMessage"
  }
});
w({
  and: [{
    clock: Il,
    target: Qa
  }],
  or: {
    loc: {
      file: B,
      line: 348,
      column: 0
    },
    sid: "oetz0o:/src/features/meet/chat/model/common.ts:"
  }
});
Qa.use(async ({
  accessToken: e,
  fileAttachments: n
}) => n.length > 0 ? Promise.all(n.map(async (i) => {
  try {
    const a = new FormData();
    a.append("file", i.file);
    const l = (await Us.request({
      data: a,
      headers: {
        Authorization: e
      },
      method: "POST",
      onUploadProgress(o) {
        pl({
          fileAttachment: i,
          progress: Math.round(100 * o.loaded / (o.total ?? 1))
        });
      },
      url: "/media"
    })).data;
    return Sl({
      fileAttachment: i,
      response: l
    }), {
      file: i.file,
      response: l,
      state: "uploaded"
    };
  } catch (a) {
    return a instanceof $s && vl({
      error: a,
      fileAttachment: i
    }), {
      error: a,
      file: i.file,
      state: "error"
    };
  }
})) : []);
An.use(async ({
  currentMessage: e,
  fileAttachments: n
}) => {
  if (e.text === "" && !n.every((t) => t.state === "uploaded"))
    throw new Error("currentMessage is null and no attachments");
  if (n.some((t) => t.state !== "uploaded"))
    throw new Error("Cannot upload failed or non-uploaded files");
  const a = n.map((t) => ({
    type: ht.EmbedTypeFile,
    value: t.response.id
  }));
  switch (e.type) {
    case "new": {
      const t = await Q().mutate({
        mutation: xt,
        variables: {
          embeds: a,
          text: e.text
        }
      });
      if (t.data == null)
        throw new Error("data is null");
      return {
        messageId: t.data.meet.chat.send
      };
    }
    case "editing":
      return await Q().mutate({
        mutation: Mm,
        variables: {
          embeds: e.oldMessage.embeds.map((t) => ({
            type: t.type,
            value: t.value
          })).concat(a),
          id: e.oldMessage.id,
          text: e.text
        }
      }), {
        messageId: null
      };
    case "reply": {
      const t = await Q().mutate({
        mutation: xt,
        variables: {
          embeds: a.concat([{
            type: ht.EmbedTypeMessage,
            value: e.repliedMessage.id
          }]),
          text: e.text
        }
      });
      if (t.data == null)
        throw new Error("data is null");
      return {
        messageId: t.data.meet.chat.send
      };
    }
    default:
      throw new X(e);
  }
});
w({
  and: [{
    clock: Qa.done,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e) => ({
      ...e.params,
      fileAttachments: e.result
    }),
    target: An
  }],
  or: {
    loc: {
      file: B,
      line: 469,
      column: 0
    },
    sid: "-uz8rco:/src/features/meet/chat/model/common.ts:"
  }
});
Ya.on(An.done, () => ({
  ref: /* @__PURE__ */ new Map()
}));
pn.on(ia, (e, n) => ({
  ...e,
  cursorPosition: n
})).on(Rl, (e, n) => ({
  ...e,
  cursorPosition: n.cursorPosition,
  text: n.text
}));
pn.on(xl, (e, n) => {
  const i = e.text, a = i.slice(0, e.cursorPosition), t = i.slice(e.cursorPosition), l = `${a}${n}${t}`;
  return {
    ...e,
    cursorPosition: e.cursorPosition + n.length,
    text: l
  };
});
pn.on(w({
  and: [{
    clock: fl,
    source: Ui,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => Se((i) => i.find((a) => a.type === "RECEIVED" && a.message.id === n), void 0, e)
  }],
  or: {
    loc: {
      file: B,
      line: 505,
      column: 2
    },
    sid: "-j7bdtj:/src/features/meet/chat/model/common.ts:"
  }
}), (e, n) => n == null ? {
  cursorPosition: 0,
  // embeds: [],
  localId: Wn(),
  text: " ",
  type: "new"
} : {
  cursorPosition: n.message.text.length - 1,
  // embeds: payload.message.embeds.map(({ type, value }) => ({
  //   type,
  //   value,
  // })),
  localId: Wn(),
  oldMessage: n.message,
  text: n.message.text,
  type: "editing"
});
pn.on(w({
  and: [{
    clock: Fl,
    source: Ui,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => Se((i) => i.find((a) => a.type === "RECEIVED" && a.message.id === n), void 0, e)
  }],
  or: {
    loc: {
      file: B,
      line: 543,
      column: 4
    },
    sid: "-hc8hdr:/src/features/meet/chat/model/common.ts:"
  }
}), (e, n) => n == null ? {
  cursorPosition: 0,
  // embeds: [],
  localId: Wn(),
  text: " ",
  type: "new"
} : {
  cursorPosition: 0,
  // embeds: payload.message.embeds.map(({ type, value }) => ({
  //   type,
  //   value,
  // })),
  localId: Wn(),
  repliedMessage: n.message,
  text: " ",
  type: "reply"
}).reset(gl, An.done);
const Al = x(0, {
  loc: {
    file: B,
    line: 579,
    column: 41
  },
  name: "$receivedMessagesEditedAt",
  sid: "iudvzm:/src/features/meet/chat/model/common.ts:$receivedMessagesEditedAt"
}).on(wl, () => Date.now()).on(_l, () => Date.now());
Ui.on(Hn.doneData, (e, n) => he([...Se((i) => i, [], e), ...n.data.map((i) => ({
  message: i,
  type: "RECEIVED"
})).reverse()])).on(w({
  and: [{
    clock: El,
    source: an,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn(e, n) {
      var i;
      return {
        messages: n,
        myPeerId: (i = e == null ? void 0 : e.member) == null ? void 0 : i.id
      };
    }
  }],
  or: {
    loc: {
      file: B,
      line: 593,
      column: 4
    },
    sid: "-ez0des:/src/features/meet/chat/model/common.ts:"
  }
}), (e, n) => he([...n.messages.messages.map((i) => ({
  message: i,
  type: "RECEIVED"
})).reverse(), ...Se((i) => i, [], e)])).on(wl, (e, n) => Xe((i) => i.filter((a) => a.type !== "RECEIVED" || !n.messages.includes(a.message.id)), e)).on(_l, (e, n) => Xe((i) => i.map((a) => {
  const t = n.messages.find((l) => a.type === "RECEIVED" && l.id === a.message.id);
  return t == null || a.type !== "RECEIVED" ? a : {
    ...a,
    message: {
      ...a.message,
      embeds: t.embeds,
      text: t.text,
      updated: t.updated
    }
  };
}), e)).on(Pm, (e, n) => Xe((i) => i.map((a) => {
  if (a.type !== "RECEIVED")
    return a;
  const t = n.messages.find((l) => l.id === a.message.id);
  return t == null ? a : {
    ...a,
    message: {
      ...a.message,
      reactions: t.reactions.map((l) => {
        var o;
        return {
          ...l,
          me: ((o = a.message.reactions.find((s) => s.name === l.name)) == null ? void 0 : o.me) ?? !1
        };
      })
    }
  };
}), e)).on(Lm, (e, n) => Xe((i) => i.map((a) => {
  if (a.type !== "RECEIVED")
    return a;
  const t = n.messages.find((l) => l.id === a.message.id);
  return t == null ? a : {
    ...a,
    message: {
      ...a.message,
      reactions: a.message.reactions.map((l) => ({
        ...l,
        me: t.reactions.includes(l.name)
      }))
    }
  };
}), e)).reset(ue, Ne);
Cl.on(Il, (e, n) => (
  /* FIXME переделать на sample? */
  n.currentMessage.type === "new" ? [{
    localId: n.currentMessage.localId,
    messageText: n.currentMessage.text,
    sent: (/* @__PURE__ */ new Date()).toISOString(),
    type: "UNCONFIRMED"
  }, ...e] : void 0
)).on(An.done, (e, n) => e.filter((i) => i.localId !== n.params.currentMessage.localId)).reset(ue, Ne);
et.use((e) => Q().mutate({
  mutation: wm,
  variables: {
    id: e
  }
}).then(() => {
}));
w({
  and: [{
    clock: Ja,
    target: hl
  }],
  or: {
    loc: {
      file: B,
      line: 738,
      column: 0
    },
    sid: "bm9fjh:/src/features/meet/chat/model/common.ts:"
  }
});
hl.use(({
  messageId: e,
  reaction: n
}) => Q().mutate({
  mutation: Em,
  variables: {
    emoji: n,
    messageId: e
  }
}).then(() => {
}));
w({
  and: [{
    clock: bl,
    target: yl
  }],
  or: {
    loc: {
      file: B,
      line: 748,
      column: 0
    },
    sid: "c3b1xo:/src/features/meet/chat/model/common.ts:"
  }
});
yl.use(({
  messageId: e,
  reaction: n
}) => Q().mutate({
  mutation: _m,
  variables: {
    emoji: n,
    messageId: e
  }
}).then(() => {
}));
function Wn() {
  return mi("unsent");
}
var ni = "/src/features/meet/chat/model/has-new-messages.ts";
const Ol = h({
  loc: {
    file: ni,
    line: 7,
    column: 45
  },
  name: "eventToggleChatHasNewMessages",
  sid: "-6v8mqw:/src/features/meet/chat/model/has-new-messages.ts:eventToggleChatHasNewMessages"
}), Vl = h({
  loc: {
    file: ni,
    line: 8,
    column: 44
  },
  name: "eventResetChatHasNewMessages",
  sid: "-dikvdt:/src/features/meet/chat/model/has-new-messages.ts:eventResetChatHasNewMessages"
}), Um = x(!1, {
  loc: {
    file: ni,
    line: 10,
    column: 35
  },
  name: "$chatHasNewMessages",
  sid: "-c23oj9:/src/features/meet/chat/model/has-new-messages.ts:$chatHasNewMessages"
}).on(Ol, (e, n) => n).reset(Vl);
w({
  and: [{
    clock: Qn,
    source: xn,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => e === re.Chat,
    target: Vl
  }],
  or: {
    loc: {
      file: ni,
      line: 15,
      column: 0
    },
    sid: "2hcwqc:/src/features/meet/chat/model/has-new-messages.ts:"
  }
});
w({
  and: [{
    clock: El,
    source: xn,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e) => e !== re.Chat,
    target: Ol
  }],
  or: {
    loc: {
      file: ni,
      line: 25,
      column: 0
    },
    sid: "2yej4j:/src/features/meet/chat/model/has-new-messages.ts:"
  }
});
var $i = "/src/features/meet/chat/model/message-context-menu.ts";
const aa = h({
  loc: {
    file: $i,
    line: 8,
    column: 36
  },
  name: "eventOpenContextMenu",
  sid: "-abihg0:/src/features/meet/chat/model/message-context-menu.ts:eventOpenContextMenu"
}), Pl = h({
  loc: {
    file: $i,
    line: 9,
    column: 37
  },
  name: "eventCloseContextMenu",
  sid: "3e18dw:/src/features/meet/chat/model/message-context-menu.ts:eventCloseContextMenu"
}), $m = h({
  loc: {
    file: $i,
    line: 10,
    column: 37
  },
  name: "eventResetContextMenu",
  sid: "193cet:/src/features/meet/chat/model/message-context-menu.ts:eventResetContextMenu"
}), Ll = x({
  messageId: "",
  opened: !1
}, {
  and: {
    name: "$chatContextMenuStore"
  },
  loc: {
    file: $i,
    line: 12,
    column: 38
  },
  name: "$chatContextMenuOpened",
  sid: "85cfcg:/src/features/meet/chat/model/message-context-menu.ts:$chatContextMenuOpened"
}).on(aa, (e, n) => n).on(Pl, () => ({
  messageId: "",
  opened: !1
})).reset($m);
var nt = "/src/features/meet/chat/model/message-delete.ts";
const Ul = h({
  loc: {
    file: nt,
    line: 5,
    column: 42
  },
  name: "eventMessageDeleteSelected",
  sid: "ywqjn7:/src/features/meet/chat/model/message-delete.ts:eventMessageDeleteSelected"
}), $l = h({
  loc: {
    file: nt,
    line: 7,
    column: 39
  },
  name: "eventMessageDeleteReset",
  sid: "-xbb983:/src/features/meet/chat/model/message-delete.ts:eventMessageDeleteReset"
}), Bm = x(null, {
  loc: {
    file: nt,
    line: 8,
    column: 32
  },
  name: "$messageDeleteId",
  sid: "s90ulh:/src/features/meet/chat/model/message-delete.ts:$messageDeleteId"
}).on(Ul, (e, n) => n).reset($l, et.done), Si = /* @__PURE__ */ g(({
  file: e,
  fileName: n,
  fileSize: i,
  mime: a,
  onDelete: t,
  progress: l,
  url: o
}) => {
  const s = me(), r = E(() => {
    t == null || t();
  }, [t]), c = P(() => {
    var u;
    return a != null && a.includes("image/") ? o != null ? (u = Sa(o, 48)) == null ? void 0 : u.uri : e != null ? URL.createObjectURL(e) : null : null;
  }, [e, a, o]);
  return /* @__PURE__ */ f(ne, {
    alignItems: "center",
    flexDirection: "row",
    onPress: () => {
      o != null && ld.openURL(o);
    },
    py: "1",
    children: [/* @__PURE__ */ d(_, {
      alignItems: "center",
      bg: "rgba(255, 255, 255, 0.08)",
      borderRadius: "5",
      h: "48px",
      justifyContent: "center",
      mr: "3",
      w: "48px",
      children: c != null ? /* @__PURE__ */ d(fi, {
        borderRadius: "5",
        h: "48px",
        resizeMode: "cover",
        source: {
          uri: c
        },
        w: "48px"
      }) : /* @__PURE__ */ d(Fs, {
        fill: s.colors.blue[50]
      })
    }), /* @__PURE__ */ f(_, {
      flex: "1",
      children: [/* @__PURE__ */ d(b, {
        color: "gray.400",
        ellipsizeMode: "middle",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "20",
        mb: "1",
        numberOfLines: 1,
        children: n
      }), /* @__PURE__ */ d(b, {
        color: "text.200",
        ellipsizeMode: "tail",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "20",
        mb: "1",
        numberOfLines: 1,
        children: i
      }), l ? /* @__PURE__ */ d(es, {
        size: "xs",
        value: l,
        w: "100%"
      }) : null]
    }), /* @__PURE__ */ d(_, {
      children: t != null ? /* @__PURE__ */ d(gi, {
        onPress: r,
        children: /* @__PURE__ */ d(yi, {
          fill: s.colors.gray[400],
          height: "32",
          width: "32"
        })
      }) : null
    })]
  });
});
Si.displayName = "AttachedFile";
const zm = [
  "B",
  "kB",
  "MB",
  "GB",
  "TB",
  "PB",
  "EB",
  "ZB",
  "YB"
], jm = [
  "B",
  "kiB",
  "MiB",
  "GiB",
  "TiB",
  "PiB",
  "EiB",
  "ZiB",
  "YiB"
], Hm = [
  "b",
  "kbit",
  "Mbit",
  "Gbit",
  "Tbit",
  "Pbit",
  "Ebit",
  "Zbit",
  "Ybit"
], Wm = [
  "b",
  "kibit",
  "Mibit",
  "Gibit",
  "Tibit",
  "Pibit",
  "Eibit",
  "Zibit",
  "Yibit"
], Dt = (e, n, i) => {
  let a = e;
  return typeof n == "string" || Array.isArray(n) ? a = e.toLocaleString(n, i) : (n === !0 || i !== void 0) && (a = e.toLocaleString(void 0, i)), a;
};
var Gm = (e, n) => {
  if (!Number.isFinite(e))
    throw new TypeError(`Expected a finite number, got ${typeof e}: ${e}`);
  n = Object.assign({ bits: !1, binary: !1 }, n);
  const i = n.bits ? n.binary ? Wm : Hm : n.binary ? jm : zm;
  if (n.signed && e === 0)
    return ` 0 ${i[0]}`;
  const a = e < 0, t = a ? "-" : n.signed ? "+" : "";
  a && (e = -e);
  let l;
  if (n.minimumFractionDigits !== void 0 && (l = { minimumFractionDigits: n.minimumFractionDigits }), n.maximumFractionDigits !== void 0 && (l = Object.assign({ maximumFractionDigits: n.maximumFractionDigits }, l)), e < 1) {
    const c = Dt(e, n.locale, l);
    return t + c + " " + i[0];
  }
  const o = Math.min(Math.floor(n.binary ? Math.log(e) / Math.log(1024) : Math.log10(e) / 3), i.length - 1);
  e /= Math.pow(n.binary ? 1024 : 1e3, o), l || (e = e.toPrecision(3));
  const s = Dt(Number(e), n.locale, l), r = i[o];
  return t + s + " " + r;
};
const ta = /* @__PURE__ */ bd(Gm), Bl = /* @__PURE__ */ g(({
  message: e
}) => {
  const n = me(), {
    t: i
  } = le(), a = E(() => {
    gl();
  }, []);
  return /* @__PURE__ */ f(_, {
    alignItems: "center",
    flexDirection: "row",
    mb: "2",
    children: [e.type === "editing" ? /* @__PURE__ */ d(gs, {
      fill: n.colors.blue[100],
      height: "24",
      width: "24"
    }) : /* @__PURE__ */ d(hs, {
      fill: n.colors.blue[100],
      height: "24",
      width: "24"
    }), /* @__PURE__ */ f(_, {
      borderLeftColor: "blue.100",
      borderLeftStyle: "solid",
      borderLeftWidth: "2",
      flex: "1",
      mx: "2",
      px: "10px",
      children: [/* @__PURE__ */ d(b, {
        color: "blue.100",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "18",
        children: e.type === "editing" ? i("CHAT.TITLE.EDIT", {
          defaultValue: "Редактирование"
        }) : i("CHAT.TITLE.REPLY", {
          defaultValue: "Ответ"
        })
      }), /* @__PURE__ */ d(b, {
        color: "gray.400",
        ellipsizeMode: "tail",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "18",
        numberOfLines: 1,
        children: e.type === "editing" ? e.oldMessage.text : e.repliedMessage.text
      })]
    }), /* @__PURE__ */ d(ne, {
      onPress: a,
      w: "6",
      children: /* @__PURE__ */ d(yi, {
        height: "24",
        width: "24"
      })
    })]
  });
});
Bl.displayName = "ChatMessageEditPreview";
const qm = {
  backgroundColor: "rgba(0, 0, 0, 0)",
  bottom: -12,
  left: -12,
  position: "absolute",
  right: -12,
  top: -12,
  zIndex: 1
}, it = /* @__PURE__ */ g(() => A(Ll).opened ? /* @__PURE__ */ d("div", {
  "data-testid": "ContextMenuOverlay",
  style: qm
}) : null);
it.displayName = "ContextMenuOverlay";
var Ht;
const at = typeof window < "u" && !!((Ht = window.document) != null && Ht.createElement), Xm = at && !!(window.addEventListener || window.attachEvent), Ym = at && !!window.screen, Km = !1, Jm = {
  /**
   * Denotes the currently running platform.
   * Can be one of ios, android, web.
   */
  OS: Qe.OS,
  /**
   * Returns the value with the matching platform.
   * Object keys can be any of ios, android, native, web, default.
   *
   * @ios ios, native, default
   * @android android, native, default
   * @web web, default
   */
  select: Qe.select,
  /**
   * Denotes if the DOM API is available in the current environment.
   * The DOM is not available in native React runtimes and Node.js.
   */
  isDOMAvailable: at,
  /**
   * Denotes if the current environment can attach event listeners
   * to the window. This will return false in native React
   * runtimes and Node.js.
   */
  canUseEventListeners: Xm,
  /**
   * Denotes if the current environment can inspect properties of the
   * screen on which the current window is being rendered. This will
   * return false in native React runtimes and Node.js.
   */
  canUseViewport: Ym,
  /**
   * If the JavaScript is being executed in a remote JavaScript environment.
   * When `true`, synchronous native invocations cannot be executed.
   */
  isAsyncDebugging: Km
}, Qm = Jm;
class Zm extends Error {
  constructor(i, a) {
    super(a);
    Xi(this, "code");
    Xi(this, "info");
    this.code = i;
  }
}
globalThis.ExpoModulesCore_CodedError = Zm;
var ec = { exports: {} };
(function(e, n) {
  (function(i, a) {
    e.exports = a();
  })(hd, function() {
    var i = /^v?(?:\d+)(\.(?:[x*]|\d+)(\.(?:[x*]|\d+)(\.(?:[x*]|\d+))?(?:-[\da-z\-]+(?:\.[\da-z\-]+)*)?(?:\+[\da-z\-]+(?:\.[\da-z\-]+)*)?)?)?$/i;
    function a(S, m) {
      return S.indexOf(m) === -1 ? S.length : S.indexOf(m);
    }
    function t(S) {
      var m = S.replace(/^v/, "").replace(/\+.*$/, ""), p = a(m, "-"), k = m.substring(0, p).split(".");
      return k.push(m.substring(p + 1)), k;
    }
    function l(S) {
      return isNaN(Number(S)) ? S : Number(S);
    }
    function o(S) {
      if (typeof S != "string")
        throw new TypeError("Invalid argument expected string");
      if (!i.test(S))
        throw new Error("Invalid argument not valid semver ('" + S + "' received)");
    }
    function s(S, m) {
      [S, m].forEach(o);
      for (var p = t(S), k = t(m), F = 0; F < Math.max(p.length - 1, k.length - 1); F++) {
        var v = parseInt(p[F] || 0, 10), N = parseInt(k[F] || 0, 10);
        if (v > N)
          return 1;
        if (N > v)
          return -1;
      }
      var y = p[p.length - 1], T = k[k.length - 1];
      if (y && T) {
        var C = y.split(".").map(l), M = T.split(".").map(l);
        for (F = 0; F < Math.max(C.length, M.length); F++) {
          if (C[F] === void 0 || typeof M[F] == "string" && typeof C[F] == "number")
            return -1;
          if (M[F] === void 0 || typeof C[F] == "string" && typeof M[F] == "number" || C[F] > M[F])
            return 1;
          if (M[F] > C[F])
            return -1;
        }
      } else if (y || T)
        return y ? -1 : 1;
      return 0;
    }
    var r = [
      ">",
      ">=",
      "=",
      "<",
      "<="
    ], c = {
      ">": [1],
      ">=": [0, 1],
      "=": [0],
      "<=": [-1, 0],
      "<": [-1]
    };
    function u(S) {
      if (typeof S != "string")
        throw new TypeError("Invalid operator type, expected string but got " + typeof S);
      if (r.indexOf(S) === -1)
        throw new TypeError("Invalid operator, expected one of " + r.join("|"));
    }
    return s.validate = function(S) {
      return typeof S == "string" && i.test(S);
    }, s.compare = function(S, m, p) {
      u(p);
      var k = s(S, m);
      return c[p].indexOf(k) > -1;
    }, s;
  });
})(ec);
var It;
(function(e) {
  e.GRANTED = "granted", e.UNDETERMINED = "undetermined", e.DENIED = "denied";
})(It || (It = {}));
const nc = {
  get name() {
    return "ExpoDocumentPicker";
  },
  async getDocumentAsync({ type: e = "*/*", multiple: n = !1 }) {
    if (!Qm.isDOMAvailable)
      return { canceled: !0, assets: null };
    const i = document.createElement("input");
    return i.style.display = "none", i.setAttribute("type", "file"), i.setAttribute("accept", Array.isArray(e) ? e.join(",") : e), i.setAttribute("id", String(Math.random())), n && i.setAttribute("multiple", "multiple"), document.body.appendChild(i), new Promise((a, t) => {
      i.addEventListener("change", async () => {
        if (i.files) {
          const o = [];
          for (let s = 0; s < i.files.length; s++)
            o.push(ic(i.files[s]));
          try {
            const s = await Promise.all(o);
            a({ canceled: !1, assets: s, output: i.files });
          } catch (s) {
            t(s);
          }
        } else
          a({ canceled: !0, assets: null });
        document.body.removeChild(i);
      });
      const l = new MouseEvent("click");
      i.dispatchEvent(l);
    });
  }
};
function ic(e) {
  return new Promise((n, i) => {
    const a = e.type, t = new FileReader();
    t.onerror = () => {
      i(new Error("Failed to read the selected media because the operation failed."));
    }, t.onload = ({ target: l }) => {
      const o = l.result;
      n({
        uri: o,
        mimeType: a,
        name: e.name,
        lastModified: e.lastModified,
        size: e.size,
        file: e
      });
    }, t.readAsDataURL(e);
  });
}
async function ac({ type: e = "*/*", copyToCacheDirectory: n = !0, multiple: i = !1 } = {}) {
  return typeof e == "string" && (e = [e]), await nc.getDocumentAsync({
    type: e,
    copyToCacheDirectory: n,
    multiple: i
  });
}
const tc = async () => {
  const e = await ac();
  !e.canceled && e.output && Li(e.assets.map((n) => n.file).filter(ze));
}, zl = /* @__PURE__ */ g(() => /* @__PURE__ */ d(ne, {
  color: "gray.400",
  onPress: tc,
  children: /* @__PURE__ */ d(Bs, {})
}));
zl.displayName = "FilePicker";
const jl = "textarea-enter-message", dc = "_container_1v965_1", lc = "_row_1v965_5", oc = "_rowInside_1v965_10", sc = "_button_1v965_20", rc = "_containerShort_1v965_24", mc = "_gap_1v965_48", cc = "_inputContainer_1v965_53", uc = "_input_1v965_53", kc = "_buttonSend_1v965_88", vc = "_buttonDisabled_1v965_92", Te = {
  container: dc,
  row: lc,
  rowInside: oc,
  button: sc,
  containerShort: rc,
  gap: mc,
  inputContainer: cc,
  input: uc,
  buttonSend: kc,
  buttonDisabled: vc
}, tt = /* @__PURE__ */ g(({
  placeholder: e,
  shortView: n
}) => {
  const {
    t: i
  } = le(), a = A(pn), [t, l] = H(!1), o = je(null), s = je(null), r = E(() => {
    o.current != null && (s.current != null && (clearTimeout(s.current), s.current = null), o.current.classList.add("focused"));
  }, [s]), c = E(() => {
    s.current != null && (clearTimeout(s.current), s.current = null), s.current = setTimeout(() => {
      o.current != null && o.current.classList.remove("focused");
    }, 500);
  }, []), u = E(() => {
    o.current != null && o.current.focus();
  }, []), S = E(() => {
    var M;
    ia(((M = o.current) == null ? void 0 : M.selectionStart) ?? 0);
  }, []), m = E(() => {
    o.current != null && (o.current.style.height = "24px", o.current.style.height = `${o.current.scrollHeight}px`);
  }, []), p = E(() => {
    if (o.current == null)
      return;
    const M = o.current.value, V = o.current.selectionStart;
    Rl({
      cursorPosition: V,
      text: M
    }), l(!1), m();
  }, [m]), k = E(() => {
    o.current != null && (l(!1), Tl(), m(), u());
  }, [u, m]), F = E((M) => {
    M.preventDefault(), k();
  }, [k]), v = E(() => {
    l((M) => !M), u();
  }, [u]), N = E((M) => {
    M != null && M.code === "Enter" && !M.getModifierState("Shift") && (M.preventDefault(), M.stopPropagation(), k());
  }, [k]), y = E(() => {
    o.current != null && ia(o.current.selectionStart);
  }, []), T = E((M) => {
    o.current != null && xl(M);
  }, []), C = E((M) => {
    if (M == null)
      return;
    const V = M.clipboardData.items;
    for (let G = 0; G < V.length; G++)
      if (V[G].type.includes("image")) {
        const se = V[G].getAsFile();
        if (se == null)
          return;
        Li([se]);
      }
  }, []);
  return J(() => {
    o.current != null && (o.current.value = a.text, o.current.setSelectionRange(a.cursorPosition, a.cursorPosition), o.current.focus(), m());
  }, [a.cursorPosition, a.text, m]), /* @__PURE__ */ f(be, {
    children: [/* @__PURE__ */ d(it, {}), /* @__PURE__ */ f("form", {
      className: xe(Te.container, {
        [Te.containerShort]: n
      }),
      onSubmit: F,
      children: [a.type === "editing" || a.type === "reply" ? /* @__PURE__ */ d(Bl, {
        message: a
      }) : null, /* @__PURE__ */ f("div", {
        className: Te.row,
        children: [n ? null : /* @__PURE__ */ f(be, {
          children: [/* @__PURE__ */ d(zl, {}), /* @__PURE__ */ d("div", {
            className: Te.gap
          })]
        }), /* @__PURE__ */ f("div", {
          className: Te.rowInside,
          children: [/* @__PURE__ */ d("div", {
            className: Te.inputContainer,
            children: /* @__PURE__ */ d("textarea", {
              ref: o,
              className: xe(Te.input),
              id: jl,
              onBlur: c,
              onChange: p,
              onClick: S,
              onFocus: r,
              onKeyDown: N,
              onKeyUp: y,
              onPaste: C,
              placeholder: e ?? i("CHAT.MESSAGE", {
                defaultValue: "Сообщение..."
              })
            })
          }), /* @__PURE__ */ d("div", {
            className: Te.gap
          }), /* @__PURE__ */ d("button", {
            className: Te.button,
            onClick: v,
            type: "button",
            children: /* @__PURE__ */ d(zs, {})
          })]
        }), /* @__PURE__ */ d("button", {
          className: xe(Te.button, Te.buttonSend, {
            // [css.buttonDisabled]: !Boolean(message.current),
            // [css.buttonSend]: Boolean(message.current),
          }),
          title: "Отправить сообщение",
          type: "submit",
          children: /* @__PURE__ */ d(js, {})
        }), /* @__PURE__ */ d(rs, {
          onEmojiSelect: T,
          visible: t
        })]
      })]
    })]
  });
});
tt.displayName = "MessageInputPanel";
const Sc = /* @__PURE__ */ g(() => {
  const {
    t: e
  } = le(), n = me(), i = A(Nl), a = E(() => {
    kl();
  }, []);
  return i.length === 0 ? null : /* @__PURE__ */ d(_, {
    bg: "rgba(0, 0, 0, .7)",
    bottom: "0",
    justifyContent: "center",
    left: "0",
    p: "2",
    position: "absolute",
    right: "0",
    top: "0",
    zIndex: "9",
    children: /* @__PURE__ */ f(_, {
      bg: "#141414",
      borderRadius: "12",
      maxHeight: "100%",
      p: "6",
      shadow: "1",
      children: [/* @__PURE__ */ f(_, {
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        mb: "6",
        children: [/* @__PURE__ */ d(b, {
          color: "white",
          fontSize: "16px",
          fontWeight: "500",
          lineHeight: "20px",
          children: e("CHAT.TITLE.ATTACH_FILE", {
            defaultValue: "Отправить файл"
          })
        }), /* @__PURE__ */ d(gi, {
          onPress: a,
          children: /* @__PURE__ */ d(yi, {
            fill: n.colors.gray[400],
            height: "32",
            width: "32"
          })
        })]
      }), i.map((t) => /* @__PURE__ */ d(Si, {
        file: t.file,
        fileName: t.file.name,
        fileSize: ta(t.file.size),
        mime: t.file.type,
        onDelete: () => {
          ul(t.file.name);
        },
        progress: t.state === "new" ? t.progress : void 0
      }, t.file.name + t.file.size)), /* @__PURE__ */ d(_, {
        mb: "8",
        mt: "6",
        children: /* @__PURE__ */ d(tt, {
          shortView: !0,
          placeholder: e("CHAT.SIGN", {
            defaultValue: "Подпись..."
          })
        })
      })]
    })
  });
});
Sc.displayName = "ChatAddAttachments";
const da = "chatBodyPanel", pc = "_dropzoneContainer_m10z4_1", Nc = "_dropzoneOverlay_m10z4_9", At = {
  dropzoneContainer: pc,
  dropzoneOverlay: Nc
}, dt = /* @__PURE__ */ g(({
  children: e
}) => {
  const {
    getInputProps: n,
    getRootProps: i,
    isDragActive: a
  } = Gs({
    noClick: !0,
    // accept: {
    //   "image/*": [".png", ".gif", ".jpeg", ".jpg"],
    // },
    onDrop: Li
  });
  return /* @__PURE__ */ f("div", {
    className: At.dropzoneContainer,
    ...i(),
    "data-testid": "ChatBodyPanel",
    id: da,
    children: [/* @__PURE__ */ d("input", {
      ...n()
    }), a ? /* @__PURE__ */ d("div", {
      className: At.dropzoneOverlay
    }) : null, e]
  });
});
dt.displayName = "ChatBodyPanel";
const la = /* @__PURE__ */ g(({
  children: e,
  distance: n
}) => /* @__PURE__ */ f(b, {
  color: "white",
  fontSize: "14",
  lineHeight: "18",
  maxWidth: "100%",
  children: [e, /* @__PURE__ */ d(b, {
    display: "inline-flex",
    minWidth: "40px",
    opacity: "0",
    pr: "7",
    children: n
  })]
}));
la.displayName = "ChatItemText";
const oa = /* @__PURE__ */ g(/* @__PURE__ */ ls(({
  children: e
}, n) => /* @__PURE__ */ d(b, {
  ref: n,
  bottom: "6px",
  color: "text.100",
  fontSize: "12",
  fontWeight: "500",
  lineHeight: "20px",
  position: "absolute",
  right: "2",
  children: e
})));
oa.displayName = "ChatItemTime";
const lt = /* @__PURE__ */ g(({
  isOwner: e,
  message: n
}) => {
  if (n.type !== "RECEIVED")
    return null;
  const i = n.message.reactions;
  return /* @__PURE__ */ d(_, {
    flexDirection: e ? "row-reverse" : "row",
    flexWrap: "wrap",
    ml: e ? "-1" : "0",
    mr: e ? "-1" : "0",
    my: "1",
    children: i.map((a) => /* @__PURE__ */ d(Hl, {
      count: a.count,
      hasMyReaction: a.me,
      isOwner: e ?? !1,
      messageId: n.message.id,
      reaction: a.name
    }, a.name))
  });
});
lt.displayName = "ChatItemReaction";
const Hl = /* @__PURE__ */ g(({
  count: e,
  hasMyReaction: n,
  isOwner: i,
  messageId: a,
  reaction: t
}) => {
  const [l, o] = H(!1);
  return /* @__PURE__ */ f(ne, {
    alignItems: "center",
    bg: "rgba(255, 255, 255, .04)",
    borderRadius: "12",
    flexDirection: "row",
    h: "24px",
    ml: i ? "0" : "1",
    mr: i ? "1" : "0",
    mt: "1",
    onHoverIn: () => o(!0),
    onHoverOut: () => o(!1),
    onPress: () => {
      n ? bl({
        messageId: a,
        reaction: t
      }) : Ja({
        messageId: a,
        reaction: t
      });
    },
    px: "2",
    children: [/* @__PURE__ */ d(b, {
      fontSize: "12",
      lineHeight: "20",
      children: t
    }), /* @__PURE__ */ d(b, {
      color: l ? "white" : n ? "blue.100" : "text.100",
      fontSize: "12",
      fontWeight: "500",
      lineHeight: "20",
      ml: "1",
      position: "relative",
      top: "1px",
      children: e
    })]
  });
});
Hl.displayName = "Reaction";
const Wl = /* @__PURE__ */ g(({
  children: e,
  message: n
}) => /* @__PURE__ */ f(_, {
  alignItems: "flex-start",
  flex: "1",
  flexDirection: "column",
  mr: "36px",
  mt: "1",
  children: [/* @__PURE__ */ d(_, {
    bg: "#232324",
    borderBottomLeftRadius: "2px",
    borderBottomRightRadius: "12px",
    borderTopRadius: "10px",
    maxWidth: "100%",
    minWidth: 90,
    position: "relative",
    px: "2",
    py: "10px",
    children: e
  }), /* @__PURE__ */ d(lt, {
    message: n
  })]
}));
Wl.displayName = "Message";
const fc = "_emojiesInner_16r1q_1", Fc = "_emojies_16r1q_1", Ot = {
  emojiesInner: fc,
  emojies: Fc
}, Gl = /* @__PURE__ */ g(({
  onReactionSelect: e
}) => {
  const n = rd.emojis, i = je(null);
  return J(() => {
    const a = i.current;
    if (a != null) {
      const t = (l) => {
        a.scrollLeft += l.deltaY, l.preventDefault(), l.stopPropagation();
      };
      return a.addEventListener("wheel", t), () => {
        a.removeEventListener("wheel", t);
      };
    }
  }, [i]), J(() => {
    const a = i.current;
    if (a != null) {
      const t = (l) => {
        l.code === "ArrowLeft" ? a.scrollLeft -= 10 : l.code === "ArrowRight" && (a.scrollLeft += 10);
      };
      return document.body.addEventListener("keydown", t, !1), () => {
        document.body.removeEventListener("keydown", t);
      };
    }
  }, []), /* @__PURE__ */ d("div", {
    ref: i,
    className: Ot.emojiesInner,
    children: /* @__PURE__ */ d("div", {
      className: Ot.emojies,
      children: Qt.emojis.map((a) => {
        const t = n[a];
        return t == null || t.version !== 1 ? null : /* @__PURE__ */ d(ms, {
          emoji: t.skins[0].native,
          onEmojiSelect: e
        }, t.id);
      })
    })
  });
});
Gl.displayName = "MessageReactionSelect";
const gc = "_menuContainer_utwk9_1", hc = "_showMenu_utwk9_1", bc = "_menu_utwk9_1", yc = "_menuItem_utwk9_20", Tc = "_menuItemDelete_utwk9_33", Cc = "_container_utwk9_41", Mc = "_containerOwner_utwk9_47", wc = "_containerSelected_utwk9_51", Ec = "_containerHidden_utwk9_55", _c = "_emojies_utwk9_71", Rc = "_bg_utwk9_96", Ce = {
  menuContainer: gc,
  showMenu: hc,
  menu: bc,
  menuItem: yc,
  menuItemDelete: Tc,
  container: Cc,
  containerOwner: Mc,
  containerSelected: wc,
  containerHidden: Ec,
  emojies: _c,
  bg: Rc
}, ti = 208, xc = 36, Pn = 10, Dc = 20, sa = /* @__PURE__ */ g(({
  children: e,
  isMe: n,
  messageId: i
}) => {
  const a = A(Ll), {
    height: t,
    width: l
  } = wn(), [o, s] = H({
    panelRect: void 0,
    rect: null,
    x: 0,
    y: 0
  }), r = je(null), c = je(!1), [u, S] = H({}), m = mn(), p = E((T) => {
    if (r.current == null)
      return;
    T.preventDefault(), (!a.opened || a.messageId !== i) && aa({
      messageId: i,
      opened: !0
    });
    const C = r.current.getBoundingClientRect(), M = document.querySelector(`#${da}`);
    s({
      panelRect: M == null ? void 0 : M.getBoundingClientRect(),
      rect: C,
      x: T.clientX,
      y: T.clientY
    });
  }, [i, a.messageId, a.opened]), k = E(() => {
    a.opened && a.messageId === i && (Pl(), c.current = !1);
  }, [i, a.messageId, a.opened]), F = E(() => {
    c.current = !0;
  }, []), v = E((T) => {
    if (r.current == null)
      return;
    const C = r.current.getBoundingClientRect(), M = document.querySelector(`#${da}`);
    s({
      panelRect: M == null ? void 0 : M.getBoundingClientRect(),
      rect: C,
      x: T.clientX,
      y: T.clientY
    }), c.current ? a.opened ? k() : aa({
      messageId: i,
      opened: !0
    }) : a.opened && k();
  }, [k, i, a.opened]), N = E((T) => {
    Ja({
      messageId: i,
      reaction: T
    }), k();
  }, [k, i]);
  J(() => {
    const T = (C) => {
      var M;
      (M = r.current) != null && M.contains(C.target) || k();
    };
    return document.addEventListener("click", T, !1), document.addEventListener("contextmenu", T, !1), () => {
      document.removeEventListener("click", T), document.removeEventListener("contextmenu", T);
    };
  }, [k]), J(() => {
    const T = (C) => {
      C.code === "Escape" && k();
    };
    return document.body.addEventListener("keydown", T, !1), () => {
      document.body.removeEventListener("keydown", T);
    };
  }, [k]);
  const y = P(() => {
    const T = [{
      className: Ce.menuItem,
      id: "reply",
      onClick: (C) => {
        C.stopPropagation(), Fl(i), k();
      },
      title: "Ответить"
    }];
    return n && T.unshift({
      className: xe(Ce.menuItem, Ce.menuItemDelete),
      id: "delete",
      onClick: (C) => {
        C.stopPropagation(), Ul(i), k();
      },
      title: "Удалить"
    }, {
      className: Ce.menuItem,
      id: "edit",
      onClick: (C) => {
        C.stopPropagation(), fl(i), k();
      },
      title: "Редактировать"
    }), T;
  }, [k, n, i]);
  return J(() => {
    if (!(r.current == null || o.rect == null || o.panelRect == null) && a.opened && a.messageId === i) {
      const {
        panelRect: T,
        rect: C,
        x: M,
        y: V
      } = o, G = Math.abs(M - C.x), se = Math.abs(V - C.y), Y = y.length, I = Dc + Y * xc + 40, R = {
        left: M + ti < l - Pn ? M - C.x : C.width - ti,
        top: Number(T.bottom) > V + I + Pn ? se + Pn : C.height - I
      };
      m === "base" && (R.left = M - ti > Pn ? G - ti - Pn : 0), S(R);
    }
  }, [y.length, t, l, n, m, o, a.opened, a.messageId, i]), J(() => {
    if (r.current == null)
      return;
    const T = r.current.parentNode;
    if (T == null || !(T instanceof HTMLDivElement))
      return;
    a.opened && a.messageId === i ? T.style.zIndex = "3" : T.style.zIndex = "auto";
    const C = document.querySelector(`#${jl}`);
    C != null && C.classList.contains("focused") && C.focus();
  }, [i, a.messageId, a.opened]), /* @__PURE__ */ f("div", {
    ref: r,
    className: xe(Ce.container, {
      [Ce.containerOwner]: n,
      [Ce.containerSelected]: a.opened && a.messageId === i,
      [Ce.containerHidden]: a.opened && a.messageId !== i
    }),
    onClick: v,
    onContextMenu: p,
    onTouchEnd: F,
    children: [e, a.opened && a.messageId === i && /* @__PURE__ */ f("div", {
      className: Ce.menuContainer,
      style: u,
      children: [/* @__PURE__ */ d("div", {
        className: Ce.emojies,
        children: /* @__PURE__ */ d(Gl, {
          onReactionSelect: N
        })
      }), /* @__PURE__ */ d("div", {
        className: Ce.menu,
        children: y.map(({
          className: T,
          id: C,
          onClick: M,
          title: V
        }) => /* @__PURE__ */ d(ql, {
          className: T,
          onClick: M,
          children: V
        }, C))
      })]
    }), a.opened && a.messageId !== i ? /* @__PURE__ */ d(it, {}) : null]
  });
});
sa.displayName = "MessageContainer";
const ql = /* @__PURE__ */ g(({
  children: e,
  className: n,
  onClick: i
}) => /* @__PURE__ */ d("div", {
  className: n,
  onClick: i,
  children: e
}));
ql.displayName = "MenuItem";
const Xl = /* @__PURE__ */ g(({
  children: e,
  message: n
}) => /* @__PURE__ */ f(_, {
  alignItems: "flex-end",
  flex: "1",
  flexDirection: "column",
  ml: "36px",
  children: [/* @__PURE__ */ d(_, {
    bg: "#232324",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "2px",
    borderTopRadius: "10px",
    maxWidth: "100%",
    position: "relative",
    px: "2",
    py: "10px",
    children: e
  }), /* @__PURE__ */ d(lt, {
    isOwner: !0,
    message: n
  })]
}));
Xl.displayName = "MyMessage";
const ra = /* @__PURE__ */ g(({
  message: e
}) => {
  const n = P(() => e.text !== "" ? cl(e.text) : e.text, [e.text]);
  return /* @__PURE__ */ f(_, {
    borderLeftColor: "blue.50",
    borderLeftStyle: "solid",
    borderLeftWidth: "2",
    mb: "3",
    pl: "10px",
    children: [/* @__PURE__ */ d(b, {
      color: "blue.50",
      ellipsizeMode: "tail",
      fontSize: "14",
      fontWeight: "500",
      lineHeight: "18",
      numberOfLines: 1,
      children: nn(e.user)
    }), /* @__PURE__ */ d(b, {
      color: "gray.400",
      ellipsizeMode: "tail",
      fontSize: "14",
      fontWeight: "400",
      lineHeight: "18",
      numberOfLines: 1,
      children: n
    })]
  });
});
ra.displayName = "RespondingMessage";
const ot = /* @__PURE__ */ g(({
  message: e,
  myUserId: n
}) => {
  var u, S;
  const i = me(), {
    data: a
  } = qn(Ti, {
    skip: e.type !== "RECEIVED",
    variables: {
      id: e.type === "RECEIVED" ? e.message.member : ""
    }
  }), [t, l] = e.type === "RECEIVED" ? [Xt(e.message.created), e.message.text] : [e.sent, e.messageText], o = P(() => {
    const m = new Date(t), p = qs(new Date(t)), k = Xs(m), F = Et(new Date(t), "HH:mm");
    return p ? `Сегодня, в ${F}` : k ? `Вчера, в ${F}` : `${Et(new Date(t), "dd.MM.yyyy")} в ${F}`;
  }, [t]), s = P(() => l !== "" ? cl(l) : l, [l]), r = P(() => {
    var m;
    if (e.type === "RECEIVED")
      return (m = e.message.embeds.find((p) => p.message != null)) == null ? void 0 : m.message;
  }, [e]), c = P(() => e.type === "RECEIVED" ? e.message.embeds.filter((m) => m.file != null).map((m) => m.file) : void 0, [e]);
  return e.type !== "RECEIVED" || n === ((u = e.message.user) == null ? void 0 : u.id) ? /* @__PURE__ */ d(sa, {
    isMe: !0,
    messageId: e.type === "RECEIVED" ? e.message.id : "",
    children: /* @__PURE__ */ f(Xl, {
      message: e,
      children: [r != null ? /* @__PURE__ */ d(ra, {
        message: r
      }) : null, c == null ? void 0 : c.map((m) => /* @__PURE__ */ d(Si, {
        fileName: (m == null ? void 0 : m.filename) ?? "",
        fileSize: ta((m == null ? void 0 : m.size) ?? 0),
        mime: m == null ? void 0 : m.mime,
        onDelete: () => {
        },
        url: m == null ? void 0 : m.uri
      }, m == null ? void 0 : m.id)), /* @__PURE__ */ f(oa, {
        children: [o, " ", /* @__PURE__ */ d(Yl, {
          fill: e.type === "RECEIVED" ? i.colors.blue[50] : i.colors.gray[500],
          style: Ic.mark
        })]
      }), /* @__PURE__ */ d(la, {
        distance: o,
        children: s
      })]
    })
  }) : /* @__PURE__ */ f(sa, {
    messageId: e.message.id,
    children: [r != null ? /* @__PURE__ */ d(ra, {
      message: r
    }) : null, c == null ? void 0 : c.map((m) => /* @__PURE__ */ d(Si, {
      fileName: (m == null ? void 0 : m.filename) ?? "",
      fileSize: ta((m == null ? void 0 : m.size) ?? 0),
      mime: m == null ? void 0 : m.mime,
      url: m == null ? void 0 : m.uri
      // onDelete={() => {
      //   eventFileAttachmentsRemoved(item.file.fil);
      // }}
    }, m == null ? void 0 : m.id)), /* @__PURE__ */ f(_, {
      alignItems: "center",
      flexDirection: "row",
      mb: "6px",
      mr: "32px",
      children: [/* @__PURE__ */ d(Fa, {
        bg: "#262626",
        h: "32px",
        source: Sa((S = e.message.user) == null ? void 0 : S.avatar.uri, 32),
        w: "32px",
        children: /* @__PURE__ */ d(b, {
          color: "text.100",
          fontSize: 16,
          fontWeight: 400,
          children: pa(a == null ? void 0 : a.meet.member.get)
        })
      }), /* @__PURE__ */ d(b, {
        color: "text.100",
        ellipsizeMode: "tail",
        flex: "1",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "24",
        ml: "2",
        numberOfLines: 1,
        children: nn(e.message.user)
      })]
    }), /* @__PURE__ */ f(Wl, {
      message: e,
      children: [/* @__PURE__ */ d(fa, {
        label: o,
        children: /* @__PURE__ */ d(oa, {
          children: o
        })
      }), /* @__PURE__ */ d(la, {
        distance: o,
        children: s
      })]
    })]
  });
});
ot.displayName = "ChatMessageItem";
const Yl = /* @__PURE__ */ g((e) => /* @__PURE__ */ d(Ys, {
  fill: "none",
  height: 11,
  width: 16,
  ...e,
  children: /* @__PURE__ */ d(Ks, {
    d: "M11.071.653a.457.457 0 0 0-.304-.102.493.493 0 0 0-.381.178l-6.19 7.636-2.405-2.272a.463.463 0 0 0-.336-.146.47.47 0 0 0-.343.146l-.311.31a.445.445 0 0 0-.14.337c0 .136.047.25.14.343l2.996 2.996a.724.724 0 0 0 .501.203.697.697 0 0 0 .546-.266l6.646-8.417a.497.497 0 0 0 .108-.299.441.441 0 0 0-.19-.374L11.07.653Zm-2.45 7.674a15.31 15.31 0 0 1-.546-.355.43.43 0 0 0-.317-.12.46.46 0 0 0-.362.158l-.292.33a.482.482 0 0 0 .013.666l1.079 1.073c.135.135.3.203.495.203a.699.699 0 0 0 .552-.267l6.62-8.391a.446.446 0 0 0 .109-.298.487.487 0 0 0-.178-.375l-.355-.273a.487.487 0 0 0-.673.076L8.62 8.327Z"
  })
}));
Yl.displayName = "Mark";
const Ic = En.create({
  mark: {
    marginLeft: 2
  }
}), Ac = /* @__PURE__ */ g(() => {
  var s;
  const e = (s = Ni()) == null ? void 0 : s.id, [n, i] = A([Ml, Al]), a = je(null);
  J(() => {
    a.current != null && a.current.scrollToEnd({
      animated: !0
    });
  }, []);
  const t = E((r) => /* @__PURE__ */ d(ot, {
    message: r.item,
    myUserId: e
  }, r.item.type === "RECEIVED" ? r.item.message.id : r.item.messageText), [e]), l = P(() => Se((r) => r, [], n), [n]), o = P(() => [e, i], [e, i]);
  return /* @__PURE__ */ d(dt, {
    children: Xn(n) ? /* @__PURE__ */ d(ga, {
      ref: a,
      inverted: !0,
      data: l,
      extraData: o,
      keyExtractor: Oc,
      mb: "2",
      onEndReached: Za,
      px: "4",
      renderItem: t
    }) : /* @__PURE__ */ d(Fi, {
      flex: 1
    })
  });
});
Ac.displayName = "ChatBody";
const Oc = (e) => e.type === "RECEIVED" ? e.message.id + e.message.updated + e.message.created : e.messageText, Vc = /* @__PURE__ */ g(() => {
  const e = A(Bm), {
    t: n
  } = le(), i = E(() => {
    e != null && et(e);
  }, [e]), a = E(() => {
    $l();
  }, []);
  return e == null ? null : /* @__PURE__ */ d(_, {
    bg: "rgba(0, 0, 0, .7)",
    bottom: "0",
    justifyContent: "center",
    left: "0",
    p: "2",
    position: "absolute",
    right: "0",
    top: "0",
    zIndex: "9",
    children: /* @__PURE__ */ f(_, {
      bg: "#141414",
      borderRadius: "12",
      maxHeight: "100%",
      p: "6",
      shadow: "1",
      children: [/* @__PURE__ */ d(b, {
        color: "white",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "20px",
        mb: "8",
        children: n("CHAT.TITLE.REMOVE_MESSAGE", {
          defaultValue: "Хотите удалить это сообщение?"
        })
      }), /* @__PURE__ */ f(Je, {
        justifyContent: "flex-end",
        space: "2",
        children: [/* @__PURE__ */ d(ne, {
          alignItems: "center",
          bg: "rgba(61, 61, 77, 0.25)",
          borderRadius: "22",
          h: "44",
          justifyContent: "center",
          onPress: a,
          px: "6",
          children: /* @__PURE__ */ d(b, {
            color: "white",
            fontSize: "14",
            fontWeight: "500",
            lineHeight: "24",
            children: n("BUTTON.CANCEL")
          })
        }), /* @__PURE__ */ d(ne, {
          alignItems: "center",
          bg: "rgba(61, 61, 77, 0.25)",
          borderRadius: "22",
          h: "44",
          justifyContent: "center",
          onPress: i,
          px: "6",
          children: /* @__PURE__ */ d(b, {
            color: "red.50",
            fontSize: "14",
            fontWeight: "500",
            lineHeight: "24",
            children: n("BUTTON.DELETE", {
              defaultValue: "Удалить"
            })
          })
        })]
      })]
    })
  });
});
Vc.displayName = "ChatMessageDelete";
const Pc = /* @__PURE__ */ g(() => {
  const e = E(() => {
    Qn();
  }, []);
  return /* @__PURE__ */ d(_, {
    alignItems: "flex-end",
    display: {
      base: "flex",
      lg: "none"
    },
    pb: "3",
    pt: "5",
    px: "4",
    children: /* @__PURE__ */ d(ne, {
      h: "32px",
      onPress: e,
      w: "32px",
      children: /* @__PURE__ */ d(id, {})
    })
  });
});
Pc.displayName = "ChatMobileHead";
const Kl = /* @__PURE__ */ g(() => {
  var s;
  const e = (s = Ni()) == null ? void 0 : s.id, {
    chatMessagesRemoteData: n,
    receivedMessagesEditedAt: i
  } = A({
    chatMessagesRemoteData: Ml,
    receivedMessagesEditedAt: Al
  }), a = je(null);
  J(() => {
    a.current != null && a.current.scrollToEnd({
      animated: !0
    });
  }, []);
  const t = E((r) => /* @__PURE__ */ d(ot, {
    message: r.item,
    myUserId: e
  }, r.item.type === "RECEIVED" ? r.item.message.id : r.item.messageText), [e]), l = P(() => Se((r) => r, [], n), [n]), o = P(() => [e, i], [e, i]);
  return /* @__PURE__ */ d(dt, {
    children: Xn(n) ? /* @__PURE__ */ d(ga, {
      ref: a,
      inverted: !0,
      data: l,
      extraData: o,
      keyExtractor: Lc,
      mb: "2",
      onEndReached: Za,
      px: "4",
      renderItem: t
    }) : /* @__PURE__ */ d(Fi, {
      flex: 1
    })
  });
});
Kl.displayName = "MessagesList";
const Lc = (e) => e.type === "RECEIVED" ? e.message.id + e.message.updated + e.message.created : e.messageText, Uc = /* @__PURE__ */ g(() => /* @__PURE__ */ f(be, {
  children: [/* @__PURE__ */ d(_, {
    bg: "#141414",
    borderBottomRadius: "12",
    flex: "1",
    children: /* @__PURE__ */ d(Kl, {})
  }), /* @__PURE__ */ d(_, {
    bg: "#141414",
    borderRadius: "12",
    mt: "2",
    children: /* @__PURE__ */ d(tt, {})
  })]
}));
Uc.displayName = "ChatPanel";
const $c = /* @__PURE__ */ g(() => {
  const e = me(), n = E(() => {
    Qn();
  }, []);
  return /* @__PURE__ */ d(_, {
    flexDirection: "row",
    h: "48px",
    justifyContent: "flex-end",
    p: "2",
    children: /* @__PURE__ */ d(gi, {
      onPress: n,
      children: /* @__PURE__ */ d(yi, {
        fill: e.colors.gray[400],
        height: "32",
        width: "32"
      })
    })
  });
});
$c.displayName = "ChatPanelHead";
const Vt = j.object({
  conditions: j.object({
    join: j.object({
      // emails: z.boolean().optional().nullable(),
      link: j.boolean().optional().nullable(),
      // password: z.boolean().optional().nullable(),
      password: j.string().min(2).max(30).optional().nullable().transform((e) => e === "" ? void 0 : e)
    }),
    start: j.object({
      always: j.boolean().optional().nullable(),
      time: j.any().optional().nullable()
    }).optional().nullable(),
    terminate: j.object({
      empty: j.boolean().optional().nullable(),
      force: j.boolean().optional().nullable(),
      time: j.any().optional().nullable()
    }).optional().nullable()
  }),
  description: j.string().min(1, "должно содержать минимум один символ").optional().or(j.literal("")).transform((e) => e === "" ? void 0 : e),
  joinContactsEmails: j.array(j.object({
    value: j.string()
  })).optional().nullable(),
  pincode: j.string().optional().nullable().transform((e) => e === "" ? void 0 : e),
  recording: j.boolean().optional().nullable(),
  title: j.string({
    required_error: D("VALIDATION.ERRORS.REQUIRED")
  }).min(1, {
    message: D("VALIDATION.ERRORS.AT_LEAST_ONE", {
      defaultValue: "Название должно содержать минимум один символ"
    })
  }),
  vanity_name: j.string().min(4, "минимум 4 символа").regex(/^[a-z\d-.]+$/, 'содержит невалидные символы, разрешены цифры, английские буквы в нижнем регистре и тире "-"').optional().or(j.literal("")).transform((e) => e === "" ? void 0 : e)
}), Jl = /* @__PURE__ */ g(({
  meetSpace: e,
  onSuccess: n,
  type: i,
  variant: a
}) => {
  var G, se, Y;
  const [t, l] = H(!1), [o, s] = H(!1), r = Qo(), c = (r == null ? void 0 : r.type) === "authenticated" && r.user.premium, [u, {
    loading: S
  }] = Cn(ud, {
    refetchQueries: [md, nr, _k]
  }), {
    control: m,
    formState: {
      errors: p,
      isValid: k
    },
    handleSubmit: F,
    setError: v
  } = _n({
    defaultValues: {
      /* @ts-expect-error TODO null vs undefined */
      title: "",
      ...e,
      ...e == null ? {
        conditions: {
          join: {
            link: !0
          }
        }
      } : void 0,
      joinContactsEmails: (Y = (se = (G = e == null ? void 0 : e.conditions) == null ? void 0 : G.join.contacts) == null ? void 0 : se.email) == null ? void 0 : Y.map((I) => ({
        value: I
      }))
    },
    mode: "all",
    resolver: Kn(Vt)
  }), N = E(async (I) => {
    const {
      joinContactsEmails: R,
      ...q
    } = Vt.parse(I);
    try {
      await u({
        variables: {
          id: e == null ? void 0 : e.id,
          ...q,
          conditions: {
            ...q.conditions,
            join: {
              ...q.conditions.join,
              contacts: {
                email: R == null ? void 0 : R.map((L) => L.value)
              }
            }
            // ...(recording ? { terminate: { empty: true } } : {}),
          }
          // recording,
        }
      }), n();
    } catch (L) {
      L instanceof ua && v("root", {
        message: L.message === "rpc error: code = Unknown desc = permission denied" ? "Доступ запрещен" : L.message,
        type: "serverError"
      });
    }
  }, [u, e == null ? void 0 : e.id, n, v]), {
    append: y,
    fields: T,
    remove: C
  } = sd({
    control: m,
    name: "joinContactsEmails"
  }), M = E(() => {
    T.length === 0 && y({
      value: ""
    }), s((I) => !I);
  }, [y, T.length]), V = i === "create" ? a === "room" ? D("ROOM.EDIT_ROOM.CREATE_ROOM") : D("ROOM.EDIT_ROOM.CREATE_EVENT") : a === "room" ? D("ROOM.EDIT_ROOM.EDIT_ROOM") : D("ROOM.EDIT_ROOM.EDIT_EVENT");
  return /* @__PURE__ */ d(_, {
    alignItems: "stretch",
    flex: {
      lg: "1"
    },
    justifyContent: "space-between",
    children: /* @__PURE__ */ f(Ql, {
      children: [/* @__PURE__ */ f(_, {
        width: "100%",
        children: [/* @__PURE__ */ f(Me, {
          isRequired: !0,
          mb: {
            base: 6,
            lg: 10
          },
          children: [/* @__PURE__ */ d(dn, {
            mb: "2",
            children: V
          }), /* @__PURE__ */ d(de, {
            control: m,
            name: "title",
            render: ({
              field: I,
              fieldState: {
                error: R
              }
            }) => /* @__PURE__ */ d(ln, {
              errorMessage: R == null ? void 0 : R.message,
              isInvalid: !!R,
              mb: 0,
              onBlur: I.onBlur,
              onChangeText: I.onChange,
              placeholder: D("ROOM.EDIT.TITLE_PLACEHOLDER"),
              value: I.value,
              withAnimation: !1
            })
          })]
        }), /* @__PURE__ */ f(Me, {
          mb: {
            base: 6,
            lg: 10
          },
          children: [/* @__PURE__ */ d(dn, {
            mb: "2",
            children: D("ROOM.EDIT.VANITY_NAME")
          }), /* @__PURE__ */ d(de, {
            control: m,
            name: "vanity_name",
            render: ({
              field: I,
              fieldState: {
                error: R
              }
            }) => /* @__PURE__ */ d(ln, {
              errorMessage: R == null ? void 0 : R.message,
              isInvalid: !!R,
              mb: 0,
              onBlur: I.onBlur,
              onChangeText: I.onChange,
              placeholder: D("ROOM.EDIT.VANITY_NAME_PLACEHOLDER"),
              value: I.value,
              withAnimation: !1
            })
          })]
        }), /* @__PURE__ */ f(Me, {
          mb: {
            base: 6,
            lg: 10
          },
          children: [/* @__PURE__ */ d(dn, {
            mb: "2",
            children: D("ROOM.EDIT.DESCRIPTION")
          }), /* @__PURE__ */ d(de, {
            control: m,
            name: "description",
            render: ({
              field: I,
              fieldState: {
                error: R
              }
            }) => /* @__PURE__ */ d(ln, {
              errorMessage: R == null ? void 0 : R.message,
              isInvalid: !!R,
              mb: 0,
              onBlur: I.onBlur,
              onChangeText: I.onChange,
              placeholder: D("ROOM.EDIT.DESCRIPTION_PLACEHOLDER"),
              value: I.value,
              withAnimation: !1
            })
          })]
        }), a === "event" ? /* @__PURE__ */ f(be, {
          children: [/* @__PURE__ */ d(bn, {
            color: "black",
            fontSize: "16",
            fontWeight: "600",
            lineHeight: "24",
            mb: {
              base: 4,
              lg: 6
            },
            mt: "0",
            children: D("ROOM.CREATE.DATE_AND_TIME_ACTIVITY", {
              defaultValue: "Время активности встречи"
            })
          }), /* @__PURE__ */ f(_, {
            mb: "3",
            children: [/* @__PURE__ */ d(dn, {
              display: {
                base: "none",
                lg: "flex"
              },
              mb: "2",
              children: D("ROOM.CREATE.DATE_AND_TIME", {
                defaultValue: "Дата и время"
              })
            }), /* @__PURE__ */ d(dn, {
              display: {
                base: "flex",
                lg: "none"
              },
              mb: "2",
              children: D("ROOM.CREATE.DATE_AND_TIME_START", {
                defaultValue: "Дата и время начала встречи"
              })
            }), /* @__PURE__ */ f(_, {
              alignItems: "flex-start",
              flex: "1",
              flexDirection: {
                base: "column",
                lg: "row"
              },
              children: [/* @__PURE__ */ d(de, {
                control: m,
                name: "conditions.start.time",
                render: ({
                  field: {
                    onBlur: I,
                    onChange: R,
                    value: q
                  }
                }) => /* @__PURE__ */ d(Ct, {
                  mode: "datetime",
                  onBlur: I,
                  onChange: R,
                  value: q
                })
              }), /* @__PURE__ */ d(b, {
                color: "black",
                display: {
                  base: "none",
                  lg: "flex"
                },
                fontSize: "16",
                fontWeight: "500",
                h: "12",
                justifyContent: "center",
                lineHeight: "48",
                textAlign: "center",
                w: "10",
                children: "–"
              }), /* @__PURE__ */ d(dn, {
                display: {
                  base: "flex",
                  lg: "none"
                },
                mb: "2",
                mt: "2",
                children: D("ROOM.CREATE.DATE_AND_TIME_STOP", {
                  defaultValue: "Дата и время окончания встречи"
                })
              }), /* @__PURE__ */ d(de, {
                control: m,
                name: "conditions.terminate.time",
                render: ({
                  field: {
                    onBlur: I,
                    onChange: R,
                    value: q
                  }
                }) => /* @__PURE__ */ d(Ct, {
                  mode: "datetime",
                  onBlur: I,
                  onChange: R,
                  value: q
                })
              })]
            })]
          }), /* @__PURE__ */ d(Me, {
            children: /* @__PURE__ */ d(de, {
              control: m,
              name: "conditions.start.always",
              render: ({
                field: {
                  onChange: I,
                  value: R
                }
              }) => /* @__PURE__ */ f(ne, {
                flexDir: "row",
                onPress: () => I(!R),
                children: [/* @__PURE__ */ d(Ge, {
                  checked: !!R,
                  view: {
                    mr: 2
                  }
                }), /* @__PURE__ */ d(b, {
                  children: D("ROOM.CREATE.AUTOSTART", {
                    defaultValue: "Стартует автоматически"
                  })
                })]
              })
            })
          }), /* @__PURE__ */ d(Me, {
            children: /* @__PURE__ */ d(de, {
              control: m,
              name: "conditions.terminate.empty",
              render: ({
                field: {
                  onChange: I,
                  value: R
                }
              }) => /* @__PURE__ */ f(ne, {
                flexDir: "row",
                onPress: () => I(!R),
                children: [/* @__PURE__ */ d(Ge, {
                  checked: !!R,
                  view: {
                    mr: 2
                  }
                }), /* @__PURE__ */ d(b, {
                  children: D("ROOM.CREATE.AUTOCLOSE", {
                    defaultValue: "Завершается при выходе последнего участника"
                  })
                })]
              })
            })
          }), /* @__PURE__ */ d(Me, {
            children: /* @__PURE__ */ d(de, {
              control: m,
              name: "conditions.terminate.force",
              render: ({
                field: {
                  onChange: I,
                  value: R
                }
              }) => /* @__PURE__ */ f(ne, {
                flexDir: "row",
                onPress: () => I(!R),
                children: [/* @__PURE__ */ d(Ge, {
                  checked: !!R,
                  view: {
                    mr: 2
                  }
                }), /* @__PURE__ */ d(b, {
                  children: D("ROOM.CREATE.CLOSE_BY_HAND", {
                    defaultValue: "Можно завершить вручную"
                  })
                })]
              })
            })
          })]
        }) : null, c ? /* @__PURE__ */ d(Me, {
          children: /* @__PURE__ */ d(de, {
            control: m,
            name: "recording",
            render: ({
              field: {
                onChange: I,
                value: R
              }
            }) => /* @__PURE__ */ f(_, {
              children: [/* @__PURE__ */ f(ne, {
                flexDir: "row",
                onPress: () => I(!R),
                children: [/* @__PURE__ */ d(Ge, {
                  checked: !!R,
                  view: {
                    mr: 2
                  }
                }), /* @__PURE__ */ d(b, {
                  children: D("ROOM.CREATE.ENABLE_RECORDING", {
                    defaultValue: "Разрешена запись аудио"
                  })
                })]
              }), R ? /* @__PURE__ */ f(_, {
                flexDirection: "row",
                mt: 1,
                children: [/* @__PURE__ */ d(Ge, {
                  checked: !0,
                  disabled: !0,
                  view: {
                    mr: 2
                  }
                }), /* @__PURE__ */ d(b, {
                  children: D("ROOM.CREATE.AUTOCLOSE", {
                    defaultValue: "Завершается при выходе последнего участника"
                  })
                })]
              }) : null]
            })
          })
        }) : null, /* @__PURE__ */ d(bn, {
          color: "black",
          fontSize: "16",
          fontWeight: "600",
          lineHeight: "24",
          mb: {
            base: 4,
            lg: 6
          },
          mt: 4,
          children: D("ROOM.CREATE.ACCESS", {
            defaultValue: "Доступ"
          })
        }), /* @__PURE__ */ d(Me, {
          children: /* @__PURE__ */ d(de, {
            control: m,
            name: "conditions.join.link",
            render: ({
              field: {
                onChange: I,
                value: R
              }
            }) => /* @__PURE__ */ f(ne, {
              flexDir: "row",
              onPress: () => I(!R),
              children: [/* @__PURE__ */ d(Ge, {
                checked: !!R,
                view: {
                  mr: 2
                }
              }), /* @__PURE__ */ d(b, {
                children: D("ROOM.CREATE.BY_LINK", {
                  defaultValue: "По ссылке"
                })
              })]
            })
          })
        }), /* @__PURE__ */ f(Me, {
          flexDir: "column",
          isInvalid: !!p.title,
          children: [/* @__PURE__ */ f(ne, {
            flexDir: "row",
            onPress: () => l((I) => !I),
            children: [/* @__PURE__ */ d(Ge, {
              checked: t,
              view: {
                mr: 2
              }
            }), /* @__PURE__ */ d(b, {
              children: D("ROOM.CREATE.BY_PASSWORD", {
                defaultValue: "По паролю"
              })
            })]
          }), t ? /* @__PURE__ */ d(de, {
            control: m,
            name: "conditions.join.password",
            render: ({
              field: {
                onBlur: I,
                onChange: R,
                value: q
              },
              fieldState: {
                error: L,
                isDirty: ke
              }
            }) => /* @__PURE__ */ d(ln, {
              errorMessage: L == null ? void 0 : L.message,
              isInvalid: !!L && ke,
              mb: "4",
              mt: "3",
              onBlur: I,
              onChangeText: R,
              type: "password",
              value: q ?? void 0,
              withAnimation: !1
            })
          }) : null]
        }), /* @__PURE__ */ f(Me, {
          flexDirection: "column",
          children: [/* @__PURE__ */ f(ne, {
            flexDir: "row",
            onPress: () => M(),
            children: [/* @__PURE__ */ d(Ge, {
              checked: o,
              view: {
                mr: 2
              }
            }), /* @__PURE__ */ d(b, {
              children: D("ROOM.CREATE.BY_EMAILS", {
                defaultValue: "Список по e-mail"
              })
            })]
          }), o ? /* @__PURE__ */ f(be, {
            children: [/* @__PURE__ */ d(Bn, {
              mb: "4",
              mt: "3",
              space: 3,
              children: T.map((I, R) => /* @__PURE__ */ f(Je, {
                alignItems: "center",
                space: 1,
                children: [/* @__PURE__ */ d(_, {
                  flex: "1",
                  children: /* @__PURE__ */ d(de, {
                    control: m,
                    name: `joinContactsEmails[${R}].value`,
                    render: ({
                      field: {
                        name: q,
                        onBlur: L,
                        onChange: ke,
                        value: tn
                      }
                    }) => /* @__PURE__ */ d(ln, {
                      mb: "0",
                      nativeID: q,
                      onBlur: L,
                      onChange: ke,
                      value: tn,
                      w: "100%"
                    })
                  })
                }), /* @__PURE__ */ d(ne, {
                  alignItems: "center",
                  h: "36px",
                  justifyContent: "center",
                  onPress: () => C(R),
                  w: "36px",
                  children: /* @__PURE__ */ d(Kt, {})
                })]
              }, I.id))
            }), /* @__PURE__ */ d(_, {
              flexDir: "row-reverse",
              pr: "10",
              children: /* @__PURE__ */ d(He, {
                h: "36",
                onPress: () => y({
                  value: ""
                }),
                pl: "2",
                pr: "4",
                variant: "primary",
                children: /* @__PURE__ */ f(_, {
                  alignItems: "center",
                  flexDirection: "row",
                  children: [/* @__PURE__ */ d(bs, {
                    fill: Be.colors.white,
                    height: "24",
                    width: "24"
                  }), /* @__PURE__ */ d(b, {
                    color: "white",
                    fontSize: "14",
                    fontWeight: "500",
                    ml: "2",
                    children: D("BUTTON.ADD_EMAIL", {
                      defaultValue: "Добавить email"
                    })
                  })]
                })
              })
            })]
          }) : null]
        })]
      }), p.root != null ? /* @__PURE__ */ d(cs, {
        message: p.root.message
      }) : null, /* @__PURE__ */ d(_, {
        alignSelf: "flex-start",
        mt: 6,
        w: {
          base: "100%",
          lg: "auto"
        },
        children: /* @__PURE__ */ d(He, {
          color: "white",
          disabled: !k,
          isLoadingText: "Сохранение",
          loading: S,
          onPress: F(N),
          variant: "primary",
          w: {
            base: "100%"
          },
          children: /* @__PURE__ */ d(b, {
            color: "white",
            fontSize: "14",
            fontWeight: "500",
            children: D(i === "create" ? "BUTTON.CREATE" : "BUTTON.SAVE", {
              defaultValue: i === "create" ? "Создать" : "Сохранить"
            })
          })
        })
      })]
    })
  });
});
Jl.displayName = "EditMeetSpaceForm";
const Me = /* @__PURE__ */ g((e) => /* @__PURE__ */ d(on, {
  mb: "3",
  ...e
}));
Me.displayName = "MyFormControl";
const dn = /* @__PURE__ */ g(({
  isSwitch: e,
  ...n
}) => /* @__PURE__ */ d(on.Label, {
  alignItems: "center",
  flexDirection: e ? {
    lg: "row-reverse"
  } : void 0,
  mr: {
    lg: 2
  },
  ...n
}));
dn.displayName = "MyFormControlLabel";
const Ql = /* @__PURE__ */ g((e) => {
  const {
    height: n
  } = wn();
  return /* @__PURE__ */ d(ha, {
    maxHeight: {
      base: n - 94,
      lg: Math.min(n - 100, 716)
    },
    mb: "4",
    mr: {
      base: -2,
      lg: 0
    },
    pr: {
      base: 2,
      lg: 0
    },
    py: {
      base: 2,
      lg: 5
    },
    children: e.children
  });
});
Ql.displayName = "FormContent";
const Zl = /* @__PURE__ */ g(({
  children: e,
  onClose: n,
  title: i
}) => /* @__PURE__ */ d(od, {
  transparent: !0,
  visible: !0,
  onRequestClose: n,
  children: /* @__PURE__ */ f(_, {
    bg: "white",
    borderBottomLeftRadius: {
      base: 0,
      lg: 24
    },
    borderTopLeftRadius: {
      base: 0,
      lg: 24
    },
    flex: 1,
    marginLeft: {
      base: 0,
      lg: "auto"
    },
    p: 2,
    width: {
      base: "100%",
      lg: "50%"
    },
    children: [/* @__PURE__ */ f(Je, {
      alignItems: "center",
      justifyContent: "space-between",
      children: [/* @__PURE__ */ d(b, {
        color: "black",
        fontSize: "24",
        fontWeight: "500",
        lineHeight: "24",
        children: i
      }), /* @__PURE__ */ d(ba, {
        _focus: {
          bg: "transparent"
        },
        _hover: {
          bg: "transparent"
        },
        _pressed: {
          bg: "transparent"
        },
        bg: "transparent",
        height: "6",
        mr: 3,
        onPress: n,
        variant: "unstyled",
        width: "6",
        children: /* @__PURE__ */ d(ys, {})
      })]
    }), e]
  })
}));
Zl.displayName = "LayoutModal";
const Bc = /* @__PURE__ */ g(({
  onClose: e,
  params: n
}) => {
  const {
    t: i
  } = le(), {
    data: a,
    error: t,
    loading: l
  } = qn(cd, {
    skip: n.type === "create",
    variables: {
      id: n.type === "edit" ? n.roomId : (
        /* impossible case — will be skipped */
        ""
      )
    }
  }), o = a == null ? void 0 : a.user.meet.get.space, s = n.type === "create" ? n.variant : Sr(o), r = n.type === "create" ? i(s === "room" ? "ROOM.EDIT_ROOM.CREATE_ROOM" : "ROOM.EDIT_ROOM.CREATE_EVENT") : i(s === "room" ? "ROOM.EDIT_ROOM.EDIT_ROOM" : "ROOM.EDIT_ROOM.EDIT_EVENT");
  return /* @__PURE__ */ f(Zl, {
    onClose: e,
    title: r,
    children: [t != null ? /* @__PURE__ */ d(hi, {
      error: t
    }) : null, l ? /* @__PURE__ */ d(zn, {
      size: "lg"
    }) : /* @__PURE__ */ d(Jl, {
      meetSpace: o,
      onSuccess: e,
      type: n.type,
      variant: s
    })]
  });
});
Bc.displayName = "EditMeetSpace";
const mS = (e) => e != null && e.createEvent ? {
  roomId: void 0,
  type: "create",
  variant: "event"
} : e != null && e.createRoom ? {
  roomId: void 0,
  type: "create",
  variant: "room"
} : e != null && e.edit ? {
  roomId: e.edit,
  type: "edit"
} : null;
var Bi = "/src/features/meet/focused-peer/model/index.ts";
const st = h({
  loc: {
    file: Bi,
    line: 12,
    column: 32
  },
  name: "eventPeerFocused",
  sid: "y5tz8h:/src/features/meet/focused-peer/model/index.ts:eventPeerFocused"
}), eo = x({
  alreadyExecuted: !1,
  memberId: null
}, {
  loc: {
    file: Bi,
    line: 14,
    column: 25
  },
  name: "$focusedMemberId",
  sid: "-6q48vs:/src/features/meet/focused-peer/model/index.ts:$focusedMemberId"
});
eo.on(st, (e, n) => n == null ? {
  alreadyExecuted: !0,
  memberId: null
} : e.memberId === n ? {
  alreadyExecuted: !0,
  memberId: null
} : {
  alreadyExecuted: !0,
  memberId: n
}).on(w({
  and: [{
    clock: fe,
    source: {
      currentMeetSpaceMemberMe: an
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => ({
      ...e,
      peers: n
    })
  }],
  or: {
    loc: {
      file: Bi,
      line: 32,
      column: 4
    },
    sid: "hpgo9r:/src/features/meet/focused-peer/model/index.ts:"
  }
}), (e, n) => {
  var a, t;
  const i = n.peers.find((l) => l.id === e.memberId);
  if (e.alreadyExecuted || e.memberId != null && i == null)
    return e;
  if (e.memberId == null) {
    const l = n.peers.filter((o) => o.media.video === _e.MediaVideoScreen);
    if (l.length === 1 && ((t = (a = n.currentMeetSpaceMemberMe) == null ? void 0 : a.peer) == null ? void 0 : t.id) !== l[0].id)
      return {
        alreadyExecuted: !0,
        memberId: l[0].id
      };
  }
  return e;
});
const cS = we({
  and: [{
    focusedMemberId: eo,
    members: Oa,
    peers: fe
  }, ({
    focusedMemberId: e,
    members: n,
    peers: i
  }) => {
    const a = i.find((t) => t.id === e.memberId);
    return e.memberId != null && a != null ? {
      member: n.find((l) => l.id === e.memberId),
      peer: a
    } : null;
  }],
  or: {
    loc: {
      file: Bi,
      line: 61,
      column: 28
    },
    name: "$focusedPeer",
    sid: "lqgaxm:/src/features/meet/focused-peer/model/index.ts:$focusedPeer"
  }
}), rt = /* @__PURE__ */ g(({
  meetSpace: e
}) => /* @__PURE__ */ d(Zt, {
  mobileView: "full",
  textForCopy: Gt(e),
  view: {
    bg: "blue.50",
    color: "white"
  },
  children: /* @__PURE__ */ d(b, {
    color: "white",
    fontSize: "14",
    fontWeight: "500",
    lineHeight: "24",
    children: Wo(e)
  })
}));
rt.displayName = "InviteToCallButton";
const zc = /* @__PURE__ */ g(({
  height: e,
  meetSpace: n,
  width: i
}) => {
  var t;
  const {
    t: a
  } = le();
  return /* @__PURE__ */ d(_, {
    display: {
      base: "none",
      lg: "flex"
    },
    h: e,
    p: {
      base: 1,
      lg: 2
    },
    w: i,
    children: /* @__PURE__ */ d(Fi, {
      bg: "#141414",
      borderRadius: "12",
      flex: "1",
      children: (t = n.conditions) != null && t.join.link ? /* @__PURE__ */ f(be, {
        children: [/* @__PURE__ */ d(b, {
          color: "white",
          fontSize: "24",
          fontWeight: "500",
          lineHeight: "24",
          mb: "5",
          textAlign: "center",
          children: a("CALL.INVITE_MESSAGE", {
            defaultValue: "Пригласите других участников"
          })
        }), /* @__PURE__ */ d(rt, {
          meetSpace: n
        })]
      }) : /* @__PURE__ */ d(b, {
        color: "gray.500",
        fontSize: "24",
        fontWeight: "500",
        lineHeight: "24",
        mb: "5",
        textAlign: "center",
        children: a("CALL.INVITE_MESSAGE_WAITING")
      })
    })
  });
});
zc.displayName = "InviteToCallCard";
const zi = /* @__PURE__ */ g(({
  mobile: e
}) => /* @__PURE__ */ d(_, {
  left: 1,
  position: "absolute",
  top: 1,
  zIndex: 1,
  children: /* @__PURE__ */ d(Ca, {
    fill: Be.colors.yellow[500],
    height: "24",
    width: "24"
  })
}));
zi.displayName = "RaiseHandPopup";
const jc = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetUpdateReaction"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "emotion"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "gesture"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "rtc"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "reaction"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "emotion"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "emotion"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "gesture"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "gesture"
                    }
                  }
                }]
              }]
            }
          }]
        }
      }]
    }
  }]
}, Hc = "_emojiContainer_jg6pi_1", Wc = {
  emojiContainer: Hc
}, Gc = /* @__PURE__ */ g(() => {
  const [e] = Cn(jc), n = rd.emojis, i = E((a) => {
    e({
      variables: {
        emotion: a
      }
    });
  }, [e]);
  return /* @__PURE__ */ d(ha, {
    horizontal: !0,
    maxWidth: "900",
    w: "90%",
    children: /* @__PURE__ */ d("div", {
      className: Wc.emojiContainer,
      children: /* @__PURE__ */ d(Je, {
        space: "2",
        children: Qt.emojis.map((a) => {
          const t = n[a];
          return t == null || t.version !== 1 ? null : /* @__PURE__ */ d(no, {
            emoji: t.skins[0].native,
            onPress: () => i(t.skins[0].native)
          }, t.id);
        })
      })
    })
  });
});
Gc.displayName = "MeetSpaceReaction";
const no = /* @__PURE__ */ g(({
  emoji: e,
  ...n
}) => {
  const [i, a] = H(!1), t = P(() => i ? "rgba(255, 255, 255, .12)" : "rgba(255, 255, 255, 0.08)", [i]);
  return /* @__PURE__ */ d(ne, {
    _focus: {
      bg: t
    },
    _hover: {
      bg: t
    },
    _pressed: {
      bg: t
    },
    alignItems: "center",
    bg: t,
    borderRadius: "32",
    h: "48px",
    justifyContent: "center",
    onHoverIn: () => a(!0),
    onHoverOut: () => a(!1),
    shadow: "1",
    w: "48px",
    ...n,
    children: /* @__PURE__ */ d(b, {
      fontSize: "xl",
      selectable: !1,
      children: e
    })
  });
});
no.displayName = "Emojya";
const qc = "_container_8i69q_1", Xc = "_fly_8i69q_1", Yc = "_reaction_8i69q_12", Kc = "_author_8i69q_18", Qi = {
  container: qc,
  fly: Xc,
  reaction: Yc,
  author: Kc
}, Jc = /* @__PURE__ */ g(() => {
  const e = A(yr), n = P(() => e.filter((i) => Date.now() - i.created.getTime() < Od), [e]);
  return /* @__PURE__ */ d(Hs, {
    children: n.map((i) => /* @__PURE__ */ d(io, {
      authorId: i.author,
      left: i.left,
      reaction: i.reaction
    }, i.id.toString()))
  });
});
Jc.displayName = "FlyingReactions";
const io = /* @__PURE__ */ g(({
  authorId: e,
  left: n,
  reaction: i
}) => {
  const {
    data: a,
    loading: t
  } = qn(Ti, {
    variables: {
      id: e
    }
  });
  return /* @__PURE__ */ f("div", {
    className: Qi.container,
    style: {
      left: n
    },
    children: [/* @__PURE__ */ d("div", {
      className: Qi.reaction,
      children: i
    }), e && !t ? /* @__PURE__ */ d("div", {
      className: Qi.author,
      children: nn(a == null ? void 0 : a.meet.member.get)
    }) : null]
  });
});
io.displayName = "R";
const Qc = /* @__PURE__ */ g(() => {
  var o;
  const e = A(an), [n, {
    error: i,
    loading: a
  }] = Cn(er), t = E(() => n({
    variables: {
      gesture: yn
    }
  }), [n]), l = E(() => n({
    variables: {
      gesture: ""
    }
  }), [n]);
  return ((o = e == null ? void 0 : e.peer) == null ? void 0 : o.reaction.gesture) === yn ? /* @__PURE__ */ d(rn, {
    onPress: l,
    children: D("ROOM.GESTURES.LOWER_HAND", {
      defaultValue: "Опустить руку"
    })
  }) : /* @__PURE__ */ d(rn, {
    onPress: t,
    children: D("ROOM.GESTURES.RAISE_HAND", {
      defaultValue: "Поднять руку"
    })
  });
});
Qc.displayName = "MenuItemRaiseHand";
const uS = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "RecordingTranscript"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RecordingTranscriptType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "collected"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transcript"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "duration"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "member_id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "text"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "time"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "nickname"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "user"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "id"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "nickname"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "end"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "exported"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recognized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "start"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "submitted"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "summarized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "summary"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "text"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "orders"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "orders"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transcribed"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "debug"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "summary_request"
            }
          }]
        }
      }]
    }
  }]
}, kS = {
  kind: "Document",
  definitions: [{
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "Recording"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RecordingType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "collected"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "end"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "exported"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recognized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "start"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "submitted"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "summarized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transcribed"
        }
      }]
    }
  }]
}, vS = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetRecordingsList"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "recording"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "list"
                    },
                    arguments: [{
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "id"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "id"
                        }
                      }
                    }],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "Recording"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "Recording"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RecordingType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "collected"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "end"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "exported"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recognized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "start"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "submitted"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "summarized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transcribed"
        }
      }]
    }
  }]
}, SS = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetRecording"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "id"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "recordingId"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "ID"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "recording"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "get"
                    },
                    arguments: [{
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "id"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "id"
                        }
                      }
                    }, {
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "recording_id"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "recordingId"
                        }
                      }
                    }],
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "RecordingTranscript"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "RecordingTranscript"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "RecordingTranscriptType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "collected"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transcript"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "duration"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "member_id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "text"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "time"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "nickname"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "user"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "id"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "nickname"
                }
              }]
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "end"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "exported"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recognized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "size"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "start"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "submitted"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "summarized"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "summary"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "text"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "orders"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "name"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "orders"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transcribed"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "debug"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "summary_request"
            }
          }]
        }
      }]
    }
  }]
}, Zc = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetRecordingStart"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "recording"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "start"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, eu = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetRecordingStop"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "recording"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "stop"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
var ii = "/src/features/meet/recording/model/index.ts";
const mt = h({
  loc: {
    file: ii,
    line: 16,
    column: 36
  },
  name: "eventToggleRecording",
  sid: "-mwbf1o:/src/features/meet/recording/model/index.ts:eventToggleRecording"
}), ct = _i.map((e) => (e == null ? void 0 : e.recording_session_since) != null), ao = $(() => Q().mutate({
  mutation: Zc
}), {
  loc: {
    file: ii,
    line: 22,
    column: 25
  },
  name: "startRecordingFx",
  sid: "17fsgr:/src/features/meet/recording/model/index.ts:startRecordingFx"
}), to = $(() => Q().mutate({
  mutation: eu
}), {
  loc: {
    file: ii,
    line: 25,
    column: 24
  },
  name: "stopRecordingFx",
  sid: "-93imkn:/src/features/meet/recording/model/index.ts:stopRecordingFx"
});
Ea.on([ao.failData, to.failData], (e, n) => n instanceof ua ? [...e, ...n.graphQLErrors.map((i) => i.extensions.code === "not_authorized" && i.extensions.details === "permission denied" ? {
  id: mi("alert"),
  message: "Нет прав для включения записи",
  status: "error"
} : void 0).filter(ze)] : [...e, {
  id: mi("alert"),
  message: String(n),
  status: "error"
}]);
w({
  and: [{
    clock: mt,
    source: ct,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => e,
    target: to
  }],
  or: {
    loc: {
      file: ii,
      line: 60,
      column: 0
    },
    sid: "-buf1h7:/src/features/meet/recording/model/index.ts:"
  }
});
w({
  and: [{
    clock: mt,
    source: ct,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    filter: (e) => !e,
    target: ao
  }],
  or: {
    loc: {
      file: ii,
      line: 67,
      column: 0
    },
    sid: "-bqkhbo:/src/features/meet/recording/model/index.ts:"
  }
});
const Pt = z.object({
  description: z.string().nullable().optional(),
  title: z.string({
    required_error: D("VALIDATION.ERRORS.REQUIRED")
  }).min(1, {
    message: D("VALIDATION.ERRORS.AT_LEAST_ONE", {
      defaultValue: "Название должно содержать минимум один символ"
    })
  })
}).strict(), lo = /* @__PURE__ */ g(({
  meetSpace: e
}) => {
  const {
    control: n,
    formState: {
      isDirty: i
    },
    handleSubmit: a,
    reset: t
  } = _n({
    defaultValues: {
      description: e.description,
      title: e.title ?? ""
    },
    mode: "all",
    resolver: Kn(Pt)
  }), [l, {
    error: o,
    loading: s
  }] = Cn(ud, {
    refetchQueries: [md]
  }), r = E(async (c) => {
    const u = Pt.parse(c);
    try {
      await l({
        variables: {
          id: e.id,
          ...u
        }
      }), t();
    } catch (S) {
      console.error(S);
    }
  }, [t, e.id, l]);
  return /* @__PURE__ */ f(be, {
    children: [/* @__PURE__ */ d(b, {
      color: "white",
      children: "Здесь можно изменитть параметры встречи. Доступ к этим настройкам имеют только организаторы"
    }), /* @__PURE__ */ f(on, {
      isRequired: !0,
      mb: {
        base: 3,
        lg: 5
      },
      children: [/* @__PURE__ */ d(on.Label, {
        mb: "2",
        mt: 5,
        children: D("ROOM.EDIT.TITLE")
      }), /* @__PURE__ */ d(de, {
        control: n,
        name: "title",
        render: ({
          field: c,
          fieldState: {
            error: u
          }
        }) => /* @__PURE__ */ d(ln, {
          errorMessage: u == null ? void 0 : u.message,
          isInvalid: !!u,
          mb: 0,
          onBlur: c.onBlur,
          onChangeText: c.onChange,
          placeholder: D("ROOM.EDIT.TITLE_PLACEHOLDER"),
          value: c.value,
          withAnimation: !1
        })
      })]
    }), /* @__PURE__ */ f(on, {
      mb: {
        base: 3,
        lg: 5
      },
      children: [/* @__PURE__ */ d(on.Label, {
        mb: "2",
        children: D("ROOM.EDIT.DESCRIPTION")
      }), /* @__PURE__ */ d(de, {
        control: n,
        name: "description",
        render: ({
          field: c,
          fieldState: {
            error: u
          }
        }) => /* @__PURE__ */ d(ln, {
          errorMessage: u == null ? void 0 : u.message,
          isInvalid: !!u,
          mb: 0,
          onBlur: c.onBlur,
          onChangeText: c.onChange,
          placeholder: D("ROOM.EDIT.DESCRIPTION_PLACEHOLDER"),
          value: c.value ?? "",
          withAnimation: !1
        })
      })]
    }), /* @__PURE__ */ d(Je, {
      children: /* @__PURE__ */ d(He, {
        disabled: !i,
        height: 7,
        onPress: a(r),
        variant: "primary",
        children: "Применить"
      })
    })]
  });
});
lo.displayName = "EditShortMeetSpaceSettings";
const nu = /* @__PURE__ */ g(({
  images: e,
  isOwner: n,
  meetSpace: i,
  onChange: a,
  value: t
}) => /* @__PURE__ */ f(Is, {
  style: Lt.scroll,
  children: [n ? /* @__PURE__ */ d(lo, {
    meetSpace: i
  }) : null, /* @__PURE__ */ d(bn, {
    borderTopColor: "white",
    borderTopWidth: 1,
    mt: 5,
    pt: 5,
    size: "md",
    children: "Видео"
  }), /* @__PURE__ */ d(ho, {
    small: !0,
    images: e,
    onChange: a,
    value: t
  }), /* @__PURE__ */ d(td, {
    style: Lt.inviteToCallButton,
    children: /* @__PURE__ */ d(rt, {
      meetSpace: i
    })
  })]
}));
nu.displayName = "MeetSpaceRuntimeSettings";
const Lt = En.create({
  inviteToCallButton: {
    marginTop: "auto"
  },
  scroll: {
    flex: 1,
    padding: Be.sizes[3]
  }
}), iu = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetFetchMemberList"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "limit"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "Int"
          }
        }
      }
    }, {
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "cursor"
        }
      },
      type: {
        kind: "NamedType",
        name: {
          kind: "Name",
          value: "String"
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "member"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "list"
                },
                arguments: [{
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "cursor"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "cursor"
                    }
                  }
                }, {
                  kind: "Argument",
                  name: {
                    kind: "Name",
                    value: "limit"
                  },
                  value: {
                    kind: "Variable",
                    name: {
                      kind: "Name",
                      value: "limit"
                    }
                  }
                }],
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "cursor"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "data"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetMember"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetMember"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetMemberType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_deny"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions_grant"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "presence_state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }]
    }
  }]
};
var au = "/src/features/meet/user-list/model/index.ts";
const {
  $cursor: pS,
  $data: oo,
  eventLoadMore: Ut
} = Go(iu, (e) => e.data.meet.member.list);
oo.reset(ue, Ne);
const tu = fe.map((e) => new Set(e.map((n) => n.id))), du = we({
  and: [{
    $data: oo,
    $peerIds: tu
  }],
  or: {
    loc: {
      file: au,
      line: 27,
      column: 39
    },
    name: "$meetSpaceUsersListData",
    sid: "6s6adq:/src/features/meet/user-list/model/index.ts:$meetSpaceUsersListData"
  }
}).map(({
  $data: e,
  $peerIds: n
}) => e.filter((i) => !n.has(i.id))), lu = z.object({
  hadIssues: z.boolean(),
  issueDescription: z.string().nullable()
}).strict(), ou = /* @__PURE__ */ g(({
  onClose: e
}) => {
  const n = Ni(), i = A(Na), {
    control: a,
    handleSubmit: t
  } = _n({
    defaultValues: {
      hadIssues: !1,
      issueDescription: ""
    }
  });
  return /* @__PURE__ */ d(od, {
    onRequestClose: e,
    children: /* @__PURE__ */ f(ya, {
      hasCancel: !0,
      okButtonText: "ОК",
      onClose: e,
      onOk: t((o) => {
        const s = lu.parse(o), r = Mt.captureMessage("User Feedback");
        Mt.captureUserFeedback({
          comments: s.issueDescription ?? "",
          email: n == null ? void 0 : n.email,
          event_id: r,
          name: `userId:${n == null ? void 0 : n.id}, username:${n == null ? void 0 : n.username}, nickname:${((n == null ? void 0 : n.nickname) ?? "") !== "" ? n == null ? void 0 : n.nickname : i}`
        }), Sd(), e();
      }),
      title: "Оцените качество звонка",
      children: [/* @__PURE__ */ d(de, {
        control: a,
        name: "hadIssues",
        render: ({
          field: o
        }) => /* @__PURE__ */ f(_, {
          alignItems: "center",
          flexDirection: "row",
          my: 5,
          children: [/* @__PURE__ */ d(As, {
            onValueChange: o.onChange,
            value: o.value
          }), /* @__PURE__ */ d(b, {
            ml: 4,
            children: " Во-время звонка возникли проблемы"
          })]
        })
      }), /* @__PURE__ */ d(de, {
        control: a,
        name: "issueDescription",
        render: ({
          field: o,
          fieldState: {
            error: s
          }
        }) => /* @__PURE__ */ d(ns, {
          autoCompleteType: "off",
          isInvalid: !!s,
          onBlur: o.onBlur,
          onChangeText: o.onChange,
          placeholder: "Опишите проблему",
          value: o.value ?? "",
          w: "100%"
        })
      }), /* @__PURE__ */ d(ba, {
        _text: {
          color: "black"
        },
        backgroundColor: "green.600",
        borderRadius: 500,
        mt: 4,
        onPress: () => vd(),
        variant: "subtle",
        children: "Вернуться к звонку"
      })]
    })
  });
});
ou.displayName = "RateACallModal";
const su = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "subscription",
    name: {
      kind: "Name",
      value: "User"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "authentication"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anonymous"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "expiration"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "session_id"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "user"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "User"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "contact"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "add"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "User"
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "remove"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "init"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "peer_id"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "meet"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "invite"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "add"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "invited"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "space"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "FragmentSpread",
                            name: {
                              kind: "Name",
                              value: "MeetSpace"
                            }
                          }]
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "user"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "FragmentSpread",
                            name: {
                              kind: "Name",
                              value: "User"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "remove"
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "space"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "add"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpace"
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "remove"
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_since"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "force"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, ru = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "UserMeetSpaces"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "user"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "meet"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "list"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "invites"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "invited"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "space"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "FragmentSpread",
                            name: {
                              kind: "Name",
                              value: "MeetSpace"
                            }
                          }]
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "user"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "FragmentSpread",
                            name: {
                              kind: "Name",
                              value: "User"
                            }
                          }]
                        }
                      }]
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "spaces"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "joined"
                        }
                      }, {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "space"
                        },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{
                            kind: "FragmentSpread",
                            name: {
                              kind: "Name",
                              value: "MeetSpace"
                            }
                          }]
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpaceConditionsContacts"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceConditionsContactsType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "password"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "user_id"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "MeetSpace"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "MeetSpaceType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "created"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "description"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "permissions"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "rtc_shard"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "schedule_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "started"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "state"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "terminated"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "title"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "transient"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "recording_session_since"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "vanity_name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "conditions"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "draw"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "anyone"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "authenticated"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "join"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "link"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "contacts"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "FragmentSpread",
                    name: {
                      kind: "Name",
                      value: "MeetSpaceConditionsContacts"
                    }
                  }]
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "start"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "always"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "terminate"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "empty"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "force"
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "time"
                }
              }]
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "vote"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "cast"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "anyone"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }, {
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "start"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "authenticated"
                    }
                  }, {
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "contacts"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "MeetSpaceConditionsContacts"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
};
var mu = "/src/features/user/model/index.ts";
const cu = x([], {
  loc: {
    file: mu,
    line: 6,
    column: 27
  },
  name: "$myContacts",
  sid: "z2ixy1:/src/features/user/model/index.ts:$myContacts"
}), $t = j.object({
  deviceId: j.string()
}), uu = /* @__PURE__ */ g(() => {
  const {
    microphoneDevices: e,
    showNewAudioDeviceSelect: n
  } = A({
    microphoneDevices: Pr,
    showNewAudioDeviceSelect: nl
  }), i = E((r) => {
    const c = $t.parse(r);
    za(c);
  }, []), a = E(() => {
    el();
  }, []), t = Se((r) => r.length > 0 ? r[0].deviceId : void 0, void 0, e), {
    control: l,
    handleSubmit: o
  } = _n({
    defaultValues: {
      deviceId: t
    },
    mode: "all",
    resolver: Kn($t)
  }), s = t != null;
  return n ? /* @__PURE__ */ d(ya, {
    hasCancel: s,
    okButtonText: "Продолжить",
    onClose: a,
    onOk: s ? o(i) : a,
    title: n.type === "new-device-connected" ? "Обнаружено новое аудиоустройство" : "Аудиоустройство отключено, выберите другое",
    children: Wt((r) => r.length > 0 ? /* @__PURE__ */ d(de, {
      control: l,
      name: "deviceId",
      render: ({
        field: c,
        fieldState: {
          error: u
        }
      }) => /* @__PURE__ */ d(on, {
        isRequired: !0,
        isInvalid: u != null,
        mb: "3",
        children: /* @__PURE__ */ d(Un, {
          color: "white",
          onPointerLeave: c.onBlur,
          onValueChange: c.onChange,
          placeholder: "Выберите устройство",
          selectedValue: c.value,
          children: r.map((S) => /* @__PURE__ */ d(Un.Item, {
            color: "white",
            label: S.label,
            value: S.deviceId
          }, S.deviceId))
        })
      })
    }) : /* @__PURE__ */ d(b, {
      children: "Не обнаружено доступных микрофонов"
    }), () => /* @__PURE__ */ d(zn, {
      size: "lg"
    }), () => /* @__PURE__ */ d(zn, {
      size: "lg"
    }), (r) => /* @__PURE__ */ d(b, {
      children: String(r)
    }))(e)
  }, String(s)) : null;
});
uu.displayName = "AudioDeviceChangedModal";
var ji = "/src/features/video-call/speaking-analyzer/model/index.ts";
const ut = h("eventSpeakingUpdate", {
  loc: {
    file: ji,
    line: 7,
    column: 35
  },
  name: "eventSpeakingUpdate",
  sid: "-3d0v2m:/src/features/video-call/speaking-analyzer/model/index.ts:eventSpeakingUpdate"
}), kt = h({
  loc: {
    file: ji,
    line: 11,
    column: 37
  },
  name: "eventMeSpeakingUpdate",
  sid: "fz3bcb:/src/features/video-call/speaking-analyzer/model/index.ts:eventMeSpeakingUpdate"
}), ku = fe.map((e) => e.filter((n) => {
  var i, a;
  return (((i = n.stream) == null ? void 0 : i.getAudioTracks().length) ?? 0) > 0 && ((a = n.stream) == null ? void 0 : a.getAudioTracks().some((t) => t.enabled));
})), vu = x(!1, {
  loc: {
    file: ji,
    line: 21,
    column: 27
  },
  name: "$meSpeaking",
  sid: "-6ln4ae:/src/features/video-call/speaking-analyzer/model/index.ts:$meSpeaking"
}).on(kt, (e, n) => n).reset(ue), Su = x({
  ref: /* @__PURE__ */ new Map()
}, {
  loc: {
    file: ji,
    line: 25,
    column: 30
  },
  name: "$peersSpeaking",
  sid: "sybh3x:/src/features/video-call/speaking-analyzer/model/index.ts:$peersSpeaking"
}).on(ut, (e, n) => {
  if (e.ref.size !== n.length)
    return {
      ref: new Map(n.map((a) => [a.peerId, a.speaking]))
    };
  let i;
  for (const a of n)
    e.ref.get(a.peerId) !== a.speaking && (e.ref.set(a.peerId, a.speaking), i = !0);
  return i ? {
    ref: e.ref
  } : void 0;
});
Mn([ut, kt, vu, Su], {
  log: "disabled"
});
const so = -75, ro = 120, mo = 500;
function NS() {
  const e = A(ku);
  J(() => {
    if (process.env.VITE_NO_STATS !== "true") {
      if (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        window.AudioContext ?? window.webkitAudioContext
      )
        return () => {
        };
      const n = new AudioContext();
      let i = null;
      const a = e.map((t) => {
        const l = n.createAnalyser();
        l.minDecibels = so;
        const o = n.createMediaStreamSource(t.stream);
        return o.connect(l), {
          analyzer: l,
          peerId: t.id,
          streamNode: o
        };
      });
      if (a.length > 0) {
        const t = new Uint8Array(a[0].analyzer.frequencyBinCount);
        i = setInterval(() => {
          const o = a.map(({
            analyzer: s,
            peerId: r
          }) => {
            s.getByteFrequencyData(t);
            let c = !1;
            for (let u = 0; u < Math.min(50, t.length); u++)
              t[u] > ro && (c = !0);
            return {
              peerId: r,
              speaking: c
            };
          });
          ut(o);
        }, mo);
      }
      return () => {
        for (const {
          analyzer: t,
          streamNode: l
        } of a)
          t.disconnect(), l.disconnect();
        n.close(), i && clearInterval(i);
      };
    } else
      return () => {
      };
  }, [e]);
}
function fS() {
  const e = A(Ve);
  J(() => {
    if (process.env.VITE_NO_STATS !== "true") {
      let n, i, a;
      if (e.state !== "initial" && e.stream != null) {
        a = new AudioContext(), i = a.createAnalyser(), i.minDecibels = so, a.createMediaStreamSource(e.stream).connect(i);
        const l = new Uint8Array(i.frequencyBinCount);
        n = setInterval(() => {
          if (i == null)
            return;
          i.getByteFrequencyData(l);
          let s = !1;
          for (let r = 0; r < Math.min(50, l.length); r++)
            l[r] > ro && (s = !0);
          kt(s);
        }, mo);
      }
      return () => {
        n && clearInterval(n), i == null || i.disconnect(), a == null || a.close();
      };
    } else
      return () => {
      };
  }, [e]);
}
var co = {}, vt = {};
Object.defineProperty(vt, "__esModule", { value: !0 });
var pu = { radix: 1e3, unit: ["b", "kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"] }, Nu = { radix: 1024, unit: ["b", "Kib", "Mib", "Gib", "Tib", "Pib", "Eib", "Zib", "Yib"] }, fu = { radix: 1024, unit: ["b", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"] };
vt.SPECS = {
  si: pu,
  iec: Nu,
  jedec: fu
};
Object.defineProperty(co, "__esModule", { value: !0 });
var Bt = vt;
function Fu(e, n, i) {
  n === void 0 && (n = 1), e = Math.abs(e);
  for (var a = Bt.SPECS[i] || Bt.SPECS.jedec, t = a.radix, l = a.unit, o = 0; e >= t; )
    e /= t, ++o;
  return e.toFixed(n) + " " + l[o];
}
var Ke = co.default = Fu, St = "/src/features/video-call/stats/model/stats.ts";
const pt = h({
  loc: {
    file: St,
    line: 58,
    column: 33
  },
  name: "eventStatsUpdated",
  sid: "-lads7q:/src/features/video-call/stats/model/stats.ts:eventStatsUpdated"
}), Hi = x({
  ref: {
    myStats: {
      audio: void 0,
      video: void 0
    },
    peers: /* @__PURE__ */ new Map()
  }
}, {
  loc: {
    file: St,
    line: 60,
    column: 26
  },
  name: "$peerStats",
  sid: "-5y3ur8:/src/features/video-call/stats/model/stats.ts:$peerStats"
});
Mn([Hi, pt], {
  log: "disabled"
});
Hi.on(w({
  and: [{
    clock: pt,
    source: {
      $peersByTrackId: Dd
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => ({
      peers: e,
      stats: n
    })
  }],
  or: {
    loc: {
      file: St,
      line: 66,
      column: 2
    },
    sid: "-nriy1n:/src/features/video-call/stats/model/stats.ts:"
  }
}), (e, n) => {
  var m, p, k, F;
  const i = /* @__PURE__ */ new Map(), a = /* @__PURE__ */ new Map(), t = /* @__PURE__ */ new Map(), l = /* @__PURE__ */ new Map(), o = /* @__PURE__ */ new Map(), s = /* @__PURE__ */ new Map();
  let r, c;
  n.stats.forEach((v) => {
    switch (v.type) {
      case "inbound-rtp": {
        v.kind === "video" ? i.set(v.id, v) : a.set(v.id, v);
        break;
      }
      case "remote-inbound-rtp": {
        v.kind === "video" ? t.set(v.localId, v) : l.set(v.localId, v);
        break;
      }
      case "outbound-rtp": {
        v.kind === "video" ? r = v : c = v;
        break;
      }
      case "remote-outbound-rtp": {
        v.kind === "video" ? o.set(v.id, v) : s.set(v.id, v);
        break;
      }
    }
  });
  for (const v of i.values()) {
    const N = n.peers.$peersByTrackId.ref.get(v.trackIdentifier), y = o.get(v.remoteId);
    if (N != null && y != null) {
      const T = e.ref.peers.get(N), C = (m = T == null ? void 0 : T.video) == null ? void 0 : m.inbound, M = di(v.bytesReceived, v.timestamp, C == null ? void 0 : C.bytesReceived, C == null ? void 0 : C.timestamp), V = {
        computed: {
          lastPacketReceivedDelay: v.timestamp - (v.lastPacketReceivedTimestamp ?? 0),
          speed: M,
          speedKib: Ke(M)
        },
        inbound: v,
        remoteOutbound: y
      };
      T == null ? e.ref.peers.set(N, {
        audio: void 0,
        video: V
      }) : e.ref.peers.set(N, {
        ...T,
        video: V
      });
    }
  }
  for (const v of a.values()) {
    const N = n.peers.$peersByTrackId.ref.get(v.trackIdentifier), y = s.get(v.remoteId);
    if (N != null && y != null) {
      const T = e.ref.peers.get(N), C = (p = T == null ? void 0 : T.audio) == null ? void 0 : p.inbound, M = di(v.bytesReceived, v.timestamp, C == null ? void 0 : C.bytesReceived, C == null ? void 0 : C.timestamp), V = {
        computed: {
          lastPacketReceivedDelay: v.timestamp - (v.lastPacketReceivedTimestamp ?? 0),
          speed: M,
          speedKib: Ke(M)
        },
        inbound: v,
        remoteOutbound: y
      };
      T == null ? e.ref.peers.set(N, {
        audio: V,
        video: void 0
      }) : e.ref.peers.set(N, {
        ...T,
        audio: V
      });
    }
  }
  let u, S;
  if (r != null) {
    const v = t.get(r.id), N = (k = e.ref.myStats.video) == null ? void 0 : k.outbound, y = di(r.bytesSent, r.timestamp, N == null ? void 0 : N.bytesSent, N == null ? void 0 : N.timestamp);
    u = {
      computed: {
        speed: y,
        speedKib: Ke(y)
      },
      outbound: r,
      remoteInbound: v
    };
  }
  if (c != null) {
    const v = l.get(c.id), N = (F = e.ref.myStats.audio) == null ? void 0 : F.outbound, y = di(c.bytesSent, c.timestamp, N == null ? void 0 : N.bytesSent, N == null ? void 0 : N.timestamp);
    S = {
      computed: {
        speed: y,
        speedKib: Ke(y)
      },
      outbound: c,
      remoteInbound: v
    };
  }
  return {
    ref: {
      myStats: {
        audio: S,
        video: u
      },
      peers: e.ref.peers
    }
  };
});
function di(e, n, i, a) {
  return ((e ?? 0) - (i ?? 0)) / ((n - (a ?? 0)) / 1e3);
}
function FS() {
  const e = A(te);
  J(() => {
    let n = null;
    return process.env.VITE_NO_STATS !== "true" && (n = setInterval(() => {
      e == null || e.getStats().then((a) => {
        pt(a);
      });
    }, 1e3)), () => {
      n != null && clearInterval(n);
    };
  }, [e]);
}
const uo = /* @__PURE__ */ g(({
  peerId: e
}) => {
  const n = A(Hi).ref, i = A({
    connectionState: vn,
    iceConnectionState: In,
    iceGatheringState: Wa,
    signalingState: Dn
  }), a = e != null ? n.peers.get(e) : n.myStats, t = JSON.stringify(i, null, 2);
  return /* @__PURE__ */ f(Bn, {
    children: [/* @__PURE__ */ d(b, {
      children: t
    }), /* @__PURE__ */ d(b, {
      children: JSON.stringify({
        peerId: e,
        ...a
      }, null, 2)
    })]
  });
});
uo.displayName = "StatsPopoverBody";
const Nt = /* @__PURE__ */ g(({
  peerId: e,
  showDetails: n,
  small: i
}) => {
  var c, u, S, m, p, k;
  const {
    t: a
  } = le(), t = A(hm), l = t === pe.GREEN ? Be.colors.green[400] : t === pe.YELLOW ? Be.colors.yellow[400] : Be.colors.red[400], o = A(Hi).ref, s = e != null ? o.peers.get(e) : null, r = e == null ? o.myStats : null;
  return /* @__PURE__ */ f(sn, {
    backgroundColor: "rgba(0,0,0,0.64)",
    borderRadius: "50%",
    bottom: i ? void 0 : 0,
    left: 0,
    position: "absolute",
    top: i ? 0 : void 0,
    children: [/* @__PURE__ */ d(ge, {
      trigger: (F) => /* @__PURE__ */ d(Yn, {
        ...F,
        _focus: {
          bg: "rgba(255, 255, 255, 0.12)"
        },
        _hover: {
          bg: "rgba(255, 255, 255, 0.12)"
        },
        bg: "rgba(255, 255, 255, 0.08)",
        h: i ? "16px" : "36px",
        w: i ? "16px" : "36px",
        children: /* @__PURE__ */ d(Ts, {
          color: l,
          fill: l
        })
      }),
      children: /* @__PURE__ */ f(ge.Content, {
        children: [/* @__PURE__ */ d(ge.Arrow, {}), /* @__PURE__ */ d(ge.CloseButton, {}), /* @__PURE__ */ d(ge.Header, {
          children: a("ROOM.CONNECTION_STATS.LABEL")
        }), /* @__PURE__ */ d(ge.Body, {
          height: "2xl",
          overflow: "scroll",
          w: "100%",
          children: /* @__PURE__ */ d(uo, {
            peerId: e
          })
        })]
      })
    }), n ? s != null ? /* @__PURE__ */ f(sn, {
      flexWrap: "nowrap",
      w: "max-content",
      children: [/* @__PURE__ */ f(b, {
        color: "white",
        children: ["a: ", Ke(((c = s.audio) == null ? void 0 : c.computed.speed) ?? 0), ",", " ", zt((u = s.audio) == null ? void 0 : u.computed.lastPacketReceivedDelay)]
      }), /* @__PURE__ */ f(b, {
        color: "white",
        children: ["v: ", Ke(((S = s.video) == null ? void 0 : S.computed.speed) ?? 0), ",", " ", zt((m = s.video) == null ? void 0 : m.computed.lastPacketReceivedDelay)]
      })]
    }) : r != null ? /* @__PURE__ */ f(sn, {
      flexWrap: "nowrap",
      w: "max-content",
      children: [/* @__PURE__ */ f(b, {
        color: "white",
        children: ["a: ", Ke(((p = r.audio) == null ? void 0 : p.computed.speed) ?? 0)]
      }), /* @__PURE__ */ f(b, {
        color: "white",
        children: ["v: ", Ke(((k = r.video) == null ? void 0 : k.computed.speed) ?? 0)]
      })]
    }) : null : null]
  });
});
Nt.displayName = "StatsPopover";
const zt = (e) => e == null ? "" : e < 1e3 ? `${e} мс` : `${Math.round(e / 1e3)} сек`, gu = z.object({
  backgroundImage: z.union([z.string(), z.undefined()]),
  backgroundType: z.union([z.literal("blur"), z.literal("image"), z.literal("none")])
}), ai = {
  "160x96": [160, 96],
  "256x144": [256, 144],
  "256x256": [256, 256],
  "640x360": [640, 360]
};
function hu(e, n) {
  switch (e) {
    case "meet":
      return n === "256x144" ? "segm_full_v679" : "segm_lite_v681";
    case "mlkit":
      return "selfiesegmentation_mlkit-256x256-2021_01_19-v1215.f16";
    default:
      throw new Error(`No TFLite file for this segmentation model: ${e}`);
  }
}
const Ie = String.raw;
function Nn(e, n, i, a, t) {
  const l = bu(e, n, i), o = e.getAttribLocation(l, "a_position");
  e.enableVertexAttribArray(o), e.bindBuffer(e.ARRAY_BUFFER, a), e.vertexAttribPointer(o, 2, e.FLOAT, !1, 0, 0);
  const s = e.getAttribLocation(l, "a_texCoord");
  return e.enableVertexAttribArray(s), e.bindBuffer(e.ARRAY_BUFFER, t), e.vertexAttribPointer(s, 2, e.FLOAT, !1, 0, 0), l;
}
function bu(e, n, i) {
  const a = e.createProgram();
  if (e.attachShader(a, n), e.attachShader(a, i), e.linkProgram(a), !e.getProgramParameter(a, e.LINK_STATUS))
    throw new Error(`Could not link WebGL program: ${e.getProgramInfoLog(a)}`);
  return a;
}
function Ae(e, n, i) {
  const a = e.createShader(n);
  if (e.shaderSource(a, i), e.compileShader(a), !e.getShaderParameter(a, e.COMPILE_STATUS))
    throw new Error(`Could not compile shader: ${e.getShaderInfoLog(a)}`);
  return a;
}
function Ze(e, n, i, a, t = e.NEAREST, l = e.NEAREST) {
  const o = e.createTexture();
  return e.bindTexture(e.TEXTURE_2D, o), e.texParameteri(e.TEXTURE_2D, e.TEXTURE_WRAP_S, e.CLAMP_TO_EDGE), e.texParameteri(e.TEXTURE_2D, e.TEXTURE_WRAP_T, e.CLAMP_TO_EDGE), e.texParameteri(e.TEXTURE_2D, e.TEXTURE_MIN_FILTER, t), e.texParameteri(e.TEXTURE_2D, e.TEXTURE_MAG_FILTER, l), e.texStorage2D(e.TEXTURE_2D, 1, n, i, a), o;
}
async function yu(e, n, i, a, t, l, o, s) {
  const r = e.createBuffer();
  return e.bindBuffer(e.PIXEL_PACK_BUFFER, r), e.bufferData(e.PIXEL_PACK_BUFFER, s.byteLength, e.STREAM_READ), e.readPixels(n, i, a, t, l, o, 0), e.bindBuffer(e.PIXEL_PACK_BUFFER, null), await Tu(e, e.PIXEL_PACK_BUFFER, r, 0, s), e.deleteBuffer(r), s;
}
async function Tu(e, n, i, a, t, l, o) {
  const s = e.fenceSync(e.SYNC_GPU_COMMANDS_COMPLETE, 0);
  e.flush();
  const r = await Cu(e, s);
  e.deleteSync(s), r !== e.WAIT_FAILED && (e.bindBuffer(n, i), e.getBufferSubData(n, a, t, l, o), e.bindBuffer(n, null));
}
function Cu(e, n) {
  return new Promise((i) => {
    function a() {
      const t = e.clientWaitSync(n, 0, 0);
      if (t === e.WAIT_FAILED) {
        i(t);
        return;
      }
      if (t === e.TIMEOUT_EXPIRED) {
        requestAnimationFrame(a);
        return;
      }
      i(t);
    }
    requestAnimationFrame(a);
  });
}
function Mu(e, n, i, a, t, l) {
  const o = wu(e, n, i, a, t, l), s = Eu(e, i, a, l);
  function r() {
    o.render(), s.render();
  }
  function c(S) {
    s.updateCoverage(S);
  }
  function u() {
    s.cleanUp(), o.cleanUp();
  }
  return {
    cleanUp: u,
    render: r,
    updateCoverage: c
  };
}
function wu(e, n, i, a, t, l) {
  const o = Ie`#version 300 es

    precision highp float;

    uniform sampler2D u_inputFrame;
    uniform sampler2D u_personMask;
    uniform vec2 u_texelSize;

    in vec2 v_texCoord;

    out vec4 outColor;

    const float offset[5] = float[](0.0, 1.0, 2.0, 3.0, 4.0);
    const float weight[5] = float[](0.2270270270, 0.1945945946, 0.1216216216,
      0.0540540541, 0.0162162162);

    void main() {
      vec4 centerColor = texture(u_inputFrame, v_texCoord);
      float personMask = texture(u_personMask, v_texCoord).a;

      vec4 frameColor = centerColor * weight[0] * (1.0 - personMask);

      for (int i = 1; i < 5; i++) {
        vec2 offset = vec2(offset[i]) * u_texelSize;

        vec2 texCoord = v_texCoord + offset;
        frameColor += texture(u_inputFrame, texCoord) * weight[i] *
          (1.0 - texture(u_personMask, texCoord).a);

        texCoord = v_texCoord - offset;
        frameColor += texture(u_inputFrame, texCoord) * weight[i] *
          (1.0 - texture(u_personMask, texCoord).a);
      }
      outColor = vec4(frameColor.rgb + (1.0 - frameColor.a) * centerColor.rgb, 1.0);
    }
  `, s = 0.2, r = l.width * s, c = l.height * s, u = 1 / r, S = 1 / c, m = Ae(e, e.FRAGMENT_SHADER, o), p = Nn(e, n, m, i, a), k = e.getUniformLocation(p, "u_inputFrame"), F = e.getUniformLocation(p, "u_personMask"), v = e.getUniformLocation(p, "u_texelSize"), N = Ze(e, e.RGBA8, r, c, e.NEAREST, e.LINEAR), y = Ze(e, e.RGBA8, r, c, e.NEAREST, e.LINEAR), T = e.createFramebuffer();
  e.bindFramebuffer(e.FRAMEBUFFER, T), e.framebufferTexture2D(e.FRAMEBUFFER, e.COLOR_ATTACHMENT0, e.TEXTURE_2D, N, 0);
  const C = e.createFramebuffer();
  e.bindFramebuffer(e.FRAMEBUFFER, C), e.framebufferTexture2D(e.FRAMEBUFFER, e.COLOR_ATTACHMENT0, e.TEXTURE_2D, y, 0), e.useProgram(p), e.uniform1i(F, 1);
  function M() {
    e.viewport(0, 0, r, c), e.useProgram(p), e.uniform1i(k, 0), e.activeTexture(e.TEXTURE1), e.bindTexture(e.TEXTURE_2D, t);
    for (let G = 0; G < 3; G++)
      e.uniform2f(v, 0, S), e.bindFramebuffer(e.FRAMEBUFFER, T), e.drawArrays(e.TRIANGLE_STRIP, 0, 4), e.activeTexture(e.TEXTURE2), e.bindTexture(e.TEXTURE_2D, N), e.uniform1i(k, 2), e.uniform2f(v, u, 0), e.bindFramebuffer(e.FRAMEBUFFER, C), e.drawArrays(e.TRIANGLE_STRIP, 0, 4), e.bindTexture(e.TEXTURE_2D, y);
  }
  function V() {
    e.deleteFramebuffer(C), e.deleteFramebuffer(T), e.deleteTexture(y), e.deleteTexture(N), e.deleteProgram(p), e.deleteShader(m);
  }
  return {
    cleanUp: V,
    render: M
  };
}
function Eu(e, n, i, a) {
  const t = Ie`#version 300 es

    in vec2 a_position;
    in vec2 a_texCoord;

    out vec2 v_texCoord;

    void main() {
      // Flipping Y is required when rendering to canvas
      gl_Position = vec4(a_position * vec2(1.0, -1.0), 0.0, 1.0);
      v_texCoord = a_texCoord;
    }
  `, l = Ie`#version 300 es

    precision highp float;

    uniform sampler2D u_inputFrame;
    uniform sampler2D u_personMask;
    uniform sampler2D u_blurredInputFrame;
    uniform vec2 u_coverage;

    in vec2 v_texCoord;

    out vec4 outColor;

    void main() {
      vec3 color = texture(u_inputFrame, v_texCoord).rgb;
      vec3 blurredColor = texture(u_blurredInputFrame, v_texCoord).rgb;
      float personMask = texture(u_personMask, v_texCoord).a;
      personMask = smoothstep(u_coverage.x, u_coverage.y, personMask);
      outColor = vec4(mix(blurredColor, color, personMask), 1.0);
    }
  `, {
    height: o,
    width: s
  } = a, r = Ae(e, e.VERTEX_SHADER, t), c = Ae(e, e.FRAGMENT_SHADER, l), u = Nn(e, r, c, n, i), S = e.getUniformLocation(u, "u_inputFrame"), m = e.getUniformLocation(u, "u_personMask"), p = e.getUniformLocation(u, "u_blurredInputFrame"), k = e.getUniformLocation(u, "u_coverage");
  e.useProgram(u), e.uniform1i(S, 0), e.uniform1i(m, 1), e.uniform1i(p, 2), e.uniform2f(k, 0, 1);
  function F() {
    e.viewport(0, 0, s, o), e.useProgram(u), e.bindFramebuffer(e.FRAMEBUFFER, null), e.drawArrays(e.TRIANGLE_STRIP, 0, 4);
  }
  function v(y) {
    e.useProgram(u), e.uniform2f(k, y[0], y[1]);
  }
  function N() {
    e.deleteProgram(u), e.deleteShader(c), e.deleteShader(r);
  }
  return {
    cleanUp: N,
    render: F,
    updateCoverage: v
  };
}
function _u(e, n, i, a, t, l) {
  const o = Ie`#version 300 es

    uniform vec2 u_backgroundScale;
    uniform vec2 u_backgroundOffset;

    in vec2 a_position;
    in vec2 a_texCoord;

    out vec2 v_texCoord;
    out vec2 v_backgroundCoord;

    void main() {
      // Flipping Y is required when rendering to canvas
      gl_Position = vec4(a_position * vec2(1.0, -1.0), 0.0, 1.0);
      v_texCoord = a_texCoord;
      v_backgroundCoord = a_texCoord * u_backgroundScale + u_backgroundOffset;
    }
  `, s = Ie`#version 300 es

    precision highp float;

    uniform sampler2D u_inputFrame;
    uniform sampler2D u_personMask;
    uniform sampler2D u_background;
    uniform vec2 u_coverage;
    uniform float u_lightWrapping;
    uniform float u_blendMode;

    in vec2 v_texCoord;
    in vec2 v_backgroundCoord;

    out vec4 outColor;

    vec3 screen(vec3 a, vec3 b) {
      return 1.0 - (1.0 - a) * (1.0 - b);
    }

    vec3 linearDodge(vec3 a, vec3 b) {
      return a + b;
    }

    void main() {
      vec3 frameColor = texture(u_inputFrame, v_texCoord).rgb;
      vec3 backgroundColor = texture(u_background, v_backgroundCoord).rgb;
      float personMask = texture(u_personMask, v_texCoord).a;
      float lightWrapMask = 1.0 - max(0.0, personMask - u_coverage.y) / (1.0 - u_coverage.y);
      vec3 lightWrap = u_lightWrapping * lightWrapMask * backgroundColor;
      frameColor = u_blendMode * linearDodge(frameColor, lightWrap) +
        (1.0 - u_blendMode) * screen(frameColor, lightWrap);
      personMask = smoothstep(u_coverage.x, u_coverage.y, personMask);
      outColor = vec4(frameColor * personMask + backgroundColor * (1.0 - personMask), 1.0);
    }
  `, {
    height: r,
    width: c
  } = l, u = c / r, S = Ae(e, e.VERTEX_SHADER, o), m = Ae(e, e.FRAGMENT_SHADER, s), p = Nn(e, S, m, n, i), k = e.getUniformLocation(p, "u_backgroundScale"), F = e.getUniformLocation(p, "u_backgroundOffset"), v = e.getUniformLocation(p, "u_inputFrame"), N = e.getUniformLocation(p, "u_personMask"), y = e.getUniformLocation(p, "u_background"), T = e.getUniformLocation(p, "u_coverage"), C = e.getUniformLocation(p, "u_lightWrapping"), M = e.getUniformLocation(p, "u_blendMode");
  e.useProgram(p), e.uniform2f(k, 1, 1), e.uniform2f(F, 0, 0), e.uniform1i(v, 0), e.uniform1i(N, 1), e.uniform2f(T, 0, 1), e.uniform1f(C, 0), e.uniform1f(M, 0);
  let V = null;
  t != null && t.complete ? se(t) : t && t.addEventListener("load", () => {
    se(t);
  });
  function G() {
    e.viewport(0, 0, c, r), e.useProgram(p), e.activeTexture(e.TEXTURE1), e.bindTexture(e.TEXTURE_2D, a), V !== null && (e.activeTexture(e.TEXTURE2), e.bindTexture(e.TEXTURE_2D, V), e.uniform1i(y, 2)), e.bindFramebuffer(e.FRAMEBUFFER, null), e.drawArrays(e.TRIANGLE_STRIP, 0, 4);
  }
  function se(L) {
    V = Ze(e, e.RGBA8, L.naturalWidth, L.naturalHeight, e.LINEAR, e.LINEAR), e.texSubImage2D(e.TEXTURE_2D, 0, 0, 0, L.naturalWidth, L.naturalHeight, e.RGBA, e.UNSIGNED_BYTE, L);
    let ke = 0, tn = 0, fn = L.naturalWidth, Le = L.naturalHeight;
    fn / Le < u ? (Le = fn / u, tn = (L.naturalHeight - Le) / 2) : (fn = Le * u, ke = (L.naturalWidth - fn) / 2);
    const Gi = fn / L.naturalWidth, qi = Le / L.naturalHeight;
    ke /= L.naturalWidth, tn /= L.naturalHeight, e.uniform2f(k, Gi, qi), e.uniform2f(F, ke, tn);
  }
  function Y(L) {
    e.useProgram(p), e.uniform2f(T, L[0], L[1]);
  }
  function I(L) {
    e.useProgram(p), e.uniform1f(C, L);
  }
  function R(L) {
    e.useProgram(p), e.uniform1f(M, L === "screen" ? 0 : 1);
  }
  function q() {
    e.deleteTexture(V), e.deleteProgram(p), e.deleteShader(m), e.deleteShader(S);
  }
  return {
    cleanUp: q,
    render: G,
    updateBlendMode: R,
    updateCoverage: Y,
    updateLightWrapping: I
  };
}
function Ru(e, n, i, a, t, l, o, s) {
  const r = Ie`#version 300 es

    precision highp float;

    uniform sampler2D u_inputFrame;
    uniform sampler2D u_segmentationMask;
    uniform vec2 u_texelSize;
    uniform float u_step;
    uniform float u_radius;
    uniform float u_offset;
    uniform float u_sigmaTexel;
    uniform float u_sigmaColor;

    in vec2 v_texCoord;

    out vec4 outColor;

    float gaussian(float x, float sigma) {
      float coeff = -0.5 / (sigma * sigma * 4.0 + 1.0e-6);
      return exp((x * x) * coeff);
    }

    void main() {
      vec2 centerCoord = v_texCoord;
      vec3 centerColor = texture(u_inputFrame, centerCoord).rgb;
      float newVal = 0.0;

      float spaceWeight = 0.0;
      float colorWeight = 0.0;
      float totalWeight = 0.0;

      // Subsample kernel space.
      for (float i = -u_radius + u_offset; i <= u_radius; i += u_step) {
        for (float j = -u_radius + u_offset; j <= u_radius; j += u_step) {
          vec2 shift = vec2(j, i) * u_texelSize;
          vec2 coord = vec2(centerCoord + shift);
          vec3 frameColor = texture(u_inputFrame, coord).rgb;
          float outVal = texture(u_segmentationMask, coord).a;

          spaceWeight = gaussian(distance(centerCoord, coord), u_sigmaTexel);
          colorWeight = gaussian(distance(centerColor, frameColor), u_sigmaColor);
          totalWeight += spaceWeight * colorWeight;

          newVal += spaceWeight * colorWeight * outVal;
        }
      }
      newVal /= totalWeight;

      outColor = vec4(vec3(0.0), newVal);
    }
  `, [c, u] = ai[l.inputResolution], {
    height: S,
    width: m
  } = s, p = 1 / m, k = 1 / S, F = Ae(e, e.FRAGMENT_SHADER, r), v = Nn(e, n, F, i, a), N = e.getUniformLocation(v, "u_inputFrame"), y = e.getUniformLocation(v, "u_segmentationMask"), T = e.getUniformLocation(v, "u_texelSize"), C = e.getUniformLocation(v, "u_step"), M = e.getUniformLocation(v, "u_radius"), V = e.getUniformLocation(v, "u_offset"), G = e.getUniformLocation(v, "u_sigmaTexel"), se = e.getUniformLocation(v, "u_sigmaColor"), Y = e.createFramebuffer();
  e.bindFramebuffer(e.FRAMEBUFFER, Y), e.framebufferTexture2D(e.FRAMEBUFFER, e.COLOR_ATTACHMENT0, e.TEXTURE_2D, o, 0), e.useProgram(v), e.uniform1i(N, 0), e.uniform1i(y, 1), e.uniform2f(T, p, k), R(0), q(0);
  function I() {
    e.viewport(0, 0, m, S), e.useProgram(v), e.activeTexture(e.TEXTURE1), e.bindTexture(e.TEXTURE_2D, t), e.bindFramebuffer(e.FRAMEBUFFER, Y), e.drawArrays(e.TRIANGLE_STRIP, 0, 4);
  }
  function R(ke) {
    ke *= Math.max(m / c, S / u);
    const tn = 0.66, Le = Math.max(1, Math.sqrt(ke) * tn), gt = ke, Gi = Le > 1 ? Le * 0.5 : 0, qi = Math.max(p, k) * ke;
    e.useProgram(v), e.uniform1f(C, Le), e.uniform1f(M, gt), e.uniform1f(V, Gi), e.uniform1f(G, qi);
  }
  function q(ke) {
    e.useProgram(v), e.uniform1f(se, ke);
  }
  function L() {
    e.deleteFramebuffer(Y), e.deleteProgram(v), e.deleteShader(F);
  }
  return {
    cleanUp: L,
    render: I,
    updateSigmaColor: q,
    updateSigmaSpace: R
  };
}
function xu(e, n, i, a, t, l, o) {
  const s = Ie`#version 300 es

    precision highp float;

    uniform sampler2D u_inputSegmentation;

    in vec2 v_texCoord;

    out vec4 outColor;

    void main() {
      float segmentation = texture(u_inputSegmentation, v_texCoord).r;
      outColor = vec4(vec3(0.0), segmentation);
    }
  `, r = l._getOutputMemoryOffset() / 4, [c, u] = ai[t.inputResolution], S = Ae(e, e.FRAGMENT_SHADER, s), m = Nn(e, n, S, i, a), p = e.getUniformLocation(m, "u_inputSegmentation"), k = Ze(e, e.R32F, c, u), F = e.createFramebuffer();
  e.bindFramebuffer(e.FRAMEBUFFER, F), e.framebufferTexture2D(e.FRAMEBUFFER, e.COLOR_ATTACHMENT0, e.TEXTURE_2D, o, 0), e.useProgram(m), e.uniform1i(p, 1);
  function v() {
    e.viewport(0, 0, c, u), e.useProgram(m), e.activeTexture(e.TEXTURE1), e.bindTexture(e.TEXTURE_2D, k), e.texSubImage2D(e.TEXTURE_2D, 0, 0, 0, c, u, e.RED, e.FLOAT, l.HEAPF32, r), e.bindFramebuffer(e.FRAMEBUFFER, F), e.drawArrays(e.TRIANGLE_STRIP, 0, 4);
  }
  function N() {
    e.deleteFramebuffer(F), e.deleteTexture(k), e.deleteProgram(m), e.deleteShader(S);
  }
  return {
    cleanUp: N,
    render: v
  };
}
function Du(e, n, i, a, t, l) {
  const o = Ie`#version 300 es

    precision highp float;

    uniform sampler2D u_inputFrame;

    in vec2 v_texCoord;

    out vec4 outColor;

    void main() {
      outColor = texture(u_inputFrame, v_texCoord);
    }
  `, s = l._getInputMemoryOffset() / 4, [r, c] = ai[t.inputResolution], u = r * c, S = Ae(e, e.FRAGMENT_SHADER, o), m = Nn(e, n, S, i, a), p = e.getUniformLocation(m, "u_inputFrame"), k = Ze(e, e.RGBA8, r, c), F = e.createFramebuffer();
  e.bindFramebuffer(e.FRAMEBUFFER, F), e.framebufferTexture2D(e.FRAMEBUFFER, e.COLOR_ATTACHMENT0, e.TEXTURE_2D, k, 0);
  const v = new Uint8Array(u * 4);
  e.useProgram(m), e.uniform1i(p, 0);
  async function N() {
    e.viewport(0, 0, r, c), e.useProgram(m), e.bindFramebuffer(e.FRAMEBUFFER, F), e.drawArrays(e.TRIANGLE_STRIP, 0, 4);
    const T = yu(e, 0, 0, r, c, e.RGBA, e.UNSIGNED_BYTE, v);
    t.deferInputResizing || await T;
    for (let C = 0; C < u; C++) {
      const M = s + C * 3, V = C * 4;
      l.HEAPF32[M] = v[V] / 255, l.HEAPF32[M + 1] = v[V + 1] / 255, l.HEAPF32[M + 2] = v[V + 2] / 255;
    }
  }
  function y() {
    e.deleteFramebuffer(F), e.deleteTexture(k), e.deleteProgram(m), e.deleteShader(S);
  }
  return {
    cleanUp: y,
    render: N
  };
}
function Iu(e, n, i, a, t, l, o) {
  const s = Ie`#version 300 es

    precision highp float;

    uniform sampler2D u_inputSegmentation;

    in vec2 v_texCoord;

    out vec4 outColor;

    void main() {
      vec2 segmentation = texture(u_inputSegmentation, v_texCoord).rg;
      float shift = max(segmentation.r, segmentation.g);
      float backgroundExp = exp(segmentation.r - shift);
      float personExp = exp(segmentation.g - shift);
      outColor = vec4(vec3(0.0), personExp / (backgroundExp + personExp));
    }
  `, r = l._getOutputMemoryOffset() / 4, [c, u] = ai[t.inputResolution], S = Ae(e, e.FRAGMENT_SHADER, s), m = Nn(e, n, S, i, a), p = e.getUniformLocation(m, "u_inputSegmentation"), k = Ze(e, e.RG32F, c, u), F = e.createFramebuffer();
  e.bindFramebuffer(e.FRAMEBUFFER, F), e.framebufferTexture2D(e.FRAMEBUFFER, e.COLOR_ATTACHMENT0, e.TEXTURE_2D, o, 0), e.useProgram(m), e.uniform1i(p, 1);
  function v() {
    e.viewport(0, 0, c, u), e.useProgram(m), e.activeTexture(e.TEXTURE1), e.bindTexture(e.TEXTURE_2D, k), e.texSubImage2D(e.TEXTURE_2D, 0, 0, 0, c, u, e.RG, e.FLOAT, l.HEAPF32, r), e.bindFramebuffer(e.FRAMEBUFFER, F), e.drawArrays(e.TRIANGLE_STRIP, 0, 4);
  }
  function N() {
    e.deleteFramebuffer(F), e.deleteTexture(k), e.deleteProgram(m), e.deleteShader(S);
  }
  return {
    cleanUp: N,
    render: v
  };
}
function Au(e, n, i, a, t, l, o) {
  const s = Ie`#version 300 es

    in vec2 a_position;
    in vec2 a_texCoord;

    out vec2 v_texCoord;

    void main() {
      gl_Position = vec4(a_position, 0.0, 1.0);
      v_texCoord = a_texCoord;
    }
  `, {
    height: r,
    width: c
  } = e, [u, S] = ai[a.inputResolution], m = t.getContext("webgl2"), p = Ae(m, m.VERTEX_SHADER, s), k = m.createVertexArray();
  m.bindVertexArray(k);
  const F = m.createBuffer();
  m.bindBuffer(m.ARRAY_BUFFER, F), m.bufferData(m.ARRAY_BUFFER, new Float32Array([-1, -1, 1, -1, -1, 1, 1, 1]), m.STATIC_DRAW);
  const v = m.createBuffer();
  m.bindBuffer(m.ARRAY_BUFFER, v), m.bufferData(m.ARRAY_BUFFER, new Float32Array([0, 0, 1, 0, 0, 1, 1, 1]), m.STATIC_DRAW);
  const N = m.createTexture();
  m.bindTexture(m.TEXTURE_2D, N), m.texParameteri(m.TEXTURE_2D, m.TEXTURE_WRAP_S, m.CLAMP_TO_EDGE), m.texParameteri(m.TEXTURE_2D, m.TEXTURE_WRAP_T, m.CLAMP_TO_EDGE), m.texParameteri(m.TEXTURE_2D, m.TEXTURE_MIN_FILTER, m.NEAREST), m.texParameteri(m.TEXTURE_2D, m.TEXTURE_MAG_FILTER, m.NEAREST);
  const y = Ze(m, m.RGBA8, u, S), T = Ze(m, m.RGBA8, c, r), C = Du(m, p, F, v, a, l), M = a.model === "meet" ? Iu(m, p, F, v, a, l, y) : xu(m, p, F, v, a, l, y), V = Ru(m, p, F, v, y, a, T, t), G = i.type === "blur" ? Mu(m, p, F, v, T, t) : _u(m, F, v, T, n, t);
  async function se() {
    m.activeTexture(m.TEXTURE0), m.bindTexture(m.TEXTURE_2D, N), m.texImage2D(m.TEXTURE_2D, 0, m.RGBA, m.RGBA, m.UNSIGNED_BYTE, e.htmlElement), m.bindVertexArray(k), await C.render(), o(), l._runInference(), o(), M.render(), V.render(), G.render();
  }
  function Y(R) {
    if (V.updateSigmaSpace(R.jointBilateralFilter.sigmaSpace), V.updateSigmaColor(R.jointBilateralFilter.sigmaColor), i.type === "image") {
      const q = G;
      q.updateCoverage(R.coverage), q.updateLightWrapping(R.lightWrapping), q.updateBlendMode(R.blendMode);
    } else if (i.type === "blur")
      G.updateCoverage(R.coverage);
    else {
      const q = G;
      q.updateCoverage([0, 0.9999]), q.updateLightWrapping(0);
    }
  }
  function I() {
    G.cleanUp(), V.cleanUp(), M.cleanUp(), C.cleanUp(), m.deleteTexture(T), m.deleteTexture(y), m.deleteTexture(N), m.deleteBuffer(v), m.deleteBuffer(F), m.deleteVertexArray(k), m.deleteShader(p);
  }
  return {
    cleanUp: I,
    render: se,
    updatePostProcessingConfig: Y
  };
}
function Ou(e) {
  return new Worker(
    "/assets/timerWorker--7dR5ATP.js",
    {
      name: e == null ? void 0 : e.name
    }
  );
}
function Vu() {
  const e = /* @__PURE__ */ new Map(), n = new Ou();
  n.addEventListener("message", (o) => {
    const s = e.get(o.data.callbackId);
    s && (e.delete(o.data.callbackId), s());
  });
  let i = 1;
  function a(o, s) {
    const r = i++;
    return e.set(r, o), n.postMessage({
      callbackId: r,
      timeoutMs: s
    }), r;
  }
  function t(o) {
    e.has(o) && (n.postMessage({
      callbackId: o
    }), e.delete(o));
  }
  function l() {
    e.clear(), n.terminate();
  }
  return {
    clearTimeout: t,
    setTimeout: a,
    terminate: l
  };
}
function Pu(e, n, i, a) {
  const [t, l] = H(null), o = je(null), s = je(null), [r, c] = H(0), [u, S] = H([]);
  return J(() => {
    if (a == null || e == null)
      return () => {
      };
    const m = 1e3 / i.targetFps;
    let p = 0, k = 0, F = 0, v = 0;
    const N = [];
    let y;
    const T = Vu();
    if (i.pipeline !== "webgl2")
      throw new Error('segmentationConfig.pipeline !== "webgl2"');
    const C = Au(e, o.current, n, i, s.current, a, G);
    async function M() {
      const Y = performance.now();
      V(), await C.render(), se(), y = T.setTimeout(M, Math.max(0, m - (performance.now() - Y)));
    }
    function V() {
      k = Date.now();
    }
    function G() {
      const Y = Date.now();
      N[F] = Y - k, k = Y, F++;
    }
    function se() {
      const Y = Date.now();
      N[F] = Y - k, v++, Y >= p + 1e3 && (c(v * 1e3 / (Y - p)), S(N), p = Y, v = 0), F = 0;
    }
    return M(), console.info("Animation started:", e, n, i), l(C), () => {
      T.clearTimeout(y), T.terminate(), C.cleanUp(), console.info("Animation stopped:", e, n, i), l(null);
    };
  }, [e, n, i, a]), {
    backgroundImageRef: o,
    canvasRef: s,
    durations: u,
    fps: r,
    pipeline: t
  };
}
function Lu(e) {
  const [n, i] = H(), [a, t] = H(), [l, o] = H(), [s, r] = H(!1);
  return J(() => {
    async function c() {
      createTFLiteModule().then(i);
      try {
        const u = await createTFLiteSIMDModule();
        t(u), r(!0);
      } catch (u) {
        console.warn("Failed to create TFLite SIMD WebAssembly module.", u);
      }
    }
    c();
  }, []), J(() => {
    async function c() {
      if (!n || s && !a || !s && e.backend === "wasmSimd" || e.model !== "meet" && e.model !== "mlkit")
        return;
      o(void 0);
      const u = e.backend === "wasmSimd" ? a : n;
      if (!u)
        throw new Error(`TFLite backend unavailable: ${e.backend}`);
      const S = hu(e.model, e.inputResolution);
      console.info("Loading tflite model:", S);
      const m = `/models/${S}.tflite`, p = await (await fetch(m)).arrayBuffer();
      console.info("Model buffer size:", p.byteLength);
      const k = u._getModelBufferMemoryOffset();
      console.info("Model buffer memory offset:", k), console.info("Loading model buffer..."), u.HEAPU8.set(new Uint8Array(p), k), console.info("_loadModel result:", u._loadModel(p.byteLength)), console.info("Input memory offset:", u._getInputMemoryOffset()), console.info("Input height:", u._getInputHeight()), console.info("Input width:", u._getInputWidth()), console.info("Input channels:", u._getInputChannelCount()), console.info("Output memory offset:", u._getOutputMemoryOffset()), console.info("Output height:", u._getOutputHeight()), console.info("Output width:", u._getOutputWidth()), console.info("Output channels:", u._getOutputChannelCount()), o(u);
    }
    c();
  }, [n, a, s, e.model, e.backend, e.inputResolution]), {
    isSIMDSupported: s,
    tflite: l
  };
}
const ko = /* @__PURE__ */ g(() => {
  const e = A(ce), [n, i] = H(), [a, t] = H({
    backend: "wasm",
    deferInputResizing: !0,
    inputResolution: "160x96",
    model: "meet",
    pipeline: "webgl2",
    // 60 introduces fps drop and unstable fps on Chrome
    // targetFps: 65,
    targetFps: 30
  }), [
    l
    /* setPostProcessingConfig */
  ] = H({
    blendMode: "screen",
    coverage: [0.5, 0.75],
    jointBilateralFilter: {
      sigmaColor: 0.1,
      sigmaSpace: 1
    },
    lightWrapping: 0.3,
    smoothSegmentationMask: !0
  }), {
    isSIMDSupported: o,
    tflite: s
  } = Lu(a);
  J(() => {
    t((v) => v.backend === "wasm" && o ? {
      ...v,
      backend: "wasmSimd"
    } : v);
  }, [o]);
  const r = P(() => e.state === "background-blur-initializing" || e.state === "background-blur-ready" ? {
    type: "blur"
  } : e.state === "background-image-initializing" || e.state === "background-image-ready" ? {
    type: "image",
    url: e.backgroundImage
  } : {
    type: "none",
    url: void 0
  }, [e]), {
    backgroundImageRef: c,
    canvasRef: u,
    // durations: [resizingDuration, inferenceDuration, postProcessingDuration],
    // fps,
    pipeline: S
  } = Pu(
    n,
    r,
    a,
    // props.bodyPix,
    s
  );
  J(() => {
    i(void 0);
  }, []), J(() => {
    S && S.updatePostProcessingConfig(l);
  }, [S, l]);
  const m = E((v) => {
    const N = v.target;
    i({
      height: N.videoHeight,
      htmlElement: N,
      width: N.videoWidth
    }), Gd(u);
  }, [u]), p = P(() => ({
    // border: "1px solid red",
    position: "absolute",
    // zIndex: 99,
    visibility: "hidden",
    zIndex: -1
  }), []), k = P(() => jd(e), [e]), F = e.state === "background-image-initializing" || e.state === "background-image-ready" ? e.backgroundImage : void 0;
  return /* @__PURE__ */ f("div", {
    style: p,
    children: [/* @__PURE__ */ d("img", {
      ref: c,
      alt: "",
      src: F
    }, e.state === "background-image-initializing" || e.state === "background-image-ready" ? e.backgroundImage : ""), /* @__PURE__ */ d(bi, {
      muted: !0,
      onLoadedData: m,
      stream: k
    }), /* @__PURE__ */ d("canvas", {
      ref: u,
      height: n == null ? void 0 : n.height,
      width: n == null ? void 0 : n.width
    }, a.pipeline)]
  });
});
ko.displayName = "VirtualBackgroundViewerInner";
const Uu = /* @__PURE__ */ g(() => {
  const e = A(ce), n = e.state === "initial" || e.state === "video-track-ended" ? null : e.state === "background-blur-initializing" || e.state === "background-blur-ready" ? e.enabled ? "blur" : null : (e.state === "background-image-initializing" || e.state === "background-image-ready") && e.enabled ? `image-${e.backgroundImage}` : null;
  return n != null ? /* @__PURE__ */ d(ko, {}, n) : null;
});
Uu.displayName = "VirtualBackgroundViewer";
const $u = /* @__PURE__ */ g(() => {
  const [e, n] = H(!1), i = me(), a = E(() => {
    Fd();
  }, []), t = A(xn), l = P(() => e || t === re.Votes ? "white" : i.colors.gray[400], [e, t, i.colors.gray]), o = P(() => e || t === re.Votes ? "rgba(255, 255, 255, .12)" : "transparent", [t, e]), s = A(Mr);
  return /* @__PURE__ */ f(He, {
    _focus: {
      bg: o
    },
    _hover: {
      bg: o
    },
    _pressed: {
      bg: o
    },
    bg: o,
    borderRadius: "32",
    h: "48px",
    onHoverIn: () => n(!0),
    onHoverOut: () => n(!1),
    onPress: a,
    shadow: "1",
    w: "48px",
    children: [/* @__PURE__ */ d(Ca, {
      fill: l
    }), s > 0 ? /* @__PURE__ */ d(_, {
      alignItems: "center",
      backgroundColor: "red.100",
      borderRadius: "50%",
      color: "white",
      justifyContent: "center",
      position: "absolute",
      right: -5,
      top: -5,
      w: "2ch",
      children: /* @__PURE__ */ d(b, {
        fontSize: 10,
        children: s
      })
    }) : null]
  });
});
$u.displayName = "ControlActiveVotesToggle";
const Bu = "_container_1tydh_1", zu = "_panel_1tydh_11", ju = "_openPanel_1tydh_1", Hu = "_header_1tydh_40", Wu = "_footer_1tydh_47", Gu = "_body_1tydh_51", qu = "_title_1tydh_58", Xu = "_buttonClose_1tydh_65", Yu = "_label_1tydh_87", Ku = "_group_1tydh_95", Ju = "_input_1tydh_99", Qu = "_inputFocused_1tydh_112", Zu = "_inputWrap_1tydh_117", ek = "_inputEmpty_1tydh_128", nk = "_inputNotEmpty_1tydh_136", ik = "_inputAdd_1tydh_140", ve = {
  container: Bu,
  panel: zu,
  openPanel: ju,
  header: Hu,
  footer: Wu,
  body: Gu,
  title: qu,
  buttonClose: Xu,
  label: Yu,
  group: Ku,
  input: Ju,
  inputFocused: Qu,
  inputWrap: Zu,
  inputEmpty: ek,
  inputNotEmpty: nk,
  inputAdd: ik
}, jt = z.object({
  answers: z.array(z.object({
    value: z.string()
  })).min(2),
  // conditions: z
  //   .object({
  //     terminate: z
  //       .object({
  //         completion: z.boolean(),
  //         majority: z.boolean(),
  //         time: z.date(),
  //       })
  //       .strict(),
  //   })
  //   .strict(),
  title: z.string().min(1)
}).strict(), ak = /* @__PURE__ */ g(() => A(wr) ? /* @__PURE__ */ d(vo, {}) : null);
ak.displayName = "CreateVote";
const vo = /* @__PURE__ */ g(() => {
  const {
    t: e
  } = le(), n = E(() => {
    jn(!1);
  }, []), {
    control: i,
    formState: {
      errors: a,
      isSubmitting: t,
      isValid: l
    },
    handleSubmit: o,
    register: s,
    setValue: r
  } = _n({
    defaultValues: {
      answers: [{
        value: ""
      }],
      /* conditions: { terminate: {} }, */
      title: ""
    },
    mode: "all",
    resolver: Kn(jt)
  }), {
    append: c,
    fields: u,
    remove: S
  } = sd({
    control: i,
    name: "answers"
  }), [m, {
    error: p,
    loading: k
  }] = Cn(kd), F = !l || t, v = E(async (N) => {
    const y = jt.parse(N);
    await m({
      variables: {
        cases: y.answers.filter((T) => T.value.trim() !== "").map((T) => T.value),
        conditions: {
          terminate: {
            completion: !0,
            majority: !0
          }
        },
        multiple: !1,
        title: y.title
      }
    }), jn(!1);
  }, [m]);
  return /* @__PURE__ */ f("div", {
    className: ve.container,
    children: [/* @__PURE__ */ f("div", {
      className: ve.panel,
      children: [/* @__PURE__ */ f("div", {
        className: ve.header,
        children: [/* @__PURE__ */ d("div", {
          className: ve.title,
          children: e("ROOM.NEW_VOTING", {
            defaultValue: "Новое голосование"
          })
        }), /* @__PURE__ */ d("button", {
          className: ve.buttonClose,
          onClick: n,
          type: "button",
          children: /* @__PURE__ */ d(wt, {
            height: "10",
            width: "10"
          })
        })]
      }), /* @__PURE__ */ f("div", {
        className: ve.body,
        children: [/* @__PURE__ */ f("div", {
          className: ve.group,
          children: [/* @__PURE__ */ d("div", {
            className: ve.label,
            children: e("LABEL.QUESTION", {
              defaultValue: "Вопрос"
            })
          }), /* @__PURE__ */ d("div", {
            ...s("title"),
            contentEditable: !0,
            className: xe(ve.input),
            onInput: (N) => {
              r("title", N.currentTarget.textContent ?? "", {
                shouldDirty: !0,
                shouldTouch: !0,
                shouldValidate: !0
              });
            },
            tabIndex: -1
          })]
        }), /* @__PURE__ */ d("div", {
          className: ve.label,
          children: e("LABEL.ANSWERS_VARIANTS", {
            defaultValue: "Варианты ответа"
          })
        }), u.map((N, y) => /* @__PURE__ */ f("div", {
          className: ve.inputWrap,
          children: [/* @__PURE__ */ d("div", {
            ...s(`answers.${y}.value`),
            contentEditable: !0,
            className: xe(ve.input),
            onFocus: (T) => {
              y === u.length - 1 && c({
                value: ""
              }, {
                shouldFocus: !1
              });
            },
            onInput: (T) => {
              r(`answers.${y}.value`, T.currentTarget.textContent ?? "", {
                shouldDirty: !0,
                shouldTouch: !0,
                shouldValidate: !0
              });
            },
            tabIndex: 0
          }), /* @__PURE__ */ d("button", {
            className: ve.buttonClose,
            onClick: () => S(y),
            type: "button",
            children: /* @__PURE__ */ d(wt, {
              height: "10",
              width: "10"
            })
          })]
        }, N.id))]
      }), /* @__PURE__ */ d("div", {
        className: ve.footer,
        children: /* @__PURE__ */ d(He, {
          bg: F ? "#19328F" : "blue.50",
          disabled: F,
          onPress: o(v),
          variant: "primary",
          children: /* @__PURE__ */ d(b, {
            color: "white",
            fontSize: "14",
            fontWeight: "500",
            lineHeight: "24",
            opacity: F ? 0.3 : 1,
            children: e("BUTTON.CREATE", {
              defaultValue: "Создать"
            })
          })
        })
      })]
    }), p ? /* @__PURE__ */ d(hi, {
      error: p,
      onOk: n
    }) : null]
  });
});
vo.displayName = "CreateVotingContainer";
const So = /* @__PURE__ */ g(({
  vote: e
}) => {
  const {
    currentMeetSpaceMyPermissions: n,
    pending: i
  } = A({
    currentMeetSpaceMyPermissions: cn,
    pending: _t.pending
  }), a = !n.has(K.PermissionChannelVoteControl), t = E(() => {
    _t(e);
  }, [e]);
  return /* @__PURE__ */ d(us, {
    size: "xs",
    children: /* @__PURE__ */ d(rn, {
      disabledText: i ? "загружается" : e.terminated != null ? "Голосование уже закончено" : a ? "Нет прав" : void 0,
      onPress: t,
      children: "Завершить голосование"
    })
  });
});
So.displayName = "VoteListItemMenu";
const tk = "_container_b0y1t_1", dk = "_author_b0y1t_8", lk = "_authorAvatar_b0y1t_14", ok = "_content_b0y1t_20", sk = "_control_b0y1t_26", rk = "_label_b0y1t_32", mk = "_controlRadio_b0y1t_44", ck = "_controlAside_b0y1t_57", uk = "_checkbox_b0y1t_60", kk = "_controlResult_b0y1t_64", vk = "_controlResultLine_b0y1t_74", Sk = "_iconCheck_b0y1t_81", pk = "_controlContent_b0y1t_93", Nk = "_footer_b0y1t_98", fk = "_menu_b0y1t_105", ie = {
  container: tk,
  author: dk,
  authorAvatar: lk,
  content: ok,
  control: sk,
  label: rk,
  controlRadio: mk,
  controlAside: ck,
  checkbox: uk,
  controlResult: kk,
  controlResultLine: vk,
  iconCheck: Sk,
  controlContent: pk,
  footer: Nk,
  menu: fk
}, po = /* @__PURE__ */ g(({
  vote: e
}) => {
  var s;
  const n = e.cases.reduce((r, c) => r + c.count, 0), i = !e.terminated && e.cases.every((r) => !r.me), {
    data: a,
    error: t,
    loading: l
  } = qn(Ti, {
    variables: {
      id: e.member
    }
  }), o = a == null ? void 0 : a.meet.member.get;
  return /* @__PURE__ */ f("div", {
    className: ie.container,
    children: [/* @__PURE__ */ d("div", {
      className: ie.menu,
      children: /* @__PURE__ */ d(So, {
        vote: e
      })
    }), o != null ? /* @__PURE__ */ f("div", {
      className: ie.author,
      children: [/* @__PURE__ */ d("div", {
        className: ie.authorAvatar,
        children: /* @__PURE__ */ d(Fa, {
          h: "32px",
          source: {
            uri: ((s = o.user) == null ? void 0 : s.avatar.uri) ?? pi(o.id)
          },
          w: "32px"
        })
      }), /* @__PURE__ */ d(b, {
        color: "text.100",
        fontSize: "14",
        fontWeight: "500",
        lineBreakMode: "tail",
        lineHeight: "24",
        numberOfLines: 1,
        children: nn(o)
      })]
    }) : null, /* @__PURE__ */ f("div", {
      className: ie.content,
      children: [/* @__PURE__ */ d(b, {
        color: "white",
        display: "flex",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "18",
        mb: "3",
        children: e.title
      }), i ? /* @__PURE__ */ d(No, {
        vote: e
      }) : /* @__PURE__ */ d(Fo, {
        totalVotes: n,
        vote: e
      }), /* @__PURE__ */ f("div", {
        className: ie.footer,
        children: [n === 0 ? /* @__PURE__ */ d(b, {
          color: "text.100",
          fontSize: "12",
          fontWeight: "500",
          lineHeight: "20",
          children: "нет голосов"
        }) : null, /* @__PURE__ */ d(b, {
          color: "text.100",
          fontSize: "12",
          fontWeight: "500",
          lineHeight: "20",
          ml: "auto",
          children: qo(Xt(e.created))
        })]
      })]
    }), /* @__PURE__ */ d(hi, {
      error: t
    })]
  });
});
po.displayName = "VoteListItem";
const No = /* @__PURE__ */ g(({
  vote: e
}) => /* @__PURE__ */ d(be, {
  children: e.cases.map((n, i) => /* @__PURE__ */ d(fo, {
    index: i,
    multiple: e.multiple,
    text: n.name,
    voteId: e.id
  }, `${e.id}_${n.name}`))
}));
No.displayName = "Inputs";
const fo = /* @__PURE__ */ g(({
  index: e,
  multiple: n,
  text: i,
  voteId: a
}) => {
  const t = E(() => Pd({
    cases: [e],
    voteId: a
  }), [e, a]);
  return /* @__PURE__ */ f("label", {
    className: xe(ie.control, ie.label),
    children: [/* @__PURE__ */ d("input", {
      name: "name1",
      onChange: t,
      onSelect: t,
      type: "radio"
    }), /* @__PURE__ */ d("div", {
      className: ie.controlAside,
      children: /* @__PURE__ */ d("div", {
        className: xe(ie.controlRadio, n && ie.checkbox)
      })
    }), /* @__PURE__ */ d("div", {
      className: ie.controlContent,
      children: /* @__PURE__ */ d(b, {
        color: "gray.400",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "20",
        children: i
      })
    })]
  });
});
fo.displayName = "AnswerInput";
const Fo = /* @__PURE__ */ g(({
  totalVotes: e,
  vote: n
}) => /* @__PURE__ */ d(be, {
  children: n.cases.map((i) => {
    const a = i.count, t = e > 0 ? Math.floor(a / e * 100) : 0;
    return /* @__PURE__ */ f("div", {
      className: xe(ie.control, ie.result),
      children: [/* @__PURE__ */ d("div", {
        className: ie.controlAside,
        children: /* @__PURE__ */ d(b, {
          color: "text.100",
          fontSize: "12",
          fontWeight: "500",
          lineHeight: "20",
          children: `${t}%`
        })
      }), /* @__PURE__ */ f("div", {
        className: ie.controlContent,
        children: [/* @__PURE__ */ d(b, {
          color: "gray.400",
          fontSize: "14",
          fontWeight: "500",
          lineHeight: "20",
          children: i.name
        }), /* @__PURE__ */ f("div", {
          className: ie.controlResult,
          children: [i.me ? /* @__PURE__ */ d(Ws, {
            className: ie.iconCheck,
            height: "12",
            width: "12"
          }) : null, /* @__PURE__ */ d("div", {
            className: ie.controlResultLine,
            style: {
              width: `${t}%`
            }
          })]
        })]
      })]
    }, i.name);
  })
}));
Fo.displayName = "Results";
const Fk = /* @__PURE__ */ g(() => {
  const {
    t: e
  } = le(), n = A(Ud), i = E(({
    item: a
  }) => /* @__PURE__ */ d(po, {
    vote: a
  }), []);
  return /* @__PURE__ */ f(sn, {
    flex: 1,
    children: [/* @__PURE__ */ d(ba, {
      bg: "gray.200",
      mb: 3,
      onPress: () => {
        jn(!0);
      },
      children: e("ROOM.CREATE_VOTING", {
        defaultValue: "Создать голосование"
      })
    }), /* @__PURE__ */ d(ga, {
      ItemSeparatorComponent: go,
      data: n,
      flex: 1,
      keyExtractor: gk,
      onEndReached: Vd,
      renderItem: i,
      updateCellsBatchingPeriod: 1
    })]
  });
});
Fk.displayName = "VotesPanel";
const gk = (e) => e.id, go = /* @__PURE__ */ g(() => /* @__PURE__ */ d(_, {
  style: hk.separator
}));
go.displayName = "FlatListItemSeparator";
const hk = En.create({
  separator: {
    backgroundColor: Be.colors.black,
    height: 2,
    width: "100%"
  }
}), bk = z.object({
  defaultCamera: Wr
}).merge(gu), yk = bk.partial(), Tk = Wt((e) => e.map((n) => /* @__PURE__ */ d(gn, {
  value: n.deviceId,
  children: n.label
}, n.deviceId)), () => /* @__PURE__ */ d(zn, {
  size: "sm"
}), () => /* @__PURE__ */ d(zn, {
  size: "sm"
}), (e) => /* @__PURE__ */ d(b, {
  color: "red.100",
  children: String(e)
})), ho = /* @__PURE__ */ g(({
  images: e,
  onChange: n,
  small: i,
  value: a
}) => {
  var r, c, u;
  const [t, l] = H(((r = a.defaultCamera) == null ? void 0 : r.type) ?? "simple");
  J(() => {
    Bd();
  }, []);
  const o = A(Vr), s = E((S) => {
    const m = yk.parse(S);
    n({
      ...a,
      ...m
    });
  }, [n, a]);
  return /* @__PURE__ */ f(_, {
    marginY: 3,
    children: [/* @__PURE__ */ f(_, {
      children: [/* @__PURE__ */ d(b, {
        fontSize: 16,
        mb: 2,
        children: D("SETTINGS.CAMERA.TYPE_SELECTION_LABEL")
      }), /* @__PURE__ */ d(gn.Group, {
        name: "camera_type",
        onChange: (S) => {
          l(S);
        },
        size: 1,
        value: t,
        children: /* @__PURE__ */ f(yt, {
          alignItems: {
            base: "flex-start",
            md: "center"
          },
          direction: {
            base: "column",
            md: "row"
          },
          mb: 4,
          space: 4,
          w: "100%",
          children: [/* @__PURE__ */ d(gn, {
            value: "simple",
            children: D("SETTINGS.CAMERA.SELECTION_TYPE_SIMPLE")
          }), /* @__PURE__ */ d(gn, {
            value: "by_id",
            children: D("SETTINGS.CAMERA.SELECTION_TYPE_DETAILED")
          })]
        })
      })]
    }), t === "simple" ? /* @__PURE__ */ f(Un, {
      onValueChange: (S) => s({
        defaultCamera: {
          facing: S,
          type: "simple"
        }
      }),
      placeholder: "Выберите камеру",
      selectedValue: ((c = a.defaultCamera) == null ? void 0 : c.type) === "simple" ? a.defaultCamera.facing : void 0,
      children: [/* @__PURE__ */ d(Un.Item, {
        label: D("SETTINGS.CAMERA.FACING_USER"),
        value: "user"
      }), /* @__PURE__ */ d(Un.Item, {
        label: D("SETTINGS.CAMERA.FACING_ENVIRONMENT"),
        value: "environment"
      })]
    }) : /* @__PURE__ */ d(_, {
      borderBottomColor: "gray.500",
      borderBottomStyle: "solid",
      borderBottomWidth: "1px",
      pb: 2,
      children: /* @__PURE__ */ d(gn.Group, {
        name: "camera_id",
        onChange: (S) => {
          s({
            defaultCamera: {
              id: S,
              type: "by_id"
            }
          });
        },
        size: 1,
        value: ((u = a.defaultCamera) == null ? void 0 : u.type) === "by_id" ? a.defaultCamera.id : void 0,
        children: /* @__PURE__ */ d(yt, {
          children: Tk(o)
        })
      })
    }), /* @__PURE__ */ d(Hd, {
      images: e,
      onChange: s,
      small: i,
      value: a
    })]
  });
});
ho.displayName = "SettingsCameraForm";
const Ck = /* @__PURE__ */ g(() => {
  const e = {}, [n, i] = H(en());
  J(() => {
    async function t() {
      try {
        const l = await De.enumerateDevices();
        i(he(l.filter((o) => o.kind === "audioinput")));
      } catch {
        i(ka("Нет доступа к устройствам"));
      }
    }
    t();
  }, []);
  const a = Se((t) => t, [], n);
  return /* @__PURE__ */ f(ks, {
    children: [/* @__PURE__ */ d(vs, {
      children: "Микрофон"
    }), /* @__PURE__ */ d(_, {
      children: /* @__PURE__ */ d(gn.Group, {
        accessibilityLabel: "default microphone",
        name: "myRadioGroup",
        onChange: (t) => {
        },
        size: "0.5",
        value: e.defaultCamera,
        children: a.map((t, l) => /* @__PURE__ */ d(Ss, {
          label: t.label === "" ? `Микрофон ${l + 1}` : t.label,
          noBorder: a.length - 1 === l,
          selected: e.defaultMic === t.deviceId,
          value: t.deviceId
        }, t.deviceId))
      })
    })]
  });
});
Ck.displayName = "SettingsMicrophone";
const Mk = /* @__PURE__ */ g((e) => /* @__PURE__ */ d(_, {
  bg: "#141414",
  borderRadius: "12",
  bottom: "100%",
  display: {
    base: "none",
    lg: "flex"
  },
  left: "0",
  mb: "1",
  position: "absolute",
  shadow: "1",
  w: "300px",
  zIndex: 1e3,
  ...e
}));
Mk.displayName = "UsersListDesktop";
const wk = /* @__PURE__ */ g(({
  linkForCopy: e,
  textForCopy: n
}) => {
  const {
    width: i
  } = wn(), a = P(() => ({
    bg: "blue.50",
    borderRadius: {
      base: 0,
      lg: "24"
    },
    color: "white",
    justifyContent: {
      base: "center",
      lg: "flex-start"
    },
    width: {
      base: i,
      lg: "auto"
    }
  }), [i]);
  return /* @__PURE__ */ d(_, {
    flexDirection: "row",
    justifyContent: "flex-start",
    p: {
      lg: 5
    },
    children: /* @__PURE__ */ d(Zt, {
      mobileView: "full",
      textForCopy: e,
      view: a,
      children: /* @__PURE__ */ d(b, {
        color: "white",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "24",
        children: n
      })
    })
  });
});
wk.displayName = "UsersListFooter";
const Gn = /* @__PURE__ */ g(({
  audioDisabled: e,
  avatarUri: n,
  hideControls: i,
  isOrganizer: a,
  nickname: t,
  onAudioClick: l,
  onVideoClick: o,
  permissions: s = /* @__PURE__ */ new Set(),
  videoDisabled: r
}) => {
  const c = pa({
    nickname: t
  }), u = P(() => Sa(n, 48), [n]);
  return /* @__PURE__ */ d(ge, {
    trigger: (S) => /* @__PURE__ */ f(ne, {
      ...S,
      alignItems: "center",
      flexDirection: "row",
      h: "60px",
      px: {
        base: 2,
        lg: 4
      },
      py: "6px",
      children: [/* @__PURE__ */ d(Fa, {
        bg: "rgba(255, 255, 255, 0.08)",
        h: "48px",
        source: u,
        w: "48px",
        children: /* @__PURE__ */ d(b, {
          color: "text.100",
          fontSize: 16,
          fontWeight: 400,
          children: c.toLocaleUpperCase()
        })
      }), /* @__PURE__ */ f(_, {
        flex: "1",
        pl: "3",
        children: [/* @__PURE__ */ d(b, {
          isTruncated: !0,
          color: "gray.400",
          fontSize: "14",
          fontWeight: "500",
          lineHeight: "20px",
          noOfLines: 1,
          children: t
        }), a && /* @__PURE__ */ d(b, {
          isTruncated: !0,
          color: "text.100",
          fontSize: "14",
          fontWeight: "500",
          lineHeight: "20px",
          noOfLines: 1,
          children: "Организатор"
        })]
      }), !i && /* @__PURE__ */ f(be, {
        children: [/* @__PURE__ */ d(ma, {
          Icon: Ta,
          IconDisabled: ad,
          disabled: e ?? !1,
          hasPermission: s.has(K.PermissionChannelMediaAudioSend),
          onClick: l
        }), /* @__PURE__ */ d(ma, {
          Icon: Cs,
          IconDisabled: Ms,
          disabled: r ?? !1,
          hasPermission: s.has(K.PermissionChannelMediaVideoSend),
          onClick: o
        })]
      })]
    }),
    children: /* @__PURE__ */ f(ge.Content, {
      children: [/* @__PURE__ */ d(ge.Arrow, {}), /* @__PURE__ */ d(ge.CloseButton, {}), /* @__PURE__ */ d(ge.Header, {
        children: "Информация"
      }), /* @__PURE__ */ f(ge.Body, {
        children: [/* @__PURE__ */ d(bn, {
          size: "sm",
          children: "Доступы"
        }), /* @__PURE__ */ d(bo, {
          permissions: s
        })]
      })]
    })
  });
});
Gn.displayName = "UsersListItemView";
const bo = /* @__PURE__ */ g(({
  permissions: e
}) => {
  const {
    t: n
  } = le(), i = Xo(K);
  return /* @__PURE__ */ d(Bn, {
    children: i.map((a) => /* @__PURE__ */ d(_, {
      children: /* @__PURE__ */ d(b, {
        children: `${e.has(a) ? "✅" : "❌"} ${n(`BACKEND.PERMISSIONS.${a}`)}`
      })
    }, a))
  });
});
bo.displayName = "PermissionsList";
const ma = /* @__PURE__ */ g(({
  disabled: e,
  hasPermission: n,
  Icon: i,
  IconDisabled: a,
  onClick: t
}) => {
  const l = me();
  return /* @__PURE__ */ d(_, {
    w: "92px",
    children: /* @__PURE__ */ d(gi, {
      onPress: t,
      children: e || !n ? /* @__PURE__ */ d(a, {
        fill: n ? l.colors.gray[400] : l.colors.red[400],
        height: "16",
        opacity: n ? 1 : 0.4,
        width: "16"
      }) : /* @__PURE__ */ d(i, {
        fill: l.colors.gray[400],
        height: "16",
        width: "16"
      })
    })
  });
});
ma.displayName = "DeviceControl";
const yo = /* @__PURE__ */ g(({
  canControlUser: e,
  isOrganizer: n,
  member: i,
  peer: a
}) => {
  var m;
  const t = A(_i), [l, {
    error: o,
    loading: s
  }] = Cn(Zs), r = o, c = P(() => Td(t == null ? void 0 : t.permissions, i), [i, t == null ? void 0 : t.permissions]), u = E(async () => {
    e && !s && (c.has(K.PermissionChannelMediaAudioSend) ? await l({
      variables: {
        id: a.id,
        permissions_deny: [K.PermissionChannelMediaAudioSend]
      }
    }) : await l({
      variables: {
        id: a.id,
        permissions_grant: [K.PermissionChannelMediaAudioSend]
      }
    }));
  }, [e, s, a.id, c, l]), S = E(async () => {
    e && !s && (c.has(K.PermissionChannelMediaVideoSend) ? await l({
      variables: {
        id: a.id,
        permissions_deny: [K.PermissionChannelMediaVideoSend]
      }
    }) : await l({
      variables: {
        id: a.id,
        permissions_grant: [K.PermissionChannelMediaVideoSend]
      }
    }));
  }, [e, s, a.id, c, l]);
  return /* @__PURE__ */ f(be, {
    children: [/* @__PURE__ */ d(Gn, {
      audioDisabled: (a.media.audio ?? ci.MediaAudioOff) === ci.MediaAudioOff,
      avatarUri: ((m = i.user) == null ? void 0 : m.avatar.uri) ?? pi(a.id),
      isOrganizer: n,
      nickname: nn(i),
      onAudioClick: u,
      onVideoClick: S,
      permissions: c,
      videoDisabled: (a.media.video ?? _e.MediaVideoOff) === _e.MediaVideoOff
    }), r != null ? /* @__PURE__ */ d(hi, {
      error: r
    }) : null]
  });
});
yo.displayName = "MeetMemberActivePeer";
const Ek = /* @__PURE__ */ g(() => {
  const {
    width: e
  } = wn(), {
    currentMeetSpaceMembersExceptMe: n,
    video: i
  } = A({
    currentMeetSpaceMembersExceptMe: Va,
    video: ce
  }), a = A(an);
  return /* @__PURE__ */ d(ha, {
    horizontal: !0,
    height: {
      base: 96,
      lg: 112
    },
    children: /* @__PURE__ */ f(Je, {
      alignItems: "center",
      justifyContent: "center",
      minWidth: e,
      px: {
        base: "4px",
        lg: "8px"
      },
      space: {
        base: "4px",
        lg: "8px"
      },
      children: [(a == null ? void 0 : a.peer) != null ? /* @__PURE__ */ d(ca, {
        member: a.member,
        myStream: Lr(i),
        peer: a.peer
      }) : null, n.slice(1).filter((t) => t.peer != null).map((t) => /* @__PURE__ */ d(ca, {
        member: t.member,
        peer: t.peer
      }, t.member.id))]
    })
  });
});
Ek.displayName = "UsersListMobileWithPreviews";
const ca = /* @__PURE__ */ g(({
  member: e,
  myStream: n,
  peer: i
}) => {
  var u;
  const a = mn(), t = (i.media.video ?? _e.MediaVideoOff) !== _e.MediaVideoOff, l = P(() => ({
    style: {
      ...li.rtcView,
      ...a === "base" ? li.rtcViewBase : (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        a === "lg" ? li.rtcViewLG : {}
      ),
      ...t ? {} : li.hidden
    }
  }), [a, t]), o = n ?? i.stream, s = E(() => {
    e != null && st(e.id);
  }, [e]), r = ((u = e == null ? void 0 : e.user) == null ? void 0 : u.avatar.uri) ?? "", c = P(() => r !== "" ? r : pi((e == null ? void 0 : e.id) ?? i.id), [e == null ? void 0 : e.id, i.id, r]);
  return /* @__PURE__ */ d(To, {
    children: /* @__PURE__ */ f(ne, {
      flex: "1",
      onPress: s,
      testID: n != null ? "ULPItem-Pressable-me" : "ULPItem-Pressable",
      children: [i.reaction.gesture === yn ? /* @__PURE__ */ d(zi, {}) : null, /* @__PURE__ */ d(bi, {
        muted: o == null,
        objectFit: "contain",
        stream: Qe.OS === "web" ? o : void 0,
        streamURL: Qe.OS !== "web" ? o == null ? void 0 : o.toURL() : void 0,
        videoProps: l
      }), t ? null : /* @__PURE__ */ d(Co, {
        alt: pa(e),
        uri: c
      }), /* @__PURE__ */ d(Mo, {
        children: n != null ? Yo(e) : nn(e)
      })]
    })
  });
});
ca.displayName = "ULPItem";
const To = /* @__PURE__ */ g((e) => /* @__PURE__ */ d(_, {
  alignItems: "center",
  bg: "#141414",
  borderRadius: "6",
  height: "96px",
  justifyContent: "center",
  position: "relative",
  width: {
    base: "96px",
    lg: "172px"
  },
  ...e
}));
To.displayName = "ULPContainer";
const Co = /* @__PURE__ */ g(({
  alt: e,
  uri: n
}) => /* @__PURE__ */ d(fi, {
  alt: e,
  bg: "#141414",
  borderRadius: {
    base: 12,
    lg: 6
  },
  h: {
    base: "96px",
    lg: "56px"
  },
  source: {
    uri: n
  },
  testID: "Image",
  w: {
    base: "96px",
    lg: "56px"
  }
}));
Co.displayName = "ULPImage";
const Mo = /* @__PURE__ */ g((e) => /* @__PURE__ */ d(b, {
  bg: "rgba(0, 0, 0, 0.64)",
  borderRadius: "16",
  bottom: {
    base: 1,
    lg: 2
  },
  color: "gray.400",
  ellipsizeMode: "tail",
  h: {
    base: "20px",
    lg: "32px"
  },
  left: {
    base: 1,
    lg: 2
  },
  lineHeight: {
    base: "20px",
    lg: "24px"
  },
  numberOfLines: 1,
  position: "absolute",
  px: {
    base: "6px",
    lg: "12px"
  },
  py: {
    base: 0,
    lg: 1
  },
  testID: "nickname",
  textAlign: "center",
  w: {
    base: "88px",
    // base: "64px",
    lg: "156px"
    // lg: "120px",
  },
  ...e
}));
Mo.displayName = "ULPNickname";
const li = En.create({
  hidden: {
    display: "none"
  },
  rtcView: {
    flex: 1,
    minHeight: 0
  },
  rtcViewBase: {
    height: 96,
    width: 96
  },
  rtcViewLG: {
    height: 56,
    width: 56
  }
}), _k = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "query",
    name: {
      kind: "Name",
      value: "MeetSpaceVanityNames"
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "vanity_name"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "list"
                    },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [{
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "VanityName"
                        }
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "User"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "UserType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "attributes"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "settings"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "username"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone_verified"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "avatar"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "dimensions"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "id"
            }
          }, {
            kind: "Field",
            name: {
              kind: "Name",
              value: "uri"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "email"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "id"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "nickname"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "phone"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "premium"
        }
      }]
    }
  }, {
    kind: "FragmentDefinition",
    name: {
      kind: "Name",
      value: "VanityName"
    },
    typeCondition: {
      kind: "NamedType",
      name: {
        kind: "Name",
        value: "VanityNameType"
      }
    },
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "name"
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "owner"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "FragmentSpread",
            name: {
              kind: "Name",
              value: "User"
            }
          }]
        }
      }, {
        kind: "Field",
        name: {
          kind: "Name",
          value: "space_id"
        }
      }]
    }
  }]
}, gS = {
  kind: "Document",
  definitions: [{
    kind: "OperationDefinition",
    operation: "mutation",
    name: {
      kind: "Name",
      value: "MeetSpaceVanityNameRemove"
    },
    variableDefinitions: [{
      kind: "VariableDefinition",
      variable: {
        kind: "Variable",
        name: {
          kind: "Name",
          value: "name"
        }
      },
      type: {
        kind: "NonNullType",
        type: {
          kind: "NamedType",
          name: {
            kind: "Name",
            value: "String"
          }
        }
      }
    }],
    selectionSet: {
      kind: "SelectionSet",
      selections: [{
        kind: "Field",
        name: {
          kind: "Name",
          value: "meet"
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [{
            kind: "Field",
            name: {
              kind: "Name",
              value: "space"
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{
                kind: "Field",
                name: {
                  kind: "Name",
                  value: "vanity_name"
                },
                selectionSet: {
                  kind: "SelectionSet",
                  selections: [{
                    kind: "Field",
                    name: {
                      kind: "Name",
                      value: "remove"
                    },
                    arguments: [{
                      kind: "Argument",
                      name: {
                        kind: "Name",
                        value: "name"
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "name"
                        }
                      }
                    }]
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }]
}, Rk = /* @__PURE__ */ g(() => {
  const e = A(Ea);
  return e.length > 0 ? /* @__PURE__ */ d(Bn, {
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    children: e.map((n) => /* @__PURE__ */ d(Tt, {
      left: 0,
      position: "absolute",
      right: 0,
      status: n.status,
      top: 0,
      zIndex: 1,
      children: /* @__PURE__ */ d(Bn, {
        flexShrink: 1,
        space: 2,
        w: "100%",
        children: /* @__PURE__ */ f(Je, {
          flexShrink: 1,
          justifyContent: "space-between",
          space: 2,
          children: [/* @__PURE__ */ f(Je, {
            flexShrink: 1,
            space: 2,
            children: [/* @__PURE__ */ d(Tt.Icon, {
              mt: "1"
            }), /* @__PURE__ */ d(b, {
              color: "gray.800",
              fontSize: "md",
              children: n.message
            })]
          }), /* @__PURE__ */ d(is, {
            _focus: {
              borderWidth: 0
            },
            _icon: {
              color: "coolGray.600"
            },
            icon: /* @__PURE__ */ d(Kt, {
              size: "3"
            }),
            onPress: () => {
              Nd(n.id);
            },
            variant: "unstyled",
            zIndex: 3
          })]
        })
      })
    }, String(n.message)))
  }) : null;
});
Rk.displayName = "Alerts";
const Wi = /* @__PURE__ */ g(({
  active: e,
  Icon: n,
  onPress: i
}) => {
  const [a, t] = H(!1), l = P(() => a || e ? "white" : Be.colors.gray[400], [e, a]), o = P(() => a || e ? "rgba(255, 255, 255, .12)" : "transparent", [e, a]);
  return /* @__PURE__ */ d(He, {
    _focus: {
      bg: o
    },
    _hover: {
      bg: o
    },
    _pressed: {
      bg: o
    },
    bg: o,
    borderRadius: "32",
    h: "48px",
    onHoverIn: () => t(!0),
    onHoverOut: () => t(!1),
    onPress: i,
    shadow: "1",
    w: "48px",
    children: /* @__PURE__ */ d(n, {
      fill: l,
      height: 24,
      width: 24
    })
  });
});
Wi.displayName = "CallRoomControlButton";
const xk = /* @__PURE__ */ g(() => {
  const e = A(Um), n = E(() => {
    Qn();
  }, []), i = A(xn);
  return /* @__PURE__ */ d(Wi, {
    Icon: e ? ws : id,
    active: i === re.Chat,
    onPress: n
  });
});
xk.displayName = "ControlChatToggle";
const Dk = /* @__PURE__ */ g(() => {
  const e = E(() => {
    Pa();
  }, []), n = A(Id);
  return /* @__PURE__ */ d(Wi, {
    Icon: Es,
    active: n,
    onPress: e
  });
});
Dk.displayName = "ControlReaction";
const Ik = /* @__PURE__ */ g(() => {
  const e = E(() => {
    gd();
  }, []), n = A(xn);
  return /* @__PURE__ */ d(Wi, {
    Icon: _s,
    active: n === re.Chat,
    onPress: e
  });
});
Ik.displayName = "ControlSettingsToggle";
const Ak = /* @__PURE__ */ g(() => {
  const e = A(fe), [n, i] = H(!1), a = me(), t = A(xn), l = E(() => {
    fd();
  }, []), o = P(() => n || t === re.UserList ? "white" : a.colors.gray[400], [t, n, a.colors.gray]), s = P(() => n || t === re.UserList ? "rgba(255, 255, 255, .12)" : "transparent", [t, n]);
  return /* @__PURE__ */ d(_, {
    position: "relative",
    children: /* @__PURE__ */ f(Yn, {
      alignItems: "center",
      bg: s,
      flexDirection: "row",
      height: "12",
      onHoverIn: () => i(!0),
      onHoverOut: () => i(!1),
      onPress: l,
      px: "4",
      width: "auto",
      children: [
        /* @__PURE__ */ d(Rs, {
          fill: o,
          height: "24",
          width: "24"
        }),
        e.length > 0 ? /* @__PURE__ */ d(b, {
          color: o,
          fontSize: "14",
          fontWeight: "500",
          ml: "2",
          children: e.length
        }) : /* @__PURE__ */ d(b, {})
        /* пустой элемент чтобы не вылетала typescript ошибка */
      ]
    })
  });
});
Ak.displayName = "ControlUsersListToggle";
const Ok = /* @__PURE__ */ g(({
  meetSpace: e
}) => {
  const {
    t: n
  } = le(), {
    onCopy: i
  } = as(), a = ts(), t = E(() => (a.show({
    description: n("ROOM.ROOM_LINK_COPIED_TO_CLIPBOARD")
  }), i(Gt(e))), [e, i, n, a]);
  return /* @__PURE__ */ d(rn, {
    onPress: t,
    children: n("ROOM.COPY_LINK")
  });
});
Ok.displayName = "MenuItemCopyRoomLink";
const Vk = /* @__PURE__ */ g(({}) => {
  const {
    t: e
  } = le();
  return /* @__PURE__ */ d(rn, {
    onPress: Pk,
    children: e("ROOM.CREATE_VOTING", {
      defaultValue: "Создать голосование"
    })
  });
});
Vk.displayName = "MenuItemCreateVote";
const Pk = () => {
  jn(!0);
}, Lk = /* @__PURE__ */ g(({}) => {
  const e = A(ct), n = E(() => mt(), []);
  return /* @__PURE__ */ d(rn, {
    onPress: n,
    children: e ? "Остановить запись и распознавание разговора" : "Начать запись и распознавание разговора"
  });
});
Lk.displayName = "MenuItemMeetRecording";
const Uk = /* @__PURE__ */ g(({}) => {
  const {
    t: e
  } = le();
  return /* @__PURE__ */ d(rn, {
    onPress: $k,
    children: e("ROOM.ADD_RESPONSE", {
      defaultValue: "Отправить отклик"
    })
  });
});
Uk.displayName = "MenuItemSendReaction";
const $k = () => {
  Pa();
}, ft = /* @__PURE__ */ g(({
  hasAudioEnabled: e,
  hasVideoEnabled: n,
  small: i
}) => {
  const a = me();
  return /* @__PURE__ */ f(sn, {
    bottom: i ? void 0 : 2,
    position: "absolute",
    right: "2",
    top: i ? 2 : void 0,
    children: [/* @__PURE__ */ d(_, {
      alignItems: "center",
      bg: "rgba(0, 0, 0, 0.64)",
      borderRadius: "16",
      h: "32px",
      justifyContent: "center",
      w: "32px",
      children: e ? /* @__PURE__ */ d(Ta, {
        fill: a.colors.gray[500],
        height: "16",
        width: "16"
      }) : /* @__PURE__ */ d(ad, {
        fill: a.colors.gray[500],
        height: "16",
        width: "16"
      })
    }), /* @__PURE__ */ d(_, {
      alignItems: "center",
      bg: "rgba(0, 0, 0, 0.64)",
      borderRadius: "16",
      h: "32px",
      justifyContent: "center",
      w: "32px",
      children: n ? /* @__PURE__ */ d(nd, {
        fill: a.colors.gray[500],
        height: "16",
        width: "16"
      }) : /* @__PURE__ */ d(xs, {
        fill: a.colors.gray[500],
        height: "16",
        width: "16"
      })
    })]
  });
});
ft.displayName = "PeerMediaIndicators";
const Ft = /* @__PURE__ */ g(({
  children: e,
  fullscreen: n,
  height: i,
  onPress: a,
  speaking: t,
  width: l
}) => /* @__PURE__ */ d(ne, {
  flex: n ? 1 : void 0,
  height: n ? "auto" : i,
  onPress: a,
  p: {
    base: 1,
    lg: 2
  },
  testID: "VideoContainer",
  width: n ? "auto" : l,
  children: /* @__PURE__ */ f(Fi, {
    bg: "#141414",
    borderRadius: "12",
    flex: "1",
    position: "relative",
    zIndex: "3",
    children: [/* @__PURE__ */ d(_, {
      borderColor: t ? "blue.50" : "gray.800",
      borderRadius: "12",
      borderStyle: "solid",
      borderWidth: t ? "2" : "1",
      bottom: "0",
      left: "0",
      position: "absolute",
      right: "0",
      top: "0"
    }), e]
  })
}));
Ft.displayName = "VideoContainer";
const Bk = /* @__PURE__ */ g(({
  cardHeight: e,
  cardWidth: n,
  enableVideoRendering: i,
  fullscreen: a,
  hasAudioEnabled: t,
  hasVideoEnabled: l,
  hideConnectionDetails: o,
  isHidden: s,
  memberId: r,
  peerId: c,
  reaction: u,
  small: S = !1,
  speaking: m,
  stream: p
}) => {
  var T;
  const {
    data: k
  } = qn(Ti, {
    skip: r == null,
    variables: {
      id: r
    }
  }), F = k == null ? void 0 : k.meet.member.get, v = E(() => {
    c != null && st(c);
  }, [c]), N = P(() => ({
    style: {
      ...oi.rtcView,
      ...l ? {} : oi.hidden
    }
  }), [l]), y = nn(F);
  return /* @__PURE__ */ f(Ft, {
    fullscreen: a ?? !1,
    height: e,
    onPress: v,
    speaking: m,
    width: n,
    children: [(u == null ? void 0 : u.gesture) === yn ? /* @__PURE__ */ d(zi, {}) : null, l ? null : /* @__PURE__ */ d(ed, {
      nickname: y,
      uri: (T = F == null ? void 0 : F.user) == null ? void 0 : T.avatar.uri
      /* ?? makeGravatarUri(peerId) */
      // showName={!isMe}
    }), i && /* @__PURE__ */ d(
      bi,
      {
        objectFit: "contain",
        stream: Qe.OS === "web" ? p : void 0,
        streamURL: Qe.OS !== "web" ? p.toURL() : void 0,
        style: [oi.rtcView, !l || s ? oi.hidden : void 0],
        videoProps: N
      }
    ), l && /* @__PURE__ */ d(_, {
      bottom: "2",
      flexDirection: "row",
      justifyContent: "center",
      left: S ? 0 : "48px",
      position: "absolute",
      right: S ? 0 : "48px",
      zIndex: "3",
      children: /* @__PURE__ */ d(b, {
        bg: "rgba(0, 0, 0, .64)",
        borderRadius: "32",
        color: "gray.400",
        ellipsizeMode: "tail",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "24",
        maxWidth: "100%",
        numberOfLines: 1,
        px: "4",
        py: "1",
        children: y
      })
    }), /* @__PURE__ */ d(Nt, {
      peerId: c,
      showDetails: !o && (l || t),
      small: S
    }), /* @__PURE__ */ d(ft, {
      hasAudioEnabled: t,
      hasVideoEnabled: l,
      small: S
    })]
  });
});
Bk.displayName = "PeerCard";
const oi = En.create({
  hidden: {
    display: "none"
  },
  rtcView: {
    height: "100%",
    width: "100%"
  }
}), zk = /* @__PURE__ */ g(({
  avatarUri: e,
  cardHeight: n,
  cardWidth: i,
  enableVideoRendering: a,
  fullscreen: t,
  hasAudioEnabled: l,
  hasVideoEnabled: o,
  hideConnectionDetails: s,
  isHidden: r,
  myPeer: c,
  nickname: u,
  small: S = !1,
  speaking: m,
  stream: p
}) => {
  const {
    t: k
  } = le(), F = me(), v = E(() => {
  }, []), N = P(() => ({
    style: {
      ...Ln.rtcView,
      ...o ? {} : Ln.hidden
    }
  }), [o]), y = u !== "" ? u : k("ROOM.ANONYMOUS_USER"), T = P(() => [Ln.rtcView, !o || r ? Ln.hidden : void 0], [o, r]);
  return /* @__PURE__ */ f(Ft, {
    fullscreen: t ?? !1,
    height: n,
    onPress: v,
    speaking: m,
    width: i,
    children: [(c == null ? void 0 : c.reaction.gesture) === yn ? /* @__PURE__ */ d(zi, {}) : null, o ? null : /* @__PURE__ */ d(ed, {
      nickname: y,
      small: S,
      uri: e
      // showName={!isMe}
    }), a && /* @__PURE__ */ d(bi, {
      muted: !0,
      objectFit: "contain",
      stream: Qe.OS === "web" ? p : void 0,
      streamURL: Qe.OS !== "web" ? p.toURL() : void 0,
      style: T,
      videoProps: N
    }), o && // <Text
    //   color="white"
    //   fontSize={{
    //     base: 20,
    //     lg: 24,
    //   }}
    //   fontWeight={500}
    //   lineHeight={24}
    //   m={6}
    //   textAlign="center"
    // >
    //   {nickname1}
    // </Text>
    /* @__PURE__ */ f(_, {
      bottom: "2",
      flexDirection: "row",
      justifyContent: "center",
      left: "48px",
      position: "absolute",
      right: "48px",
      zIndex: "3",
      children: [(c == null ? void 0 : c.reaction.gesture) === yn ? /* @__PURE__ */ d(_, {
        mr: "2",
        children: /* @__PURE__ */ d(Ca, {
          fill: F.colors.gray[500],
          height: "16",
          style: Ln.iconRaisedHand,
          width: "16"
        })
      }) : null, /* @__PURE__ */ d(b, {
        bg: "rgba(0, 0, 0, .64)",
        borderRadius: "32",
        color: "gray.400",
        ellipsizeMode: "tail",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "24",
        maxWidth: "100%",
        numberOfLines: 1,
        px: "4",
        py: "1",
        children: y
      })]
    }), /* @__PURE__ */ d(Nt, {
      showDetails: !s && (l || o),
      small: S
    }), /* @__PURE__ */ d(ft, {
      hasAudioEnabled: l,
      hasVideoEnabled: o,
      small: S
    })]
  });
});
zk.displayName = "SelfPeerCard";
const Ln = En.create({
  hidden: {
    display: "none"
  },
  iconRaisedHand: {
    marginRight: 8
  },
  rtcView: {
    aspectRatio: 1,
    minHeight: 0,
    minWidth: 0,
    objectFit: "contain"
  }
}), wo = /* @__PURE__ */ g(({
  isOrganizer: e,
  member: n
}) => {
  var i;
  return /* @__PURE__ */ d(Gn, {
    hideControls: !0,
    avatarUri: ((i = n.user) == null ? void 0 : i.avatar.uri) ?? pi(n.id),
    isOrganizer: e,
    nickname: nn(n)
  });
});
wo.displayName = "MeetMemberInactive";
const jk = /* @__PURE__ */ g(() => {
  var p;
  const {
    t: e
  } = le(), n = Ni(), {
    anonymousNickname: i,
    audio: a,
    currentMeetSpace: t,
    currentMeetSpaceMembersExceptMe: l,
    currentMeetSpaceMyPermissions: o,
    meetSpaceUsersListData: s,
    video: r
  } = A({
    anonymousNickname: Na,
    audio: Ve,
    currentMeetSpace: _i,
    currentMeetSpaceMembersExceptMe: Va,
    currentMeetSpaceMyPermissions: cn,
    meetSpaceUsersListData: du,
    video: ce
  });
  J(() => {
    Ut({
      distanceFromEnd: 5
    });
  }, []);
  const c = P(() => [{
    data: [n != null ? {
      me: n,
      type: "me"
    } : {
      anonymousNickname: i,
      type: "me_anon"
    }, ...l.filter((k) => k.member.presence_state === Uo.MemberPresenceStateOnline && k.peer != null).map((k) => ({
      member: k.member,
      peer: k.peer,
      type: "peer"
    }))],
    title: `${e("ROOM.USERSLIST.MEMBERS_COUNT", {
      defaultValue: "Участников"
    })} (${l.length + 1})`
  }, {
    data: s.map((k) => ({
      member: k,
      type: "not_on_call"
    })),
    title: "Оффлайн"
  }], [n, i, l, e, s]), u = o.has(K.PermissionChannelMediaControl), S = E(({
    item: k
  }) => {
    var F, v, N, y;
    return k.type === "me" ? /* @__PURE__ */ d(Gn, {
      audioDisabled: !a.enabled,
      avatarUri: k.me.avatar.uri,
      isOrganizer: k.me.id === ((F = t == null ? void 0 : t.owner) == null ? void 0 : F.id),
      nickname: k.me.nickname,
      onAudioClick: () => {
      },
      permissions: o,
      videoDisabled: r.state === "initial" || r.state === "video-track-ended" || !r.enabled
    }, k.me.id) : k.type === "me_anon" ? /* @__PURE__ */ d(Gn, {
      audioDisabled: !a.enabled,
      avatarUri: "",
      isOrganizer: !1,
      nickname: k.anonymousNickname ?? "",
      onAudioClick: () => {
      },
      permissions: o,
      videoDisabled: r.state === "initial" || r.state === "video-track-ended" || !r.enabled
    }, "anon") : k.type === "peer" ? /* @__PURE__ */ d(yo, {
      canControlUser: u,
      isOrganizer: k.peer.id === ((v = t == null ? void 0 : t.owner) == null ? void 0 : v.id),
      member: k.member,
      peer: k.peer
    }, k.peer.id) : /* @__PURE__ */ d(wo, {
      isOrganizer: ((N = t == null ? void 0 : t.owner) == null ? void 0 : N.id) === ((y = k.member.user) == null ? void 0 : y.id),
      member: k.member
    });
  }, [a, u, (p = t == null ? void 0 : t.owner) == null ? void 0 : p.id, o, r]), m = P(() => ({
    currentMeetSpaceMembershipInfo: t
  }), [t]);
  return /* @__PURE__ */ d(_, {
    flex: "1",
    flexDirection: "column",
    py: "5",
    children: /* @__PURE__ */ d(ds, {
      extraData: m,
      keyExtractor: Hk,
      onEndReached: (k) => {
        Ut(k);
      },
      renderItem: S,
      renderSectionHeader: ({
        section: k
      }) => /* @__PURE__ */ d(b, {
        color: "text.100",
        fontSize: "14",
        fontWeight: "500",
        lineHeight: "24",
        my: "2",
        px: "4",
        children: k.title
      }),
      sections: c
    })
  });
});
jk.displayName = "UsersList";
const Hk = (e, n) => ((e.type === "me" ? e.me.id : e.type === "me_anon" ? e.anonymousNickname : e.type === "not_on_call" ? e.member.id : e.peer.id) ?? "empty") + n;
var ye = "/src/processes/meet/index.ts";
const hn = h({
  loc: {
    file: ye,
    line: 44,
    column: 2
  },
  name: "eventUserSubscriptionAuthenticationChanged",
  sid: "-e8o6dl:/src/processes/meet/index.ts:eventUserSubscriptionAuthenticationChanged"
}), Eo = h({
  loc: {
    file: ye,
    line: 46,
    column: 52
  },
  name: "eventUserSubscriptionMyPeerIdUpdated",
  sid: "b43djc:/src/processes/meet/index.ts:eventUserSubscriptionMyPeerIdUpdated"
});
gr.on(Eo, (e, n) => he(n));
const _o = h({
  loc: {
    file: ye,
    line: 51,
    column: 49
  },
  name: "eventUserSubscriptionContactAdded",
  sid: "-98cxqg:/src/processes/meet/index.ts:eventUserSubscriptionContactAdded"
}), Ro = h({
  loc: {
    file: ye,
    line: 52,
    column: 51
  },
  name: "eventUserSubscriptionContactRemoved",
  sid: "ji6wlc:/src/processes/meet/index.ts:eventUserSubscriptionContactRemoved"
});
w({
  and: [{
    clock: $n,
    filter: (e) => e.status === va.Success,
    target: Ma
  }],
  or: {
    loc: {
      file: ye,
      line: 54,
      column: 0
    },
    sid: "-lmsyem:/src/processes/meet/index.ts:"
  }
});
Yt.on(hn, (e, n) => n);
cu.on(_o, (e, n) => [...e, n].sort((i, a) => i.nickname.localeCompare(a.nickname))).on(Ro, (e, n) => e.filter((i) => i.id !== n));
const Wk = $(() => Q().subscribe({
  query: su
}).subscribe({
  error: (e) => {
    hn(ka(e));
  },
  next: (e) => {
    var n, i, a, t, l;
    e.data != null && (((n = e.data.user.authentication) == null ? void 0 : n.anonymous) != null && hn(he({
      type: "anonymous"
    })), ((i = e.data.user.authentication) == null ? void 0 : i.authenticated) != null && hn(he({
      type: "authenticated",
      user: e.data.user.authentication.authenticated.user
    })), e.data.user.init != null && Eo(e.data.user.init.peer_id), ((a = e.data.user.contact) == null ? void 0 : a.add) != null && _o(e.data.user.contact.add), ((t = e.data.user.contact) == null ? void 0 : t.remove) != null && Ro(e.data.user.contact.remove), ((l = e.data.user.meet) == null ? void 0 : l.space) != null);
  },
  start: () => {
    hn(Po());
  }
}), {
  loc: {
    file: ye,
    line: 73,
    column: 39
  },
  name: "subscribeToUserEventsFx",
  sid: "-q802y8:/src/processes/meet/index.ts:subscribeToUserEventsFx"
}), hS = Vo(Wk.doneData, null, {
  loc: {
    file: ye,
    line: 116,
    column: 33
  },
  name: "$userSubscription",
  sid: "gu2fbt:/src/processes/meet/index.ts:$userSubscription"
}), xo = $(() => Q().query({
  fetchPolicy: "network-only",
  query: ru
}).then((e) => e.data.user.meet.list), {
  loc: {
    file: ye,
    line: 121,
    column: 37
  },
  name: "fetchUserMeetSpacesFx",
  sid: "-lsu3gk:/src/processes/meet/index.ts:fetchUserMeetSpacesFx"
});
w({
  and: [{
    clock: hn,
    filter: (e) => Se((n) => n.type === "authenticated", !1, e),
    target: xo
  }],
  or: {
    loc: {
      file: ye,
      line: 126,
      column: 0
    },
    sid: "-jts7lo:/src/processes/meet/index.ts:"
  }
});
Aa.on(xo.doneData, (e, n) => he({
  ref: Se((i) => (n.spaces.forEach((a) => {
    i.ref.set(a.space.id, a.space);
  }), i.ref), /* @__PURE__ */ new Map(), e)
}));
const Gk = w({
  and: [{
    clock: _a,
    source: {
      currentMeetSpaceMemberMe: an
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn: (e, n) => {
      var i;
      return ((i = n.remove) == null ? void 0 : i.members) != null ? n.remove.members.find((a) => {
        var t, l;
        return a.id === ((l = (t = e.currentMeetSpaceMemberMe) == null ? void 0 : t.member) == null ? void 0 : l.id);
      }) ?? null : null;
    }
  }],
  or: {
    loc: {
      file: ye,
      line: 147,
      column: 44
    },
    name: "eventSubscriptionDisconnectReceived",
    sid: "3ugf1v:/src/processes/meet/index.ts:eventSubscriptionDisconnectReceived"
  }
}).filter({
  fn: ze
});
w({
  and: [{
    clock: Gk,
    source: {
      audio: Ve,
      peers: fe,
      rtcPeerConnection: te,
      video: ce
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn(e, n) {
      return {
        ...e,
        exitReason: qk(n.source)
      };
    },
    target: Pe
  }],
  or: {
    loc: {
      file: ye,
      line: 163,
      column: 0
    },
    sid: "-hz93sz:/src/processes/meet/index.ts:"
  }
});
w({
  and: [{
    clock: ui,
    source: {
      audio: Ve,
      peers: fe,
      rtcPeerConnection: te,
      video: ce
    },
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    fn(e, n) {
      return {
        ...e,
        exitReason: n
      };
    },
    target: Pe
  }],
  or: {
    loc: {
      file: ye,
      line: 181,
      column: 0
    },
    sid: "-h29g7b:/src/processes/meet/index.ts:"
  }
});
function qk(e) {
  return e.ban != null ? {
    message: e.ban.reason,
    type: Ye.Ban
  } : e.kick != null ? {
    message: e.kick.reason,
    type: Ye.Kick
  } : e.conflict != null ? {
    message: e.conflict.device_id != null ? D("ROOM.EXIT_REASON.DEVICE_CONFLICT") : e.conflict.user_id != null ? D("ROOM.EXIT_REASON.USER_CONFLICT") : D("ROOM.EXIT_REASON.GENERIC_DISCONNECT"),
    type: Ye.DisconnectedFromBackend
  } : {
    message: D("ROOM.EXIT_REASON.GENERIC_DISCONNECT"),
    type: Ye.DisconnectedFromBackend
  };
}
export {
  Mr as $activeVotesCount,
  Ea as $alerts,
  xn as $asidePanel,
  Ve as $audio,
  Jr as $audioMode,
  Kr as $audioPending,
  Ll as $chatContextMenuOpened,
  Um as $chatHasNewMessages,
  Ml as $chatMessages,
  vn as $connectionState,
  Kv as $controlsOpened,
  _i as $currentMeetSpace,
  an as $currentMeetSpaceMe,
  Oa as $currentMeetSpaceMembers,
  Va as $currentMeetSpaceMembersExceptMe,
  cn as $currentMeetSpaceMyPermissions,
  pn as $currentMessage,
  Jn as $currentSpaceId,
  oo as $data,
  xi as $devices,
  Ci as $fatalErrors,
  Nl as $fileAttachments,
  Ya as $fileAttachmentsInternal,
  cS as $focusedPeer,
  In as $iceConnectionState,
  Wa as $iceGatheringState,
  Ga as $iceServers,
  dS as $isOffering,
  ct as $isRecording,
  wr as $isVoteCreateFormVisible,
  hm as $linkQuality,
  Qv as $loading,
  vu as $meSpeaking,
  pS as $meetSpaceUserListCursor,
  du as $meetSpaceUsersListData,
  Aa as $meetSpacesBySpaceId,
  Ia as $meetSpacesMembershipBySpaceId,
  Rd as $meetSubscriptionStatus,
  Bm as $messageDeleteId,
  Pr as $microphoneDevices,
  cu as $myContacts,
  gr as $myPeerId,
  Dd as $peerIdByTrackId,
  Hi as $peerStats,
  fe as $peers,
  Su as $peersSpeaking,
  ku as $peersWithActiveAudioTrack,
  Ui as $receivedMessages,
  Al as $receivedMessagesEditedAt,
  cr as $roomExitReason,
  Id as $roomReactionPanelOpened,
  yr as $roomReactions,
  te as $rtcPeerConnection,
  vi as $selectedBackgroundType,
  Ua as $selectedCamera,
  Xr as $selectedMicrophone,
  Yr as $selectedSpeakers,
  rS as $sendingMessage,
  nl as $showNewAudioDeviceSelect,
  tr as $showRateACallModal,
  aS as $showVirtualBackgroundCanvas,
  Dn as $signalingState,
  iS as $speakerDevices,
  Cl as $unconfirmedMessages,
  hS as $userSubscription,
  ce as $video,
  Vr as $videoDevices,
  qr as $videoIsOn,
  Jd as $videoPending,
  Ud as $votes,
  Di as $webrtcInitialized,
  tS as $webrtcUserFriendlyConnectionStatus,
  Rk as Alerts,
  re as AsidePanelItem,
  Si as AttachedFile,
  uu as AudioDeviceChangedModal,
  Er as CallsView,
  Sc as ChatAddAttachments,
  Ac as ChatBody,
  dt as ChatBodyPanel,
  wm as ChatDeleteMessageDocument,
  Mm as ChatEditMessageDocument,
  la as ChatItemText,
  oa as ChatItemTime,
  Vc as ChatMessageDelete,
  Bl as ChatMessageEditPreview,
  _v as ChatMessageEmbedFragmentDoc,
  Ev as ChatMessageEmbedPreviewFragmentDoc,
  xv as ChatMessageFragmentDoc,
  ot as ChatMessageItem,
  Pc as ChatMobileHead,
  Uc as ChatPanel,
  $c as ChatPanelHead,
  Em as ChatReactToMessageDocument,
  xt as ChatSendMessageDocument,
  _m as ChatUnreactToMessageDocument,
  it as ContextMenuOverlay,
  $u as ControlActiveVotesToggle,
  oS as ControlAudio,
  sS as ControlCamera,
  bm as ControlChangeCamera,
  xk as ControlChatToggle,
  _r as ControlHangup,
  ym as ControlMode,
  Dk as ControlReaction,
  Cm as ControlScreenShare,
  Ik as ControlSettingsToggle,
  Ak as ControlUsersListToggle,
  ak as CreateVote,
  La as DEFAULT_CAMERA,
  Od as EMOJI_LIFETIME,
  Bc as EditMeetSpace,
  lo as EditShortMeetSpaceSettings,
  wv as EmbeddedChatMessageFragmentDoc,
  Ti as FetchMemberByIdDocument,
  zl as FilePicker,
  Jc as FlyingReactions,
  Vv as IceServersFragmentDoc,
  rt as InviteToCallButton,
  zc as InviteToCallCard,
  pe as LinkQuality,
  zv as MeeetEventSpaceFragmentDoc,
  Qs as MeetDocument,
  Dv as MeetEventChatFragmentDoc,
  Rv as MeetEventChatMessageEditFragmentDoc,
  Av as MeetEventMemberFragmentDoc,
  Pv as MeetEventRtcFragmentDoc,
  Uv as MeetEventSelfFragmentDoc,
  Gv as MeetEventUserFragmentDoc,
  Xv as MeetEventVoteFragmentDoc,
  ir as MeetFetchVotesDocument,
  yo as MeetMemberActivePeer,
  Iv as MeetMemberFragmentDoc,
  Lv as MeetMemberSpaceFragmentDoc,
  md as MeetMySpacesListDocument,
  SS as MeetRecordingDocument,
  Zc as MeetRecordingStartDocument,
  eu as MeetRecordingStopDocument,
  vS as MeetRecordingsListDocument,
  cd as MeetSpaceByIdOrVanityNameDocument,
  $v as MeetSpaceConditionsContactsFragmentDoc,
  Ye as MeetSpaceExitReason,
  Bv as MeetSpaceFragmentDoc,
  Wv as MeetSpaceLeaveDocument,
  Zs as MeetSpaceMemberControlDocument,
  Dr as MeetSpaceMinHeightLG,
  Gc as MeetSpaceReaction,
  Hv as MeetSpaceRemoveDocument,
  nu as MeetSpaceRuntimeSettings,
  ud as MeetSpaceSaveDocument,
  gS as MeetSpaceVanityNameRemoveDocument,
  _k as MeetSpaceVanityNamesDocument,
  er as MeetUpdateRtcReactionDocument,
  ar as MeetVoteCastDocument,
  qv as MeetVoteFragmentDoc,
  kd as MeetVoteStartDocument,
  Yv as MeetVoteTerminateDocument,
  Ok as MenuItemCopyRoomLink,
  Vk as MenuItemCreateVote,
  Lk as MenuItemMeetRecording,
  Qc as MenuItemRaiseHand,
  Uk as MenuItemSendReaction,
  tt as MessageInputPanel,
  Kl as MessagesList,
  nr as MyMeetCalendarDocument,
  Or as PasswordModal,
  Bk as PeerCard,
  ft as PeerMediaIndicators,
  zi as RaiseHandPopup,
  ou as RateACallModal,
  kS as RecordingFragmentDoc,
  uS as RecordingTranscriptFragmentDoc,
  Js as RequestChatHistoryDocument,
  xr as RoomChatWidth,
  Rr as RoomControlsHeight,
  Yi as RoomReactionHeight,
  Ov as RtcMemberFragmentDoc,
  zk as SelfPeerCard,
  jr as SendAnswerDocument,
  Br as SendIceCandidateDocument,
  zr as SendOfferDocument,
  ho as SettingsCameraForm,
  Ck as SettingsMicrophone,
  Nt as StatsPopover,
  Wd as UpdateMyMediaStatusDocument,
  su as UserDocument,
  ru as UserMeetSpacesDocument,
  jk as UsersList,
  Mk as UsersListDesktop,
  wk as UsersListFooter,
  Gn as UsersListItemView,
  Ek as UsersListMobileWithPreviews,
  jv as VanityNameFragmentDoc,
  Ft as VideoContainer,
  Uu as VirtualBackgroundViewer,
  po as VoteListItem,
  Fk as VotesPanel,
  $e as WebRTCConnectionStatus,
  rl as addIceCandidateFx,
  kn as audioStateReducerFx,
  gu as backgroundSettingsSchema,
  Pd as castVoteFx,
  Vi as closeRTCPeertConnectionFx,
  fr as closeSubscriptionFx,
  Wr as defaultCameraSchema,
  et as deleteMessageFx,
  _t as endVoteFx,
  ur as eventAsidePanelClosed,
  Ba as eventAudioModeChanged,
  Ii as eventAudioTrackEnded,
  Sd as eventCallRatedSuccessfully,
  Jv as eventCallViewFullToggle,
  Ja as eventChatMessageReacted,
  bl as eventChatMessageUnreacted,
  Za as eventChatScrollEndReached,
  Pl as eventCloseContextMenu,
  lr as eventControlsToggle,
  Rl as eventCurrentMesageChanged,
  ia as eventCurrentMesageCursorPositionChanged,
  xl as eventCurrentMesageEmojiInserted,
  $d as eventDeviceListChanged,
  Bd as eventDeviceListInitialized,
  or as eventError,
  Li as eventFileAttachmentsAdded,
  ul as eventFileAttachmentsRemoved,
  kl as eventFileAttachmentsReset,
  vl as eventFileAttachmentsUploadFailed,
  Sl as eventFileAttachmentsUploadFinished,
  pl as eventFileAttachmentsUploadProgressUpdated,
  wa as eventGraphqlSubscriptionComplete,
  dr as eventGraphqlSubscriptionStarted,
  Nd as eventHideAlert,
  Ut as eventLoadMoreMeetUsers,
  Vd as eventLoadMoreVotes,
  kt as eventMeSpeakingUpdate,
  Ne as eventMeetSpaceCleanup,
  pd as eventMeetSpaceExitFinished,
  ue as eventMeetSpaceJoinRequested,
  Ma as eventMeetSpaceJoinedSuccessfully,
  ui as eventMeetSpaceLeave,
  vd as eventMeetSpaceRestoreCall,
  Ed as eventMeetSubscriptionChatReceived,
  _a as eventMeetSubscriptionMemberReceived,
  Cd as eventMeetSubscriptionRtcIceCandidateReceived,
  Ra as eventMeetSubscriptionRtcInitReceived,
  Ei as eventMeetSubscriptionRtcMemberReceived,
  Nr as eventMeetSubscriptionRtcMidiEventsReceived,
  Md as eventMeetSubscriptionRtcSDPReceived,
  pr as eventMeetSubscriptionSelfInitReceived,
  wi as eventMeetSubscriptionSelfReceived,
  _d as eventMeetSubscriptionSpaceReceived,
  $n as eventMeetSubscriptionStatusUpdated,
  wd as eventMeetSubscriptionVoteReceived,
  $l as eventMessageDeleteReset,
  Ul as eventMessageDeleteSelected,
  gl as eventMessageEditReset,
  fl as eventMessageEditSelected,
  Fl as eventMessageReplySelected,
  Zd as eventMicrophoneIconPressed,
  Ha as eventNegotiationNeeded,
  rr as eventNewAlert,
  za as eventNewAudioDeviceSelected,
  el as eventNewAudioDeviceSelectionCancelled,
  vm as eventOfferingStateUpdated,
  aa as eventOpenContextMenu,
  st as eventPeerFocused,
  Ai as eventRTCConnectionStateUpdated,
  Oi as eventRTCIceConnectionStateUpdated,
  ol as eventRTCIceGatheringStateUpdated,
  ll as eventRTCSignalingStateUpdated,
  Vl as eventResetChatHasNewMessages,
  $m as eventResetContextMenu,
  xa as eventRestartSubscription,
  hr as eventRoomReactionPanelReset,
  Pa as eventRoomReactionPanelToggle,
  br as eventRoomReactionReset,
  Ad as eventRoomReactionUpdate,
  Xd as eventScreenSharingIconPressed,
  Tl as eventSendMessage,
  ut as eventSpeakingUpdate,
  pt as eventStatsUpdated,
  _l as eventSubscriptionChatEditMessagesReceived,
  Pm as eventSubscriptionChatReactionCountReceived,
  Lm as eventSubscriptionChatReactionSelfReceived,
  wl as eventSubscriptionChatRemoveMessagesReceived,
  El as eventSubscriptionChatSentMessagesReceived,
  Yd as eventSwitchToNextCamera,
  qd as eventToggleCameraOnOff,
  Qn as eventToggleChat,
  Ol as eventToggleChatHasNewMessages,
  mt as eventToggleRecording,
  gd as eventToggleSettings,
  fd as eventToggleUsersList,
  Fd as eventToggleVotes,
  hn as eventUserSubscriptionAuthenticationChanged,
  _o as eventUserSubscriptionContactAdded,
  Ro as eventUserSubscriptionContactRemoved,
  Eo as eventUserSubscriptionMyPeerIdUpdated,
  $a as eventVideoTrackEnded,
  Gd as eventVirtualBackgroundCreated,
  jn as eventVoteCreateFormVisible,
  Mi as fetchMeetSpaceByIdOrVanityNameFx,
  Hn as fetchMessagesFromHistoryFx,
  xo as fetchUserMeetSpacesFx,
  Ri as fetchVotesFx,
  cl as formatMessage,
  jd as getCameraStreamFromVideoState,
  Sr as getRoomVariant,
  mS as getShowModalInitialState,
  Lr as getVirtualStreamFromVideoState,
  Pe as hangupFx,
  Td as mergePermissions,
  Zv as postVoteFx,
  ml as processSDPFx,
  Pi as recreateRTCPeerConnectionAfterReconnectFx,
  sl as restartGraphQLConnectionFx,
  An as sendChatMessageFx,
  Sm as sendIceCandidateFx,
  hl as sendMessageReactionFx,
  yl as sendMessageUnreactionFx,
  Qa as sendMessageUploadFilesFx,
  na as sendOfferFx,
  sr as subscribeToFatalError,
  xd as subscribeToMeetFx,
  Wk as subscribeToUserEventsFx,
  Kd as switchToNextCameraFx,
  Xa as updateAVStateFx,
  nS as useCardDimensions,
  FS as useConnectionStats,
  lS as useIsFullyConnected,
  fS as useMeSpeakingAnalyzer,
  eS as useMeetScreenDimensions,
  NS as useSpeakingAnalyzer,
  We as videoStateReducerFx
};
