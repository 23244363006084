import { $ as n } from "./apollo-RP1ZVEU8.js";
import { b as U, m as x, i as T, k as h, j as D, c as v, a as F, F as L, L as S, R as M, S as N, U as R, h as b, f as E, d as j, e as A, l as O, s as q } from "./apollo-RP1ZVEU8.js";
import { maybeData as o } from "@dmitry.olyenyov/remote-data";
import { useUnit as t } from "effector-react";
import { persist as z } from "effector-storage/local";
import { persist as C } from "effector-storage/session";
import "@apollo/client";
import "@apollo/client/dev";
import "@apollo/client/link/context";
import "@apollo/client/link/error";
import "@apollo/client/link/subscriptions";
import "effector";
import "jwt-decode";
function d() {
  const s = t(n);
  return o((e) => e, null, s);
}
function k() {
  const s = t(n);
  return o((e) => e.type === "authenticated" ? e.user : null, null, s);
}
function I() {
  const s = t(n);
  return o((e) => e, null, s);
}
export {
  U as $accessToken,
  x as $accessTokenExpireTimestamp,
  T as $anonymousNickname,
  h as $isLoggedIn,
  D as $myNickname,
  n as $myUserInfo,
  v as $refreshToken,
  F as $traceId,
  L as FetchMyUserInfoDocument,
  S as LoginDocument,
  M as RefreshTokenDocument,
  N as SignUpDocument,
  R as UserFragmentDoc,
  b as eventAnonymousNicknameUpdated,
  E as eventLogout,
  j as eventTokenRefreshed,
  A as eventTraceIdReceived,
  O as loginFx,
  z as persistLS,
  C as persistSES,
  q as signupFx,
  d as useMyInfo,
  k as useMyInfoOld,
  I as useMyNickname
};
